export const minLength = (_: any, value: number) => {
  if (
    value == null ||
    value.toString() == "" ||
    value >= 1000000000 ||
    isNaN(Number(value)) ||
    value.toString().length == 10
  ) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(`Value can't be less than 10 digits`));
};

export const minLengthNine = (_: any, value: number) => {
  if (
    value == null ||
    value.toString().trim() == "" ||
    value >= 100000000 ||
    isNaN(Number(value)) ||
    value.toString().length == 9
  ) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(`Value can't be less than 9 digits`));
};
