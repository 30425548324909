import axios from "axios";

// export function UploadDocumentsAction(payload: any,documentType: any){
//     return UploadDocuments(payload,documentType);
// }
export function UploadDocuments(payload: any, id: any, documentType: any) {
  return axios.post(
    "UploadDocuments?documentType=" + documentType + "&encryptedID=" + id,
    payload
  );
}
export function DocumentList(documentType: any, id: any) {
  return axios.get("documents?documentType=" + documentType + "&Id=" + id);
}

export function AllPdfDocuments(id: any) {
  return axios.get("allPdfDocuments?Id=" + id);
}

export function GetDocumentsCount(id: any) {
  return axios.get("DocumentsCount?Id=" + id);
}
export function DownloadDocument(id: number, documentType: any, encryptedId: string) {
  return axios.get(
    "downloaddocuments?id=" + id + "&documentType=" + documentType + "&encryptedID=" + encryptedId
  );
}
export function DeleteDocument(id: number) {
  return axios.delete("documents?id=" + id);
}
