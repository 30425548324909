import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/auth.slice";

const reducer = combineReducers({
  auth: authReducer,
});

let store = configureStore({
  reducer: reducer,
});

export default store;
