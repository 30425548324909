import React, { useState, useEffect } from "react";
import { getCommonAllLocations } from "../../actions/common";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { Form, Select } from "antd";

type StatesType = {
  id: string;
  name: string;
  abbreviations: string;
  _timeZone: string;
};

export interface StatesDropdownFormItemProps {
  showLabel?: boolean;
  noValidation?: boolean;
  useLocationIdsAsBackingValue?: boolean;
}
const StatesDropdownFormItem = (props: StatesDropdownFormItemProps) => {
  const [allStates, setAllStates] = useState<StatesType[]>([]);

  const filterOption = (
    input: string,
    option?: { label: string; key: string }
  ) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) ||
    (option?.key ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    getCommonAllLocations().then((res) => {
      var loc = res.data.responseData;
      setAllStates(loc);
    });
  }, []);

  return (
    <>
      <Form.Item
        name={props.useLocationIdsAsBackingValue ? "locationId" : "state"}
        label={props.showLabel && "State"}
        rules={
          props.noValidation ? undefined : [{ validator: validateBlankSpace }]
        }
      >
        <Select
          placeholder='State'
          optionFilterProp='children'
          showSearch
          onClick={(e) => e.preventDefault()}
          filterOption={filterOption}
          options={allStates.map(({ name, abbreviations, id }: StatesType) => ({
            label: name,
            value: props.useLocationIdsAsBackingValue ? id : abbreviations,
            key: abbreviations,
          }))}
        />
      </Form.Item>
    </>
  );
};

export default StatesDropdownFormItem;
