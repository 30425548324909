import React from "react";
import "../Loader/index.module.scss";

const Loader = (overrideClass: any = "") => {
  return (
    <>
      <div
        className={
          "d-flex justify-content-center align-items-center class_loader " +
          overrideClass?.overrideClass
        }
      >
        <div className='loader'></div>
      </div>
    </>
  );
};

export default Loader;
