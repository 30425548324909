import axios from "axios";

export function AddHearingCalendarEvent(payload: any) {
  return axios.post("HearingCalendarEvent", payload);
}

export function GetHearingCalendarEvents() {
  return axios.get("HearingCalendarEvent");
}

export function UpdateHearingCalendarEvent(payload: any) {
  return axios.put("HearingCalendarEvent", payload);
}

export function DeleteHearingCalendarEvent(id: any) {
  return axios.delete(`HearingCalendarEvent/${id}`);
}