import React from "react";
import AddressWithoutNameField from "./SharedFields/AddressWithoutNameField";
const FeeLetter = () => {
  return (
    <>
      <div className='row'>
        <label className='col-form-label'>
          <strong>Social Security Office Address</strong>
          <AddressWithoutNameField />
        </label>
      </div>
    </>
  );
};

export default FeeLetter;
