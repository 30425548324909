export const getShortString = (data: any, noOfCharacters: any) => {
  let length = data && data.length;
  if (length > noOfCharacters) {
    return data.substring(0, noOfCharacters) + "...";
  } else {
    return data ? data.substring(0, noOfCharacters) : "";
  }
};

export function convertNumberWithCommas(number: any) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getTextFromHtmlString(data: any) {
  if (data) {
    var span = document.createElement("span");
    span.innerHTML = data;
    return span.textContent || span.innerText;
  }
}

export function getOnlyShowMyCasesFromSearchParams(searchParams: any) {
  const onlyShowMyCasesSearchParam = searchParams.get("onlyShowMyCases");
  const onlyShowMyCases =
    !onlyShowMyCasesSearchParam || onlyShowMyCasesSearchParam == "null"
      ? "false"
      : onlyShowMyCasesSearchParam;

  return onlyShowMyCases;
}

export function CaseInsensitiveContains(
  firstString: string,
  lastString: string
) {
  return firstString?.toUpperCase().includes(lastString?.toUpperCase());
}

export function containsPhoneNumber(
  phoneNumber: string,
  searchQuery: string
): boolean {
  const phoneSpecialCharactersRegex = /[+\-()\s]/g;
  return searchQuery
    .trim()
    .replace(phoneSpecialCharactersRegex, "")
    .includes(phoneNumber?.trim().replace(phoneSpecialCharactersRegex, ""));
}

export function addCountryCode(
  phoneNumber: string,
  countryCode: string = "1"
): string {
  const num = phoneNumber?.trim();

  if (num?.slice(0, 2) === `+${countryCode}`) {
    return num;
  }

  if (num?.length === 11 && num[0] === countryCode) {
    return "+" + num;
  }

  return "+" + countryCode + phoneNumber;
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return "";
  }

  let cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

  if (cleanedPhoneNumber.length === 11 && cleanedPhoneNumber.startsWith("1")) {
    cleanedPhoneNumber = cleanedPhoneNumber.slice(1);
  }

  if (cleanedPhoneNumber.length === 10) {
    return `${cleanedPhoneNumber.slice(0, 3)}-${cleanedPhoneNumber.slice(
      3,
      6
    )}-${cleanedPhoneNumber.slice(6)}`;
  }
  return phoneNumber;
};
