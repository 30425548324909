import React from "react";
import OHOAddressInput from "./SharedFields/AddressWithoutNameField";
import { DatePicker, Form, Input } from "antd";
import { antdNoEmptyRule } from "../../../validations/validateBlankSpace";

const PriorRepWithdrawalAndWaiver = () => (
  <>
    <div className='row'>
      <Form.Item
        label="Prior Representative's First and Last Name"
        rules={[{ required: true }, antdNoEmptyRule]}
        name='priorRep'
      >
        <Input placeholder='Enter Name of Prior Rep' />
      </Form.Item>
    </div>
    <div className='row'>
      <label className='col-form-label'>
        <strong>OHO Address</strong>
        <OHOAddressInput />
      </label>
    </div>
  </>
);

export default PriorRepWithdrawalAndWaiver;
