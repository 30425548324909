import axios from "axios";

export type SaveClaimPayload = {
  ClaimTypeId: number;
  CaseLevelId: number;
  ClaimantMindsetUserId: number;
  RegisteredByMindsetUserId: number;
  initialApplicationOriginId: number;
};

export function SaveClaim(payload: SaveClaimPayload) {
  return axios.post("Claim", payload);
}

export function GetClaims(claimantMindsetUserId: number) {
  return axios.get(`GetClaims?claimantMindsetUserId=${claimantMindsetUserId}`);
}

export function GetClaimOptions() {
  return axios.options("Claim");
}

export function UpdateClaim(payload: any) {
  return axios.patch("Claim", payload);
}

export function DeleteClaim(claimId: number) {
  return axios.delete(`Claim?claimId=${claimId}`);
}
