import axios from "axios";

export function SaveMedicalHistory(payload: any) {
  return axios.post("MedicalHistory", payload);
}
export function GetMedicalHistory(id: any) {
  return axios.get("MedicalHistory?Id=" + id);
}
export function DeleteMedicalHistory(Id: any) {
  return axios.delete("MedicalHistory?Id=" + Id);
}
export function GetMedicalHistoryById(Id: any) {
  return axios.get("MedicalHistoryById?Id=" + Id);
}
export function UpdateMedicalHistory(payload: any) {
  return axios.put("MedicalHistory", payload);
}
