import {
  AddFacility,
  DeleteFacility,
  GetFacilities,
  GetFacilityById,
  UpdateFacility,
} from "../../apis/facilities";

export function addFacility(payload: any) {
  return AddFacility(payload);
}
export function getFacilities() {
  return GetFacilities();
}
export function getFacilityById(id: any) {
  return GetFacilityById(id);
}
export function updateFacility(payload: any) {
  return UpdateFacility(payload);
}
export function deleteFacility(id: any) {
  return DeleteFacility(id);
}
