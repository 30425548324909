import React, { useState, useEffect } from "react";
import "../index.scss";
import dwdtemp from "../../../images/download_temp.png";
import { Tooltip, Modal, Table } from "antd";
import info from "../../../images/info.png";
import {
  getCollegeHistory,
  getCollegeHistoryById,
  deleteCollegeHistory,
  updateCollegeHistory,
  saveCollegeHistory,
} from "../../../actions/collegeHistory";
import { FlashMessage } from "../../../utils/flash_message";
import { useSearchParams } from "react-router-dom";
import { Form, Input, DatePicker } from "antd";
import moment from "moment";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Constants } from "../../../common/constants";
import { validateBlankSpace } from "../../../validations/validateBlankSpace";
import Loader from "../../Loader";
import GooglePlacesAutocompleteWrapper from "../../GooglePlacesAutoCompleteWrapper";

const CollegeHistory = (props: any) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [searchParams] = useSearchParams();
  const [appdata, setAppData] = useState({});
  const [form] = Form.useForm();
  const [startDate, setstartDate] = useState(new Date());
  const [addressValue, setAddressValue] = useState<string | null>(null);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const dateFormat = Constants.dateFormat;
  const handleTextLength = (item: any) => {
    if (item != null) {
      return (
        <Tooltip title={item}>
          {item.substring(0, 20) + (item.length > 20 ? "......" : "")}
        </Tooltip>
      );
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "collegeName",
      key: "collegeName",
      render: handleTextLength,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: handleTextLength,
    },
    {
      title: "Start Date",
      dataIndex: "collegeStartDate",
      key: "collegeStartDate",
      render: (item: any, file: any) => {
        return item == null
          ? ""
          : moment.utc(item).local().format(Constants.monthYearFormat);
      },
    },
    {
      title: "End Date",
      dataIndex: "collegeEndDate",
      key: "collegeEndDate",
      render: (item: any, file: any) => {
        return item == null
          ? ""
          : moment.utc(item).local().format(Constants.monthYearFormat);
      },
    },
    {
      title: "Degrees/Certificates/Licenses",
      dataIndex: "degreesCertificateLicenses",
      key: "degreesCertificateLicenses",
      render: handleTextLength,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return (
          <div className='d-flex'>
            <Tooltip title={"Edit"}>
              <a className='mr-2' onClick={() => getCollegeHistoryByIdApi(id)}>
                <EditFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>

            <Tooltip title={"Delete"}>
              <a onClick={() => deleteCollegeHistoryApi(id)}>
                <DeleteFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const addUpdateCollegeHistoryApi = (e: any) => {
    var param = searchParams.get("id") ?? "0";
    var payload = {
      Id: isAdd ? 0 : e.id,
      CollegeName: e.collegeName,
      Address: addressValue,
      CollegeStartDate: moment(e.collegeStartDate).utc().format(),
      CollegeEndDate: moment(e.collegeEndDate).utc().format(),
      DegreesCertificateLicenses: e.degreesCertificateLicenses,
      EncryptedUserId: param,
    };
    if (!isAdd) {
      setIsPopUpLoader(true);
      updateCollegeHistory(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "College History updated Successfully.");
          setOpenEdit(false);
          setIsPopUpLoader(false);
          getCollegeHistoryApi();
        }
      });
    } else {
      setIsPopUpLoader(true);
      saveCollegeHistory(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "College History added Successfully.");
          setOpenEdit(false);
          setIsPopUpLoader(false);
          props.TemplateCount();
        }
      });
    }
  };

  const getCollegeHistoryByIdApi = (id: any) => {
    setIsPopUpLoader(true);
    setOpenEdit(true);
    setAddressValue(null);
    getCollegeHistoryById(id).then((res) => {
      var eduData = res.data.responseData;
      if (res.data.responseCode == 200) {
        var collegeStartDateValueStore =
          eduData.collegeStartDate == null
            ? null
            : moment.utc(eduData.collegeStartDate).local().format();
        eduData.collegeStartDate = collegeStartDateValueStore;
        var collegeEndDateValueStore =
          eduData.collegeEndDate == null
            ? null
            : moment.utc(eduData.collegeEndDate).local().format();
        eduData.collegeEndDate = collegeEndDateValueStore;
        setAppData(eduData);
        setIsPopUpLoader(false);
        setAddressValue(eduData.address ?? "");
        form.setFieldsValue(eduData);
      }
    });
  };
  const getCollegeHistoryApi = () => {
    setIsPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    var param = searchParams.get("id") ?? "0";
    getCollegeHistory(param)
      .then((res) => {
        var college = res.data.responseData;
        if (res.data.responseCode == 200) {
          setDocumentData(college);
          setIsPopUpLoader(false);
        } else {
          FlashMessage("error", res.data.responseMessage);
        }
      })
      .catch((Error) => { });
  };
  const deleteCollegeHistoryApi = (id: any) => {
    setIsPopUpLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteCollegeHistory(id).then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", response.data.responseMessage);
          setIsPopUpLoader(false);
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
          setIsPopUpLoader(false);
        }
        getCollegeHistoryApi();
        props.TemplateCount();
      });
    } else {
      setIsPopUpLoader(false);
    }
  };

  const datePickerOnChange = (date: any) => {
    if (date != null) {
      return moment(date).format();
    }
    return null;
  };
  const formSubmit = () => {
    form.submit();
  };
  const addPopUp = () => {
    setIsAdd(true);
    setOpenEdit(true);
    resetFormValues();
  };
  const resetFormValues = () => {
    var formDefaultValues = {
      collegeName: "",
      address: "",
      degreesCertificateLicenses: "",
      collegeEndDate: null,
      collegeStartDate: null,
    };

    setAddressValue(null);
    setTimeout(() => {
      setAppData(formDefaultValues);
      setAddressValue(formDefaultValues.address);
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };
  const predefinedValue = {
    label: addressValue,
    value: Constants.googleAutoCompleteStaticId,
  };
  return (
    <>
      {openEdit && (
        <Modal
          title={isAdd ? "Add College History" : "Update College History"}
          centered
          okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
          open={openEdit}
          onOk={() => (isPopUpLoader ? null : formSubmit())}
          onCancel={() => setOpenEdit(false)}
          width={1000}
        >
          <>
            <div className='col-xl-9 margin-auto'>
              <div className='row'>
                <div className='col-xl-12'>
                  {isPopUpLoader && <Loader></Loader>}
                  {!isPopUpLoader && (
                    <>
                      <p className='fill_description mt-2 text-center mb-1'>
                        Please enter the college/trade schools you’ve attended
                        after high school.
                      </p>
                      <Form
                        className='mt-4'
                        form={form}
                        name='CollegeHistoryForm'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={appdata}
                        autoComplete='off'
                        onFinish={addUpdateCollegeHistoryApi}
                      >
                        <div className='form-group row margin_mobile'>
                          <Form.Item name='id' />
                          <label className='col-form-label fill_description col-sm-4 des_font'>
                            1. College/Trade School Name
                          </label>
                          <div className='col-sm-8 p-0'>
                            <Form.Item
                              name='collegeName'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                onBlur={(ele: any) => {
                                  var item = ele.target.value.trim();
                                  ele.target.value = item;
                                }}
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-4 des_font'>
                            2. Address
                          </label>
                          <div className='col-sm-8 p-0'>
                            <Form.Item
                              name='address'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              {addressValue != null && (
                                <GooglePlacesAutocompleteWrapper
                                  apiKey={process.env.REACT_APP_API_KEY ?? ""}
                                  autocompletionRequest={{
                                    componentRestrictions: {
                                      country: ["us"],
                                    },
                                  }}
                                  selectProps={{
                                    value: predefinedValue,
                                    isClearable: false,
                                    onChange: (o: any) => {
                                      form.setFieldsValue({
                                        address: o?.label ?? "",
                                      });
                                      setAddressValue(o?.label ?? "");
                                    },
                                    onClose: (o: any) => {
                                      setAddressValue(o?.label ?? "");
                                    },
                                  }}
                                />
                              )}
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-4 des_font'>
                            3. Dates Attended
                          </label>
                          <div className='col-sm-3 p-0 date_month'>
                            <Form.Item
                              name='collegeStartDate'
                              getValueFromEvent={datePickerOnChange}
                              getValueProps={(i) => ({
                                value: i == null ? null : moment(i),
                              })}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                              ]}
                            >
                              <DatePicker
                                format={Constants.monthYearFormat}
                                picker='month'
                              />
                            </Form.Item>
                          </div>
                          <div className='col-sm-2 p-0'></div>
                          <div className='col-sm-3 p-0 date_month mobile_date'>
                            <Form.Item
                              name='collegeEndDate'
                              getValueFromEvent={datePickerOnChange}
                              getValueProps={(i) => ({
                                value: i == null ? null : moment(i),
                              })}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                              ]}
                            >
                              <DatePicker
                                format={Constants.monthYearFormat}
                                picker='month'
                                disabledDate={(d) =>
                                  d.isBefore(
                                    form.getFieldValue("collegeStartDate")
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-4 des_font'>
                            4. Degrees/Certificates/Licenses
                          </label>
                          <div className='col-sm-8 p-0'>
                            <Form.Item
                              name='degreesCertificateLicenses'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <p className='click_doc text-center'>
                          Click save to enter additional college/trade school
                          information
                        </p>
                      </Form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        </Modal>
      )}
      {open && (
        <Modal
          title='College History'
          centered
          footer={null}
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={1000}
        >
          {isPopUpLoader && <Loader></Loader>}
          {!isPopUpLoader && (
            <Table
              dataSource={documentData}
              pagination={{
                pageSize: Constants.gridDefaultPageSize,
                showSizeChanger: false,
              }}
              columns={columns}
            />
          )}{" "}
        </Modal>
      )}

      <div className='col-md-12 col-xl-4 mt-3 template_panel'>
        <div className='background_sq_boxes height_temp_box p-4 text-center position-relative'>
          <div className='popover__wrapper2 position-absolute'>
            <img className='img-fluid info' src={info} />
            <div className='popover__content3'>
              <p className='popover__message2'>
                {" "}
                List the college(s) you have attended.
              </p>
            </div>
          </div>
          <img className='img-fluid dws_temp' src={dwdtemp} />
          <h5 className='boxes_headings mb-3 ps-5 pe-5'>College History</h5>
          <button
            onClick={getCollegeHistoryApi}
            type='button'
            className={`btn btn-primary button_viewtemp`}
          >
            View
          </button>
          <button
            onClick={() => addPopUp()}
            type='button'
            className={`btn btn-primary button_edittemp mt-4`}
          >
            Add
          </button>
          {props.collegeHistoryCount == 1 ? (
            <p className='mr_record'>{props.collegeHistoryCount} record </p>
          ) : (
            <p className='mr_record'>{props.collegeHistoryCount} records</p>
          )}
        </div>
      </div>
    </>
  );
};

export default CollegeHistory;
