import {
  ClientUserAttributes,
  GetClientUserAttributes,
  GetLeadUserAttributes,
  LeadUserAttributes,
  ClientsWithNoLeadId,
  PipelineStatusWithNoLead,
  GetClientsByApplicationStatusId,
  GePipelineStatusById,
  GetLeadByApplicationStatusId,
  ProfileViewDetailsData,
  UnMapLead,
} from "../../apis/leadsAndClients";

export function gePipelineStatusById(pipelineId: any) {
  return GePipelineStatusById(pipelineId);
}
export function getLeadByApplicationStatusId(
  applicationStatusId: any,
  pageSize: number,
  pageNum: number,
  sortByDescending: boolean
) {
  return GetLeadByApplicationStatusId(
    applicationStatusId,
    pageSize,
    pageNum,
    sortByDescending
  );
}
export function pipelineStatusWithNoLead(
  pipelineId: any,
  onlyShowMyCases: any
) {
  return PipelineStatusWithNoLead(pipelineId, onlyShowMyCases);
}
export function getClientsByApplicationStatusId(
  applicationStatusId: any,
  pageSize: number,
  pageNum: number,
  sortByDescending: boolean,
  onlyShowMyCases: any
) {
  return GetClientsByApplicationStatusId(
    applicationStatusId,
    pageSize,
    pageNum,
    sortByDescending,
    onlyShowMyCases
  );
}
export function clientsWithNoLeadId() {
  return ClientsWithNoLeadId();
}
export function leadUserAttributes(payload: any) {
  return LeadUserAttributes(payload);
}
export function getLeadUserAttributes(Id: any) {
  return GetLeadUserAttributes(Id);
}
export function clientUserAttributes(payload: any) {
  return ClientUserAttributes(payload);
}
export function getClientUserAttributes(Id: any) {
  return GetClientUserAttributes(Id);
}
export function profileViewDetails(payload: any) {
  return ProfileViewDetailsData(payload);
}
export function unMapLead(Id: any) {
  return UnMapLead(Id);
}