import React, { useState } from "react";
import NameInput from "./SharedFields/NameField";
import { Checkbox, Form } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { TemplateOption } from "..";
import Loader from "../../../components/Loader";
import AddressWithNameField from "./SharedFields/AddressWithNameField";

interface RFCRequestProps {
  templates: TemplateOption[];
}

const RFCRequest = ({ templates }: RFCRequestProps) => {
  const [checkedValues, setCheckedValues] = useState<CheckboxValueType[]>([]);

  const handleChange = (checkedList: CheckboxValueType[]) => {
    setCheckedValues(checkedList);
  };

  return (
    <>
      <div className='row'>
        <NameInput
          label="Physician's First and Last Name"
          placeholder="Enter Physician's Name"
          name='physicianName'
        />
      </div>
      <div className='row'>
        <label className='col-form-label'>
          <strong>Medical Facility</strong>
        </label>
        <AddressWithNameField label='Medical Facility Name' />
      </div>
      <div className='row'>
        {templates && templates.length ? (
          <Form.Item label='Select RFC Forms to Enclose' name='EnclosedFormIds'>
            <Checkbox.Group
              className={"d-flex flex-column mh-100"}
              onChange={handleChange}
              options={templates}
              value={checkedValues}
            />
          </Form.Item>
        ) : (
          <Loader overrideClass='loaderLeftPanelHeight'></Loader>
        )}
      </div>
    </>
  );
};

export default RFCRequest;
