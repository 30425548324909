import axios from "axios";

// export function GetGlobalTask(assignTo:any, assignBy:any, startDate:any, endDate:any ,priorityId:any,typeId:any,statusId :any) {
//     return axios.get("GlobalTask?assignTo="+ assignTo + "&assignBy=" + assignBy + "&startDate=" + startDate + "&endDate=" + endDate + "&priorityId=" + priorityId + "&typeId=" + typeId + "&statusId=" + statusId);
// }
export function GetGlobalTask(payload: any) {
  return axios.post("GetGlobalTask", payload);
}
export function SaveGlobalTask(payload: any) {
  return axios.post("GlobalTask", payload);
}
export function UpdateGlobalTask(payload: any) {
  return axios.put("GlobalTask", payload);
}
export function NotificationContactMessage(payload: any) {
  return axios.post("NotificationContactMessage", payload);
}
export function DeleteGlobalTask(id: any) {
  return axios.delete("GlobalTask?Id=" + id);
}
export function GetTaskPriority() {
  return axios.get("TaskPriority");
}
export function GetTaskStatus() {
  return axios.get("TaskStatus");
}
export function GetGlobalTaskById(id: any) {
  return axios.get("GlobalTaskById?Id=" + id);
}
export function GetTaskType() {
  return axios.get("TaskType");
}
export function GetClientsData() {
  return axios.get("GetClientsData");
}
export function GetLeadsData() {
  return axios.get("GetLeadsData");
}
export function GetNotificationMessage(ClaimantId: any) {
  return axios.get("GetNotificationMessage?ClaimantId=" + ClaimantId);
}
export function CompletedNotification(Id: any) {
  return axios.put("CompletedNotification?Id=" + Id);
}
export function SaveGlobalTaskNotes(payload: any) {
  return axios.post("SaveGlobalTaskNotes", payload);
}
