import { useRef, useEffect } from "react";
import { Constants } from "../constants";
import { useNavigate, useLocation } from "react-router-dom";
import { get } from "../../utils/clientStorageUtils";

export default function useCanWriteAndEnforcePagePermissions(): boolean {
  const location = useLocation();
  const navigate = useNavigate();
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const canReadRef = useRef(false);
  const canWriteRef = useRef(false);

  useEffect(() => {
    if (!canReadRef.current) navigate(`/${Constants.pageRoutes.leads}`);
  }, []);

  switch (location.pathname.substring(1).toUpperCase()) {
    case Constants.pageRoutes.notificationconfiguration.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.notificationConfiguration.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.notificationConfiguration.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.notificationTemplates.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.notificationTemplate.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.notificationTemplate.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.sendFax.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.sendFax.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.sendFax.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.generateDocuments.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.generateDocuments.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.generateDocuments.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.sendPhysicalMail.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.sendPhysicalMail.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.sendPhysicalMail.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.logs.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.logs.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.logs.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.tenants.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.tenantManagement.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.tenantManagement.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.manageUser.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.manageUser.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.manageUser.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.caseManagement.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.caseManagement.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.caseManagement.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.application.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.claimantProfileSensitiveData.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.claimantProfileSensitiveData.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.leadNotes.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.leadNotes.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.leadNotes.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.clientsnotes.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.claimantNotes.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.claimantNotes.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.manageMedication.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.manageMedication.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.manageMedication.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.featureFlagSettings.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.manageFeatureFlagSettings.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.manageFeatureFlagSettings.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.prequalification.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.claimantProfileSensitiveData.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.claimantProfileSensitiveData.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.prequalificationclient.toUpperCase(): {
      canReadRef.current =
        Constants.permissionedRolesBySection.claimantProfileSensitiveData.readGroup.includes(
          roleInStorage
        );
      canWriteRef.current =
        Constants.permissionedRolesBySection.claimantProfileSensitiveData.writeGroup.includes(
          roleInStorage
        );
      break;
    }
    case Constants.pageRoutes.uploadDocuments.toUpperCase(): {
      canReadRef.current =
          Constants.permissionedRolesBySection.uploadDocuments.readGroup.includes(
              roleInStorage
          );
      canWriteRef.current =
          Constants.permissionedRolesBySection.uploadDocuments.writeGroup.includes(
              roleInStorage
          );
      break;
  }
  case Constants.pageRoutes.templates.toUpperCase(): {
      canReadRef.current =
          Constants.permissionedRolesBySection.templates.readGroup.includes(
              roleInStorage
          );
      canWriteRef.current =
          Constants.permissionedRolesBySection.templates.writeGroup.includes(
              roleInStorage
          );
      break;
  }
  case Constants.pageRoutes.facilities.toUpperCase(): {
    canReadRef.current =
        Constants.permissionedRolesBySection.facilities.readGroup.includes(
            roleInStorage
        );
    canWriteRef.current =
        Constants.permissionedRolesBySection.facilities.writeGroup.includes(
            roleInStorage
        );
    break;
}
  case Constants.pageRoutes.requestedInfo.toUpperCase(): {
    canReadRef.current =
        Constants.permissionedRolesBySection.requestedInfo.readGroup.includes(
            roleInStorage
        );
    canWriteRef.current =
        Constants.permissionedRolesBySection.requestedInfo.writeGroup.includes(
            roleInStorage
        );
    break;
}
    default: {
      throw new Error(
        `Route: ${location.pathname} not mapped to permissions routing`
      );
    }
  }
  return canWriteRef.current;
}
