import axios from "axios";

export function SaveMedicationDetails(payload: any) {
  return axios.post("MedicationDetails", payload);
}
export function GetMedicationDetails(id: any) {
  return axios.get("MedicationDetails?Id=" + id);
}
export function GetMedicationDetailsById(id: any) {
  return axios.get("MedicationDetailsById?Id=" + id);
}
export function DeleteMedicationDetails(Id: any) {
  return axios.delete("MedicationDetails?Id=" + Id);
}
export function UpdateMedicationDetails(payload: any) {
  return axios.put("MedicationDetails", payload);
}
