import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagic, faPhone } from "@fortawesome/free-solid-svg-icons";
import drp_tab from "../../../../images/drp_tab.png";
import profile_new from "../../../../images/profile_new.png";
import { formatPhoneNumber } from "../../../../utils/commonHelper";
import { Constants } from "../../../../common/constants";
import axios from "axios";
import { FlashMessage } from "../../../../utils/flash_message";
import "../style.css";
import { SupportUser } from "./ClaimantAssignments";

const { confirm } = Modal;

const compareByName = (a: SupportUser, b: SupportUser) => {
  const nameA = a.firstName.toUpperCase(); // ignore case
  const nameB = b.firstName.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

type ClaimantAssignment = {
  id: number;
  claimantMindsetUserId: number;
  caseManagerMindsetUserId: number;
  lawyerMindsetUserId: number;
  intakeSpecialistMindsetUserId: number;
  version: string;
};

const supportUserTypes = [
  Constants.appUserRoles.caseManager,
  Constants.appUserRoles.admin,
  Constants.appUserRoles.superUser,
  Constants.appUserRoles.intakeMsr,
];

type ClaimaintAssignmentType = "Case Manager" | "Lawyer" | "Intake Specialist";

const assignmentTypeToKey: {
  [key in ClaimaintAssignmentType]:
    | "caseManagerMindsetUserId"
    | "lawyerMindsetUserId"
    | "intakeSpecialistMindsetUserId";
} = {
  "Case Manager": "caseManagerMindsetUserId",
  Lawyer: "lawyerMindsetUserId",
  "Intake Specialist": "intakeSpecialistMindsetUserId",
};

const AssignmentDropdown = ({
  appData,
  type,
  claimantAssignment,
  getClaimaintAssignment,
  supportUsers,
}: {
  appData: any;
  type: ClaimaintAssignmentType;
  claimantAssignment: ClaimantAssignment;
  getClaimaintAssignment: Function;
  supportUsers: SupportUser[];
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [assignedUser, setAssignedUser] = useState<any>(null);

  const updateAssignment = async (id: number) => {
    try {
      setIsUpdating(true);
      if (claimantAssignment) {
        await axios.patch(
          `ClaimantAssignment?claimantMindsetUserId=${appData.mindsetUserId}`,
          {
            id: claimantAssignment.id,
            [assignmentTypeToKey[type]]: id,
            version: claimantAssignment.version,
          }
        );
      } else {
        await axios.post(`ClaimantAssignment`, {
          ClaimantMindsetUserId: appData.mindsetUserId,
          [assignmentTypeToKey[type]]: id,
        });
      }
      await getClaimaintAssignment();
      FlashMessage("success", `${type} has successfully been updated.`);
    } catch (err) {
      setIsUpdating(false);
    }
  };

  const onAutoAssign = async () => {
    try {
      setIsUpdating(true);
      await axios.post(`ClaimantAssignmentAutoAssignCaseManager`, {
        ClaimantMindsetUserId: appData.mindsetUserId,
      });
      await getClaimaintAssignment();
      FlashMessage("success", "A case manager has been auto-assigned!");
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (claimantAssignment) {
      const assigned_user = supportUsers.find(
        (user: SupportUser) =>
          user.id === claimantAssignment[assignmentTypeToKey[type]]
      );
      setAssignedUser(assigned_user);
    }
  }, [claimantAssignment]);

  const options = supportUsers.filter((item: any) =>
    type === "Case Manager"
      ? supportUserTypes.includes(item.supportUserType)
      : type === "Intake Specialist"
      ? item.supportUserType === Constants.appUserRoles.intakeMsr
      : item.supportUserType === Constants.appUserRoles.lawyer
  );

  return (
    <div className='assignment-component'>
      <span className='text-nowrap'>Assigned {type}:</span>
      <span className='mail_log'>
        <Dropdown
          className='w-30 ant_drp caseManagerButton'
          onToggle={() => setSearchText("")}
        >
          <Dropdown.Toggle
            disabled={isUpdating}
            variant='success'
            id='dropdown-basic'
            className='border-none-class p-0'
          >
            <button
              className='btn btn-secondary dropdown-toggle button_clients'
              type='button'
              id='assignCaseManagerButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span>
                {isUpdating ? (
                  <>
                    <Spinner size='sm' /> Updating
                  </>
                ) : (
                  (
                    <>
                      <img
                        className=' profile_image profileImg'
                        src={profile_new}
                      />
                      {assignedUser ? (
                        <Tooltip
                          title={
                            <a
                              className='text-white align-items-center d-flex gap-1 flex-row'
                              href={`tel:${assignedUser.phoneNumber}`}
                              onClick={(e) => e.stopPropagation()}
                              key={"1234"}
                            >
                              <FontAwesomeIcon icon={faPhone} />
                              {formatPhoneNumber(assignedUser.phoneNumber)}
                            </a>
                          }
                        >
                          <span>
                            {assignedUser.firstName} {assignedUser.lastName}
                          </span>
                        </Tooltip>
                      ) : (
                        <span>Click to assign</span>
                      )}
                      <img className='img-fluid dropdown-tab' src={drp_tab} />
                    </>
                  ) || `Assign a ${type}`
                )}
              </span>
            </button>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {options.length > 0 ? (
              <>
                <div className='assignment-dropdown-search-container'>
                  <input
                    type='text'
                    placeholder='Search...'
                    className='form-control'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <div className='assignment-dropdown-options-list'>
                  {type === "Case Manager" && (
                    <Dropdown.Item className='item_drp auto-assign-option'>
                      <h5
                        onClick={() =>
                          confirm({
                            className: "brand-modal",
                            title: "Auto-Assign Case Manager",
                            content:
                              "Do you want to auto assign a case manager? We will pick an available case manager for you.",
                            onOk() {
                              return onAutoAssign();
                            },
                            onCancel() {},
                          })
                        }
                        className='heading_tab'
                      >
                        <FontAwesomeIcon icon={faMagic} /> Auto-Assign
                      </h5>
                    </Dropdown.Item>
                  )}
                  {options
                    .sort(compareByName)
                    .filter((user) => user.isActive)
                    .map((ele: SupportUser) => {
                      const fullName = `${ele.firstName} ${ele.lastName}`;
                      return fullName
                        .toLowerCase()
                        .includes(searchText.trim().toLowerCase()) ? (
                        <Dropdown.Item key={ele.id} className='item_drp'>
                          <h5
                            onClick={() =>
                              confirm({
                                className: "brand-modal",
                                title: `Update ${type}`,
                                content: `Are you sure you want to change the ${type} to ${fullName}?`,
                                onOk() {
                                  return updateAssignment(ele.id);
                                },
                                onCancel() {},
                              })
                            }
                            className='heading_tab'
                          >
                            {fullName}
                          </h5>
                        </Dropdown.Item>
                      ) : null;
                    })}
                </div>
              </>
            ) : (
              <div className='text-center'>
                <Spinner size='sm' />
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </span>
    </div>
  );
};

export default AssignmentDropdown;
