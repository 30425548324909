import { Card, Tag, Typography } from "antd";
import { FileTextFilled } from "@ant-design/icons";
import moment from "moment";
import profile_new from "../../../images/profile_new.png";
import BrandButton from "../../BrandButton";
import { updateClaim } from "../../../actions/claims";
import { get } from "../../../utils/clientStorageUtils";
import { useState } from "react";
import { FlashMessage } from "../../../utils/flash_message";
import { Constants } from "../../../common/constants";
import { ClaimOptions, updateClaimPayloads } from "./Claims/constants";

const { Title } = Typography;

const claim_names: {
  [key: string]: string;
} = {
  Ssi: "Supplemental Security Income",
  Ssdi: "Social Security Disability Insurance",
};

export const UserRow = ({
  label,
  displayName,
}: {
  label: string;
  displayName: string;
}) => {
  return (
    <p>
      {label}{" "}
      <span>
        <img
          height='24'
          className='img-fluid profile_image profileImg'
          src={profile_new}
        />
        {displayName}
      </span>
    </p>
  );
};

const TimestampRow = ({
  label,
  timestamp,
}: {
  label: string;
  timestamp: string;
}) => {
  return (
    <p>
      {label}{" "}
      <span>
        {moment.utc(timestamp).local().format("MMMM DD[,] YYYY h:mm A")}
      </span>
    </p>
  );
};

const ClaimCard = ({
  claim,
  getClaimantClaims,
  claimOptions,
}: {
  claim: any;
  getClaimantClaims: Function;
  claimOptions: ClaimOptions;
}) => {
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const [isConfirmingFax, setIsConfirmingFax] = useState(false);

  const getClaimBadge = () => {
    if (claim.caseLevelId === 1) {
      return "Intake Incomplete";
    } else if (
      claim.caseLevelId === 2 &&
      claim.intakeCompletedByMindsetUserId
    ) {
      return "Claim Not Filed";
    } else if (claim.caseLevelId === 2 && !claim.Faxed8001ByMindsetUserId) {
      return "8001 Not Faxed";
    } else if (claim.caseLevelId >= 3) {
      return "ERE Sync Pending";
    }
    return "getClaimBadge TODO";
  };

  const getClaimStatus = () => {
    if (!claim.intakeCompletedByMindsetUserId) {
      return "Registration Complete";
    } else if (claim.caseLevelId === 2) {
      return "Information Collected";
    } else if (claim.caseLevelId === 3 && !claim.isEreSynced) {
      return "Case Level: Initial (Assumed)";
    } else if (claim.iaSubmittedByMindsetUserId) {
      return `Case Level: ${
        claimOptions.caseLevels.find((level) => level.id === claim.caseLevelId)
          ?.name
      }`;
    }
    return "getClaimStatus TODO";
  };

  const confirmFax = async () => {
    setIsConfirmingFax(true);
    const payload = updateClaimPayloads("CONFIRM_FAX", claim, claim.isEligible);
    await updateClaim(payload);
    FlashMessage("The claim has been updated successfully.", "success");
    await getClaimantClaims();
    setIsConfirmingFax(false);
  };

  const displayName = claim.ClaimTypeIdDisplayNameReadOnly;

  return (
    <Card
      className={`${
        claim.isEligible ? "claim-card" : "claim-not-eligible-card"
      }`}
      data-cy={`claim-card-${displayName}`}
    >
      <Tag
        className={claim.isEligible ? "brand-tag" : "brand-not-eligible-tag"}
        data-cy={`claim-badge-${displayName}`}
        icon={<FileTextFilled />}
      >
        {getClaimBadge()}
      </Tag>
      <Title
        level={5}
        className={`claim-title ${
          !claim.isEligible ? "not-eligible-text" : ""
        }`}
      >
        {claim_names[displayName]}
      </Title>
      {claim.isEligible ? (
        <>
          <p data-cy={`claim-status-${displayName}`}>{getClaimStatus()}</p>
          {claim.intakeCompletedByMindsetUserId && (
            <div className='intake-section'>
              <UserRow
                label='Intake Completed by:'
                displayName={claim.intakeCompletedByDisplayNameReadOnly}
              />
              <TimestampRow
                label='Intake Completed on:'
                timestamp={claim.intakeCompletedDateTimeUtc}
              />
            </div>
          )}
          {claim.iaSubmittedByMindsetUserId && (
            <div className='intake-section'>
              <UserRow
                label='IA Submitted by:'
                displayName={claim.iaSubmittedByDisplayNameReadOnly}
              />
              <TimestampRow
                label='IA Submitted on:'
                timestamp={claim.iaSubmittedDateTimeUtc}
              />
              {claim.faxed8001ByDisplayNameReadOnly && (
                <UserRow
                  label='8001 Faxed by:'
                  displayName={claim.faxed8001ByDisplayNameReadOnly}
                />
              )}
              {claim.faxed8001DateTimeUtc && (
                <TimestampRow
                  label='Faxed on:'
                  timestamp={claim.faxed8001DateTimeUtc}
                />
              )}
            </div>
          )}
          {claim.caseLevelId === 2 &&
            claim.iaSubmittedByMindsetUserId &&
            !claim.faxed8001ByDisplayNameReadOnly && (
              <BrandButton
                isLoading={isConfirmingFax}
                onClick={confirmFax}
                disabled={!Constants.caseManagerGroup.includes(roleInStorage)}
                className='mt-2'
                label='Confirm 8001 Faxed'
              />
            )}
        </>
      ) : (
        <p className={`${!claim.isEligible ? "not-eligible-text" : ""}`}>
          <b>Not eligible</b>
        </p>
      )}
    </Card>
  );
};

export default ClaimCard;
