import axios from "axios";

export function SaveNotificationConfiguration(payload: any, form: any) {
  return axios.post("NotificationConfiguration", payload);
}
export function GetNotificationConfiguration() {
  return axios.get("NotificationConfiguration");
}
export function GetNotificationConfigurationById(id: any) {
  return axios.get("NotificationConfigurationById?Id=" + id);
}
export function UpdateNotificationConfiguration(payload: any) {
  return axios.put("NotificationConfiguration", payload);
}
export function DeleteNotificationConfiguration(id: any) {
  return axios.delete("NotificationConfiguration?Id=" + id);
}
