import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { Form, Input, Modal, Table, Tooltip, Select, Space } from "antd";
import { DeleteFilled, EditFilled, CopyOutlined } from "@ant-design/icons";
import { Constants } from "../../common/constants";
import {
  number,
  validateZipCode,
} from "../../validations/numeric_only-validate";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { minLength } from "../../validations/validate_min_length";
import { getTenant } from "../../actions/tenants";
import { FlashMessage } from "../../utils/flash_message";
import Loader from "../../components/Loader";
import {
  CaseInsensitiveContains,
  formatPhoneNumber,
} from "../../utils/commonHelper";
import useCanWriteAndEnforcePagePermissions from "../../common/customHooks/useCanWriteAndEnforcePagePermissions";
import GooglePlacesAutocompleteWrapper from "../../components/GooglePlacesAutoCompleteWrapper";
import {
  addFacility,
  deleteFacility,
  getFacilities,
  getFacilityById,
  updateFacility,
} from "../../actions/facilities";
import { GetFacilityById } from "../../apis/facilities";

const { Option } = Select;

interface Tenant {
  code: string;
  confirmationMessage: string;
  displayName: string;
  id: number;
  isFAQNeeded: boolean;
  isHeaderNeeded: boolean;
  isFooterNeeded: number;
  isIncludeCalendlySchedule: boolean;
  isIncludeReleaseDate: boolean;
  logo: string;
  name: string;
  parentID: number;
  phone: string;
}

interface Facility {
  name: string;
  address: string;
  phoneNumber: string;
  ipAddress: string;
  zipCode: string;
  facilityID: string;
  description: string;
  tenantId: string;
}

const { TextArea } = Input;
const Facilities = () => {
  const canWrite = useCanWriteAndEnforcePagePermissions();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [form] = Form.useForm();
  const [isLoader, setIsLoader] = useState(false);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const [logo, setLogo] = useState("");
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [filteredFacilities, setFilteredFacilities] = useState<Facility[]>([]);
  const [searchString, setSearchString] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [debouncedSearchString] = useDebounce(
    searchString,
    Constants.debounceTime
  );
  const [isAdd, setIsAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [userById, setUserById] = useState({});
  const [url, setUrl] = useState(process.env.REACT_APP_BASE_URL);
  const [fileForm, setFileForm] = useState(null);
  const [ipAddresses, setIPAddresses] = useState<string[]>([]);
  const formSubmit = () => {
    form.submit();
  };

  const isValidIP = (ip: string) => {
    const ipRegex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipRegex.test(ip);
  };

  const getFacilityByIdApi = (id: any) => {
    setIsPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    getFacilityById(id).then((res) => {
      setIsPopUpLoader(false);
      var appRes = res.data;
      console.log(appRes);
      if (appRes.logo != null && appRes.logo != "") {
        setLogo(appRes?.logo);
      }
      setUserById(appRes);
      setAddressValue(appRes.address);
      const ipData = appRes.ipAddress === "" ? [] : appRes.ipAddress.split(",");
      setIPAddresses(ipData);
      form.setFieldsValue({
        ...appRes,
        ipAddress: ipData,
      });
      console.log(appRes);
    });
  };

  const deleteFacilityApi = (id: any) => {
    setIsLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteFacility(id).then((response) => {
        if (response.data.responseCode == 200) {
          setIsLoader(false);
          getFacilitiesApi();
          FlashMessage("success", response.data.responseMessage);
        } else if (response.data.responseCode != 200) {
          setIsLoader(false);
          FlashMessage("error", response.data.responseMessage);
        }
        getTenantApi();
      });
    } else {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    setFilteredFacilities(
      [...facilities].filter((facility) => {
        return (
          CaseInsensitiveContains(facility.name, debouncedSearchString) ||
          CaseInsensitiveContains(facility.address, debouncedSearchString) ||
          CaseInsensitiveContains(facility.facilityID, debouncedSearchString) ||
          CaseInsensitiveContains(facility.ipAddress, debouncedSearchString) ||
          CaseInsensitiveContains(
            facility.zipCode.toString(),
            debouncedSearchString
          )
        );
      })
    );
  }, [facilities, debouncedSearchString]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Facility ID",
      dataIndex: "facilityID",
      key: "facilityID",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      key: "ipAddress",
      render: (ipAddress: string) =>
        ipAddress.split(",").map((address) => <div>{address}</div>),
    },
    {
      title: "ZipCode",
      dataIndex: "zipCode",
      key: "zipCode",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (number: string) => {
        return (
          number && (
            <a href={`tel:${formatPhoneNumber(number)}`}>
              {formatPhoneNumber(number)}
            </a>
          )
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        if (canWrite) {
          return (
            <div className='d-flex'>
              <Tooltip title={"Edit"}>
                <a className='mr-2' onClick={() => getFacilityByIdApi(id)}>
                  <EditFilled
                    style={{
                      fontSize: "25px",
                      color: "#747497",
                    }}
                  />
                </a>
              </Tooltip>
              <Tooltip title={"Delete"}>
                <a className='mr-2' onClick={() => deleteFacilityApi(id)}>
                  <DeleteFilled
                    style={{
                      fontSize: "25px",
                      color: "#747497",
                    }}
                  />
                </a>
              </Tooltip>
            </div>
          );
        }
      },
    },
  ];
  const onkeyup = (e: any) => {
    var onlyNumber = e.target.value
      .split("(")
      .join("")
      .split("-")
      .join("")
      .split(")")
      .join("")
      .split(" ")
      .join("");
    if (onlyNumber.length > 10) {
      onlyNumber = onlyNumber.substring(0, 10);
    }
    form.setFieldsValue({ phone: onlyNumber });
  };

  const addPopUp = () => {
    setIsAdd(true);
    setOpen(true);
    resetFormValues();
  };

  const resetFormValues = () => {
    var formDefaultValues = {
      name: "",
      facilityID: "",
      address: "",
      ipAddress: [],
      phoneNumber: "",
      zipCode: "",
      description: "",
      tenantId: null,
    };
    setIPAddresses([]);
    setAddressValue("");
    setTimeout(() => {
      setUserById(formDefaultValues);
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };

  const addUpdateFacility = (e: any) => {
    var payload = e;
    payload.ipAddress = e.ipAddress.join(",");
    payload.tenantId = Number.parseInt(e.tenantId);
    if (isAdd) {
      setIsPopUpLoader(true);
      addFacility(payload).then((res) => {
        FlashMessage("success", "Facility added Successfully.");
        setOpen(false);
        setIsPopUpLoader(false);
        getFacilitiesApi();
      });
    } else {
      setIsPopUpLoader(true);
      updateFacility(payload).then((res) => {
        FlashMessage("success", "Facility updated Successfully.");
        setOpen(false);
        setIsPopUpLoader(false);
        getFacilitiesApi();
      });
    }
  };
  const cancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const getTenantApi = () => {
    getTenant().then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        setTenants(userdata);
      }
    });
  };

  const getFacilitiesApi = () => {
    setIsLoader(true);
    setIsAdd(true);
    getFacilities().then((res) => {
      if (res.data.responseCode == 200) {
        setIsLoader(false);
        setFacilities(res.data.responseData);
      }
    });
  };

  useEffect(() => {
    getFacilitiesApi();
    getTenantApi();
  }, []);

  const predefinedValue = {
    label: addressValue,
    value: Constants.googleAutoCompleteStaticId,
  };

  return (
    <>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <>
          <Modal
            title={isAdd ? "Add Facility" : "Update Facility"}
            centered
            okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
            open={open}
            onOk={() => (isPopUpLoader ? null : formSubmit())}
            onCancel={() => cancel()}
            width={1000}
          >
            {isPopUpLoader && <Loader></Loader>}
            {!isPopUpLoader && (
              <Form
                className='mt-4'
                form={form}
                name='TenantForm'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={userById}
                autoComplete='off'
                onFinish={addUpdateFacility}
              >
                <div>
                  <Form.Item name='id' />
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      Facility Name
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='name'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      Facility ID
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='facilityID'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      Address of Facility
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='address'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        {addressValue != null && (
                          <GooglePlacesAutocompleteWrapper
                            key={`google_place_autocomplete_${addressValue}`}
                            apiKey={process.env.REACT_APP_API_KEY ?? ""}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              value: predefinedValue,
                              isClearable: false,
                              onChange: (o: any) => {
                                form.setFieldsValue({
                                  address: o?.label ?? "",
                                });
                                setAddressValue(o?.label ?? "");
                              },
                              onclose: (o: any) => {
                                setAddressValue(o?.label ?? "");
                              },
                            }}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      Phone Number
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='phoneNumber'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          { validator: number },
                          {
                            validator: validateBlankSpace,
                          },
                          { validator: minLength },
                        ]}
                      >
                        <Input
                          onKeyUpCapture={onkeyup}
                          maxLength={15}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      ZipCode
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='zipCode'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          { validator: number },
                          {
                            validator: validateBlankSpace,
                          },
                          {
                            validator: validateZipCode,
                          },
                        ]}
                      >
                        <Input
                          maxLength={5}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      Tenant
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item name='tenantId'>
                        <Select
                          className='asigne-input'
                          style={{ width: "100%" }}
                          placeholder='Tenant'
                          optionLabelProp='label'
                          showSearch
                          optionFilterProp='label'
                        >
                          {tenants
                            .sort((a: any, b: any) =>
                              a.name.localeCompare(b.name)
                            )
                            .map((ele: Tenant) => {
                              return (
                                <>
                                  <Option value={ele.id} label={ele.name}>
                                    <Space> {ele.name}</Space>
                                  </Option>
                                </>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      IP Address
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item name='ipAddress'>
                        <Select
                          className='asigne-input'
                          mode='tags'
                          style={{ width: "100%" }}
                          placeholder='Input IP addresses.'
                          optionLabelProp='label'
                          showSearch
                          value={ipAddresses}
                          onChange={(values: string[]) => {
                            const validValues = values.filter(isValidIP);
                            setIPAddresses([...validValues]);
                            form.setFieldValue("ipAddress", [...validValues]);
                          }}
                          optionFilterProp='label'
                          tokenSeparators={[","]}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      Description
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item name='description'>
                        <TextArea rows={4} maxLength={5000} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Modal>
          <div className='d-flex justify-content-end'>
            <div className='col-md-4 mb-4'>
              <div className='d-flex justify-content-end mt-5'>
                <Input
                  placeholder='Search enterprises...'
                  className={"input_style"}
                  style={{
                    borderRadius: "5px",
                    marginRight: "20px",
                  }}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
                {canWrite && (
                  <button
                    type='button'
                    onClick={() => addPopUp()}
                    className='btn btn-primary button_signup button_clients button_add_padding px-5'
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
          <Table
            dataSource={filteredFacilities}
            pagination={{
              pageSize: Constants.logsDefaultPageSize,
              showSizeChanger: false,
              showTotal: (total) => {
                return `Total Count:  ${total}`;
              },
            }}
            columns={columns}
          />
        </>
      )}
    </>
  );
};

export default Facilities;

export function base64ToArrayBuffer(base64: any) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}
