import React from "react";
import { Form, Input } from "antd";
import { saveChangePassword } from "../../actions/users";
import { useNavigate } from "react-router-dom";
import { passwordMatch } from "../../validations/password_match";
import { validatePassword } from "../../validations/validatePassword";
import { FlashMessage } from "../../utils/flash_message";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const saveChangePasswordApi = (e: any) => {
    var payload = {
      OldPassword: e.oldPassword,
      NewPassword: e.password,
    };
    saveChangePassword(payload).then((res) => {
      if (res.data.responseCode == 303) {
        FlashMessage("error", "Old Password does not match.");
      }
      if (res.data.responseCode == 200) {
        FlashMessage("success", "Password changed Successfully.");
        navigate("/leads");
      }
    });
  };
  return (
    <>
      <section id='change_password' className='change_password mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 p-0'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='signup_modal'>
                    <div className='sign_up_details'>
                      <h2 className='heading_signup text-center'>
                        <strong>Change Password!</strong>
                      </h2>
                      <p className='fill_info mt-3 text-center mb-1'>
                        Please fill in the below to Change Password:
                      </p>
                      <div className='row'>
                        <div className='col-xl-2'></div>
                        <div className='col-xl-8'>
                          <Form
                            className='mt-4'
                            form={form}
                            name='ChangePasswordForm'
                            labelCol={{ span: 24 }}
                            wrapperCol={{
                              span: 24,
                            }}
                            autoComplete='off'
                            onFinish={saveChangePasswordApi}
                          >
                            <div className='form-group row margin_mobile justify-content-center align-items-center'>
                              <label className='col-form-label fill_description col-sm-3 text-align_dsk des_font'>
                                Old Password
                              </label>
                              <div className='col-sm-6 p-0'>
                                <Form.Item
                                  name='oldPassword'
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input the value!",
                                    },
                                  ]}
                                >
                                  <Input.Password
                                    maxLength={256}
                                    minLength={4}
                                    className={`form-control input_form_sign`}
                                    placeholder='Old Password'
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className='form-group row margin_mobile justify-content-center align-items-center'>
                              <label className='col-form-label fill_description col-sm-3 text-align_dsk des_font'>
                                New Password
                              </label>
                              <div className='col-sm-6 p-0'>
                                <Form.Item
                                  name='password'
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input the value!",
                                    },
                                    {
                                      validator: validatePassword,
                                    },
                                  ]}
                                >
                                  <Input.Password
                                    className={`form-control input_form_sign`}
                                    placeholder='Password'
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className='form-group row margin_mobile justify-content-center align-items-center'>
                              <label className='col-form-label fill_description col-sm-3 text-align_dsk des_font'>
                                Confirm Password
                              </label>
                              <div className='col-sm-6 p-0'>
                                <Form.Item
                                  name='confirmPassword'
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please confirm your password!",
                                    },
                                    // { validator: validatePassword },

                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (
                                          !value ||
                                          getFieldValue("password") === value
                                        ) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(
                                          new Error(
                                            "New password does not match the confirm password!"
                                          )
                                        );
                                      },
                                    }),
                                  ]}
                                >
                                  <Input.Password
                                    className={`form-control input_form_sign`}
                                    placeholder='Confirm Password'
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className='row justify-content-center mt-5'>
                              <div className='d-flex justify-content-center'>
                                <button
                                  type='submit'
                                  className='btn btn-primary button_signup button_leads_submit'
                                >
                                  Submit
                                </button>
                                <button
                                  onClick={() => navigate("/leads")}
                                  type='button'
                                  className='btn btn-primary button_signup button_clients_cancel ms-3'
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                        <div className='col-xl-2'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
