import React from "react";
import AddressInputs from "./SharedFields/AddressWithNameField";
import FromDate from "./SharedFields/FromDate";
const MedicalRecordsRequest = () => {
  return (
    <>
      <AddressInputs />
      <FromDate />
    </>
  );
};

export default MedicalRecordsRequest;
