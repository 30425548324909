import React, { useState, useEffect } from "react";
import "./index.scss";
import HighSchoolInformation from "./highSchoolInformation";
import CollegeHistory from "./collegeHistory";
import WorkHistory from "./workHistory";
import MedicationDetails from "./medicationDetails";
import DoctorVisits from "./doctorVisits";
import ChildrenInformation from "./childrenInformation";
import EmergencyContact from "./emergencyContact";
import MarriageInfo from "./marriageInfo";
import { getTemplateCount } from "../../actions/workHistory";
import { useSearchParams } from "react-router-dom";
import Loader from "../../components/Loader";

const AdminTemplates = () => {
  const [highSchoolInfoCount, sethighSchoolInfoCount] = useState(0);
  const [collegeHistoryCount, setcollegeHistoryCount] = useState(0);
  const [workHistoryCount, setworkHistoryCount] = useState(0);
  const [allDoctorInfoCount, setallDoctorInfoCount] = useState(0);
  const [medicationDetailsCount, setmedicationDetailsCount] = useState(0);
  const [doctorVisitsCount, setdoctorVisitsCount] = useState(0);
  const [childrenInfoCount, setchildrenInfoCount] = useState(0);
  const [emergencyContactCount, setemergencyContactCount] = useState(0);
  const [marriageInfoCount, setmMarriageInfoCount] = useState(0);
  const [searchParams] = useSearchParams();
  const [isLoader, setIsLoader] = useState(true);

  const getTemplateCountApi = () => {
    setIsLoader(true);
    var param = searchParams.get("id") ?? "0";
    getTemplateCount(param).then((res) => {
      var count = res.data.responseData;
      sethighSchoolInfoCount(count.highSchoolInfoCount);
      setcollegeHistoryCount(count.collegeHistoryCount);
      setworkHistoryCount(count.workHistoryCount);
      setallDoctorInfoCount(count.allDoctorInfoCount);
      setmedicationDetailsCount(count.medicationDetailsCount);
      setdoctorVisitsCount(count.doctorVisitsCount);
      setchildrenInfoCount(count.childrenInfoCount);
      setemergencyContactCount(count.emergencyContactCount);
      setmMarriageInfoCount(count.marriageInfoCount);
      setIsLoader(false);
    });
  };
  useEffect(() => {
    getTemplateCountApi();
  }, [searchParams.get("id")]);
  return (
    <>
      {isLoader && <Loader></Loader>}

      {!isLoader && (
        <>
          <section id='MindsetAdminTemplates' className='mt-5'>
            <div className='row'>
              <div className='col'>
                <div className='row'>
                  <HighSchoolInformation
                    highSchoolInfoCount={highSchoolInfoCount}
                    TemplateCount={getTemplateCountApi}
                  />
                  <CollegeHistory
                    collegeHistoryCount={collegeHistoryCount}
                    TemplateCount={getTemplateCountApi}
                  />
                  <WorkHistory
                    workHistoryCount={workHistoryCount}
                    TemplateCount={getTemplateCountApi}
                  />
                  <DoctorVisits
                    doctorVisitsCount={doctorVisitsCount}
                    TemplateCount={getTemplateCountApi}
                  />
                  <MedicationDetails
                    medicationDetailsCount={medicationDetailsCount}
                    TemplateCount={getTemplateCountApi}
                  />
                  <ChildrenInformation
                    childrenInfoCount={childrenInfoCount}
                    TemplateCount={getTemplateCountApi}
                  />
                  <EmergencyContact
                    emergencyContactCount={emergencyContactCount}
                    TemplateCount={getTemplateCountApi}
                  />
                  <MarriageInfo
                    marriageInfoCount={marriageInfoCount}
                    TemplateCount={getTemplateCountApi}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AdminTemplates;
