import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "antd";
import axios from "axios";
import { FlashMessage } from "../../utils/flash_message";

import { DatePicker, Select, Checkbox } from "antd";
import moment, { Moment } from "moment";
import "./index.scss";
import useCanWriteAndEnforcePagePermissions from "../../common/customHooks/useCanWriteAndEnforcePagePermissions";
import { getApplicationDetailsById } from "../../actions/application";
const noTemplatesId = -1;

const SendPhysicalMail = () => {
  useCanWriteAndEnforcePagePermissions();
  const [isLoading, setIsLoading] = useState(true);
  const [isPhysicalMailSending, setIsPhysicalMailSending] = useState(false);
  const [sendDate, setSendDate] = useState<Moment>(moment().add(2, "days"));
  const [isScheduledSend, setIsScheduledSend] = useState(false);
  const [sendToFacility, setSendToFacility] = useState(false);
  const [searchParams] = useSearchParams();
  const [applicationData, setApplicationData] = useState<any>(null);
  const [selectedTemplateId, setSelectedTemplateId] =
    useState<number>(noTemplatesId);
  const [templateOptions, setTemplateOptions] = useState<
    {
      value: Number;
      label: String;
    }[]
  >([{ value: noTemplatesId, label: "Please select a value" }]);

  useEffect(() => {
    getApplicationDetailsById(searchParams.get("id")).then((res) => {
      const appData = res.data.responseData;
      setApplicationData(appData);
    });
    axios.get("NotificationTemplate").then((r) => {
      setIsLoading(false);
      //handle template not existing case
      if (r.data.responseData.length < 1) {
        setTemplateOptions([
          {
            value: noTemplatesId,
            label: "No notification templates.  Create one before sending mail",
          },
        ]);
        setSelectedTemplateId(noTemplatesId);
        return;
      }

      setSelectedTemplateId(r.data.responseData[0].id);
      setTemplateOptions(
        r.data.responseData.map((item: { id: number; name: string }) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    });
  }, [setTemplateOptions, setSelectedTemplateId]);

  const onClick = () => {
    setIsPhysicalMailSending(true);
    axios
      .post("PhysicalMail", {
        EncryptedToUserId: searchParams.get("id") ?? "0",
        TemplateId: selectedTemplateId,
        SendDate:
          (isScheduledSend && sendDate.format("YYYY-MM-DD")) || undefined,
        SendToFacility: sendToFacility,
      })
      .then((res) => {
        FlashMessage(
          "success",
          <div>
            <span>
              Mail has been successfully scheduled to send to
              {res.data.claimantName}! You can view a preivew of the mail{" "}
            </span>
            <a
              href={res.data.previewLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
            ,
          </div>,
          "",
          5
        );
      })
      .catch((error) => {
        FlashMessage(
          "error",
          <div>
            <span>{error.response.data.responseMessage}</span>
          </div>,
          "",
          0
        );
      })
      .finally(() => setIsPhysicalMailSending(false));
  };

  return (
    (!isLoading && applicationData !== null && (
      <section id='MindsetAdminTemplates' className='mt-5'>
        <div className='row'>
          <div className='col'>
            {templateOptions.length < 1 && (
              <div>
                Please create a notification template to send phsyical mail
              </div>
            )}
            {templateOptions.length > 0 && (
              <>
                <div>
                  <Select
                    options={templateOptions}
                    onChange={(e) => {
                      setSelectedTemplateId(e);
                    }}
                    value={selectedTemplateId}
                  />
                </div>
                <br />
                <Checkbox
                  className='mb-3'
                  checked={isScheduledSend}
                  onChange={(e) => setIsScheduledSend(e.target.checked)}
                >
                  Schedule Send Date
                </Checkbox>
                {applicationData.facilityName && (
                  <Checkbox
                    className='mb-3'
                    checked={sendToFacility}
                    onChange={(e) => setSendToFacility(e.target.checked)}
                  >
                    Send to {applicationData.facilityName}
                  </Checkbox>
                )}

                {isScheduledSend && (
                  <div className='d-flex'>
                    <div className='me-3 d-flex'>
                      <label className='-label fill_description  des_font'>
                        Send Date
                        <DatePicker
                          onChange={(e) => setSendDate(e || moment())}
                          disabledDate={(d: any) =>
                            !d || d.isBefore(moment().add(1, "days"))
                          }
                          defaultValue={moment().add(2, "days")}
                          allowClear={false}
                          showTime={false}
                        />
                      </label>
                    </div>
                  </div>
                )}
                <div className='mt-3'>
                  <Button
                    onClick={onClick}
                    type='primary'
                    loading={isPhysicalMailSending}
                  >
                    Send Mail!
                  </Button>
                </div>
                <br />
              </>
            )}
          </div>
        </div>
      </section>
    )) || (
      <section id='MindsetAdminTemplates' className='mt-5'>
        <div className='row'>
          <div className='col-md-3'></div>
          <div className='col-md-9'>
            <div>Loading...</div>
          </div>
        </div>
      </section>
    )
  );
};

export default SendPhysicalMail;
