import axios from "axios";

export function GetAllActivityTypes() {
  return axios.get("ActivityTypes");
}
export function GetActivityLogData(
  email: any,
  activityTypeId: any,
  startDate: any,
  endDate: any,
  activityBy: any
) {
  return axios.get(
    "ActivityLogData?email=" +
      email +
      "&activityTypeId=" +
      activityTypeId +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&activityBy=" +
      activityBy
  );
}
