import {
  DocumentList,
  AllPdfDocuments,
  DownloadDocument,
  DeleteDocument,
  UploadDocuments,
  GetDocumentsCount,
} from "../../apis/documents";

export function UploadDocumentsAction(
  payload: any,
  id: any,
  documentType: any
) {
  return UploadDocuments(payload, id, documentType);
}
export function DocumentListAction(documentType: any, id: any) {
  return DocumentList(documentType, id);
}

export function AllDocumentsListAction(id: any) {
  return AllPdfDocuments(id);
}
export function getDocumentsCount(id: any) {
  return GetDocumentsCount(id);
}
export function DownloadDocumentAction(Id: number, documentType: any, encryptedId: string) {
  return DownloadDocument(Id, documentType, encryptedId);
}

export function DeleteDocumentAction(id: number) {
  return DeleteDocument(id);
}
