import axios from "axios";

export function SaveNotificationTemplate(payload: any) {
  return axios.post("NotificationTemplate", payload);
}
export function GetNotificationTemplate() {
  return axios.get("NotificationTemplate");
}
export function GetNotificationTemplateById(id: any) {
  return axios.get("NotificationTemplateById?Id=" + id);
}
export function DeleteNotificationTemplate(Id: any) {
  return axios.delete("NotificationTemplate?Id=" + Id);
}
export function GetNotificationConfigByTemplate(Id: any) {
  return axios.get("GetNotificationConfigByTemplate?Id=" + Id);
}
export function UpdateNotificationTemplate(payload: any) {
  return axios.put("NotificationTemplate", payload);
}
export function GetPlaceholders() {
  return axios.get("Placeholders");
}
