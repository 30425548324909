import axios from "axios";

export function GetTemplateCount(id: any) {
  return axios.get("TemplateCount?Id=" + id);
}
export function SaveWorkHistory(payload: any) {
  return axios.post("WorkHistory", payload);
}
export function GetWorkHistory(id: any) {
  return axios.get("WorkHistory?Id=" + id);
}
export function GetWorkHistoryById(id: any) {
  return axios.get("WorkHistoryById?Id=" + id);
}
export function DeleteWorkHistory(Id: any) {
  return axios.delete("WorkHistory?Id=" + Id);
}
export function UpdateWorkHistory(payload: any) {
  return axios.put("WorkHistory", payload);
}
export function AddUpdateRequestedInfo(payload: any) {
  return axios.post("RequestedInfo", payload);
}
export function GetRequestedInfoById(id: any) {
  return axios.get("RequestedInfoById?Id=" + id);
}
