export interface MentionUser {
  id: number;
  display: string;
}
export interface GroupUser {
  id: number;
  display: string;
}
export const getUpdatedMentionsValue = (
  newValue: any,
  newPlainTextValue: any,
  _mentions: any,
  user: MentionUser[],
  groups: GroupUser[]
) => {
  var desc = "";
  var taggedUserIds = [];
  let newTagsValue: string = newValue;
  const mentions = [];
  const userMentions = newPlainTextValue.match(/@[^@]*?:/g) || [];
  for (const currentUser of userMentions) {
    const userName = currentUser.substring(1, currentUser.length - 1);
    var selectedUser = user.find((val) => val.display === userName);
    var selectedGroupUser = groups.find((val) => val.display == userName);
    if (selectedUser != null) {
      newTagsValue = newTagsValue.replaceAll(
        currentUser,
        `@[${userName}](${selectedUser.id})`
      );
      mentions.push({
        id: `U:${selectedUser.id}`,
        display: selectedUser.display,
      });
    }
    if (selectedGroupUser != null) {
      newTagsValue = newTagsValue.replaceAll(
        currentUser,
        `@[${userName}](${selectedGroupUser.id})`
      );
      mentions.push({
        id: `G:${selectedGroupUser.id}`,
        display: selectedGroupUser.display,
      });
    }
  }
  desc = newTagsValue;
  if (mentions.length > 0) {
    for (var i = 0; i < mentions.length; i++) {
      var replaceText =
        "@[" +
        mentions[i].display.split("@").join("").split(":").join("") +
        "](" +
        mentions[i].id.slice(2) +
        ")";

      desc = desc.split(replaceText).join("");
      taggedUserIds.push(mentions[i].id);
    }
  }
  return {
    newTagUserIds: Array.from(new Set(taggedUserIds)).join(","),
    newSingleLineValue: desc,
    newPlainText: newPlainTextValue,
    newTagsText: newTagsValue,
  };
};
