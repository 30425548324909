import React from "react";
import { Form, Input, Button, Row, Col, Spin } from "antd";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { forgotPassword } from "../../actions/accounts";
import { FlashMessage } from "../../utils/flash_message";
import "./forgotpassword.scss";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const formSubmit = () => {
    form.submit();
  };

  const redirectUserAfterEmailSuccess = (time: any) => {
    setTimeout(() => {
      navigate("/login");
    }, time);
  };

  const submitHandler = (formValues: any) => {
    forgotPassword(formValues.email)
      .then((response) => {
        if (response.data.responseCode == 200) {
          if (response.data.responseData) {
            FlashMessage("success", response.data.responseMessage);
            redirectUserAfterEmailSuccess(3000);
          }
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <Row className={`login_bg formContainer justify-content-center`}>
        <Col
          span={8}
          xs={18}
          md={18}
          lg={8}
          className={`login_form formFieldContainer`}
        >
          <Form
            className=''
            name='basic'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={submitHandler}
            autoComplete='off'
          >
            <h4 className='heading_app text-center mb-4'>Forgot Password</h4>

            <div className='row d-flex'>
              <div className='col-md-12'>
                <div className='form-group'>
                  <Form.Item
                    label='Email'
                    name='email'
                    className={`label_weight`}
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input className={`formField`} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <Form.Item wrapperCol={{ span: 24 }} className='text-center mt-4'>
              <Button
                type='primary'
                className='btn btn-primary button_login'
                htmlType='submit'
              >
                Submit{" "}
              </Button>
              <Button
                onClick={() => navigate("/login")}
                className='btn-secondary-black fill ms-3 cancel_form'
                htmlType='button'
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ForgotPassword;
