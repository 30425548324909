import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Input, Button, Row, Col, Spin } from "antd";
import { loginUser } from "../../actions/accounts";
import { ExpireTokens } from "../../utils/expire_tokens";
import { set, get, remove } from "../../utils/clientStorageUtils";
import "./login.scss";
import "antd/dist/antd.min.css";
import { FlashMessage } from "../../utils/flash_message";
import { Constants } from "../../common/constants";
import { GoogleLogin } from "@react-oauth/google";

import useFeatureFlag from "../../components/useFeatureFlag";
import Footer from "../../components/Footer";
import axios from "axios";
import { GoogleSso } from "../../apis/accounts";
const LoginPage = ({
  loginWithCredentials,
}: {
  loginWithCredentials?: boolean;
}) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isTestEnabled =
    useFeatureFlag().featureFlags[Constants.featureFlags.Test];
  const [preLoginPath] = useState(get("originalUrl"));
  useEffect(() => {
    remove("originalUrl");
  }, []);

  const responseMessage = (response: any) => {
    setLoading(true);
    GoogleSso({ IdToken: response.credential })
      .then((response: any) => {
        set("model", JSON.stringify(response.data.responseData));
        set("accessToken", response.data.responseData.token);
        set("rid", response.data.responseData.repositoryId);
        const roles = response.data.responseData.userRoles[0];
        if (
          response.data.responseData.userRoles.some(
            (role: string) => role === Constants.userRoles.customer
          )
        ) {
          FlashMessage("error", "Login failed");
          throw new Error("Customer role is not allowed to login");
        }
        navigate(preLoginPath || `/${Constants.pageRoutes.globaltaskscreen}`);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const errorMessage = (error: any) => {
    console.log(error);
  };
  const onFinish = (values: any) => {
    setLoading(true);
    return loginUser(values)
      .then((response: any) => {
        setLoading(false);
        if (response.data.responseCode === 200) {
          set("model", JSON.stringify(response.data.responseData));
          set("accessToken", response.data.responseData.token);
          set("rid", response.data.responseData.repositoryId);
          const roles = response.data.responseData.userRoles[0];
          if (
            response.data.responseData.userRoles.some(
              (role: string) => role === Constants.userRoles.customer
            )
          ) {
            FlashMessage("error", "Login failed");
            throw new Error("Customer role is not allowed to login");
          }
          navigate(preLoginPath || `/${Constants.pageRoutes.globaltaskscreen}`);
        } else if (response.data.responseCode === 303) {
          FlashMessage("error", response.data.responseMessage);
        }
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };
  const handleGetValueFromEvent = (e: any) => {
    return e.target.value.trim();
  };
  const onFinishFailed = (errorInfo: any) => {};

  useEffect(() => {
    ExpireTokens();
  });

  if (
    loginWithCredentials &&
    window.location.hostname === "admin.mindset.care"
  ) {
    return <></>;
  }

  return (
    <Row className={`login_bg formContainer justify-content-center`}>
      <Col
        span={8}
        xs={18}
        md={18}
        lg={8}
        className={`login_form formFieldContainer`}
      >
        <h4 className={`heading_app text-center mb-4`}>
          Mindset Login
          {isTestEnabled && " via Google Sso"}
        </h4>
        {!isTestEnabled || loginWithCredentials ? (
          <>
            <Form
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <Form.Item
                label='Username'
                name='username'
                className={`label_weight`}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your username!",
                  },
                ]}
                getValueFromEvent={handleGetValueFromEvent}
              >
                <Input className={`formField`} />
              </Form.Item>
              <Form.Item
                label='Password'
                name='password'
                className={`label_weight`}
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password className={`formField`} />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }} className='text-center mt-4'>
                <Button
                  type='primary'
                  disabled={isLoading}
                  htmlType='submit'
                  className={`btn btn-primary button_login`}
                >
                  Login
                </Button>
                {isLoading ? <Spin style={{ marginLeft: "10px" }} /> : null}
              </Form.Item>
            </Form>

            <button
              onClick={() => navigate("/forgotPassword")}
              type='button'
              className={
                "btn btn-primary maping_button w-100 mt-3" +
                (location.pathname.indexOf(Constants.pageRoutes.notes) >= 0
                  ? " activemenu"
                  : "")
              }
            >
              Forgot Password ?
            </button>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GoogleLogin
              onSuccess={responseMessage}
              onError={() => errorMessage("error")}
            />
          </div>
        )}
        <Footer />
      </Col>
    </Row>
  );
};

export default LoginPage;
