export const validateBlankSpace = (_: any, value: any) => {
  if (value == null || value == "" || value.toString().trim() != "") {
    return Promise.resolve();
  }

  return Promise.reject(new Error("Please remove the blank spaces."));
};

export const antdNoEmptyRule = {
  pattern: /^(?! *$).+/,
  message: "Cannot be empty",
};

export const validatePhoneNumberFormat = (rule: any, value: string) => {
  if (!/^\d{3}-\d{3}-\d{4}$/.test(value)) {
    return Promise.reject('Please enter a valid phone number format: ###-###-####');
  }
  return Promise.resolve();
};