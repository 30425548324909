import axios from "axios";

export function AddTenant(payload: any, form: any) {
  return axios.post("Tenant", payload);
}
export function GetTenant() {
  return axios.get("Tenant");
}
export function GetTenantById(id: any) {
  return axios.get("TenantById?Id=" + id);
}
export function UpdateTenant(payload: any) {
  return axios.put("Tenant", payload);
}
export function DeleteTenant(id: any) {
  return axios.delete("Tenant?Id=" + id);
}
export function TenantExistsByCode(code: any) {
  return axios.post("TenantExistsByCode?code=" + code);
}
export function UploadLogo(tenantId: any, payload: any) {
  return axios.post("UploadLogo?tenantId=" + tenantId, payload);
}
export function DownloadLogo(id: any) {
  return axios.get("downloadLogo?id=" + id);
}
