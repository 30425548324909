import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Table, Tooltip, Select, Space } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import Loader from "../../components/Loader";
import { Constants } from "../../common/constants";
import { addGroupUser, deleteGroupUser, getGroupUser, getGroupUserById, updateGroupUser } from "../../actions/groupUsers";
import { FlashMessage } from "../../utils/flash_message";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { get } from "../../utils/clientStorageUtils";
import { getSupportUsers } from "../../actions/supportUser";

interface Groups {

  UserName: string;
  Name: string;
};
const { TextArea } = Input;
const { Option } = Select;
const ManageGroupUser = () => {
  const [form] = Form.useForm();
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [typeById, setTaskById] = useState({});
  const [fileForm, setFileForm] = useState(null);
  const [groups, setgroups] = useState<Groups[]>([]);
  const [userById, setUserById] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [allUserName, setAllUserName] = useState([]);

  const formSubmit = () => {
    form.submit();
  };


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    }, {
      title: "Users",
      dataIndex: "usersName",
      key: "usersName",
      render: (item: any) => {
        return (
          <>
            {item.map((l: any) => {

              return <>{l}<br></br></>
            })}

          </>
        );
      },

    }, {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {

        return (
          <div className='d-flex'>
            <Tooltip title={"Edit"}>
              <a className='mr-2' onClick={() => getGroupUserByIdApi(id)}>
                <EditFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title={"Delete"}>
              <a className='mr-2' onClick={() => deleteGroupUserApi(id)}>
                <DeleteFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
          </div>
        );
      }
    },
  ];

  const addPopUp = () => {
    setIsAdd(true);
    setOpen(true);
    resetFormValues();
    setFileForm(null);
  };


  const getSupportUsersApi = () => {
    setIsPopUpLoader(true);
    getSupportUsers().then((res) => {
      var userdata = res.data.responseData;

      if (res.data.responseCode == 200) {
        setIsPopUpLoader(false);
        let userid = JSON.parse(get("model"))
          ? JSON.parse(get("model")).userId
          : "";

        var allUser = userdata
          .map((a: any) => ({
            id: a.id,
            allUsersDisplay: `${a.firstName} ${a.lastName} `,
          }))
          .filter((b: any) => b.id == userid || b.id != userid);
        setAllUserName(allUser);
      }
    });
  };


  const resetFormValues = () => {
    var formDefaultValues = {
      name: "",
      usersName: "",
    }
    setTimeout(() => {
      setUserById(formDefaultValues);
      form.setFieldsValue(formDefaultValues);
    }, 100);

  };

  const addUpdateGroupUserApi = (e: any) => {
    console.log("e", e);
    var payload = {
      id: e.id,
      Name: e.name,
      userIds: e.users,
    };

    if (isAdd) {
      setIsPopUpLoader(true);
      addGroupUser(payload, form).then((res) => {
        if (res.data.responseCode == 200) {
          setIsLoader(false);
          FlashMessage("success", "Group added Successfully.");
          cancel();
          setIsPopUpLoader(false);
          getGroupUserApi();
        }
      });
    } else {
      setIsPopUpLoader(true);
      updateGroupUser(payload).then((res) => {
        if (res.data.responseCode == 200) {
          setIsLoader(false);
          setIsPopUpLoader(false);
          FlashMessage("success", "Group updated Successfully.");
          cancel();
          getGroupUserApi();
        }
      });
    }
  };

  const cancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const getGroupUserApi = () => {
    setIsLoader(true);
    setIsAdd(true);


    getGroupUser().then((res) => {
      var userdata = res.data.responseData;
      setIsLoader(false);
      if (res.data.responseCode == 200) {
        setgroups(userdata);
        var allUser = userdata
          .map((a: any) => ({
            id: a.id,
            usersName: `${a.firstName} ${a.lastName} `,
            userIds: a.userIds,
          }))
          .filter((b: any) => b.id == userById || b.id != userById);


        setAllUsers(allUser);
      }
    });
  };


  useEffect(() => {
    getGroupUserApi();

  }, []);
  useEffect(() => {
    getSupportUsersApi();
  }, []);


  const deleteGroupUserApi = (id: any) => {
    setIsLoader(true);
    const confirmDelete = window.confirm("Are you sure you want to delete this record?");
    if (confirmDelete) {
      deleteGroupUser(id).then((response) => {
        setIsLoader(false);
        if (response.data.responseCode === 200) {
          FlashMessage("success", response.data.responseMessage);
          getGroupUserApi();
        } else {
          FlashMessage("error", response.data.responseMessage);
        }
      });
    } else {
      setIsLoader(false);
    }
  };

  const getGroupUserByIdApi = (id: any) => {
    setIsPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);

    getGroupUserById(id).then((res) => {
      setIsPopUpLoader(false);
      var appRes = res.data.responseData;
      appRes.users = appRes.userIds;
      setUserById(appRes);
      form.setFieldsValue(appRes);
    });
  };



  return (
    <>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <>
          <Modal
            title={isAdd ? "Add Group" : "Update Group"}
            centered
            okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
            open={open}
            onOk={() => (isPopUpLoader ? null : formSubmit())}
            onCancel={() => cancel()}
            width={1000}
          >

            {isPopUpLoader && <Loader></Loader>}
            {!isPopUpLoader && (

              <Form
                className='mt-4'
                form={form}
                name='GroupUserForm'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}

                initialValues={typeById}
                autoComplete='off'
                onFinish={addUpdateGroupUserApi}
              >
                <div>
                  <Form.Item name="id" />
                  <div className="form-group row margin_mobile">
                    <label className="col-form-label fill_description col-sm-3 des_font">
                      1. Name
                    </label>
                    <div className="col-sm-9 p-0">
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          className="form-control input_form_sign"
                        />
                      </Form.Item>
                    </div>
                  </div>


                  <div className="form-group row margin_mobile">
                    <label className="col-form-label fill_description col-sm-3 des_font">
                      2. Users
                    </label>
                    <div className="col-sm-9 p-0 addmanagegroup">
                      <Form.Item
                        name='users'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]}
                      >
                        <Select

                          mode='multiple'
                          optionLabelProp='label'
                          showSearch
                          optionFilterProp='label'
                        >
                          {allUserName.map((ele: any) => {
                            return (
                              <>
                                <Option
                                  value={ele.id}
                                  label={ele.allUsersDisplay}
                                >
                                  <Space> {ele.allUsersDisplay}</Space>
                                </Option>
                              </>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>


              </Form>
            )}
          </Modal>

          <div className="d-flex justify-content-end">
            <div className="col-md-4 mb-4">
              <div className="d-flex justify-content-end mt-5">
                <button
                  type="button"
                  onClick={addPopUp}
                  className="btn btn-primary button_signup button_clients button_add_padding px-5"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <Table
            dataSource={groups}
            pagination={{
              pageSize: Constants.logsDefaultPageSize,
              showSizeChanger: false,
              showTotal: (total) => {
                return `Total Count:  ${total}`;
              },
            }}
            columns={columns}
          />
        </>

      )}
    </>
  );
};

export default ManageGroupUser;








