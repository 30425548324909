import axios from "axios";

export function AddFacility(payload: any) {
  return axios.post("facilities", payload);
}
export function GetFacilities() {
  return axios.get("facilities");
}
export function GetFacilityById(id: any) {
  return axios.get("facilities/" + id);
}
export function UpdateFacility(payload: any) {
  return axios.put("facilities", payload);
}
export function DeleteFacility(id: any) {
  return axios.delete("facilities/" + id);
}
