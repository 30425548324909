import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Button, Input } from "antd";
import axios from "axios";
import { FlashMessage } from "../../utils/flash_message";
import ChangeOfAddress from "./TemplateForms/ChangeOfAddress";
import { Select } from "antd";
import BunbldeList from "./BundleList";
import "./index.scss";
import MedicalRecordsRequest from "./TemplateForms/MedicalRecordsRequest";
import EducationRequest from "./TemplateForms/EducationRequest";
import FiveDayLetter from "./TemplateForms/FiveDayLetter";
import PriorRepWithdrawalAndWaiver from "./TemplateForms/PriorRepWithdrawalWaiver";
import useCanWriteAndEnforcePagePermissions from "../../common/customHooks/useCanWriteAndEnforcePagePermissions";
import RFCRequest from "./TemplateForms/RFCRequest";
import Loader from "../../components/Loader";
import FeeLetter from "./TemplateForms/FeeLetter";
import FeePetitionLetter from "./TemplateForms/FeePetitionLetter";
import WithdrawalOfRepresentative from "./TemplateForms/WithdrawalOfRepresentative";
import moment from "moment";

interface GenericDocumentGenerationPayloadItem {
  FacilityAddressLine1: string;
  FacilityAddressLine2: string;
  FacilityCity: string;
  FacilityName?: string;
  FacilityState: string;
  FacilityZip: string;
  HearingDate: string | undefined;
  JudgeName: string | undefined;
  MedicalProviders: string | undefined;
  PhysicianName: string | undefined;
  PriorRep: string | undefined;
  RequestFromDate: string | undefined;
  TemplateType: number;
  FeeAttorneyWithheld: number | undefined;
  FeePetitionAmount: number | undefined;
}

export interface TemplateOption {
  label: string;
  value: number;
}

type TemplateOptionType = {
  id: number;
  description: string;
};



const GenerateDocuments = () => {
  useCanWriteAndEnforcePagePermissions();
  const [templateForm] = Form.useForm();
  const [bundleNameForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [searchParams] = useSearchParams();
  const [selectedTemplateId, setSelectedTemplateId] = useState(-1);
  const [RFCTemplateOptions, setRFCTemplateOptions] = useState<
    TemplateOption[]
  >([]);
  const [templateOptions, setTemplateOptions] = useState<TemplateOption[]>([]);
  const [emptyTemplateOptions] = useState<TemplateOption[]>([
    { value: -1, label: "All templates have been used!" },
  ]);
  const [documentGenerationPayload, setDocumentGenerationPayload] = useState<
    GenericDocumentGenerationPayloadItem[]
  >([]);

  const getRFCTemplates = () =>
    axios.get("GenerateDocument/RFCFormTypes").then((res) =>
      setRFCTemplateOptions(
        res.data.map(({ id, description }: TemplateOptionType) => ({
          value: id,
          label: description,
        }))
      )
    );

  useEffect(() => {
    axios.get("GenerateDocument/DisplayedTemplateTypes").then((r) => {
      setIsLoading(false);
      setSelectedTemplateId(r.data[0].id);
      setTemplateOptions(
        r.data.map(({ id, description }: TemplateOptionType) => ({
          value: id,
          label: description,
        }))
      );
    });
    getRFCTemplates();
  }, [setTemplateOptions, setSelectedTemplateId]);

  const convertToPayloadObject = (
    object: any
  ): GenericDocumentGenerationPayloadItem =>
    ({
      TemplateType: object.templateType,
      FacilityName: object.addressName ? object.addressName.trim() : "",
      FacilityAddressLine1: object.line1 ? object.line1.trim() : "",
      FacilityAddressLine2: object.line2 ? object.line2.trim() : "",
      FacilityCity: object.city ? object.city.trim() : "",
      FacilityState: object.state ?? "",
      FacilityZip: object.zip ?? "",
      RequestFromDate: object.fromDate ?? "",
      JudgeName: object.judgeName ? object.judgeName.trim() : "",
      PhysicianName: object.physicianName ? object.physicianName.trim() : "",
      HearingDate: moment(object.hearingDate).format('MM/DD/YYYY') ?? "",
      MedicalProviders: object.medicalProviders
        ? object.medicalProviders.trim()
        : "",
      PriorRep: object.priorRep ? object.priorRep.trim() : "",
      FeeAttorneyWithheld: object.feeAttorneyWithheld
        ? object.feeAttorneyWithheld
        : 0,
      FeePetitionAmount: object.feePetitionAmount
        ? object.feePetitionAmount
        : 0,
    } as GenericDocumentGenerationPayloadItem);

  const onAddTemplateToDocumentBundle = async (formData: any) => {
    const enclosedForms =
      formData.EnclosedFormIds?.map((formId: number) =>
        convertToPayloadObject({
          templateType: formId,
        })
      ) ?? [];
    setDocumentGenerationPayload((oldPayloads) => [
      ...oldPayloads,
      convertToPayloadObject({ ...formData, templateType: selectedTemplateId }),
      ...enclosedForms,
    ]);
    templateForm.resetFields();
  };

  useEffect(() => {
    const usedTemplateOptions = documentGenerationPayload.map(
      (item) => item.TemplateType
    );
    const newTemplateId = templateOptions.find(
      (item) => !usedTemplateOptions.includes(item.value)
    );
    setSelectedTemplateId(newTemplateId?.value || -1);
  }, [documentGenerationPayload]);

  const getSelectedTemplateFormFields = () => {
    const templateDisplayName = templateOptions.find(
      (item) => item.value === selectedTemplateId
    )?.label;
    let fields;
    switch (templateDisplayName) {
      case "Change of Address": {
        fields = <ChangeOfAddress />;
        break;
      }
      case "Medical Records Request": {
        fields = <MedicalRecordsRequest />;
        break;
      }
      case "Education Records Request": {
        fields = <EducationRequest />;
        break;
      }
      case "Five Day Letter": {
        fields = <FiveDayLetter />;
        break;
      }
      case "Fee Letter": {
        fields = <FeeLetter />;
        break;
      }
      case "Fee Petition Letter": {
        fields = <FeePetitionLetter />;
        break;
      }
      case "Approved Closing Letter": {
        fields = <></>;
        break;
      }
      case "Call In Letter": {
        fields = <></>;
        break;
      }
      case "Withdrawal of Representative": {
        fields = <WithdrawalOfRepresentative />;
        break;
      }
      case "Withdrawal and Waiver of Prior Representative": {
        fields = <PriorRepWithdrawalAndWaiver />;
        break;
      }
      case "RFC Request": {
        fields = <RFCRequest templates={RFCTemplateOptions} />;
        break;
      }
    }

    return (
      fields && (
        <Form
          layout='vertical'
          form={templateForm}
          onFinish={onAddTemplateToDocumentBundle}
        >
          {fields}
          <Form.Item>
            <Button type='primary' htmlType='submit' className='mt-3'>
              Add to document generation bundle
            </Button>
          </Form.Item>
        </Form>
      )
    );
  };
  const onClick = async (e: any) => {
    setIsRequestPending(true);
    axios
      .post("GenerateDocument", {
        EncryptedToUserId: searchParams.get("id") ?? "0",
        DocumentBundleFileName: e.bundleName,
        DocumentsGenerationList: documentGenerationPayload,
      })
      .then((res) => {
        FlashMessage(
          "success",
          <div>
            <span>Document Generated Successfully!</span>
          </div>,
          "",
          5
        );
        templateForm.resetFields();
        bundleNameForm.resetFields();
        setDocumentGenerationPayload([]);
      })
      .catch((error) => {
        FlashMessage("error", error.response.data, undefined, 7);
      })
      .finally(() => setIsRequestPending(false));
  };

  const listItems = documentGenerationPayload.map(
    (item) =>
      templateOptions.find((option) => option.value === item.TemplateType) ??
      RFCTemplateOptions.find((option) => option.value === item.TemplateType)
  );

  const onRemoveListItem = (item: TemplateOption) => {
    const newBundleItems = [...documentGenerationPayload].filter(
      (doc) => doc.TemplateType !== item.value
    );
    setDocumentGenerationPayload(newBundleItems);
    templateForm.resetFields();
  };

  const usedTemplateOptions = documentGenerationPayload.map(
    (item) => item.TemplateType
  );

  const isTemplateSelectDisabled =
    usedTemplateOptions.length === templateOptions.length;

  return (
    (!isLoading && (
      <section id='MindsetAdminTemplates' className='mt-5'>
        <div className='row'>
          <div className='col'>
            <h6 className='label_weight mb-4'>Document Template</h6>
            <div>
              <Select
                key={"templates"}
                listHeight={200}
                options={
                  isTemplateSelectDisabled
                    ? emptyTemplateOptions
                    : templateOptions.filter(
                        (item) => !usedTemplateOptions.includes(item.value)
                      )
                }
                onChange={(e) => {
                  setSelectedTemplateId(e);
                }}
                value={selectedTemplateId}
                disabled={isTemplateSelectDisabled}
              />
            </div>
            <div className='row'>{getSelectedTemplateFormFields()}</div>
            <h6 className='label_weight mb-2 mt-5'>Document Bundle</h6>
            <div className='row'>
              <BunbldeList items={listItems} onRemoveItem={onRemoveListItem} />
            </div>
            <div className='row'>
              {isRequestPending ? (
                <Loader overrideClass='loaderLeftPanelHeight'></Loader>
              ) : (
                <>
                  <Form
                    layout='vertical'
                    form={bundleNameForm}
                    onFinish={onClick}
                  >
                    <Form.Item
                      label='Bundle Name'
                      name='bundleName'
                      rules={[
                        {
                          required: true,
                          message: "Please input a bundle name",
                        },
                      ]}
                    >
                      <Input maxLength={450} placeholder='Bundle name' />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        disabled={
                          documentGenerationPayload.length === 0 ||
                          isRequestPending
                        }
                        type='primary'
                        htmlType='submit'
                        className='mt-3'
                      >
                        Generate Document Bundle
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    )) || <Loader />
  );
};

export default GenerateDocuments;
