import {
  SaveMedicalHistory,
  GetMedicalHistory,
  DeleteMedicalHistory,
  GetMedicalHistoryById,
  UpdateMedicalHistory,
} from "../../apis/doctorVisits";

export function saveMedicalHistory(payload: any) {
  return SaveMedicalHistory(payload);
}
export function getMedicalHistory(id: any) {
  return GetMedicalHistory(id);
}
export function deleteMedicalHistory(Id: any) {
  return DeleteMedicalHistory(Id);
}
export function getMedicalHistoryById(id: any) {
  return GetMedicalHistoryById(id);
}
export function updateMedicalHistory(payload: any) {
  return UpdateMedicalHistory(payload);
}
