import axios from "axios";

export function SaveMarriageInfo(payload: any) {
  return axios.post("MarriageInfo", payload);
}
export function GetMarriageInfo(id: any) {
  return axios.get("MarriageInfo?Id=" + id);
}
export function GetMarriageInfoById(id: any) {
  return axios.get("MarriageInfoById?Id=" + id);
}
export function DeleteMarriageInfo(Id: any) {
  return axios.delete("MarriageInfo?Id=" + Id);
}
export function UpdateMarriageInfo(payload: any) {
  return axios.put("MarriageInfo", payload);
}
