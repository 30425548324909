import axios from "axios";

export function SaveNotification(payload: any, groupOfIds: any) {
  return axios.post("Notification?GroupOfIds=" + groupOfIds, payload);
}
export function GetAllNotifications() {
  return axios.get("Notifications");
}

export function ClearNotification(id: any) {
  return axios.delete("ClearNotification?notificationId=" + id);
}
export function ReadNotification(id: any) {
  return axios.put("ReadNotification?notificationId=" + id);
}
export function ClearAllNotifications(){
  return axios.delete("ClearAllNotifications");
}
