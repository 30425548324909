import axios from "axios";

export function SaveEmergencyContact(payload: any) {
  return axios.post("EmergencyContact", payload);
}
export function GetEmergencyContact(id: any) {
  return axios.get("EmergencyContact?Id=" + id);
}
export function GetEmergencyContactById(id: any) {
  return axios.get("EmergencyContactById?Id=" + id);
}
export function DeleteEmergencyContact(Id: any) {
  return axios.delete("EmergencyContact?Id=" + Id);
}
export function UpdateEmergencyContact(payload: any) {
  return axios.put("EmergencyContact", payload);
}
