import React from "react";
import "./index.scss";
import drp_tab from "../../images/drp_tab.png";
import Dropdown from "react-bootstrap/Dropdown";
import {
  formatPhoneNumber,
  getOnlyShowMyCasesFromSearchParams,
} from "../../utils/commonHelper";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Constants } from "../../common/constants";
import {
  addUpdateApplicationFormStepNine,
  getApplicationDetailsById,
  resetDocuSignStatus,
} from "../../actions/application";
import { CopyOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { updateClientLeftPanel } from "../../actions/application";
import {
  pipelineStatusDetails,
  updateApplicationStatus,
  updateAssign_ReAssignEnterpriseClaimants,
  updateLeadApplicationStatus,
} from "../../actions/pipeline";
import { FlashMessage } from "../../utils/flash_message";
import { Modal } from "antd";
import {
  validateBlankSpace,
  validatePhoneNumberFormat,
} from "../../validations/validateBlankSpace";
import { Menu, Form, Input, Select, Checkbox, Tooltip } from "antd";
import {
  number,
  validateZipCode,
} from "../../validations/numeric_only-validate";
import { EmailExistByUserId } from "../../actions/accounts";
import trim from "lodash/trim";
import { lessThanFourCharacters } from "../../validations/lessThanFourCharacters";
import { plusValidation, validateEmail } from "../../validations/validateEmail";
import { responseCodes } from "../Common/constants";
import { get } from "../../utils/clientStorageUtils";
import Loader from "../Loader";
import {
  clientUserAttributes,
  getClientUserAttributes,
  unMapLead,
} from "../../actions/leadsAndClients";
import StatesDropDownFormItem from "../../common/components/StatesDropdownFormItem";
import GooglePlacesAutocompleteWrapper from "../GooglePlacesAutoCompleteWrapper";
import { getTenant } from "../../actions/tenants";
import { Enums } from "../../common/enums";
import CustomerClaims from "./components/CustomerClaims";
import useFeatureFlag from "../useFeatureFlag";
import { getClaims } from "../../actions/claims";
import ClaimantAssignments from "./components/ClaimantAssignments/ClaimantAssignments";
import EnterpriseAssignment from "../../common/components/EnterpriseAssignment";
import SendSecureRegistrationLink from "./components/SendSecureRegistrationLink";
import SocialSecurityNumberInput from "../Common/form/SocialSecurityNumberInput";
import { minLengthNine } from "../../validations/validate_min_length";
import moment from "moment";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return { key, icon, children, label, type } as MenuItem;
}

const CustomerLeftPanel = (props: any) => {
  const [appData, setAppData] = useState({
    id: 0,
    mindsetUserId: null,
    firstName: "",
    lastName: "",
    middleName: "",
    ssn: "",
    dateOfBirth: "",
    gender: "",
    isDeniedSSN: false,
    isUnableWork: false,
    heightWithoutShoes: "",
    medicalCondition: "",
    mailingAddress: "",
    isLowVision: false,
    isDisabled: false,
    isDeniedBenefits: false,
    startDateOfDisability: "",
    phoneNumber: "",
    timeToCall: "",
    placeOfBirth: "",
    isReside: true,
    isDeadlyCondition: true,
    isUSCitizen: true,
    languageForSpeaking: "",
    languageForReading: "",
    typeOfCitizenship: "",
    isSpeakEnglish: true,
    isReadEnglish: true,
    isWriteEnglish: true,
    isOtherSSNumber: true,
    spouseFName: "",
    spouseMName: "",
    spouseLName: "",
    spouseSSNumber: "",
    spouseDOB: "",
    isMarried: false,
    isSpouseWorked: false,
    placeOfMarriage: "",
    dateOfMarriage: "",
    isPriorMarriage: true,
    isAnyChildren: true,
    isMillitary: true,
    isAppliedPDB: true,
    moneyEarnedIn2021: "",
    isOtherName: true,
    isWorkIn2022: true,
    isSelfEmployed2021: true,
    isSelfEmployed2022: true,
    isPriorApplications: true,
    moneyEarnedIn2022: "",
    isWorkedOutsideUS: true,
    isSpouseWorkedOutsideUS: true,
    isAgreeWithEarningHistory: true,
    isWorkedTaxesNotDecucted: true,
    isOwnBankAccount: true,
    weightWIthoutShoes: "",
    isReceiveMoneyUnableToWork: true,
    isExpectMoneyFromRecentEmployer: true,
    isOneParent: true,
    email: "",
    userName: "",
    applicationStatus: "",
    leadId: 0,
    addressOne: "",
    city: "",
    state: "",
    zip: "",
    addressTwo: "",
    timezone: "",
    caseManagerName: "",
    caseManagerPhoneNumber: "",
    lawyerName: "",
    tenantId: "",
    isDocusignEligible: false,
    isSelfServeRegistration: false,
    isIntakeReviewStarted: null,
    isIntakeReviewAccepted: null,
    facilityName: "",
    isJailUser: false,
  });
  const [addressValue, setAddressValue] = useState<string | null>(null);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isUpdatingCaseManager, setIsUpdatingCaseManager] = useState(true);
  const roleInStorage = JSON.parse(get("model")).userRoles[0];

  const featureFlags = useFeatureFlag().featureFlags;

  const getClientUserAttributesApi = () => {
    getClientUserAttributes(searchParams.get("id")).then((res) => {
      var resData = res.data.responseData;
      if (res.data.responseCode == 200) {
        setisSpanish(resData.isSpanish);
        setisCritical(resData.isCritical);
        setisDeceased(resData.isDeceased);
        setisMinor(resData.isMinor);
        setisTerminal(resData.isTerminal);
        setIsDenial(resData.isDenial);
        form1.setFieldsValue(resData);
      }
    });
  };
  const UnMapLeadApi = () => {
    if (
      window.confirm(
        "Are you sure you want to unlink the mapped lead? Unlinking will set the claimant's Denial, Critical, Deceased, Minor, Spanish, and Terminal checkboxes to unchecked"
      )
    ) {
      unMapLead(searchParams.get("id") ?? "0").then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", "Lead has been unlinked successfully.");
          setIsUpdatingCaseManager(false);
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
          setIsUpdatingCaseManager(false);
        }
        getApplicationDetailsByIdApi();
      });
    }
  };
  const clientUserAttributesApi = (
    type:
      | "isSpanish"
      | "isCritical"
      | "isDeceased"
      | "isMinor"
      | "isTerminal"
      | "isDenial",
    value: any
  ) => {
    let payload = {
      EncryptedId: searchParams.get("id"),
      IsSpanish: isSpanish,
      IsCritical: isCritical,
      IsDeceased: isDeceased,
      IsMinor: isMinor,
      IsTerminal: isTerminal,
      isDenial: isDenial,
    };
    if (type == "isSpanish") {
      payload.IsSpanish = value;
    }
    if (type == "isCritical") {
      payload.IsCritical = value;
    }
    if (type == "isDeceased") {
      payload.IsDeceased = value;
    }
    if (type == "isMinor") {
      payload.IsMinor = value;
    }
    if (type == "isTerminal") {
      payload.IsTerminal = value;
    }
    if (type == "isDenial") {
      payload.isDenial = value;
    }

    clientUserAttributes(payload).then((res) => {
      if (res.data.responseCode == 200) {
        getClientUserAttributesApi();
      }
    });
  };
  const getApplicationDetailsByIdApi = () => {
    getClientUserAttributesApi();
    var param = searchParams.get("id") ?? "0";
    setIsPopUpEnterpriseLoader(true);
    getApplicationDetailsById(param).then((res) => {
      var appRes = res.data.responseData;
      setAppData(appRes);
      setIsUpdatingCaseManager(false);
      setAddressValue(appRes.addressOne ?? "");
      pipelineStatusDetailsApi(appRes.applicationStatus);
      form.setFieldsValue({
        ...appRes,
        phoneNumber: formatPhoneNumber(appRes.phoneNumber),
        dateOfBirth: moment(appRes.dateOfBirth).format("YYYY-MM-DD"),
      });
      formAssign_ReAssignEnterprise.setFieldsValue(appRes);
      setIsPopUpEnterpriseLoader(false);
    });
  };

  const updateDocusignEligible = async (
    isEligible: boolean,
    showConfirm: boolean = true
  ) => {
    if (showConfirm) {
      if (
        !window.confirm(
          "Are you sure you want to make this Claimant Docusign eligible?"
        )
      ) {
        return;
      }
    }

    var payload = {
      EncryptedId: searchParams.get("id"),
      IsOtherSSNumber: appData.isOtherSSNumber,
      HeightWithoutShoes: appData.heightWithoutShoes,
      WeightWIthoutShoes: appData.weightWIthoutShoes,
      IsReceiveMoneyUnableToWork: appData.isReceiveMoneyUnableToWork,
      IsExpectMoneyFromRecentEmployer: appData.isExpectMoneyFromRecentEmployer,
      IsOneParent: appData.isOneParent,
      IsDocusignEligible: isEligible,
    };
    const res = await addUpdateApplicationFormStepNine(payload);
    if (res.data.responseCode === 200) {
      FlashMessage("success", "Claimant Docusign Eligibility Updated");
      setAppData({ ...appData, isDocusignEligible: isEligible });
    }
  };

  const onClick: MenuProps["onClick"] = (e) => {
    updateApplicationStatusApi(e.key);
  };

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isSpanish, setisSpanish] = useState(false);
  const [isCritical, setisCritical] = useState(false);
  const [isDeceased, setisDeceased] = useState(false);
  const [isMinor, setisMinor] = useState(false);
  const [isTerminal, setisTerminal] = useState(false);
  const [isDenial, setIsDenial] = useState(false);

  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [isSideMenuPopUpLoader, setIsSideMenuPopUpLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const [
    isaAssign_reassignEnterpriseClaimantModel,
    setIsAssign_reassignEnterPriseClaimantModel,
  ] = useState(false);
  const [Assign_ReAssignEnterprise, setAssign_ReAssignEnterprise] = useState(
    []
  );
  const [tenants, setTenants] = useState([]);
  const [formAssign_ReAssignEnterprise] = Form.useForm();
  const [isPopUpEnterpriseLoader, setIsPopUpEnterpriseLoader] = useState(false);
  const [ReasonNotQualified, setReasonNotQualified] = useState(false);
  const [formReasonNotQuailfied] = Form.useForm();
  const [isReasonNotQualifiedPopUpLoader, setIsReasonNotQualifiedPopUpLoader] =
    useState(false);

  const submitReasonNotQualified = (e: any) => {
    setIsReasonNotQualifiedPopUpLoader(true);
    updateLeadApplicationStatus(
      appData.leadId,
      Enums.status.main_NonQualifiedStatus,
      e.reasonForNotQualified
    ).then((res) => {
      if (res.data.responseCode == 200) {
        FlashMessage("success", "Reason saved successfully.");
        setIsReasonNotQualifiedPopUpLoader(false);
        setReasonNotQualified(false);
      } else {
        setIsReasonNotQualifiedPopUpLoader(false);
      }
    });
  };

  const formSubmitReasonNotQualified = () => {
    formReasonNotQuailfied.submit();
  };
  const cancelReasonNotQualified = () => {
    formReasonNotQuailfied.resetFields();
    setReasonNotQualified(false);
  };

  const updateApplicationStatusApi = (id: any, skipConfirmation?: boolean) => {
    setIsSideMenuPopUpLoader(true);
    var param = searchParams.get("id") ?? "0";
    if (
      skipConfirmation ||
      window.confirm("Are you sure you want to update the status?")
    ) {
      if (id == Enums.status.archived_NonQualifiedStatus && appData.leadId) {
        setReasonNotQualified(true);
      }
      updateApplicationStatus(param, id).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Application status updated Successfully.");
          setIsSideMenuPopUpLoader(false);
        }
        getApplicationDetailsByIdApi();
      });
    } else {
      setIsSideMenuPopUpLoader(false);
    }
  };

  const pipelineStatusDetailsApi = (status: any) => {
    pipelineStatusDetails().then((res) => {
      var resData = res.data.responseData;
      var uniquePipeline: string[] = [];
      resData.map((item: any) => {
        if (uniquePipeline.indexOf(item.pipelineName) === -1) {
          uniquePipeline.push(item.pipelineName);
        }
      });
      var menuItem = menuItems;
      menuItem = [];
      var items = menuItems;
      items = [];
      uniquePipeline.map((item: any) => {
        var menuSubItem = menuItems;
        menuSubItem = [];
        resData
          .filter((a: any) => a.pipelineName == item)
          .map((itemSub: any) => {
            menuSubItem.push(getItem(itemSub.statusName, itemSub.statusId));
          });
        items.push(getItem(item, item, null, menuSubItem));
      });
      menuItem.push(getItem(status, "sub1", null, items));
      setMenuItems(menuItem);
    });
  };

  useEffect(() => {
    getClientUserAttributesApi();
  }, []);

  useEffect(() => {
    getApplicationDetailsByIdApi();
  }, [searchParams.get("id")]);

  const items: MenuItem[] = [
    getItem(
      "Navigation One",
      "sub1",
      <span>
        <img className='img-fluid arrow_image_drp2' src={drp_tab} />
      </span>,
      [
        getItem(
          "Item 1",
          null,
          null,
          [getItem("Option 1", "1"), getItem("Option 2", "2")],
          "group"
        ),
        getItem(
          "Item 2",
          null,
          null,
          [getItem("Option 3", "3"), getItem("Option 4", "4")],
          "group"
        ),
      ]
    ),
  ];

  const formSubmit = () => {
    form.submit();
  };

  const updateClientLeftPanelApi = (e: any) => {
    var payload = {
      Id: e.id,
      FirstName: e.firstName,
      UserName: e.userName || e.email,
      LastName: e.lastName,
      PhoneNumber: formatPhoneNumber(e.phoneNumber),
      Email: e.email,
      AddressOne: addressValue,
      AddressTwo: e.addressTwo,
      City: e.city,
      State: e.state,
      Zip: e.zip,
      Ssn: e.ssn,
      DateOfBirth:
        e.dateOfBirth === "Invalid date"
          ? null
          : moment(e.dateOfBirth).format("YYYY-MM-DDTHH:mm:ss"),
    };
    setIsPopUpLoader(true);
    updateClientLeftPanel(payload).then((res) => {
      if (res.data.responseCode == 200) {
        FlashMessage("success", "Client Details updated Successfully.");
        setOpen(false);
        setIsPopUpLoader(false);
        getApplicationDetailsByIdApi();
      }
    });
  };

  const onClickEdit = () => {
    form.setFieldsValue({
      ...appData,
      phoneNumber: formatPhoneNumber(appData.phoneNumber),
      dateOfBirth: moment(appData.dateOfBirth).format("YYYY-MM-DD"),
    });
    setOpen(true);
  };

  const cancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const addPopUp = () => {
    if (
      window.confirm("Are you sure you want to reset docusign status?") == true
    ) {
      resetDocuSignStatus(searchParams.get("id")).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", res.data.responseMessage);
        } else if (res.data.responseCode != 200) {
          FlashMessage("error", res.data.responseMessage);
        }
      });
    }
  };

  const onChangeHandler = (event: any) => {
    var target = event.target.name;
    var value = event.target.value;
    var param = searchParams.get("id") ?? "0";

    if (target === "emailUser" && value != null && value.trim() != "") {
      EmailExistByUserId(param, value)
        .then((res) => {
          if (res.data.responseCode == responseCodes.success) {
            if (!res.data.responseData) {
              form.setFieldsValue({ email: value });
            } else {
              setIsPopUpLoader(false);
              FlashMessage("info", "Email Already Exists");
              form.setFieldsValue({ email: "" });
            }
          } else if (res.data.responseCode != responseCodes.NotFound) {
            FlashMessage("error", Constants.errorMessages.commonErrorMessage);
            setIsPopUpLoader(false);
          }
        })
        .catch((Error) => {});
    }
  };

  const onBlur = (e: any) => {
    var onlyNumber = formatPhoneNumber(e.target.value);

    form.setFieldsValue({ phoneNumber: onlyNumber });
  };

  const onChangeisSpanish = (e: any) => {
    setisSpanish(e.target.checked);
    clientUserAttributesApi("isSpanish", e.target.checked);
  };
  const onChangeisCritical = (e: any) => {
    setisCritical(e.target.checked);
    clientUserAttributesApi("isCritical", e.target.checked);
  };
  const onChangeisDeceased = (e: any) => {
    setisDeceased(e.target.checked);
    clientUserAttributesApi("isDeceased", e.target.checked);
  };
  const onChangeisMinor = (e: any) => {
    setisMinor(e.target.checked);
    clientUserAttributesApi("isMinor", e.target.checked);
  };
  const onChangeisTerminal = (e: any) => {
    setisTerminal(e.target.checked);
    clientUserAttributesApi("isTerminal", e.target.checked);
  };
  const onChangeIsDenial = (e: any) => {
    setIsDenial(e.target.checked);
    clientUserAttributesApi("isDenial", e.target.checked);
  };

  const copyToClipboard = (mindsetUserId: any) => {
    navigator.clipboard.writeText(mindsetUserId);
    FlashMessage("success", "Copied");
  };
  const predefinedValue = {
    label: addressValue,
    value: Constants.googleAutoCompleteStaticId,
  };

  const formSubmitAssign_ReAssignEnterprise = () => {
    formAssign_ReAssignEnterprise.submit();
  };
  const updateAssign_ReAssignEnterpriseApi = (e: any) => {
    setIsPopUpEnterpriseLoader(true);
    var claimantsId = searchParams.get("id") ?? "0";
    if (
      window.confirm(
        "Are you sure, you want to Assign/Reassign the Enterprise?"
      )
    ) {
      updateAssign_ReAssignEnterpriseClaimants(claimantsId, e.tenantId).then(
        (res) => {
          if (e.tenantId != null) {
            if (res.data.responseCode == Enums.responseCodes.success) {
              var resData = res.data.responseData;
              FlashMessage(
                "success",
                "Enterprise status updated Successfully."
              );
              setAssign_ReAssignEnterprise(resData);
              formAssign_ReAssignEnterprise.resetFields();
              setIsPopUpEnterpriseLoader(false);
            } else {
              setIsPopUpEnterpriseLoader(false);
            }
          }

          setIsAssign_reassignEnterPriseClaimantModel(false);
          getTenantApi();
          getApplicationDetailsByIdApi();
        }
      );
    } else {
      setIsPopUpEnterpriseLoader(false);
    }
  };
  const getTenantApi = () => {
    getTenant().then((res) => {
      var resData = res.data.responseData;
      setTenants(resData);
    });
  };
  useEffect(() => {
    getTenantApi();
  }, []);
  const assign_reassignEnterprise = () => {
    getApplicationDetailsByIdApi();
    setIsAssign_reassignEnterPriseClaimantModel(true);
  };
  const cancelAssign_reAssignEnterprise = () => {
    setIsAssign_reassignEnterPriseClaimantModel(false);
    formAssign_ReAssignEnterprise.resetFields();
  };

  /* New Claims Functionality */
  const [claims, setClaims] = useState<any[]>([]);
  const getClaimantClaims = async () => {
    const {
      data: { responseData },
    } = await getClaims(appData.id);
    setClaims(responseData);
  };

  useEffect(() => {
    if (appData && appData.id) {
      getClaimantClaims();
    }
  }, [appData]);

  if (!appData) return null;

  return (
    <>
      {isLoader && <Loader></Loader>}

      {open && !isLoader && (
        <Modal
          title='Edit Client Details'
          centered
          okText={isPopUpLoader ? "Loading" : "Update"}
          open={open}
          onCancel={() => cancel()}
          width={1000}
          onOk={() => (isPopUpLoader ? null : formSubmit())}
        >
          {isPopUpLoader && <Loader></Loader>}
          {!isPopUpLoader && (
            <Form
              className='mt-4'
              form={form}
              name='ClientDetailForm'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={appData}
              autoComplete='off'
              onFinish={updateClientLeftPanelApi}
            >
              <div className='form-group row margin_mobile'>
                <Form.Item name='id' />
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  1. First Name
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='firstName'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                      { validator: validateBlankSpace },
                    ]}
                  >
                    <Input
                      maxLength={100}
                      className={`form-control input_form_sign`}
                    />
                  </Form.Item>
                </div>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  2. Last Name
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='lastName'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                      { validator: validateBlankSpace },
                    ]}
                  >
                    <Input
                      maxLength={100}
                      className={`form-control input_form_sign`}
                    />
                  </Form.Item>
                </div>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  3. Phone Number
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='phoneNumber'
                    rules={[
                      { validator: validatePhoneNumberFormat, required: true },
                    ]}
                  >
                    <Input
                      onBlur={onBlur}
                      maxLength={12}
                      className={`form-control input_form_sign`}
                    />
                  </Form.Item>
                </div>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  4. Email
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='email'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                      { validator: validateBlankSpace },
                      { validator: plusValidation },
                      { validator: validateEmail },
                    ]}
                  >
                    <Input
                      onBlur={onChangeHandler}
                      name='emailUser'
                      maxLength={100}
                      className={`form-control input_form_sign`}
                    />
                  </Form.Item>
                </div>
                {appData.userName !== appData.email && (
                  <>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      4a. Username
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='userName'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          { validator: validateBlankSpace },
                        ]}
                      >
                        <Input
                          onBlur={onChangeHandler}
                          name='userName'
                          disabled={appData.email === appData.userName}
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  5. Street Address
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='addressOne'
                    rules={[{ validator: validateBlankSpace }]}
                  >
                    {addressValue != null && (
                      <GooglePlacesAutocompleteWrapper
                        apiKey={process.env.REACT_APP_API_KEY ?? ""}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          value: predefinedValue,
                          isClearable: false,
                          onChange: (o: any) => {
                            form.setFieldsValue({
                              address: o?.label ?? "",
                            });
                            if (o != null) {
                              var addArr = o.label.split(",");
                              var street = addArr.length > 0 ? addArr[0] : null;
                              var city = addArr.length > 0 ? addArr[1] : null;
                              var state = addArr.length > 0 ? addArr[2] : null;
                              form.setFieldsValue({
                                addressOne: trim(street),
                                city: trim(city),
                                state: trim(state),
                              });
                              setAddressValue(street);
                            }
                          },
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  6. Address 2 (optional)
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='addressTwo'
                    rules={[
                      { validator: validateBlankSpace },
                      {
                        validator: lessThanFourCharacters,
                      },
                    ]}
                  >
                    <Input
                      placeholder='i.e. Apt,Ste, Fl, Unit, etc..'
                      maxLength={1000}
                      className={`form-control input_form_sign`}
                    />
                  </Form.Item>
                </div>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  7. City
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='city'
                    rules={[{ validator: validateBlankSpace }]}
                  >
                    <Input
                      maxLength={100}
                      className={`form-control input_form_sign`}
                    />
                  </Form.Item>
                </div>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  8. State
                </label>
                <div className='col-sm-9 p-0'>
                  <StatesDropDownFormItem />
                </div>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  9. Zip
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='zip'
                    rules={[
                      { validator: validateBlankSpace },
                      { validator: number },
                      { validator: validateZipCode },
                    ]}
                  >
                    <Input
                      maxLength={100}
                      className={`form-control input_form_sign`}
                    />
                  </Form.Item>
                </div>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  10. SSN
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='ssn'
                    rules={[
                      { validator: validateBlankSpace },
                      { validator: number },
                      { validator: minLengthNine },
                    ]}
                  >
                    <SocialSecurityNumberInput />
                  </Form.Item>
                </div>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  11. Date Of Birth
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item name='dateOfBirth'>
                    <Input
                      maxLength={100}
                      type='date'
                      className={`form-control input_form_sign`}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          )}
        </Modal>
      )}
      <div className='p-2'>
        <h4 className={`name_log mb-0`}>
          {appData?.firstName} {appData?.lastName}
        </h4>
        <h5 className={"phone_log"}>
          <span key={appData?.phoneNumber}>
            <a href={`tel:${formatPhoneNumber(appData.phoneNumber)}`}>
              {formatPhoneNumber(appData.phoneNumber)}
            </a>
          </span>
          <span className={"save_num"}> {appData?.timezone}</span>
          <span
            data-cy='edit-claimaint-details-btn'
            onClick={onClickEdit}
            className={"save_num cursorPointer"}
          >
            {" "}
            edit
          </span>
        </h5>
        <p className={"mail_log mb-0"}>{appData?.email}</p>
        <p className={"mail_log"}>
          {appData?.addressOne} {appData?.addressTwo} {appData?.city}{" "}
          {appData?.state} {appData?.zip}
        </p>
        <p className={"mail_log"}>
          <div className='d-flex '>
            Claimant ID: {appData?.id}
            <Tooltip title={"Copy to Clipboard"}>
              <CopyOutlined
                onClick={() => copyToClipboard(appData?.id)}
                style={{ fontSize: "20px", color: "#747497" }}
              />
            </Tooltip>
            {appData.isSelfServeRegistration && (
              <div
                style={{
                  borderRadius: "16px",
                  border:
                    "1px solid var(--Colors-Neutral-Border-colorBorder, #D9D9D9)",
                  background: "var(--Colors-Mindset-Site-Grey, #F6F6F6)",
                  maxWidth: "300px",
                  display: "flex",
                  marginLeft: "5px",
                  marginTop: "-3px",
                  padding: "2px var(--Space-Padding-paddingXS, 8px)",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "var(--Space-Margin-marginXXS, 4px)",
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                >
                  <path
                    d='M4.76823 4.81511C4.0026 4.03907 4.0026 2.77865 4.76823 2.00261C5.53385 1.22657 6.77344 1.22657 7.53906 2.00261L8 2.47136L8.46094 2.00261C9.22656 1.22657 10.4661 1.22657 11.2318 2.00261C11.9974 2.77865 11.9974 4.03907 11.2318 4.81511L8.58594 7.50261C8.42448 7.66667 8.21354 7.7474 8 7.7474C7.78646 7.7474 7.57552 7.66667 7.41406 7.50261L4.76823 4.81511ZM15.2969 10.0078C15.638 10.4714 15.5391 11.1224 15.0755 11.4635L11.7786 13.8932C11.1693 14.3412 10.4349 14.5833 9.67708 14.5833H5.5H1.33333C0.872396 14.5833 0.5 14.2109 0.5 13.75V12.0833C0.5 11.6224 0.872396 11.25 1.33333 11.25H2.29167L3.46094 10.3125C4.05208 9.83855 4.78646 9.58334 5.54427 9.58334H7.58333H8H9.66667C10.1276 9.58334 10.5 9.95573 10.5 10.4167C10.5 10.8776 10.1276 11.25 9.66667 11.25H8H7.58333C7.35417 11.25 7.16667 11.4375 7.16667 11.6667C7.16667 11.8958 7.35417 12.0833 7.58333 12.0833H10.724L13.8411 9.78646C14.3047 9.44532 14.9557 9.54428 15.2969 10.0078ZM5.54167 11.25H5.51823C5.52604 11.25 5.53385 11.25 5.54167 11.25Z'
                    fill='#3E3E56'
                  />
                </svg>
                Self Serve
              </div>
            )}
          </div>
        </p>

        {featureFlags[
          Constants.featureFlags.claimantAccountCreationFromLeadFeatureFlag
        ] &&
          appData.applicationStatus == "Pending Registration" && (
            <SendSecureRegistrationLink
              userId={searchParams.get("id") ?? "0"}
              phoneNumber={appData.phoneNumber}
            />
          )}

        {appData.mindsetUserId && <ClaimantAssignments appData={appData} />}

        <Form
          className='mt-4'
          form={form1}
          name='LeadUserAttributeForm'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete='off'
        >
          <div className='grid-container'>
            <div className='grid-item  '>
              <label className='col-form-label fill_description des_font '>
                Spanish
              </label>
              <Form.Item name='isSpanish' valuePropName='checked'>
                <Checkbox onChange={onChangeisSpanish} />
              </Form.Item>
            </div>
            <div className='grid-item '>
              <label className='col-form-label fill_description des_font'>
                Critical
              </label>
              <Form.Item name='isCritical' valuePropName='checked'>
                <Checkbox onChange={onChangeisCritical} />
              </Form.Item>
            </div>
            <div className='grid-item'>
              <label className='col-form-label fill_description des_font'>
                Deceased
              </label>
              <Form.Item name='isDeceased' valuePropName='checked'>
                <Checkbox onChange={onChangeisDeceased} />
              </Form.Item>
            </div>
            <div className='grid-item'>
              <label className='col-form-label fill_description des_font'>
                Minor
              </label>
              <Form.Item name='isMinor' valuePropName='checked'>
                <Checkbox onChange={onChangeisMinor} />
              </Form.Item>
            </div>
            <div className='grid-item'>
              <label className='col-form-label fill_description des_font'>
                Terminal
              </label>
              <Form.Item name='isTerminal' valuePropName='checked'>
                <Checkbox onChange={onChangeisTerminal} />
              </Form.Item>
            </div>
            <div className='grid-item'>
              <label className='col-form-label fill_description des_font'>
                Denial
              </label>
              <Form.Item name='isDenial' valuePropName='checked'>
                <Checkbox onChange={onChangeIsDenial} />
              </Form.Item>
            </div>
          </div>
        </Form>

        <CustomerClaims
          claims={claims}
          getClaimantClaims={getClaimantClaims}
          updateDocusignEligible={updateDocusignEligible}
          getApplicationDetailsByIdApi={getApplicationDetailsByIdApi}
          claimantMindsetUserId={appData.id}
          applicationStatus={appData.applicationStatus}
          caseManagerName={appData.caseManagerName}
          showPreClaimFlow={!appData.isIntakeReviewAccepted}
          isSelfServeRegistration={appData.isSelfServeRegistration}
          isJailUser={appData.isJailUser}
          isIntakeReviewStarted={appData.isIntakeReviewStarted}
          isIntakeReviewAccepted={appData.isIntakeReviewAccepted}
          updateApplicationStatus={updateApplicationStatusApi}
          onReject={() => {
            if (appData.leadId) {
              setReasonNotQualified(true);
            }
          }}
        />

        <div id='container' />
        {isSideMenuPopUpLoader && (
          <Loader overrideClass='loaderLeftPanelHeight'></Loader>
        )}
        {menuItems.length > 0 && !isSideMenuPopUpLoader && (
          <Menu onClick={onClick} mode='vertical' items={menuItems} />
        )}
        {appData.leadId != null && appData.leadId > 0 && (
          <button
            onClick={() =>
              navigate(
                "/prequalificationclient?id=" +
                  (searchParams.get("id") ?? "0") +
                  "&leadId=" +
                  appData.leadId
              )
            }
            type='button'
            className={
              "btn btn-primary dropdown_button w-100 mt-2" +
              (location.pathname.indexOf(
                Constants.pageRoutes.prequalificationclient
              ) >= 0
                ? " activemenu"
                : "")
            }
          >
            Pre-Qualification
          </button>
        )}

        <button
          onClick={() =>
            navigate(
              `/clientsnotes?id=${
                searchParams.get("id") ?? "0"
              }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
                searchParams
              )}`
            )
          }
          type='button'
          className={
            "btn btn-primary dropdown_button w-100 mt-2" +
            (location.pathname.indexOf(Constants.pageRoutes.notes) >= 0
              ? " activemenu"
              : "")
          }
        >
          Notes
        </button>
        {
          <Dropdown>
            <Dropdown.Toggle
              variant='success'
              id='dropdown-basic'
              className='border-none-class p-0 w-100 mt-2'
            >
              <button
                className={
                  "btn btn-secondary dropdown-toggle dropdown_button w-100" +
                  (location.pathname.indexOf(
                    Constants.pageRoutes.application
                  ) >= 0 ||
                  location.pathname.indexOf(Constants.pageRoutes.templates) >=
                    0 ||
                  location.pathname.indexOf(
                    Constants.pageRoutes.uploadDocuments
                  ) >= 0 ||
                  location.pathname.indexOf(
                    Constants.pageRoutes.requestedInfo
                  ) >= 0 ||
                  location.pathname.indexOf(
                    Constants.pageRoutes.sendPhysicalMail
                  ) >= 0 ||
                  location.pathname.indexOf(
                    Constants.pageRoutes.generateDocuments
                  ) >= 0 ||
                  location.pathname.indexOf(Constants.pageRoutes.sendFax) >= 0
                    ? " activemenu"
                    : "")
                }
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                {location.pathname.indexOf(Constants.pageRoutes.application) >=
                0 ? (
                  <span>Application</span>
                ) : location.pathname.indexOf(Constants.pageRoutes.templates) >=
                  0 ? (
                  <span>Templates</span>
                ) : location.pathname.indexOf(
                    Constants.pageRoutes.uploadDocuments
                  ) >= 0 ? (
                  <span>Documents</span>
                ) : location.pathname.indexOf(
                    Constants.pageRoutes.requestedInfo
                  ) >= 0 ? (
                  <span>Contact Messages</span>
                ) : location.pathname.indexOf(
                    Constants.pageRoutes.sendPhysicalMail
                  ) >= 0 ? (
                  <span>Send Physical Mail</span>
                ) : location.pathname.indexOf(
                    Constants.pageRoutes.generateDocuments
                  ) >= 0 ? (
                  <span>Generate Documents</span>
                ) : location.pathname.indexOf(Constants.pageRoutes.sendFax) >=
                  0 ? (
                  <span>Send Fax</span>
                ) : (
                  <span>Application</span>
                )}
                <span>
                  <img className='img-fluid arrow_image_drp2' src={drp_tab} />
                </span>
              </button>
            </Dropdown.Toggle>
            <Dropdown.Menu className='w-100'>
              <Dropdown.Item className='item_drp'>
                <h4
                  onClick={() =>
                    navigate("/application?id=" + searchParams.get("id") ?? "0")
                  }
                  className='heading_tab cursorPointer'
                >
                  Application
                </h4>
              </Dropdown.Item>
              <Dropdown.Item className='item_drp'>
                <h4
                  onClick={() =>
                    navigate("/templates?id=" + searchParams.get("id") ?? "0")
                  }
                  className='heading_tab cursorPointer'
                >
                  Templates
                </h4>
              </Dropdown.Item>
              <Dropdown.Item className='item_drp'>
                <h4
                  onClick={() =>
                    navigate(
                      "/uploaddocuments?id=" + searchParams.get("id") ?? "0"
                    )
                  }
                  className='heading_tab cursorPointer'
                >
                  Documents
                </h4>
              </Dropdown.Item>
              <Dropdown.Item className='item_drp'>
                <h4
                  onClick={() =>
                    navigate(
                      "/requestedinfo?id=" + searchParams.get("id") ?? "0"
                    )
                  }
                  className='heading_tab cursorPointer'
                >
                  Contact Messages
                </h4>
              </Dropdown.Item>
              {Constants.permissionedRolesBySection.sendPhysicalMail.readGroup.includes(
                roleInStorage
              ) && (
                <Dropdown.Item className='item_drp'>
                  <h4
                    onClick={() =>
                      navigate(
                        "/sendphysicalmail?id=" + searchParams.get("id") ?? "0"
                      )
                    }
                    className='heading_tab cursorPointer'
                  >
                    Send Physical Mail
                  </h4>
                </Dropdown.Item>
              )}
              {Constants.permissionedRolesBySection.generateDocuments.readGroup.includes(
                roleInStorage
              ) && (
                <Dropdown.Item className='item_drp'>
                  <h4
                    onClick={() =>
                      navigate(
                        "/generateDocuments?id=" + searchParams.get("id") ?? "0"
                      )
                    }
                    className='heading_tab cursorPointer'
                  >
                    Generate Documents
                  </h4>
                </Dropdown.Item>
              )}
              {Constants.permissionedRolesBySection.sendFax.readGroup.includes(
                roleInStorage
              ) && (
                <Dropdown.Item className='item_drp'>
                  <h4
                    onClick={() =>
                      navigate("/sendFax?id=" + searchParams.get("id") ?? "0")
                    }
                    className='heading_tab cursorPointer'
                  >
                    Send Fax
                  </h4>
                </Dropdown.Item>
              )}

              {featureFlags[
                Constants.featureFlags
                  .claimantAccountCreationFromLeadFeatureFlag
              ] && (
                <SendSecureRegistrationLink
                  userId={searchParams.get("id") ?? "0"}
                  phoneNumber={appData.phoneNumber}
                  asMenuItem
                />
              )}

              <Dropdown.Item className='item_drp'>
                <h4
                  onClick={() => addPopUp()}
                  className='heading_tab cursorPointer'
                >
                  {" "}
                  Reset Docusign Status
                </h4>
              </Dropdown.Item>
              {!appData.isDocusignEligible && (
                <Dropdown.Item className='item_drp'>
                  <h4
                    onClick={() => updateDocusignEligible(true)}
                    className='heading_tab cursorPointer'
                  >
                    Make Docusign Eligible
                  </h4>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        }
        {isUpdatingCaseManager ? (
          ""
        ) : appData.leadId == null ? (
          ""
        ) : (
          <button
            onClick={UnMapLeadApi}
            type='button'
            className={
              "btn btn-primary maping_button w-100 mt-3 deactivate_button"
            }
          >
            Unlink Lead
          </button>
        )}
        <EnterpriseAssignment
          isLead={false}
          userId={searchParams.get("id") ?? "0"}
          roleInStorage={roleInStorage}
          facilityName={appData.facilityName}
        />
      </div>
      <>
        <Modal
          open={ReasonNotQualified}
          onCancel={() => cancelReasonNotQualified()}
          onOk={() => formSubmitReasonNotQualified()}
          width={500}
        >
          {isReasonNotQualifiedPopUpLoader && (
            <Loader style={{ width: 1000 }}></Loader>
          )}
          {!isReasonNotQualifiedPopUpLoader && (
            <Form
              className='mt-4'
              form={formReasonNotQuailfied}
              name='post'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              onFinish={submitReasonNotQualified}
            >
              <label className='col-form-label fill_description col-sm-12 des_font mt-3'>
                Reason for Not Qualified
              </label>
              <div className='col-sm-12 p-0 mt-3'>
                <Form.Item
                  name='reasonForNotQualified'
                  rules={[
                    { required: true, message: "Please select a reason" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp='label'
                    options={Constants.notQualifiedReason.map((loc: any) => ({
                      label: loc.name,
                      value: loc.name,
                    }))}
                  />
                </Form.Item>
              </div>
            </Form>
          )}
        </Modal>
      </>
    </>
  );
};

export default CustomerLeftPanel;
