import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  getLeadsInboundCount,
  getProfileViewDetails,
} from "../../actions/application";
import {
  formatPhoneNumber,
  getOnlyShowMyCasesFromSearchParams,
} from "../../utils/commonHelper";
import { useEffect, useState, Fragment } from "react";
import { AutoComplete, Input, Button } from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import { searchLeadsandClients } from "../../actions/application";
import drp_tab from "../../images/drp_tab.png";
import drp_admin from "../../images/drp_admin.png";
import type { SelectProps } from "antd/es/select";
import "./index.scss";
import { getAllPipeline, getPipeline } from "../../actions/pipeline";
import { get } from "../../utils/clientStorageUtils";
import * as signalR from "@microsoft/signalr";
import { Constants } from "../../common/constants";
import CreateLeadButton from "./CreateLeadButton";
import { createDispatchHook } from "react-redux";
let timeoutId: null | ReturnType<typeof setTimeout> = null;

let timeoutId1: null | ReturnType<typeof setTimeout> = null;
const CustomerHeader = () => {
  const [pipe, setPipe] = useState([]);
  const [leadPipe, setLeadPipe] = useState([]);

  const [searchValue, setSearchValue] = useState();
  const navigate = useNavigate();
  const [leadsandclient, setLeadsandclient] = useState([]);
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const [leadsCounts, setLeadsCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [profileview, setProfileView] = useState([]);
  const [valueHandle, setValueHandle] = useState<string>();
  let isProfile = false;
  let isSearch = false;
  const getleadsInboundCountApi = () => {
    getLeadsInboundCount().then((res) => {
      var resData = res.data.responseData;
      setLeadsCount(resData);
    });
  };

  useEffect(() => {
    getleadsInboundCountApi();
  }, []);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_Mindset_API_URL ?? ""}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("TagLeadsCount", (res: any) => {
      getleadsInboundCountApi();
    });
    connection.start().catch((err) => console.log(err));
    return () => {
      connection.off("TagLeadsCount");
      connection

        .stop()

        .catch((err) => console.log("connection Stop Error"));
    };
  }, [searchParams.get("id")]);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL ?? ""}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("TagLeadsCount", (res: any) => {
      getleadsInboundCountApi();
    });
    connection.start().catch((err) => console.log(err));
    return () => {
      connection.off("TagLeadsCount");
      connection

        .stop()

        .catch((err) => console.log("connection Stop Error"));
    };
  }, [searchParams.get("id")]);

  const getPipelineApi = () => {
    getPipeline().then((res) => {
      if (res.data.responseCode == 200) {
        var pipelineData = res.data.responseData;
        var pipelineOptions = pipelineData
          .filter(
            (ele: any) =>
              ele.id !== 6 ||
              (ele.id === 6 &&
                Constants.adminGroup.indexOf(roleInStorage) !== -1)
          )
          .map((ele: any) => {
            return (
              <>
                <Dropdown.Item className='item_drp custom-drp'>
                  <h5
                    onClick={() =>
                      navigate(
                        `/clients?id=${
                          ele.id
                        }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
                          searchParams
                        )}`
                      )
                    }
                    className='heading_tab custom-view'
                  >
                    {ele.name}
                  </h5>
                </Dropdown.Item>
              </>
            );
          });
        const onlyShowMyCases =
          getOnlyShowMyCasesFromSearchParams(searchParams) === "true";

        const isOnClientsPage = location.pathname === "/clients";
        pipelineOptions.push(
          <>
            <Dropdown.Item className='item_drp'>
              <h5
                onClick={() => {
                  const currentSearchParams = new URLSearchParams(searchParams);
                  currentSearchParams.set(
                    "onlyShowMyCases",
                    onlyShowMyCases ? "false" : "true"
                  );

                  setSearchParams(currentSearchParams);
                  if (!isOnClientsPage) {
                    navigate(
                      `/clients?id=${pipelineData[0].id}&onlyShowMyCases=true`
                    );
                  }
                }}
                className='heading_tab '
              >
                {onlyShowMyCases
                  ? isOnClientsPage
                    ? "All Cases"
                    : "My Cases"
                  : "My Cases"}
              </h5>
            </Dropdown.Item>
          </>
        );
        setPipe(pipelineOptions);
      }
    });
  };

  const getAllPipelineApi = () => {
    getAllPipeline().then((res) => {
      var LeadPipeline = res.data.responseData;
      setLeadPipe(
        LeadPipeline.filter((a: any) => a.isLead == true).map((d: any) => {
          return (
            <Fragment key={d.id}>
              <Dropdown.Item className='item_drp custom-drp'>
                <h5
                  onClick={() => navigate("/leads?id=" + d.id)}
                  className='heading_tab custom-view'
                >
                  {d.name}
                </h5>
              </Dropdown.Item>
            </Fragment>
          );
        })
      );
    });
  };

  const navigateClient = (encryptedId: any, item: any) => {
    console.log(options, "options");
    if (item.isLead == true) {
      navigate("/leadsnotes?id=" + item.id);
    } else if (roleInStorage != Constants.appUserRoles.preQualMsr) {
      navigate(
        `/clientsnotes?id=${encryptedId}&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
          searchParams
        )}`
      );
    }
  };
  const navigateProfileView = (item: any) => {
    if (item.mindsetUserId == null) {
      navigate("/leadsnotes?id=" + item.leadId);
    } else if (roleInStorage != Constants.appUserRoles.preQualMsr) {
      navigate(
        `/clientsnotes?id=${
          item.encryptedId
        }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(searchParams)}`
      );
    }
  };
  const navigateLead = () => {
    navigate("/leads");
  };
  const navigateGlobalTaskScreen = () => {
    navigate("/globaltaskscreen");
  };
  const navigateCaseManagementScreen = () => {
    navigate("/casemanagement");
  };

  const getProfileViewDetailsApi = () => {
    isProfile = true;

    getProfileViewDetails().then((res) => {
      if (res.data.responseCode == 200) {
        var data1 = res.data.responseData;
        setProfileView(res.data.responseData);
        if (data1 == null || data1.length == 0) {
          isProfile = false;
          setOptions([
            {
              label: (
                <div style={{ display: "flex" }}>
                  <span> No Record</span>
                </div>
              ),
            },
          ]);
        } else {
          isProfile = false;
          setOptions(
            data1.map((item: any) => {
              const newItem = JSON.stringify(item);
              return {
                value: "",
                label: (
                  <div
                    className={
                      item.mindsetUserId == null
                        ? "custom_leads"
                        : "custom_customer"
                    }
                    onClick={() => navigateProfileView(item)}
                    style={{ display: "flex" }}
                  >
                    {item.mindsetUserId == null && (
                      <span className='leads_icon'>L</span>
                    )}
                    {item.mindsetUserId != null && (
                      <span className='customer_icon'>C</span>
                    )}
                    {!isProfile && (
                      <span>
                        {item.fullName + " | "}
                        <a href={`tel:${formatPhoneNumber(item.phoneNumber)}`}>
                          {formatPhoneNumber(item.phoneNumber)}
                        </a>
                      </span>
                    )}
                  </div>
                ),
                item: JSON.parse(newItem),
              };
            })
          );
        }
      }
    });
    isProfile = true;
    setOptions([
      {
        value: "",
        label: (
          <div style={{ display: "flex" }}>
            {isProfile && <span>Loading......</span>}
          </div>
        ),
      },
    ]);
  };

  const getFormattedSearchResultDisplayForItem = (item: any) => {
    let displayValue = item.fullName;

    if (item.id) {
      displayValue += " | " + item.id;
    }

    if (item.email) {
      displayValue += " | " + item.email;
    }

    if (item.maskedSsn) {
      displayValue += " | " + item.maskedSsn;
    }

    if (item.phoneNumber) {
      displayValue += " | " + formatPhoneNumber(item.phoneNumber);
    }

    return displayValue;
  };

  const searchResult = (query: string) => {
    searchLeadsandClients(query).then((res) => {
      var resData = res.data.responseData;
      var filterRecord = resData;
      setLeadsandclient(resData);
      if (filterRecord == null || filterRecord.length == 0) {
        isSearch = false;
        setOptions([
          {
            value: "",
            label: (
              <div style={{ display: "flex" }}>
                <span> No Record</span>
              </div>
            ),
          },
        ]);
      } else {
        isSearch = false;
        setOptions(
          filterRecord.map((item: any) => {
            const newItem = JSON.stringify(item);
            return {
              value: "",
              label: (
                <div
                  className={item.isLead ? "custom_leads" : "custom_customer"}
                  onClick={() => navigateClient(item.encryptedId, item)}
                  style={{ display: "flex" }}
                >
                  {item.isLead && <span className='leads_icon'>L</span>}
                  {!item.isLead && <span className='customer_icon'>C</span>}
                  {!isSearch && (
                    <span>{getFormattedSearchResultDisplayForItem(item)}</span>
                  )}
                </div>
              ),
              item: JSON.parse(newItem),
            };
          })
        );
      }
    });
    isSearch = true;
    setOptions([
      {
        value: "",
        label: (
          <div style={{ display: "flex" }}>
            {isSearch && !isProfile && <span>Loading......</span>}
          </div>
        ),
      },
    ]);
  };

  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);

  const handleonSearch = (value: string) => {
    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }
    setOptions([]);
    timeoutId = setTimeout(function () {
      // value.trim().toLocaleLowerCase() ? searchResult(value.trim()) : setOptions([])
      value.trim().toLocaleLowerCase()
        ? searchResult(value.trim())
        : getProfileViewDetailsApi();
    }, 1000);
  };
  const handleClick = (item: any) => {
    if (timeoutId1 != null) {
      clearTimeout(timeoutId1);
    }
    if (item.target.value != "") {
      return;
    }
    const optionLength = options?.length || 0;
    if (optionLength < 10) {
      //magic number 10 from server. this is to not call api if options are already loaded
      setOptions([
        {
          value: "",
          label: (
            <div style={{ display: "flex" }}>{<span>Loading......</span>}</div>
          ),
        },
      ]);
      timeoutId1 = setTimeout(function () {
        getProfileViewDetailsApi();
      }, 0);

      return;
    }
  };
  useEffect(() => {
    getPipelineApi();
  }, [searchParams.get("onlyShowMyCases"), searchParams.get("id")]);

  useEffect(() => {
    getAllPipelineApi();
  }, []);

  const isMyClaimants =
    getOnlyShowMyCasesFromSearchParams(searchParams) === "true";
  return (
    <>
      <div className='col-md-8'>
        <div className='d-flex justify-content-start'>
          <Dropdown
            className='ms-0 w-20 ant_drp2 basic-view'
            style={{ minWidth: "20%" }}
          >
            <Dropdown.Toggle
              variant='success'
              id='dropdown-basic'
              className='border-none-class p-0 w-100'
            >
              <button
                className='btn btn-secondary dropdown-toggle w-100 button_leads'
                type='button'
                id='dropdownMenuButton1'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                Leads
                <span className={"count_lead"}>+{leadsCounts}</span>
                <span>
                  <img className='img-fluid arrow_image_drp2' src={drp_admin} />
                </span>
              </button>
            </Dropdown.Toggle>
            <Dropdown.Menu className='w-100'>{leadPipe}</Dropdown.Menu>
          </Dropdown>

          {roleInStorage == Constants.appUserRoles.preQualMsr ? (
            <></>
          ) : (
            <>
              <Dropdown
                className='ms-3 ant_drp basic-view'
                style={{ minWidth: "20%" }}
              >
                <Dropdown.Toggle
                  variant='success'
                  id='dropdown-basic'
                  className='border-none-class p-0 w-100'
                >
                  <button
                    className='btn btn-secondary dropdown-toggle w-100 button_clients'
                    type='button'
                    data-cy='menu-claimaints-dropdown'
                    id='dropdownMenuButton'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    {isMyClaimants ? "My Claimants" : "Claimants"}
                    <span>
                      <img
                        className='img-fluid arrow_image_drp2'
                        src={drp_tab}
                      />
                    </span>
                  </button>
                </Dropdown.Toggle>
                <Dropdown.Menu className='w-100'>{pipe}</Dropdown.Menu>
              </Dropdown>
            </>
          )}
          <button
            onClick={() => navigateGlobalTaskScreen()}
            type='button'
            style={{ width: "20%", padding: "0 !important" }}
            className={`btn btn-primary button_global_tasks ms-3 p-0`}
          >
            Tasks
          </button>
          {Constants.permissionedRolesBySection.caseManagement.readGroup.includes(
            roleInStorage
          ) && (
            <button
              onClick={() => navigateCaseManagementScreen()}
              type='button'
              style={{ width: "24%" }}
              className={`btn btn-primary button_case_manage ms-3 p-0`}
            >
              Case Management
            </button>
          )}
        </div>
      </div>

      <div className='col-md-4 text-center'>
        <div className='d-flex justify-content-end align-items-center mobie_search'>
          <AutoComplete
            className='search_ant_drp'
            dropdownMatchSelectWidth={500}
            style={{ width: 500 }}
            options={options}
            // onSelect={onSelect}
            onClick={handleClick}
            onSearch={handleonSearch}
          >
            <Input
              placeholder='Search by number, email, name, claimantId or last 4 SSN'
              className={"input_style"}
              value={searchValue}
              suffix={
                <Button
                  style={{
                    marginRight: -12,
                    marginTop: -10,
                  }}
                  size='large'
                  type='primary'
                >
                  Go
                </Button>
              }
            />
          </AutoComplete>
          <div style={{ marginLeft: "16px" }}>
            <CreateLeadButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerHeader;
