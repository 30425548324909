import React, { useState, useEffect } from "react";
import "./index.scss";
import dwdtemp from "../../images/download_temp.png";
import upload from "../../images/upload.png";
import info from "../../images/info.png";
import { Modal, Table, Tooltip } from "antd";
import {
  DocumentListAction,
  DownloadDocumentAction,
  DeleteDocumentAction,
  UploadDocumentsAction,
  getDocumentsCount,
} from "../../actions/documents";
import { FlashMessage } from "../../utils/flash_message";
import { useSearchParams } from "react-router-dom";
import { DeleteFilled, DownloadOutlined, EyeFilled } from "@ant-design/icons";
import moment from "moment";
import { Constants } from "../../common/constants";
import Loader from "../../components/Loader";

const UploadDoc = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [addDocumentCount, setaddDocumentCount] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [documenttype, setDocumenttype] = useState(1);
  const [documentData, setDocumentData] = useState([]);
  const [searchParams] = useSearchParams();
  const [sSACorrespondence, setsSACorrespondence] = useState(0);
  const [medical_EducationRecords, setmedical_EducationRecords] = useState(0);
  const [repDocs, setrepDocs] = useState(0);
  const [claimantSuppliedEvidence, setclaimantSuppliedEvidence] = useState(0);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);

  const columns = [
    {
      title: "Document Name",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Uploaded Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (item: any, file: any) => {
        return moment.utc(item).local().format(Constants.dateTimeFormat);
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (item: any, file: any) => {
        return (
          <div>
            <Tooltip title={"Download"}>
              <a
                className='me-2'
                onClick={() => downloadDocumentApi(item, file)}
              >
                <DownloadOutlined
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title={"Delete"}>
              <a
                className='me-2'
                onClick={() => {
                  deleteDocumentApi(item, file);
                }}
              >
                <DeleteFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title={"View"}>
              <a
                onClick={() => {
                  viewAttachmentApi(item, file);
                }}
              >
                <EyeFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getDocumentsCountApi();
  }, [searchParams.get("id")]);

  const onChangeDocuments = (event: any) => {
    setIsLoader(true);
    event.stopPropagation();
    event.preventDefault();
    var files = event.target.files;
    var filesUploaded = 0;
    Array.from(files).forEach((file: any) => {
      var form = new FormData();
      form.append("file", file);

      var param = searchParams.get("id") ?? "0";
      let file_size = file.size;
      if (file_size <= 110000000) {
        UploadDocumentsAction(form, param, documenttype).then((res) => {
          filesUploaded = filesUploaded + 1;
          if (res.data.responseCode == 200) {
            if (filesUploaded == files.length) {
              FlashMessage("success", "Document Uploaded Successfully.");

              getDocumentsCountApi();
            }
          }
        });
      } else {
        filesUploaded = filesUploaded + 1;
        FlashMessage(
          "error",
          "Please select a file whose size is less than 100MB."
        );
        if (filesUploaded == files.length) {
          getDocumentsCountApi();
        }
      }
    });
  };
  const deleteDocumentApi = (id: number, file: any) => {
    setIsPopUpLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      DeleteDocumentAction(id).then((res) => {
        if (res.data.responseCode == 200) {
          getDocumentsApi(documenttype);
          setIsPopUpLoader(false);
          FlashMessage("success", res.data.responseMessage);
          getDocumentsCountApi();
        } else {
          FlashMessage("error", res.data.responseMessage);
          setIsPopUpLoader(false);
        }
      });
    } else {
      setIsPopUpLoader(false);
    }
  };

  const downloadDocumentApi = (id: number, file: any) => {
    setIsPopUpLoader(true);
    DownloadDocumentAction(
      id,
      documenttype,
      searchParams.get("id") ?? "0"
    ).then((res) => {
      setIsPopUpLoader(false);
      createAndDownloadBlobFile(
        base64ToArrayBuffer(res.data.responseData),
        file.name
      );
    });
  };

  const getDocumentsApi = (documenType: number) => {
    setOpen(true);
    setIsPopUpLoader(true);
    setDocumenttype(documenType);
    var param = searchParams.get("id") ?? "0";
    DocumentListAction(documenType, param).then((res) => {
      var documents = res.data.responseData;
      if (res.data.responseCode == 200) {
        setIsPopUpLoader(false);
        setDocumentData(documents);
      } else {
        setIsLoader(false);
        FlashMessage("error", res.data.responseMessage);
      }
    });
  };
  const handleModel_UploadDocument = (type: any) => {
    setOpen(true);
    setDocumenttype(type);
  };

  const getDocumentsCountApi = () => {
    setIsLoader(true);
    var param = searchParams.get("id") ?? "0";
    getDocumentsCount(param).then((res) => {
      var result = res.data.responseData;
      var addDocumentRecord = result.filter((a: any) => a.documentType == 3);
      var SSACorrespondence = result.filter((a: any) => a.documentType == 4);
      var Medical_EducationRecords = result.filter(
        (a: any) => a.documentType == 5
      );
      var RepDocs = result.filter((a: any) => a.documentType == 6);
      var ClaimantSuppliedEvidence = result.filter(
        (a: any) => a.documentType == 7
      );
      setclaimantSuppliedEvidence(
        ClaimantSuppliedEvidence.length > 0
          ? ClaimantSuppliedEvidence[0].documentCount
          : 0
      );
      setrepDocs(RepDocs.length > 0 ? RepDocs[0].documentCount : 0);
      setsSACorrespondence(
        SSACorrespondence.length > 0 ? SSACorrespondence[0].documentCount : 0
      );
      setmedical_EducationRecords(
        Medical_EducationRecords.length > 0
          ? Medical_EducationRecords[0].documentCount
          : 0
      );
      setaddDocumentCount(
        addDocumentRecord.length > 0 ? addDocumentRecord[0].documentCount : 0
      );
      setIsLoader(false);
    });
  };

  const viewAttachmentApi = (id: any, file: any) => {
    setIsPopUpLoader(true);
    DownloadDocumentAction(
      id,
      documenttype,
      searchParams.get("id") ?? "0"
    ).then((res) => {
      createAndViewBlobFile(
        base64ToArrayBuffer(res.data.responseData),
        file.name
      );
      setIsPopUpLoader(false);
    });
  };

  const handleClick_UploadDocument = (type: any) => {
    setDocumenttype(type);
    inputRef.current?.click();
  };
  return (
    <>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <>
          <section id='MindsetAdminDocs' className='mt-5'>
            <Modal
              title='Documents'
              centered
              open={open}
              footer={null}
              onOk={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              width={1000}
            >
              {isPopUpLoader && (
                <span style={{ width: 1000 }}>
                  <Loader></Loader>
                </span>
              )}
              {!isPopUpLoader && (
                <Table
                  dataSource={documentData}
                  pagination={{
                    pageSize: Constants.gridDefaultPageSize,
                    showSizeChanger: false,
                  }}
                  columns={columns}
                />
              )}
            </Modal>

            <div className='row'>
              <div className='col'>
                <input
                  type='file'
                  multiple
                  ref={inputRef}
                  onChange={onChangeDocuments}
                  accept='application/pdf, image/png'
                  style={{ display: "none" }}
                />
                <div className='row'>
                  <div className='col-md-12 col-xl-4 mt-3 document_panel'>
                    <div className='background_sq_boxes p-4 text-center position-relative'>
                      <div className='popover__wrapper2 position-absolute'>
                        <img className='img-fluid info' src={info} />
                        <div className='popover__content3'>
                          <p className='popover__message2'>
                            {" "}
                            SSA mail, DDS mail, OHO mail, applications, appeals
                          </p>
                        </div>
                      </div>
                      <img className='img-fluid dws_temp' src={dwdtemp} />
                      <h5 className='boxes_headings mb-3 ps-4 pe-4'>
                        SSA Correspondence
                        <span className='font-size-ssa'>
                          (SSA mail, DDS mail, OHO mail, applications, appeals)
                        </span>
                      </h5>
                      <div
                        className='cursorPointer'
                        onClick={() => handleClick_UploadDocument(4)}
                      >
                        <div className='text-center'>
                          <img
                            className='icon_template img-fluid'
                            src={upload}
                          />
                        </div>

                        <div className='row justify-content-center'>
                          <p className='click_doc'>Click to upload documents</p>
                        </div>
                      </div>
                      <button
                        type='button'
                        onClick={() => getDocumentsApi(4)}
                        className={`btn btn-primary button_viewtemp`}
                      >
                        View
                      </button>
                      <p className='text-center'>
                        {sSACorrespondence} uploaded
                      </p>
                    </div>
                  </div>
                  <div className='col-md-12 col-xl-4 mt-3 document_panel'>
                    <div className='background_sq_boxes p-4 text-center position-relative'>
                      <div className='popover__wrapper2 position-absolute'>
                        <img className='img-fluid info' src={info} />
                        <div className='popover__content3'>
                          <p className='popover__message2'>
                            {" "}
                            Medical/Education Records
                          </p>
                        </div>
                      </div>
                      <img className='img-fluid dws_temp' src={dwdtemp} />
                      <h5 className='boxes_headings mb-3 ps-4 pe-4'>
                        Medical/Education Records
                      </h5>
                      <div
                        className='cursorPointer'
                        onClick={() => handleClick_UploadDocument(5)}
                      >
                        <div className='text-center'>
                          <img
                            className='icon_template img-fluid'
                            src={upload}
                          />
                        </div>

                        <div className='row justify-content-center'>
                          <p className='click_doc'>Click to upload documents</p>
                        </div>
                      </div>
                      <button
                        type='button'
                        onClick={() => getDocumentsApi(5)}
                        className={`btn btn-primary button_viewtemp`}
                      >
                        View
                      </button>
                      <p className='text-center'>
                        {medical_EducationRecords} uploaded
                      </p>
                    </div>
                  </div>
                  <div className='col-md-12 col-xl-4 mt-3 document_panel'>
                    <div className='background_sq_boxes p-4 text-center position-relative'>
                      <div className='popover__wrapper2 position-absolute'>
                        <img className='img-fluid info' src={info} />
                        <div className='popover__content3'>
                          <p className='popover__message2'> Rep Docs</p>
                        </div>
                      </div>
                      <img className='img-fluid dws_temp' src={dwdtemp} />
                      <h5 className='boxes_headings mb-3 ps-4 pe-4'>
                        Rep Docs
                      </h5>
                      <div
                        className='cursorPointer'
                        onClick={() => handleClick_UploadDocument(6)}
                      >
                        <div className='text-center'>
                          <img
                            className='icon_template img-fluid'
                            src={upload}
                          />
                        </div>

                        <div className='row justify-content-center'>
                          <p className='click_doc'>Click to upload documents</p>
                        </div>
                      </div>
                      <button
                        type='button'
                        onClick={() => getDocumentsApi(6)}
                        className={`btn btn-primary button_viewtemp`}
                      >
                        View
                      </button>
                      <p className='text-center'>{repDocs} uploaded</p>
                    </div>
                  </div>
                  <div className='col-md-12 col-xl-4 mt-3 document_panel'>
                    <div className='background_sq_boxes p-4 text-center position-relative'>
                      <div className='popover__wrapper2 position-absolute'>
                        <img className='img-fluid info' src={info} />
                        <div className='popover__content3'>
                          <p className='popover__message2'>
                            {" "}
                            Claimant Supplied Evidence
                          </p>
                        </div>
                      </div>
                      <img className='img-fluid dws_temp' src={dwdtemp} />
                      <h5 className='boxes_headings mb-3 ps-4 pe-4'>
                        Claimant Supplied Evidence
                      </h5>
                      <div
                        className='cursorPointer'
                        onClick={() => handleClick_UploadDocument(7)}
                      >
                        <div className='text-center'>
                          <img
                            className='icon_template img-fluid'
                            src={upload}
                          />
                        </div>

                        <div className='row justify-content-center'>
                          <p className='click_doc'>Click to upload documents</p>
                        </div>
                      </div>
                      <button
                        type='button'
                        onClick={() => getDocumentsApi(7)}
                        className={`btn btn-primary button_viewtemp`}
                      >
                        View
                      </button>
                      <p className='text-center'>
                        {claimantSuppliedEvidence} uploaded
                      </p>
                    </div>
                  </div>

                  <div className='col-md-12 col-xl-4 mt-3 document_panel'>
                    <div className='background_sq_boxes p-4 text-center position-relative'>
                      <div className='popover__wrapper2 position-absolute'>
                        <img className='img-fluid info' src={info} />
                        <div className='popover__content3'>
                          <p className='popover__message2'>
                            {" "}
                            Any additional documents that were requested for
                            your application can be uploaded here.
                          </p>
                        </div>
                      </div>
                      <img className='img-fluid dws_temp' src={dwdtemp} />
                      <h5 className='boxes_headings mb-3 ps-5 pe-5'>
                        Additional Documents
                      </h5>
                      <div
                        className='cursorPointer'
                        onClick={() => handleClick_UploadDocument(3)}
                      >
                        <div className='text-center'>
                          <img
                            className='icon_template img-fluid'
                            src={upload}
                          />
                        </div>

                        <div className='row justify-content-center'>
                          <p className='click_doc'>Click to upload documents</p>
                        </div>
                      </div>
                      <button
                        onClick={() => getDocumentsApi(3)}
                        type='button'
                        className={`btn btn-primary button_viewtemp`}
                      >
                        View
                      </button>
                      <p className='text-center'>{addDocumentCount} uploaded</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default UploadDoc;

export function createAndViewBlobFile(body: any, filename: any) {
  var ext = filename.split(".").pop();
  var type = "image/png";

  if (ext == "pdf") type = "application/pdf";
  else if (ext == "doc" || ext == "docx")
    type =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  else if (ext == "txt") type = "text/html";
  else if (ext == "xlsx") type = "text/xlsx";
  else type = "image/" + ext;
  const blob = new Blob([body], { type: type });
  const fileName = `${filename}`;
  const link = document.createElement("a");
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("target", "_blank");
    link.setAttribute("href", url);
    //link.setAttribute('download', fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function createAndDownloadBlobFile(body: any, filename: any) {
  const blob = new Blob([body]);
  const fileName = `${filename}`;

  const link = document.createElement("a");
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function base64ToArrayBuffer(base64: any) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}
