import { Fragment } from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import "../../layouts/leadslayout/index.scss";
import Loader from "../../components/Loader";
import info2 from "../../images/info2.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoaderLoadMore from "../../components/LoaderLoadMore";
import {
  getLeadByApplicationStatusId,
  gePipelineStatusById,
} from "../../actions/leadsAndClients";
import * as signalR from "@microsoft/signalr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import { faSortUp } from "@fortawesome/free-solid-svg-icons/faSortUp";
import { faSortDown } from "@fortawesome/free-solid-svg-icons/faSortDown";
import { faRibbon } from "@fortawesome/free-solid-svg-icons/faRibbon";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons/faCircleExclamation";
import { faChild } from "@fortawesome/free-solid-svg-icons/faChild";
import { faRocket } from "@fortawesome/free-solid-svg-icons/faRocket";
import greenLang from "../../images/language.png";
import {  formatPhoneNumber } from "../../utils/commonHelper";
import { Constants } from "../../common/constants";
import { faBan } from "@fortawesome/free-solid-svg-icons";

const Leads = () => {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [pstatus, setpStatus] = useState([]);
  const [plead, setpLead] = useState<any[]>([]);
  const [loaderArray, setLoaderArray] = useState<boolean[]>([]);
  const [sortOrderArray, setSortOrderArray] = useState<boolean[]>([]);
  const [pageNumArray, setPageNumArray] = useState<any[]>([]);
  const [searchParams] = useSearchParams();

  const gePipelineStatusByIdApi = () => {
    setIsLoader(true);
    var loaderArrayTemp: any[] = [];

    gePipelineStatusById(searchParams.get("id") ?? 1).then((res) => {
      if (res.data.responseCode == 200) {
        var status = res.data.responseData;
        setpStatus(status);
        let leadData: any = [];
        var statusLength = status.length;
        var currentCount = 0;
        status.map((ele: any) => {
          loaderArrayTemp.push(false);
          getLeadByApplicationStatusId(
            ele.id,
            Constants.leadsDefaultPageSize,
            0,
            true
          ).then((res) => {
            currentCount = currentCount + 1;
            if (res.data.responseCode == 200) {
              var response = res.data.responseData;
              for (var i = 0; i < response.length; i++) {
                leadData.push(response[i]);
              }
              if (statusLength == currentCount) {
                setpLead(leadData);
                setIsLoader(false);
                setLoaderArray(loaderArrayTemp);
                setPageNumArray([...Array(statusLength).fill(0)]);
                setSortOrderArray(Array(statusLength).fill(true));
              }
            }
          });
        });
      }
    });
  };

  useEffect(() => {
    gePipelineStatusByIdApi();
  }, [searchParams.get("id")]);

  const handleLoadMore = (
    id: any,
    pageNumber: number,
    index: any,
    sortByDescending: boolean,
    triggeredBySortChange: boolean
  ) => {
    setLoaderArray([
      ...loaderArray.map((v, ind) => (ind === index ? true : v)),
    ]);
    if (triggeredBySortChange)
      setpLead([...plead].filter((v) => v.applicationStatusId !== id));
    getLeadByApplicationStatusId(
      id,
      Constants.leadsDefaultPageSize,
      pageNumber,
      sortByDescending
    ).then((res) => {
      const loaderArrayTemp = [...loaderArray];
      loaderArrayTemp[index] = false;
      setLoaderArray(loaderArrayTemp);
      setPageNumArray(
        [...pageNumArray].map((v, ind) => (ind === index ? pageNumber : v))
      );
      if (res.data.responseCode == 200) {
        var response = res.data.responseData;
        setpLead([
          ...(triggeredBySortChange
            ? plead.filter((v) => v.applicationStatusId !== id)
            : plead),
          ...response,
        ]);
      }
    });
  };

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_Mindset_API_URL ?? ""}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("TagLeadsCount", (res: any) => {
      gePipelineStatusByIdApi();
    });
    connection.start().catch((err) => console.log(err));
    return () => {
      connection.off("TagLeadsCount");
      connection.stop().catch((err) => console.log("connection Stop Error"));
    };
  }, [searchParams.get("id")]);
  return (
    <>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <section id='MindsetAdminDashboard' className='mt-5'>
          <div className='row pb-5 desktop_scroll justify-content-between'>
            {pstatus
              .filter(
                (a: any) =>
                  a.name != "Did Not Answer" && a.name != "Not Qualified"
              )
              .map((statusitem: any, index) => {
                return (
                  <Fragment key={statusitem.id}>
                    <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-2 text-center'>
                      <div className='row position-relative'>
                        <div className='col-md-3'></div>
                        <div className='col-md-9'>
                          <p className={`heading_cards_2 mb-1`}>
                            {statusitem.leadCount} leads
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                            className='heading_cards'
                          >
                            <p style={{ margin: 0 }}>{statusitem.name}</p>
                            <div
                              onClick={() => {
                                setSortOrderArray(
                                  [...sortOrderArray].map((v, ind) =>
                                    ind === index ? !v : v
                                  )
                                );
                                setPageNumArray(
                                  [...pageNumArray].map((v, ind) =>
                                    ind === index ? 0 : v
                                  )
                                );
                                handleLoadMore(
                                  statusitem.id,
                                  0,
                                  index,
                                  !sortOrderArray[index],
                                  true
                                );
                              }}
                            >
                              {sortOrderArray[index] ? (
                                <FontAwesomeIcon
                                  className='popover__title'
                                  style={{ cursor: "pointer" }}
                                  icon={faSortDown}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className='popover__title'
                                  style={{ cursor: "pointer" }}
                                  icon={faSortUp}
                                />
                              )}
                            </div>
                          </div>
                          {
                            <>
                              {plead
                                .filter(
                                  (ele: any) =>
                                    ele.applicationStatusId == statusitem.id
                                )
                                .map((item: any) => {
                                  var date = item.statusChangeDate
                                    ? moment
                                        .utc(item.statusChangeDate)
                                        .local()
                                        .format("MM/DD/yyyy")
                                    : moment
                                        .utc(item.createdDate)
                                        .local()
                                        .format("MM/DD/yyyy");
                                  var time = item.statusChangeDate
                                    ? moment
                                        .utc(item.statusChangeDate)
                                        .local()
                                        .format("hh:mm a")
                                    : moment
                                        .utc(item.createdDate)
                                        .local()
                                        .format("hh:mm a");
                                  return (
                                    <Fragment key={item.id}>
                                      <div
                                        className='row position-relative mt-2 min-height_card cards_bottom'
                                        onClick={() =>
                                          navigate("/leadsnotes?id=" + item.id)
                                        }
                                      >
                                        <p
                                          className={`time_card position-absolute`}
                                        >
                                          {date}
                                        </p>
                                        <p
                                          className={`time_card_2 position-absolute`}
                                        >
                                          {time}
                                        </p>
                                        <div className='col-md-1 text-right'></div>
                                        <div
                                          className={`name_cards_bg2 col-md-10 pe-3 ps-3 pt-2 pb-2 cursorPointer position-relative`}
                                        >
                                          <p
                                            className={`name_cards2 mb-0 cursorPointer`}
                                          >
                                            {item.fullName}
                                            <span className='d-block'>
                                              {" "}
                                              
                                          {formatPhoneNumber(item.phoneNumber) +
                                            `${item.timeZone ? ` (${item.timeZone})` : ""}`}
                                        
                                              <div className='d-flex align-items-center justify-content-start flex-wrap'>
                                                {item.isCritical && (
                                                  <span>
                                                    <div className='icons_new popover__wrapper'>
                                                      <FontAwesomeIcon
                                                        className='popover__title'
                                                        icon={
                                                          faCircleExclamation
                                                        }
                                                      />

                                                      <div className='popover__content2'>
                                                        <p className='popover__message'>
                                                          Critical{" "}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </span>
                                                )}
                                                {item.isSpanish && (
                                                  <span>
                                                    <div className='icons_new popover__wrapper'>
                                                      <img
                                                        className='es_icon img-fluid popover__title'
                                                        src={greenLang}
                                                      />
                                                      <div className='popover__content2'>
                                                        <p className='popover__message'>
                                                          Spanish{" "}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </span>
                                                )}
                                                {item.isDeceased && (
                                                  <span>
                                                    <div className='icons_new popover__wrapper'>
                                                      <FontAwesomeIcon
                                                        className='popover__title'
                                                        icon={faRibbon}
                                                      />

                                                      <div className='popover__content2'>
                                                        <p className='popover__message'>
                                                          Deceased{" "}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </span>
                                                )}
                                                {item.isMinor && (
                                                  <span>
                                                    <div className='icons_new popover__wrapper'>
                                                      <FontAwesomeIcon
                                                        className='popover__title'
                                                        icon={faChild}
                                                      />

                                                      <div className='popover__content2'>
                                                        <p className='popover__message'>
                                                          Minor{" "}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </span>
                                                )}
                                                {item.isTerminal && (
                                                  <span>
                                                    <div className='icons_new popover__wrapper'>
                                                      <FontAwesomeIcon
                                                        className='popover__title'
                                                        icon={faRocket}
                                                      />

                                                      <div className='popover__content2'>
                                                        <p className='popover__message'>
                                                          Terminal{" "}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </span>
                                                )}{" "}
                                                {item.isDenial && (
                                                  <span>
                                                    <div className='icons_new popover__wrapper'>
                                                      <FontAwesomeIcon
                                                        className='popover__title'
                                                        icon={faBan}
                                                      />
                                                      <div className='popover__content2'>
                                                        <p className='popover__message'>
                                                          Denial{" "}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </span>
                                                )}
                                                {item.tenantId && (
                                                  <span>
                                                    <div className='popover__wrapper vertical-super'>
                                                      <img
                                                        className='info_image2 img-fluid popover__title'
                                                        src={info2}
                                                      />
                                                      <div className='popover__content2'>
                                                        <p className='popover__message'>
                                                          {item.tenantName}{" "}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </span>
                                                )}
                                              </div>
                                            </span>
                                          </p>
                                        </div>
                                        <div className='col-md-2'></div>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              {loaderArray[index] ? (
                                <div style={{ marginTop: "15px" }}>
                                  <LoaderLoadMore></LoaderLoadMore>
                                </div>
                              ) : (
                                <>
                                  {plead.filter(
                                    (ele: any) =>
                                      ele.applicationStatusId == statusitem.id
                                  ).length == 0 && (
                                    <div className='error'>No Leads</div>
                                  )}
                                  {statusitem.leadCount >= 15 &&
                                  plead.filter(
                                    (ele: any) =>
                                      ele.applicationStatusId == statusitem.id
                                  ).length != statusitem.leadCount ? (
                                    <div className='text-center mt-5'>
                                      <button
                                        className='ms-4 button_clients'
                                        onClick={() =>
                                          handleLoadMore(
                                            statusitem.id,
                                            pageNumArray[index] + 1,
                                            index,
                                            sortOrderArray[index],
                                            false
                                          )
                                        }
                                      >
                                        Load More
                                      </button>
                                    </div>
                                  ) : (
                                    <> </>
                                  )}
                                </>
                              )}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
          </div>
        </section>
      )}
    </>
  );
};

export default Leads;
