import axios from "axios";

export function SaveCollegeHistory(payload: any) {
  return axios.post("CollegeHistory", payload);
}
export function GetCollegeHistory(id: any) {
  return axios.get("CollegeHistory?Id=" + id);
}
export function GetCollegeHistoryById(id: any) {
  return axios.get("CollegeHistoryById?Id=" + id);
}
export function DeleteCollegeHistory(Id: any) {
  return axios.delete("CollegeHistory?Id=" + Id);
}
export function UpdateCollegeHistory(payload: any) {
  return axios.put("CollegeHistory", payload);
}
