import React, { useState, useEffect } from "react";
import { getGroupUsers, saveNotes } from "../../actions/notes";
import { Form, Input } from "antd";
import { useSearchParams } from "react-router-dom";
import { FlashMessage } from "../../utils/flash_message";
import { useNavigate, useLocation } from "react-router-dom";
import { Constants } from "../../common/constants";
import { getOnlyShowMyCasesFromSearchParams } from "../../utils/commonHelper";
import { MentionsInput, Mention } from "react-mentions";
import { getSupportUsers } from "../../actions/supportUser";
import { get } from "../../utils/clientStorageUtils";
import defaultMentionStyle from "./defaultMentionStyle";
import defaultStyle from "./defaultStyle";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import {
  GroupUser,
  MentionUser,
  getUpdatedMentionsValue,
} from "../../utils/mentions";
import { Enums } from "../../common/enums";
import NotesMetaData from "./NoteMetaData";
import GlobalTaskNotes from "../../common/components/GlobalTaskNotes";

const SaveNotes = (props: any) => {
  const [form] = Form.useForm();
  const [user, setUser] = useState<MentionUser[]>([]);
  const [plainText, setPlainText] = useState("");
  const [tagsText, setTagsText] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [singleLineValue, setSingleLineValue] = useState("");
  const [tagUserIds, setTagUserIds] = useState("");
  const [checkValidation, setCheckValidation] = useState(false);
  const [noteById, setNoteById] = useState({ plainText: "" });
  const [disable, setDisable] = useState(false);
  const [groups, setgroups] = useState<GroupUser[]>([]);
  const [noteType, setNoteType] = useState();
  const [noteMinutes, setNoteMinutes] = useState();
  const [noteSource, setNoteSource] = useState();
  const [noteCategory, setNoteCategory] = useState();

  const getSupportUsersApi = () => {
    getSupportUsers().then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        let userid = JSON.parse(get("model"))
          ? JSON.parse(get("model")).userId
          : "";
        var filterData = userdata
          .map((ele: any) => ({
            id: ele.id,
            display: `${ele.firstName} ${ele.lastName}`,
          }))
          .filter((ele: any) => ele.id != userid);
        setUser(filterData);
        setTagsText("");
      }
    });
  };
  const getGroupUserApi = () => {
    getGroupUsers().then((res) => {
      if (res.data.responseCode == Enums.responseCodes.success) {
        var groupData = res.data.responseData.map((ele: any) => ({
          id: ele.groupId,
          display: ele.groupName,
        }));
        setgroups(groupData);
        setTagsText("");
      }
    });
  };
  const saveNotesApi = (e: any) => {
    setDisable(true);
    setCheckValidation(true);
    var param = searchParams.get("id") ?? "0";
    var payload = {
      Description: singleLineValue,
      EncryptedUserId: props?.IsLeads ? null : param,
      LeadsId: props?.IsLeads ? param : null,
      Id: e.id,
      PlainText: plainText,
      TagsText: tagsText,
      Type: JSON.stringify(noteType),
      Source: noteSource,
      Category: noteCategory,
      Minutes: noteMinutes,
    };
    if (payload.Description != null && payload.Description.trim() != "") {
      saveNotes(payload, tagUserIds).then((res) => {
        if (res.data.responseCode == 200) {
          setDisable(false);
          FlashMessage("success", "Note added Successfully.");
          resetFormValues();
          if (
            location.pathname.indexOf(Constants.pageRoutes.clientsnotes) >= 0 ||
            location.pathname.indexOf(Constants.pageRoutes.leadNotes) >= 0
          ) {
            navigate("/leadsnotes?id=0");
            setTimeout(function () {
              if (props.IsLeads) {
                navigate("/leadsnotes?id=" + searchParams.get("id") ?? "0");
              } else {
                navigate(
                  `/clientsnotes?id=${
                    searchParams.get("id") ?? "0"
                  }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
                    searchParams
                  )}`
                );
              }
            }, 200);
          }
        }
      });
    } else {
      setDisable(false);
    }
  };

  const resetFormValues = () => {
    var formDefaultValues = {
      plainText: "",
      type: [],
      minutes: null,
      source: null,
      category: null,
    };
    setTimeout(() => {
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };

  const handleChangeSingle = (
    e: any,
    newValue: any,
    newPlainTextValue: any,
    _mentions: any
  ) => {
    const { newTagUserIds, newSingleLineValue, newPlainText, newTagsText } =
      getUpdatedMentionsValue(
        newValue,
        newPlainTextValue,
        _mentions,
        user,
        groups
      );
    setTagUserIds(newTagUserIds);
    setSingleLineValue(newSingleLineValue);
    setPlainText(newPlainText);
    setTagsText(newTagsText);
  };

  useEffect(() => {
    getGroupUserApi();
    getSupportUsersApi();
  }, []);

  return (
    <>
      <Form
        className='mt-4'
        form={form}
        name='NotesForm'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete='off'
        initialValues={noteById}
        onFinish={saveNotesApi}
      >
        <div className='row'>
          <div className='col'>
            <div className='d-flex align-items-center mt-3 w-100 justify-content-between'>
              <div className='text-right position-relative width-drp-btn'>
                <Form.Item
                  className='note_add'
                  name='plainText'
                  rules={[
                    {
                      required: true,
                      message: "Please input the value!",
                    },
                    { validator: validateBlankSpace },
                  ]}
                >
                  <MentionsInput
                    singleLine={false}
                    value={plainText}
                    onChange={handleChangeSingle}
                    placeholder='Post a note'
                    allowSpaceInQuery={true}
                  >
                    <Mention
                      style={defaultMentionStyle}
                      trigger='@'
                      data={groups}
                      displayTransform={(id: any, name: any) => `@${name}:`}
                    />
                    <Mention
                      style={defaultMentionStyle}
                      trigger='@'
                      data={user}
                      displayTransform={(id: any, name: any) => `@${name}:`}
                    />
                  </MentionsInput>
                </Form.Item>
                <button
                  disabled={disable}
                  type='submit'
                  className={`plus_position mb-0`}
                >
                  +
                </button>
              </div>
              <div className=''>
                <GlobalTaskNotes
                  isLeads={props.IsLeads}
                  directTaskOpen={true}
                />
              </div>
            </div>
            <NotesMetaData
              mode='Create'
              noteType={noteType}
              setNoteType={setNoteType}
              noteMinutes={noteMinutes}
              setNoteMinutes={setNoteMinutes}
              noteSource={noteSource}
              setNoteSource={setNoteSource}
              noteCategory={noteCategory}
              setNoteCategory={setNoteCategory}
              setInitialValue={(params: object) => {
                form.setFieldsValue({
                  ...params,
                });
              }}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default SaveNotes;
