export const validateEmail = (_: any, value: any) => {
  if (
    value == null ||
    value == "" ||
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ) {
    return Promise.resolve();
  }

  return Promise.reject(new Error("Invalid email address"));
};
// export const plusValidation = (_: any, value: any) => {
//     if ( value == null || value == "" || /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
//         return Promise.resolve();
//     }

//     return Promise.reject(new Error('Your email has plus(+) character. This is not allowed. Please remove it and try again.'));
// };
export const plusValidation = (_: any, value: any) => {
  if (value && value.indexOf("+") >= 0) {
    return Promise.reject(
      new Error(
        "Your email has plus(+) character. \nThis is not allowed.\n Please remove it and try again."
      )
    );
  }
  return Promise.resolve();
};
