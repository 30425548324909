import { CopyOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { AutocompletionRequest } from "react-google-places-autocomplete/build/GooglePlacesAutocomplete.types";
import "./index.scss";
import { FlashMessage } from "../../utils/flash_message";

export interface GooglePlacesPropsAutoCompleteProps {
  apiKey: string;
  autocompletionRequest: AutocompletionRequest;
  selectProps: any;
}

const GooglePlacesAutocompleteWrapper = ({
  apiKey,
  autocompletionRequest,
  selectProps,
}: GooglePlacesPropsAutoCompleteProps) => {
  const [address, setAddress] = useState(selectProps.value?.label || "");

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(address);
      FlashMessage("success", <div>Address Copied to Clipboard</div>, "", 3);
    } catch (err) {
      FlashMessage("error", <div>Failed To Copy Address</div>, "", 3);
    }
  };

  const handleSelect = async (address: string, placeId?: string) => {
    try {
      let extractedFields = {
        label: "",
        streetAddress: "",
        city: "",
        state: "",
        postalCode: "",
      };

      if (placeId) {
        const [place] = await geocodeByPlaceId(placeId);
        const components = place.address_components;

        const extractFromComponents = (type: string) => {
          const component = components.find((c: any) => c.types.includes(type));
          return component ? component.long_name : "";
        };

        extractedFields = {
          streetAddress: `${extractFromComponents(
            "street_number"
          )} ${extractFromComponents("route")}`.trim(),
          city:
            extractFromComponents("locality") ||
            extractFromComponents("sublocality"),
          state: extractFromComponents("administrative_area_level_1"),
          postalCode: extractFromComponents("postal_code"),
          label: address
        };
      }

      if (selectProps?.onChange) {
        selectProps.onChange(extractedFields);
      }

      setAddress(address);
    } catch (error) {
      console.error("Error fetching address details:", error);
    }
  };

  return (
    <div className='google-autocomplete-wrapper d-flex'>
      <div className='google-autocomplete-wrapper-input flex-3'>
        <GooglePlacesAutocomplete
          apiKey={apiKey}
          autocompletionRequest={autocompletionRequest}
          selectProps={{
            ...selectProps,
            onChange: (selected: any) => {
              if (selected?.label && selected?.value?.place_id) {
                handleSelect(selected.label, selected.value.place_id);
              }
            },
          }}
        />
      </div>
      <div
        className='google-autocomplete-wrapper-button-container'
        title='Copy Address to Clipboard'
      >
        <button
          className='google-autocomplete-button'
          onClick={copyToClipBoard}
          disabled={!address}
          type='button'
        >
          <CopyOutlined
            style={{
              fontSize: "15px",
              color: "#77d4b1",
            }}
          />
        </button>
      </div>
    </div>
  );
};

export default GooglePlacesAutocompleteWrapper;
