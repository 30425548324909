import { Provider } from "react-redux";
import store from "./redux/store";
import setUpInterceptor from "./axios/setupAxios";
import { BrowserRouter } from "react-router-dom";
import RouterComponent from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./stylesheet/global.css";
import "./stylesheet/global.scss";
import { FeatureFlagProvider } from "./components/FeatureFlagProvider";
function App() {
  setUpInterceptor(store);

  return (
    <FeatureFlagProvider>
      <Provider store={store}>
        <BrowserRouter>
          <RouterComponent />
        </BrowserRouter>
      </Provider>
    </FeatureFlagProvider>
  );
}

export default App;
