import { GetAllActivityTypes, GetActivityLogData } from "../../apis/logs";

export function getAllActivityTypes() {
  return GetAllActivityTypes();
}
export function getActivityLogData(
  email: any,
  activityTypeId: any,
  startDate: any,
  endDate: any,
  activityBy: any
) {
  return GetActivityLogData(
    email,
    activityTypeId,
    startDate,
    endDate,
    activityBy
  );
}
