import React from "react";
import { useEffect, useState } from "react";
import {
  getApplicationDetailsById,
  getIntakeQuestionnaires,
  getLeadsById,
  updateLeads,
} from "../../actions/application";
import { useSearchParams } from "react-router-dom";
import { AutoComplete, Modal } from "antd";
import { Form, Input, Select, DatePicker, Radio, Checkbox } from "antd";
import { FlashMessage } from "../../utils/flash_message";
import { getCommonAllLocations } from "../../actions/common";
import { number } from "../../validations/numeric_only-validate";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { minLength } from "../../validations/validate_min_length";
import moment from "moment";
import { numberGreaterThanNinetyNine } from "../../validations/numeric_only-validate";
import TextArea from "antd/lib/input/TextArea";
import * as signalR from "@microsoft/signalr";
import Loader from "../../components/Loader";
import { Constants } from "../../common/constants";
import { validateEmail } from "../../validations/validateEmail";
import PreQualFieldList from "./PreQualFieldList";

interface Location {
  id: number;
  name: string;
  abbreviations: string;
  timezone: string;
}

const Prequalification = (props: any) => {
  console.log(props);
  //used
  const [resById, setResById] = useState({
    age: 0,
    fullName: "",
    isSeenDoc: false,
    email: "",
    locationName: "",
    fullTime: "",
    timeZone: "",
    leadSource: "",
    phoneNumber: "",
    adId: "",
    completedEvalCallTs: "",
    mentalIllness: "",
    // physicalAilment: "",
    scheduledCallTs: null,
    isUsCitizen: false,
    lastTimeAppliedSSBenefits: null,
    isReceivingGovtBenefits: false,
    monthlyIncome: "",
    isBenefitsDenied: false,
    hoursPerWeek: null,
    ficaTaxes: null,
    isAlcoholic: false,
    isOwnAssets: false,
    tenantId: null,
    isHasAttorney: false,
    isQualifiedSSI: null,
    isQualifiedSSDI: null,
    preQualNotes: "",
    estimatedReleaseDate: null,
    isSsiSsdiBenefits: false,
  });
  const [intakeQuestionnaires, setIntakeQuestionnaires] = useState<any>(null);
  const [estimatedReleaseDate, setEstimatedReleaseDate] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const dateFormat = "MM/DD/YYYY";
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const [location, setLocation] = useState<Location[]>([]);
  const [type1, setType1] = useState(false);
  const [type2, setType2] = useState(false);
  const [type3, setType3] = useState(false);
  const [type4, setType4] = useState(false);
  const [type5, setType5] = useState(false);
  const [type6, setType6] = useState(false);
  const [type7, setType7] = useState(false);
  const [type8, setType8] = useState(false);
  const [type9, setType9] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const [locationOptions, setLocationOptions] = useState<Location[]>([]);
  const handleTypeOne = (e: any) => {
    setType1(e.target.checked);
  };
  const handleTypeTwo = (e: any) => {
    setType2(e.target.checked);
  };
  const handleTypeThree = (e: any) => {
    setType3(e.target.checked);
  };
  const handleTypeFour = (e: any) => {
    setType4(e.target.checked);
  };
  const handleTypeFive = (e: any) => {
    setType5(e.target.checked);
  };
  const handleTypeSix = (e: any) => {
    setType6(e.target.checked);
  };
  const handleTypeSeven = (e: any) => {
    setType7(e.target.checked);
  };
  const handleTypeEight = (e: any) => {
    setType8(e.target.checked);
  };
  const handleTypeNine = (e: any) => {
    setType9(e.target.checked);
  };
  const getCommonAllLocationsApi = () => {
    getCommonAllLocations().then((res) => {
      var loc = res.data.responseData;
      setLocation(loc);
      setLocationOptions(loc);
    });
  };

  const updateLeadsApi = (e: any) => {
    var mentalIllness = "";
    if (type1 == true) {
      mentalIllness += "Anxiety";
    }
    if (type2 == true) {
      mentalIllness +=
        (mentalIllness != "" ? "," : "") + "Autism Spectrum Disorder";
    }
    if (type3 == true) {
      mentalIllness += (mentalIllness != "" ? "," : "") + "Bipolar";
    }
    if (type4 == true) {
      mentalIllness += (mentalIllness != "" ? "," : "") + "Depression";
    }
    if (type5 == true) {
      mentalIllness +=
        (mentalIllness != "" ? "," : "") + "Post Traumatic Stress Disorder";
    }
    if (type6 == true) {
      mentalIllness += (mentalIllness != "" ? "," : "") + "Schizophrenia";
    }
    if (type7 == true) {
      mentalIllness += (mentalIllness != "" ? "," : "") + "Physical Ailment";
    }
    if (type8 == true) {
      mentalIllness +=
        (mentalIllness != "" ? "," : "") + "Other Mental Condition";
    }
    if (type9 == true) {
      mentalIllness += (mentalIllness != "" ? "," : "") + "ADHD";
    }
    const locationId = location.filter(
      (item: any) => item.name === e.locationName
    )[0]?.id;
    var payload = {
      Id: e.id,
      FullName: e.fullName,
      LocationId: locationId,
      Email: e.email,
      PhoneNumber: e.phoneNumber,
      MentalIllness: mentalIllness,
      FullTime: e.fullTime,
      IsSeenDoc: e.isSeenDoc,
      Age: e.age,
      ScheduledCallTs: e.scheduledTime
        ? moment(e.scheduledTime).utc().format()
        : null,
      CompletedEvalCallTs: e.completedEvalCallTs,
      LeadSource: e.leadSource,
      AdId: e.adId,
      TimeZone: e.timeZone,
      IsReceivingGovtBenefits: e.isReceivingGovtBenefits,
      IsUsCitizen: e.isUsCitizen,
      IsBenefitsDenied: e.isBenefitsDenied,
      LastTimeAppliedSSBenefits: isApply
        ? null
        : e.lastTimeAppliedBenefits
        ? moment(e.lastTimeAppliedBenefits).utc().format()
        : null,
      HoursPerWeek: e.hoursPerWeek,
      FicaTaxes: e.ficaTaxes,
      IsAlcoholic: e.isAlcoholic,
      MonthlyIncome: e.monthlyIncome,
      IsOwnAssets: e.isOwnAssets,
      IsHasAttorney: e.isHasAttorney,
      IsQualifiedSSI: e.isQualifiedSSI,
      isQualifiedSSDI: e.isQualifiedSSDI,
      PreQualNotes: e.preQualNotes,
      EstimatedReleaseDate: e.estimatedReleaseDate
        ? moment(e.estimatedReleaseDate).utc().format()
        : null,
      IsSsiSsdiBenefits: e.isSsiSsdiBenefits,
    };
    setIsPopUpLoader(true);
    updateLeads(payload).then((res) => {
      if (res.data.responseCode == 200) {
        FlashMessage("success", "Pre-qualification updated Successfully.");
        setOpen(false);
        setIsPopUpLoader(false);
        getLeadsByIdApi(props.leadId ?? "0");
      }
    });
  };
  const onChangeDoNotApply = (e: any) => {
    setIsApply(e.target.checked);
  };
  const cancel = () => {
    setOpen(false);
    form.resetFields();
    handleReset();
  };

  const editPreQualification = () => {
    setOpen(true);
    getLeadsByIdApi(props.leadId ?? "0", 1);
  };

  const handleReset = () => {
    setType1(false);
    setType2(false);
    setType3(false);
    setType4(false);
    setType5(false);
    setType6(false);
    setType7(false);
    setType8(false);
    setType9(false);
    setIsApply(false);
  };

  const getLeadsByIdApi = (id: any, isEdit: number = 0) => {
    if (isEdit == 1) {
      setIsPopUpLoader(true);
    } else {
      setIsLoader(true);
    }
    getCommonAllLocationsApi();
    getLeadsById(props.leadId).then((res) => {
      var resData = res.data.responseData;
      if (res.data.responseCode == 200) {
        setResById(resData);
        // setEstimatedReleaseDate(resData.estimatedReleaseDate);

        var lastTime =
          resData.lastTimeAppliedSSBenefits == null
            ? null
            : moment.utc(resData.lastTimeAppliedSSBenefits).local().format();
        var estimatedReleaseDateValueStore =
          resData.estimatedReleaseDate == null
            ? null
            : moment.utc(resData.estimatedReleaseDate).local().format();
        resData.estimatedReleaseDate = estimatedReleaseDateValueStore;
        var scheuls =
          resData.scheduledCallTs == null
            ? null
            : moment.utc(resData.scheduledCallTs).local().format();
        resData.scheduledTime = scheuls;
        resData.lastTimeAppliedBenefits = lastTime;
        var benefits = [];
        if (resData.mentalIllness != null)
          benefits = resData.mentalIllness.split(",");
        if (resById.lastTimeAppliedSSBenefits == null) {
          setIsApply(true);
        }
        if (resById.lastTimeAppliedSSBenefits != null) {
          setIsApply(false);
        }

        for (var i = 0; i < benefits.length; i++) {
          if (benefits[i] == "Anxiety") {
            setType1(true);
          } else if (benefits[i] == "Autism Spectrum Disorder") {
            setType2(true);
          } else if (benefits[i] == "Bipolar") {
            setType3(true);
          } else if (benefits[i] == "Depression") {
            setType4(true);
          } else if (benefits[i] == "Post Traumatic Stress Disorder") {
            setType5(true);
          } else if (benefits[i] == "Schizophrenia") {
            setType6(true);
          } else if (benefits[i] == "Physical Ailment") {
            setType7(true);
          } else if (benefits[i] == "Other Mental Condition") {
            setType8(true);
          } else if (benefits[i] == "ADHD") {
            setType9(true);
          }
        }
        if (isEdit == 1) {
          setIsPopUpLoader(false);
        } else {
          setIsLoader(false);
        }
        form.setFieldsValue(resData);
      }
    });
  };

  useEffect(() => {
    getLeadsByIdApi(props.leadId ?? "0");

    // Fetch MindsetUsers data for date of birth => age conversion
    getApplicationDetailsById(searchParams.get("id")).then((res) => {
      setUserDetails(res.data.responseData);
      getIntakeQuestionnaires(res.data.responseData.id)
        .then((intakeData) => {
          setIntakeQuestionnaires(intakeData.data.responseData);
        })
        .catch(() => setIntakeQuestionnaires({}));
    });
  }, [props.leadId]);
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL ?? ""}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("updatelead", (res: any) => {
      if (res == searchParams.get("id")) {
        getLeadsByIdApi(res);
      }
    });
    connection.start().catch((err) => console.log(err));
    return () => {
      connection.off("updatelead");
      connection.stop().catch((err) => console.log("connection Stop Error"));
    };
  }, [searchParams.get("id")]);
  const formSubmit = () => {
    form.submit();
  };

  const onkeyup = (e: any) => {
    var onlyNumber = e.target.value
      .split("(")
      .join("")
      .split(")")
      .join("")
      .split("-")
      .join("")
      .split(" ")
      .join("");
    if (onlyNumber.length > 10) {
      onlyNumber = onlyNumber.substring(0, 10);
    }
    form.setFieldsValue({ phoneNumber: onlyNumber });
  };

  const handleLocationSearch = () => {
    // Filter location options based on the search value
    const filteredOptions = location.filter((item: any) =>
      item.name
        .toLowerCase()
        .includes(form.getFieldValue("locationName").toLowerCase())
    );
    setLocationOptions(filteredOptions); // Update the state with the filtered options
  };

  const handleLocationBlur = () => {
    // Check if the current input value matches any of the options
    const value = form.getFieldValue("locationName");
    const matches = locationOptions.find(
      (option: any) => option.name === value
    );
    if (!matches) {
      form.setFieldsValue({ locationName: "" }); // Clear the input if there is no match
      handleLocationSearch();
    }
  };

  const hoursPerWeek = [
    {
      id: 1,
      name: "0 to 5 hours per week",
    },
    {
      id: 2,
      name: "6 to 10 hours per week",
    },
    {
      id: 3,
      name: "11 to 20 hours per week",
    },
    {
      id: 4,
      name: "21 to 30 hours per week",
    },
    {
      id: 5,
      name: "31 to 40 hours per week",
    },
    {
      id: 6,
      name: "40+ hours per week",
    },
  ];

  const paidFICATaxes = [
    {
      id: 1,
      name: "10 years",
    },
    {
      id: 2,
      name: "9 years",
    },
    {
      id: 3,
      name: "8 years",
    },
    {
      id: 4,
      name: "7 years",
    },
    {
      id: 5,
      name: "6 years",
    },
    {
      id: 6,
      name: "5 years",
    },
    {
      id: 7,
      name: "4 years",
    },
    {
      id: 8,
      name: "3 years",
    },
    {
      id: 9,
      name: "2 years",
    },
    {
      id: 10,
      name: "1 year",
    },
    {
      id: 11,
      name: "0-Did not work",
    },
  ];

  const isQualifiedSSI = [
    {
      id: true,
      name: "Yes",
    },
    {
      id: false,
      name: "No",
    },
  ];
  const isQualifiedSSDI = [
    {
      id: true,
      name: "Yes",
    },
    {
      id: false,
      name: "No",
    },
  ];
  const hasAttorney = [
    {
      id: true,
      name: "Yes",
    },
    {
      id: false,
      name: "No",
    },
  ];

  return (
    <>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <>
          <Modal
            title='Pre-Qualification'
            centered
            okText={isPopUpLoader ? "Loading" : "Update"}
            open={open}
            onOk={() => (isPopUpLoader ? null : formSubmit())}
            onCancel={() => cancel()}
            width={1000}
          >
            {isPopUpLoader && <Loader></Loader>}
            {!isPopUpLoader && (
              <Form
                className='mt-4'
                form={form}
                name='PrequalificationForm'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={resById}
                autoComplete='off'
                onFinish={updateLeadsApi}
              >
                <div className='form-group row margin_mobile mt-0'>
                  <Form.Item name='id' />
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    1. Age
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='age'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        { validator: number },
                        {
                          validator: validateBlankSpace,
                        },
                        {
                          validator: numberGreaterThanNinetyNine,
                        },
                      ]}
                    >
                      <Input
                        maxLength={3}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    2. Full Name
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      name='fullName'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    3. Seen Doc
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item className='text-left' name='isSeenDoc'>
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    4. Email
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      name='email'
                      rules={[
                        {
                          validator: validateBlankSpace,
                        },
                        { validator: validateEmail },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    5. Location
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item name='locationName'>
                      <AutoComplete
                        className='search_ant_drp'
                        dropdownStyle={{
                          textTransform: "capitalize",
                        }}
                        onSearch={handleLocationSearch}
                        onBlur={handleLocationBlur}
                        dropdownMatchSelectWidth={252}
                        options={locationOptions.map((loc: any) => ({
                          label: loc.name,
                          value: loc.id,
                        }))}
                        onSelect={(value, option) => {
                          form.setFieldsValue({
                            locationName: option.label,
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                  {/* <label className="col-form-label fill_description col-sm-3 des_font mt-3">6. Full Time</label>

                        <div className="col-sm-9 p-0 mt-3">
                            <Form.Item name="fullTime" rules={[{ validator: validateBlankSpace }]}>
                                <Input maxLength={100} className={`form-control input_form_sign`} />
                            </Form.Item>
                        </div> */}
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    6. Time Zone
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      name='timeZone'
                      rules={[
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    7. Lead Source
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      name='leadSource'
                      rules={[
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    8. Phone Number
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      name='phoneNumber'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        { validator: minLength },
                        { validator: number },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input
                        onKeyUpCapture={onkeyup}
                        maxLength={15}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    9. Mental Illness
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <div className='col-sm-9 p-0'>
                      <div className='d-flex align-items-center'>
                        Anxiety
                        <input
                          checked={type1}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type1'
                          value='option'
                          onChange={handleTypeOne}
                        />
                        <span className='checkmark'></span>
                      </div>
                      <div className='d-flex align-items-center'>
                        Autism Spectrum Disorder
                        <input
                          checked={type2}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type2'
                          value='option'
                          onChange={handleTypeTwo}
                        />
                        <span className='checkmark'></span>
                      </div>
                      <div className='d-flex align-items-center'>
                        Bipolar
                        <input
                          checked={type3}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type3'
                          value='option'
                          onChange={handleTypeThree}
                        />
                        <span className='checkmark'></span>
                      </div>
                      <div className='d-flex align-items-center'>
                        Depression
                        <input
                          checked={type4}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type4'
                          value='option'
                          onChange={handleTypeFour}
                        />
                        <span className='checkmark'></span>
                      </div>
                      <div className='d-flex align-items-center'>
                        Post Traumatic Stress Disorder
                        <input
                          checked={type5}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type5'
                          value='option'
                          onChange={handleTypeFive}
                        />
                        <span className='checkmark'></span>
                      </div>
                      <div className='d-flex align-items-center'>
                        Schizophrenia
                        <input
                          checked={type6}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type6'
                          value='option'
                          onChange={handleTypeSix}
                        />
                        <span className='checkmark'></span>
                      </div>
                      <div className='d-flex align-items-center'>
                        ADHD
                        <input
                          checked={type9}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type9'
                          value='option'
                          onChange={handleTypeNine}
                        />
                        <span className='checkmark'></span>
                      </div>
                      <div className='d-flex align-items-center'>
                        Physical Ailment
                        <input
                          checked={type7}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type7'
                          value='option'
                          onChange={handleTypeSeven}
                        />
                        <span className='checkmark'></span>
                      </div>
                      <div className='d-flex align-items-center'>
                        Other Mental Condition
                        <input
                          checked={type8}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type8'
                          value='option'
                          onChange={handleTypeEight}
                        />
                        <span className='checkmark'></span>
                      </div>
                    </div>
                  </div>

                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    10. Receiving Govermnent benefits
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      className='text-left'
                      name='isReceivingGovtBenefits'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    11. Is US Citizen
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      className='text-left'
                      name='isUsCitizen'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    12. Monthly Income
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      className='text-left'
                      name='monthlyIncome'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={`$0 - $499`}>$0 - $499</Radio>
                        <Radio value={`$500 - $1,000`}>$500 - $1,000</Radio>
                        <Radio value={`$1,001 - $1,400`}>$1,001 - $1,400</Radio>
                        <Radio value={`$1,401 - $2,000`}>$1,401 - $2,000</Radio>
                        <Radio value={`&gt; $2,000`}>&gt; $2,000</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    13. Last Time Applied Benefits
                  </label>
                  <div className='col-sm-7 p-0 mt-3'>
                    {isApply ? (
                      <>
                        <Form.Item
                          name='lastTimeAppliedBenefits1'
                          getValueFromEvent={(onChange) =>
                            onChange == null
                              ? null
                              : moment(onChange).format(Constants.dateFormat)
                          }
                          getValueProps={(i) => ({
                            value: i == null ? null : moment(i),
                          })}
                        >
                          <DatePicker
                            disabled
                            disabledDate={(d: any) =>
                              !d || d.isBefore("01/01/1900")
                            }
                            format={Constants.dateFormat}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        <Form.Item
                          name='lastTimeAppliedBenefits'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                          ]}
                          getValueFromEvent={(onChange) =>
                            onChange == null
                              ? null
                              : moment(onChange).format(Constants.dateFormat)
                          }
                          getValueProps={(i) => ({
                            value: i == null ? null : moment(i),
                          })}
                        >
                          <DatePicker
                            disabledDate={(d: any) =>
                              !d || d.isBefore("01/01/1900")
                            }
                            format={Constants.dateFormat}
                          />
                        </Form.Item>
                      </>
                    )}
                  </div>
                  <div className='col-sm-2 d-flex align-items-center'>
                    <label className='col-form-label fill_description des_font font-size-apply pe-0 ps-0 mt-4'>
                      Do Not apply
                    </label>
                    <div className='p-0 mobile_view_chk_input text-left mt-4'>
                      <input
                        checked={isApply}
                        className='form-check ms-2'
                        type='checkbox'
                        name='type1'
                        value='option'
                        onChange={onChangeDoNotApply}
                      />
                    </div>
                  </div>
                  {resById.tenantId == 1 ? (
                    <></>
                  ) : (
                    <>
                      <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                        14. Scheduled Call Time
                      </label>
                      <div className='col-sm-9 p-0 mt-3'>
                        <Form.Item
                          name='scheduledTime'
                          getValueFromEvent={(onChange) =>
                            onChange == null
                              ? null
                              : moment(onChange).format(Constants.dateFormat)
                          }
                          getValueProps={(i) => ({
                            value: i == null ? null : moment(i),
                          })}
                        >
                          <DatePicker
                            format={Constants.dateFormat}
                            disabledDate={(d: any) =>
                              !d || d.isBefore("01/01/1900")
                            }
                          />
                        </Form.Item>
                      </div>
                    </>
                  )}

                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    {resById.tenantId == 1 ? 14 : 15}. Is Benefits Denied
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      className='text-left'
                      name='isBenefitsDenied'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    {resById.tenantId == 1 ? 15 : 16}. Hours Per Week
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      name='hoursPerWeek'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Select
                        options={hoursPerWeek.map((loc: any) => ({
                          label: loc.name,
                          value: loc.name,
                        }))}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    {resById.tenantId == 1 ? 16 : 17}. Paid FICA Taxes
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      className='text-left'
                      name='ficaTaxes'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Select
                        options={paidFICATaxes.map((loc: any) => ({
                          label: loc.name,
                          value: loc.name,
                        }))}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    {resById.tenantId == 1 ? 17 : 18}. Is Alcoholic
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      className='text-left'
                      name='isAlcoholic'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    {resById.tenantId == 1 ? 18 : 19}. Assets above $2000
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      className='text-left'
                      name='isOwnAssets'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    {resById.tenantId == 1 ? 19 : 20}. Has Attorney
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item name='isHasAttorney'>
                      <Select
                        options={hasAttorney.map((loc: any) => ({
                          label: loc.name,
                          value: loc.id,
                        }))}
                      />
                    </Form.Item>
                  </div>

                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    {resById.tenantId == 1 ? 20 : 21}. Pre Qual Notes
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item name='preQualNotes'>
                      <TextArea rows={4} maxLength={1024} />
                    </Form.Item>
                  </div>

                  <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                    {resById.tenantId == 1 ? 21 : 22}. Is SSI/SSDI Benefits
                  </label>
                  <div className='col-sm-9 p-0 mt-3'>
                    <Form.Item
                      className='text-left'
                      name='isSsiSsdiBenefits'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  {resById.estimatedReleaseDate != null && (
                    <>
                      <label className='col-form-label fill_description col-sm-3 des_font mt-3'>
                        {resById.tenantId == 1 ? 22 : 23}. When is your
                        estimated release date?
                      </label>
                      <div className='col-sm-9 p-0 mt-3'>
                        <Form.Item
                          name='estimatedReleaseDate'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                          ]}
                          getValueFromEvent={(onChange) =>
                            onChange == null
                              ? null
                              : moment(onChange).format(dateFormat)
                          }
                          getValueProps={(i) => ({
                            value: i == null ? null : moment(i),
                          })}
                        >
                          <DatePicker
                            format={dateFormat}
                            disabledDate={(d: any) =>
                              !d || d.isBefore("01/01/1900")
                            }
                          />
                        </Form.Item>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            )}
          </Modal>

          <PreQualFieldList
            resById={resById}
            intakeQuestionnaires={intakeQuestionnaires}
            onEdit={editPreQualification}
            userDetails={userDetails}
          />
        </>
      )}
    </>
  );
};

export default Prequalification;
