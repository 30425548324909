import {
  SaveNotificationTemplate,
  GetPlaceholders,
  GetNotificationTemplate,
  GetNotificationTemplateById,
  UpdateNotificationTemplate,
  DeleteNotificationTemplate,
  GetNotificationConfigByTemplate,
} from "../../apis/notificationTemplates";

export function saveNotificationTemplate(payload: any) {
  return SaveNotificationTemplate(payload);
}
export function getNotificationTemplate() {
  return GetNotificationTemplate();
}
export function getNotificationTemplateById(id: any) {
  return GetNotificationTemplateById(id);
}
export function deleteNotificationTemplate(Id: any) {
  return DeleteNotificationTemplate(Id);
}
export function getNotificationConfigByTemplate(Id: any) {
  return GetNotificationConfigByTemplate(Id);
}
export function updateNotificationTemplate(payload: any) {
  return UpdateNotificationTemplate(payload);
}
export function getPlaceholders() {
  return GetPlaceholders();
}
