import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import CustomerLeftPanel from "../components/CustomerLeftPanel";
import ActivityLog from "../components/ActivityLog";
import { BackTop } from 'antd';
import CustomerHeader from "../components/CustomerHeader";
import CustomerNotes from "../components/SaveNotes";
import "./customerLayout.scss";

const CustomerLayout = () => {
  return (
    <>
      <Header />
      <section id='admin_application_section'>
        <div className='container-fluid pl-3 pr-3 pb-3 pt-0'>
          <div className='row m-0 bg_row align-items-start'>
            <CustomerHeader />
            <div className="col-12 col-md-auto left_column">
              <CustomerLeftPanel />
              <ActivityLog />
            </div>
            <div className="col right_column">
              <CustomerNotes />
              <Outlet />
            </div>
          </div>
        </div>
        <div className="back-top-con">
          <BackTop />
        </div>
      </section>
    </>
  );
};

export default CustomerLayout;
