import axios from "axios";

export function SaveNotes(payload: any, groupUserIds: any) {
  return axios.post("Notes?GroupOfIds=" + groupUserIds, payload);
}
export function AddNotesReply(payload: any, groupOfIds: any) {
  return axios.post("NotesReply?GroupOfIds=" + groupOfIds, payload);
}
export function GetNotes(id: any, IsLead: any) {
  if (IsLead) return axios.get("Notes?LeadsId=" + id);
  else return axios.get("Notes?Id=" + id);
}
export function GetNotesById(id: any) {
  return axios.get("NotesById?Id=" + id);
}
export function DeleteNotes(Id: any) {
  return axios.delete("Notes?Id=" + Id);
}
export function UpdateNotes(payload: any, groupOfIds: any) {
  return axios.put("Notes?GroupOfIds=" + groupOfIds, payload);
}
export function UploadAttachment(payload: any, id: any) {
  return axios.post("UploadAttachment?&noteId=" + id, payload);
}
export function GetAttachments(id: any, IsLead: any) {
  if (IsLead) return axios.get("Attachments?LeadsId=" + id);
  else return axios.get("Attachments?Id=" + id);
}
export function DownloadAttachment(id: any) {
  return axios.get("DownloadAttachment?id=" + id);
}
export function DeleteAttachment(Id: any) {
  return axios.delete("Attachment?Id=" + Id);
}
export function UpdatePinnedValueForNotes(payload: any) {
  return axios.put("UpdatePinnedValue", payload);
}
export function StatusIdCahnegdData(id: any) {
  return axios.put("StatusIdChange?Id=" + id);
}
export function GetGroupUsers() {
  return axios.get("GroupUsers");
}
export function GetNoteOptions() {
  return axios.get("NoteOptions");
}
