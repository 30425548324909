import {
  SaveMedicationDetails,
  GetMedicationDetails,
  DeleteMedicationDetails,
  UpdateMedicationDetails,
  GetMedicationDetailsById,
} from "../../apis/medicationDetails";

export function saveMedicationDetails(payload: any) {
  return SaveMedicationDetails(payload);
}
export function getMedicationDetails(id: any) {
  return GetMedicationDetails(id);
}
export function getMedicationDetailsById(id: any) {
  return GetMedicationDetailsById(id);
}
export function deleteMedicationDetails(Id: any) {
  return DeleteMedicationDetails(Id);
}
export function updateMedicationDetails(payload: any) {
  return UpdateMedicationDetails(payload);
}
