import {
  GetGlobalTask,
  GetTaskType,
  UpdateGlobalTask,
  GetGlobalTaskById,
  SaveGlobalTask,
  GetTaskStatus,
  GetTaskPriority,
  DeleteGlobalTask,
  GetLeadsData,
  GetClientsData,
  GetNotificationMessage,
  CompletedNotification,
  NotificationContactMessage,
  SaveGlobalTaskNotes,
} from "../../apis/taskManagement";

export function saveGlobalTask(payload: any) {
  return SaveGlobalTask(payload);
}
export function updateGlobalTask(payload: any) {
  return UpdateGlobalTask(payload);
}
// export function getGlobalTask(assignTo:any, assignBy:any, startDate:any, endDate:any, priorityId:any,typeId:any,statusId:any) {
//     return GetGlobalTask(assignTo,assignBy,startDate,endDate,priorityId,typeId,statusId);
// }
export function getGlobalTask(payload: any) {
  return GetGlobalTask(payload);
}
export function deleteGlobalTask(id: any) {
  return DeleteGlobalTask(id);
}
export function notificationContactMessage(payload: any) {
  return NotificationContactMessage(payload);
}
export function getTaskPriority() {
  return GetTaskPriority();
}
export function getTaskStatus() {
  return GetTaskStatus();
}
export function getGlobalTaskById(id: any) {
  return GetGlobalTaskById(id);
}
export function getTaskType() {
  return GetTaskType();
}
export function getClientsData() {
  return GetClientsData();
}
export function getLeadsData() {
  return GetLeadsData();
}
export function getNotificationMessage(ClaimantId: any) {
  return GetNotificationMessage(ClaimantId);
}
export function completedNotification(Id: any) {
  return CompletedNotification(Id);
}
export function saveGlobalTaskNotes(payload: any) {
  return SaveGlobalTaskNotes(payload);
}
