import React, { useEffect, useState } from "react";
import { Form, Select, DatePicker, Switch } from "antd";
import moment from "moment";
import drp_admin from "../../images/drp_admin.png";
import {
  getGlobalTask,
  getTaskType,
  getTaskPriority,
  getTaskStatus,
  getClientsData,
  getLeadsData,
} from "../../actions/taskManagement";
import Loader from "../../components/Loader";
import { getSupportUsers } from "../../actions/supportUser";
import { get } from "../../utils/clientStorageUtils";
import { Spinner } from "react-bootstrap";
import { Enums } from "../../common/enums";
import GlobalTasksTableV1 from "./components/GlobalTasksTableV1";
import GlobalTasksTableV2 from "./components/GlobalTasksTableV2";

const GlobalTaskScreen = () => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [task, setTask] = useState([]);
  const [priority, setPriority] = useState([]);
  const [status, setStatus] = useState([]);
  const [user, setUser] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const [taskType, setTaskType] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [filtterTaskVal, setFilterTaskval] = useState([]);
  const [clientsData, setClientData] = useState<any[]>([]);
  const [leadsData, setLeadsData] = useState([]);
  const [tableVersion, setTableVersion] = useState(1);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const userIdData = JSON.parse(get("model"))
    ? JSON.parse(get("model")).userId
    : "";
  const [valueId, setValueId] = useState({
    assignTo: userIdData,
  });
  const [isUpdatingAssignedTo, setIsUpdatingAssignedTo] = useState(false);
  const getSupportUsersApi = () => {
    setIsPopUpLoader(true);
    setIsUpdatingAssignedTo(true);
    getSupportUsers().then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        setIsPopUpLoader(false);
        let userid = JSON.parse(get("model"))
          ? JSON.parse(get("model")).userId
          : "";
        var filterData = userdata
          .map((ele: any) => ({
            id: ele.id,
            display: `${ele.firstName} ${ele.lastName}`,
          }))
          .filter((ele: any) => ele.id != userid);
        setUser(filterData);
        var allUser = userdata
          .map((a: any) => ({
            id: a.id,
            allUsersDisplay: `${a.firstName} ${a.lastName}`,
          }))
          .filter((b: any) => b.id == userid || b.id != userid);
        setAllUsers(allUser);
        setIsUpdatingAssignedTo(false);
      }
    });
  };

  const dateFormat = "MM/DD/YYYY";
  const defaultStatus = "Not Started";

  useEffect(() => {
    getGlobalTaskApi();
    getTaskPriorityApi();
    getTaskStatusApi();
    getTaskTypeApi();
    getSupportUsersApi();
    getLeadsDataStore();
    getClientsDataStore();
  }, []);

  const getFilterTaskApi = (e: any) => {
    setIsLoader(true);
    var payload = {
      AssignTo: e.assignTo ? e.assignTo : null,
      AssignBy: e.assignedBy ? e.assignedBy : null,
      StartDate: e.startDate ? moment(e.startDate) : null,
      EndDate: e.endDate ? moment(e.endDate) : null,
      PriorityIds: e.priorityIds ? e.priorityIds : null,
      TypeIds: e.typeIds ? e.typeIds : null,
      StatusIds: e.statusIds ? e.statusIds : Enums.taskStatus.notStarted,
    };
    getGlobalTask(payload).then((res) => {
      if (res.data.responseCode == 200) {
        var taskVal = res.data.responseData;
        setFilterTaskval(taskVal);
        setTask(taskVal);
        setIsLoader(false);
        form1.setFieldsValue(res.data.responseData);
      }
    });
  };
  const clear = () => {
    getGlobalTaskApi();
  };
  const getGlobalTaskApi = () => {
    setIsLoader(true);

    var payloads = {
      AssignTo: null,
      AssignBy: null,
      StartDate: null,
      EndDate: null,
      PriorityIds: null,
      TypeIds: null,
      StatusIds: Enums.taskStatus.notStarted,
    };
    getGlobalTask(payloads).then((res) => {
      if (res.data.responseCode == 200) {
        setTask(res.data.responseData);
        setIsLoader(false);
        form.setFieldsValue(res.data.responseData);
      }
    });
  };
  const getTaskTypeApi = () => {
    getTaskType().then((res) => {
      if (res.data.responseCode == 200) {
        setTaskType(res.data.responseData);
      }
    });
  };

  const getClientsDataStore = () => {
    getClientsData().then((res) => {
      if (res.data.responseData) {
        var clientsDataStore = res.data.responseData;

        clientsDataStore.push({
          fullName: "----Please Select----",
          id: null,
        });
        clientsDataStore = clientsDataStore.sort((a: any, b: any) => {
          if (a.id < b.id) {
            return -1;
          }
        });
        setClientData(clientsDataStore);
      }
    });
  };
  const getLeadsDataStore = () => {
    getLeadsData().then((res) => {
      if (res.data.responseData) {
        var leadsDataStore = res.data.responseData;
        leadsDataStore.push({
          fullName: "----Please Select----",
          id: null,
        });
        leadsDataStore = leadsDataStore.sort((s: any, t: any) => {
          if (s.id < t.id) {
            return -1;
          }
        });
        setLeadsData(leadsDataStore);
      }
    });
  };

  const getTaskPriorityApi = () => {
    getTaskPriority().then((res) => {
      if (res.data.responseCode == 200) {
        setPriority(res.data.responseData);
      }
    });
  };

  const getTaskStatusApi = () => {
    getTaskStatus().then((res) => {
      if (res.data.responseCode == 200) {
        setStatus(res.data.responseData);
      }
    });
  };

  return (
    <>
      <Form
        className='mt-4'
        form={form1}
        name='TaskForm1'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete='off'
        onFinish={getFilterTaskApi}
        initialValues={valueId}
      >
        <div className='col-md-12 margin-auto'>
          <Form.Item name='id' />
          <div className='row mt-3'>
            <div className='col-md-12'>
              <div className='row min-max-design'>
                <div className='col mobile_responsive_field'>
                  <div className='p-0 width_cust_act width-page-global col-md-8 position-relative new-global-des'>
                    <img
                      className='img-fluid arrow_image_drp-global mt-3'
                      src={drp_admin}
                    />
                    <Form.Item name='assignTo' label='Assigned To'>
                      {isUpdatingAssignedTo == true ? (
                        <>
                          <Select placeholder={<Spinner size='sm' />} />
                        </>
                      ) : (
                        <Select
                          placeholder='Assigned To'
                          showSearch
                          optionFilterProp='label'
                          // defaultValue={userName}

                          options={allUsers.map((loc: any) => ({
                            label: loc.allUsersDisplay,
                            value: loc.id,
                            key: loc.id,
                          }))}
                        />
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className='col mobile_responsive_field ps-0'>
                  <div className='p-0 width_cust_act width-page-global position-relative new-global-des'>
                    <img
                      className='img-fluid arrow_image_drp-global mt-3'
                      src={drp_admin}
                    />
                    <Form.Item name='assignedBy' label='Assigned By'>
                      <Select
                        placeholder='Assigned By'
                        showSearch
                        optionFilterProp='label'
                        options={allUsers.map((loc: any) => ({
                          label: loc.allUsersDisplay,
                          value: loc.id,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='col mobile_responsive_field ps-0'>
                  <div className='p-0  width-page-global position-relative new-global-des'>
                    <img
                      className='img-fluid arrow_image_drp-global mt-3'
                      src={drp_admin}
                    />
                    <Form.Item name='typeIds' label='Task Type'>
                      <Select
                        placeholder='Type'
                        showSearch
                        optionFilterProp='label'
                        options={taskType.map((loc: any) => ({
                          label: loc.name,
                          value: loc.id,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='col mobile_responsive_field ps-0'>
                  <div className='p-0 width_cust_act width-page-global position-relative new-global-des'>
                    <img
                      className='img-fluid arrow_image_drp-global mt-3'
                      src={drp_admin}
                    />
                    <Form.Item name='priorityIds' label='Task Priority'>
                      <Select
                        placeholder='Priority'
                        showSearch
                        optionFilterProp='label'
                        options={priority.map((loc: any) => ({
                          label: loc.name,
                          value: loc.id,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className='col mobile_responsive_field ps-0'>
                  <div className='p-0 width_cust_act width-page-global position-relative new-global-des'>
                    <img
                      className='img-fluid arrow_image_drp-global mt-3'
                      src={drp_admin}
                    />
                    <Form.Item name='statusIds' label='Task Status'>
                      <Select
                        placeholder='Status'
                        showSearch
                        optionFilterProp='label'
                        defaultValue={defaultStatus}
                        options={status.map((loc: any) => ({
                          label: loc.name,
                          value: loc.id,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className='col-md-2 mobile_responsive_field p-0 mb-res-css'>
                  <div className='p-0 col new-global-des'>
                    <Form.Item
                      name='startDate'
                      label='Due Date'
                      getValueProps={(i) => ({
                        value: i == null ? null : moment(i),
                      })}
                    >
                      <DatePicker placeholder='From' format={dateFormat} />
                    </Form.Item>
                  </div>
                  <div className='col p-0 ms-2 new-global-des'>
                    <Form.Item
                      name='endDate'
                      label='&nbsp;'
                      getValueProps={(i) => ({
                        value: i == null ? null : moment(i),
                      })}
                    >
                      <DatePicker placeholder='To' format={dateFormat} />
                    </Form.Item>
                  </div>
                </div>
                <div className='col d-flex align-items-end w-100 justify-content-end'>
                  <button
                    ref={buttonRef}
                    type='submit'
                    className='btn btn-primary button_signup button_clients px-3 p-0 height_btn ms-3'
                  >
                    Search
                  </button>
                  <button
                    type='reset'
                    onClick={() => clear()}
                    className='btn btn-primary button_signup button_leads px-3 p-0 height_btn ms-3'
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>

      <div className='row mt-3'>
        <div className='col-md-12 align-items-center d-flex justify-content-end mt-2'>
          <label htmlFor='preview-new-version' className='me-2' role='button'>
            Preview New Version
          </label>
          <Switch
            id='preview-new-version'
            checked={tableVersion === 2}
            onChange={(isChecked) => setTableVersion(isChecked ? 2 : 1)}
          />
        </div>
      </div>

      {isLoader ? (
        <Loader></Loader>
      ) : (
        <>
          {tableVersion === 1 ? (
            <GlobalTasksTableV1 dataSource={task} />
          ) : (
            <GlobalTasksTableV2 dataSource={task} />
          )}
        </>
      )}
    </>
  );
};

export default GlobalTaskScreen;
