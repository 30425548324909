import React from "react";
import Notes from "../../components/Notes";

const LeadsNotes = () => {
  return (
    <>
      <Notes isLead={true} />
    </>
  );
};

export default LeadsNotes;
