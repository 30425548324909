import React from "react";
import packageJson from "../../../package.json";

const Footer = () => {
  return (
    <footer
      style={{
        position: "fixed",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        textAlign: "center",
        padding: "10px",
        width: "100%", // Ensures the footer stretches across the full width of the viewport
      }}
    >
      <p>Version: {packageJson.version}</p>
    </footer>
  );
};

export default Footer;
