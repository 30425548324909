import axios from "axios";

export function AddGroupUser(payload: any, form: any) {
    return axios.post("groups", payload);
  }

export function GetGroupUser() {
    return axios.get("groups");
  }

  export function UpdateGroupUser(payload: any) {
    return axios.put("groups", payload);
  }

export function DeleteGroupUser(id: any) {
    return axios.delete("groups?Id=" + id);
  }

  export function GetGroupUserById(id: any) {
    return axios.get("group?Id=" + id);
  }