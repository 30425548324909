import React from "react";
import Notes from "../../components/Notes";

const ClientsNotes = () => {
  return (
    <>
      <Notes isLead={false} />
    </>
  );
};

export default ClientsNotes;
