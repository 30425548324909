import {
  login,
  signOut,
  EmailExists,
  ResetPassword,
  ForgotPassword,
  EmailExistsByUserId,
} from "../../apis/accounts";

/**
 * Action cpost login payload
 * @param {payload} object with username and password
 */
export const loginUser: any = function (payload: any) {
  return login(payload);
};

/**
 * Action post Signout payload
 */
export const signOutUser: any = function () {
  return signOut();
};

export function EmailExist(payload: any) {
  return EmailExists(payload);
}
export function EmailExistByUserId(id: any, payload: any) {
  return EmailExistsByUserId(id, payload);
}

export function resetPassword(payload: any) {
  return ResetPassword(payload);
}

export function forgotPassword(payload: any) {
  return ForgotPassword(payload);
}
