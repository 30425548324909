import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Card, Typography } from "antd";
import { GetLeadsById } from "../../../apis/applcation";
import "../index.scss";
import { Enums } from "../../../common/enums";
import { CreateNewClaimant } from "../../../apis/leadsAndClients";
import { FlashMessage } from "../../../utils/flash_message";
import { Constants } from "../../../common/constants";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const { Title } = Typography;

interface ReviewLeadProps {
  moveLeadToNotQualified: (id: any) => void;
}

const ReviewLead: React.FC<ReviewLeadProps> = ({ moveLeadToNotQualified }) => {
  const [fullName, setFullName] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const leadId = searchParams.get("id") ?? "0";

  useEffect(() => {
    if (leadId !== "0") {
      const fetchLead = async () => {
        try {
          const { data } = await GetLeadsById(leadId);
          const fullName = data.responseData?.fullName;
          if (fullName) {
            setFullName(fullName);
          }
        } catch (error) {
          console.error("Error fetching lead:", error);
        }
      };

      fetchLead();
    }
  }, [leadId]);

  useEffect(() => {
    if (fullName) {
      const splitFullName = (
        fullName: string
      ): { firstName: string; lastName: string } => {
        const [firstName, ...rest] = fullName.trim().split(" ");
        const lastName = rest.join(" ");
        return { firstName, lastName };
      };

      const { firstName, lastName } = splitFullName(fullName);
      setFirstName(firstName);
      setLastName(lastName);
    }
  }, [fullName]);

  const handleReasonNotQualifiedOpen = (): void => {
    moveLeadToNotQualified(Enums.status.main_NonQualifiedStatus);
  };

  const handleAccept = async (): Promise<void> => {
    if (
      window.confirm(
        "Click OK to accept this lead and create a new claimant profile."
      )
    ) {
      try {
        const payload = { firstName, lastName, leadId: Number(leadId) };
        const res = await CreateNewClaimant(payload);
        const id = res.data.encryptedUserId;

        navigate(`/clientsnotes?id=${id}`);
      } catch (error: any) {
        let message = error?.response?.data?.responseMessage;
        FlashMessage(
          "error",
          message ? message : Constants.errorMessages.commonErrorMessage
        );
      }
    }
  };

  return (
    <div className='review_lead'>
      <Title level={3} className='brand-header'>
        Technical Pre-Qualification
      </Title>
      <Card className='dashed-card'>
        <div className='review_lead_help_text'>
          Promoting this lead will create a Claimant profile and assign you as
          the Intake Specialist.
        </div>

        <div className='review_lead_buttons'>
          <Button
            onClick={handleReasonNotQualifiedOpen}
            icon={<DislikeOutlined />}
          >
            Not Pre-Qualified
          </Button>
          <Button onClick={handleAccept} icon={<LikeOutlined />}>
            Accept as Pre-Qualified
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ReviewLead;
