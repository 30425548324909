import axios from "axios";
/**
 * post login payload
 * @param {payload} object with username and password
 * @return {Promise} Promise with the response if password and username is correct
 */
export function login(payload: any) {
  return axios.post("login", payload);
}

/**post Signout payload
 * @return {Promise} Promise with the response
 */
export function signOut() {
  return axios.post("SignOut");
}

export function EmailExists(payload: any) {
  return axios.get("EmailExists?Email=" + payload);
}
export function EmailExistsByUserId(id: any, payload: any) {
  return axios.get("EmailExistsByUserId?userId=" + id + "&Email=" + payload);
}

export function ResetPassword(payload: any) {
  return axios.post("ResetPassword", payload);
}

export function ForgotPassword(payload: any) {
  return axios.get("ForgotPassword?Email=" + payload);
}

export function GoogleSso(payload: any) {
  return axios.post("Accounts/google", payload);
}
