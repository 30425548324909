import {
  GetPipeline,
  GetAllPipeline,
  GetStatusByPipelineId,
  DeactivateLead,
  PipelineStatusDetails,
  UpdateApplicationStatus,
  GetLeadPipeline,
  UpdateLeadApplicationStatus,
  GetConcurrentUsersForLead,
  UpdateAssign_ReAssignEnterpriseLeads,
  UpdateAssign_ReAssignEnterpriseClaimants,
} from "../../apis/pipeline";

export function getPipeline() {
  return GetPipeline();
}
export function getAllPipeline() {
  return GetAllPipeline();
}
export function pipelineStatusDetails() {
  return PipelineStatusDetails();
}
export function updateApplicationStatus(userId: any, id: any) {
  return UpdateApplicationStatus(userId, id);
}
export function getLeadPipeline() {
  return GetLeadPipeline();
}
export function updateLeadApplicationStatus(leadId: any, statusId: any, reasonNotQualified: any) {
  return UpdateLeadApplicationStatus(leadId, statusId, reasonNotQualified);
}
export function deactivateLead(leadId: any) {
  return DeactivateLead(leadId);
}
export function getStatusByPipelineId(Id: any) {
  return GetStatusByPipelineId(Id);
}

export function getConcurrentUsersForLead(leadId: any) {
  return GetConcurrentUsersForLead(leadId);
}
export function updateAssign_ReAssignEnterpriseLeads(leadId: any, tenantId: any) {
  return UpdateAssign_ReAssignEnterpriseLeads(leadId, tenantId);
}
export function updateAssign_ReAssignEnterpriseClaimants(claimantId: any, tenantId: any) {
  return UpdateAssign_ReAssignEnterpriseClaimants(claimantId, tenantId);
}