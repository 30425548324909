import { Button, Modal } from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import { FlashMessage } from "../../../utils/flash_message";
import {
  GetSecureRegistrationLinksForClaimant,
  SendSecureRegistrationLinkToClaimant,
} from "../../../apis/leadsAndClients";
import { formatPhoneNumber } from "../../../utils/commonHelper";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import Loader from "../../Loader";

interface SendSecureRegistrationLinkProps {
  userId: string;
  phoneNumber: string;
  asMenuItem?: boolean;
}

const SendSecureRegistrationLink: React.FC<SendSecureRegistrationLinkProps> = ({
  userId,
  phoneNumber,
  asMenuItem,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [secureRegistrationLinks, setSecureRegistrationLinks] = useState<any[]>(
    []
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const previousLinksExists = secureRegistrationLinks.length > 0;

  const handleOpenModal = () => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("isSecureRegistrationLinkModalOpen", "true");
    setSearchParams(updatedSearchParams);
    getClaimantSecureRegistrationLinks();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("isSecureRegistrationLinkModalOpen", "false");
    setSearchParams(updatedSearchParams);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const modalOpenSearchParam = searchParams.get(
      "isSecureRegistrationLinkModalOpen"
    );
    setIsModalOpen(modalOpenSearchParam === "true");
  }, [searchParams]);

  const getClaimantSecureRegistrationLinks = async () => {
    try {
      const {
        data: { secureRegistrationLinks },
      } = await GetSecureRegistrationLinksForClaimant(userId);
      setSecureRegistrationLinks(secureRegistrationLinks);
    } catch (error) {
      FlashMessage(
        "error",
        "Failed to fetch secure registration links for user."
      );
    }
  };

  useEffect(() => {
    getClaimantSecureRegistrationLinks();
  }, [userId]);

  const handleSendSecureRegistrationLink = async () => {
    if (userId !== "0") {
      const payload = {
        claimantMindsetUserId: userId,
      };

      setIsLoading(true);
      try {
        await SendSecureRegistrationLinkToClaimant(payload);
        FlashMessage(
          "success",
          "Successfully sent new registration link to claimant"
        );
        await getClaimantSecureRegistrationLinks();
      } catch (error) {
        FlashMessage("error", `${error}`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {asMenuItem ? (
        <Dropdown.Item className='item_drp' onClick={handleOpenModal}>
          <h4 className='heading_tab cursorPointer'>
            Account Creation Text Messages
          </h4>
        </Dropdown.Item>
      ) : (
        <div className='secure_registration_link_section'>
          <Button onClick={handleOpenModal}>
            Account Creation Text Messages
          </Button>
        </div>
      )}

      <Modal
        open={isModalOpen}
        title='Send Account Creation Text Message'
        onCancel={handleModalClose}
        footer={
          <div
            className={`secure_registration_modal_buttons ${
              previousLinksExists && "reverse_button_order"
            }`}
          >
            <Button
              type={previousLinksExists ? "default" : "ghost"}
              onClick={handleModalClose}
            >
              Close
            </Button>
            <Button
              type={previousLinksExists ? "ghost" : "default"}
              className={
                previousLinksExists ? "brand-button" : "primary_button"
              }
              onClick={() =>
                isLoading ? null : handleSendSecureRegistrationLink()
              }
              loading={isLoading}
              disabled={!phoneNumber}
            >
              Send Message
            </Button>
          </div>
        }
      >
        {!phoneNumber ? (
          <Loader />
        ) : (
          <>
            <div className='send_new_link_section'>
              This will send a text message to the claimant's phone number{" "}
              <p>
                <strong>{formatPhoneNumber(phoneNumber)}</strong> to register
                for Mindset. If this number is incorrect, please update it first
                before sending.
              </p>
            </div>
            <div className='send_new_link_message_section'>
              <p className='send_new_link_message_section_title'>Message</p>
              <p className='send_new_link_message_section_content'>
                Complete your registration with Mindset by following this secure
                link:{" "}
                <span className='unique_link_placeholder'>[unique link]</span>
              </p>
            </div>

            <label className='registration_link_label'>History</label>
            {previousLinksExists ? (
              <div className='sent_messages_section'>
                {secureRegistrationLinks
                  .sort(
                    (a, b) =>
                      moment(b.createdDate).unix() -
                      moment(a.createdDate).unix()
                  )
                  .map((link) => {
                    const expirationMoment = moment.utc(link?.expirationDate);
                    const sendMoment = moment.utc(link?.sendDate);
                    const currentMoment = moment.utc();
                    const isExpired = expirationMoment.isBefore(currentMoment);

                    const inactive = isExpired || link?.isUsed;
                    const backgroundColorClass = inactive
                      ? "expired-link"
                      : "active-link";

                    const formattedExpirationDate = expirationMoment
                      .local()
                      .format("MMM. Do, YYYY [at] h:mm:ss A");

                    const formattedSendDate = sendMoment
                      .local()
                      .format("MMM. Do, YYYY [at] h:mm:ss A");

                    return (
                      <div
                        className={`registration_link_info ${backgroundColorClass}`}
                        key={link.id}
                      >
                        <p>
                          <strong>Sent: </strong>
                          {formattedSendDate}
                        </p>
                        <p>
                          <strong>Status: </strong>
                          {inactive ? "Inactive" : "Active"}
                          {link.isUsed && " | Used"}
                        </p>
                        <p>
                          <strong>Phone: </strong>
                          {formatPhoneNumber(link.phone)}
                        </p>
                        <p>
                          <strong>Expiration: </strong>
                          {formattedExpirationDate}
                        </p>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div style={{ color: "grey", fontSize: 16 }}>
                No links have been sent.
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default SendSecureRegistrationLink;
