import React from "react";
import { Form } from "antd";
import InputMask from "react-input-mask";
import { antdNoEmptyRule } from "../../../../validations/validateBlankSpace";
import { antdPhoneNumberRule } from "../../../../validations/numeric_only-validate";

export interface PhoneFieldProps {
  label: string;
  name: string;
  placeholder?: string;
}

const PhoneField = ({
  label,
  name,
  placeholder = "Please enter a phone number",
}: PhoneFieldProps) => {
  return (
    <Form.Item
      className='w-50'
      label={label}
      rules={[
        { required: true, message: "Invalid number" },
        antdPhoneNumberRule,
        antdNoEmptyRule,
      ]}
      validateTrigger='onBlur'
      name={name}
      normalize={(value: string) => value.replace(/\D/g, "")} // Removes all non-digit characters
    >
      <InputMask mask='(999) 999-9999' maskChar='_' placeholder={placeholder}>
        {(inputProps: any) => (
          <input {...inputProps} className='ant-input w-100' type='text' />
        )}
      </InputMask>
    </Form.Item>
  );
};

export default PhoneField;
