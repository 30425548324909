import { EditFilled, TabletOutlined } from "@ant-design/icons";
import { Button, Input, List, Tag, Tooltip } from "antd";
import moment from "moment";
import { useState } from "react";
import { Constants } from "../../../common/constants";
import "./styles.css";

type PrequalFieldItem = {
  label: string;
  type: string;
  tablet?: boolean;
};

const prequal_field_labels: {
  [key: string]: PrequalFieldItem;
} = {
  fullName: {
    label: "Full Name",
    type: "string",
  },
  email: {
    label: "Email",
    type: "string",
  },
  phoneNumber: {
    label: "Phone Number",
    type: "string",
  },
  locationName: {
    label: "Location",
    type: "string",
  },
  isUsCitizen: {
    label: "Are you a United States citizen or permanent resident?",
    type: "boolean",
  },
  age: {
    label: "How old is the applicant?",
    type: "string",
  },
  isBenefitsDenied: {
    label:
      "Have you or your loved one’s previous attempts to apply for benefits been lost or denied?",
    type: "boolean",
  },
  lastTimeAppliedSSBenefits: {
    label:
      "When was the last time you or your loved one applied for Social Security Disability Benefits?",
    type: "date",
  },
  isReceivingGovtBenefits: {
    label: "Are you or your loved one currently receiving government benefits?",
    type: "boolean",
  },
  hoursPerWeek: {
    label: "How many hours per week does the applicant work?",
    type: "string",
  },
  ficaTaxes: {
    label:
      "In the last 10 years, how many years did the applicant work and pay FICA (Social Security) taxes?",
    type: "string",
  },
  mentalIllness: {
    label: "What medical condition keeps the applicant from not working?",
    type: "list",
  },
  isSeenDoc: {
    label:
      "Does the applicant see a mental health doctor such as a psychiatrist or psychologist, on a regular basis (every week, every month, or every 3 months)?",
    type: "boolean",
  },
  isAlcoholic: {
    label: "Is this applicant currently abusing alcohol or illegal drugs?",
    type: "boolean",
  },
  monthlyIncome: {
    label: "What is the applicant’s monthly income?",
    type: "string",
  },
  isOwnAssets: {
    label:
      "Does the applicant own any assets that are a combined value above $2,000?",
    type: "boolean",
  },
  isHasAttorney: {
    label:
      "Do you already have an attorney currently representing you on a disability claim?",
    type: "boolean",
  },
  lastTimeAppliedBenefits: {
    label: "Last Time Applied Benefits",
    type: "date",
  },
  isCaregiver: {
    label: "Is Caregiver",
    type: "boolean",
  },
  isMentalIllness: {
    label: "Is Mental Illness",
    type: "boolean",
  },
  isDenial: {
    label: "Is Denial",
    type: "boolean",
  },
  leadSource: {
    label: "Lead Source",
    type: "string",
  },
  scheduledCallTs: {
    label: "Scheduled Call Ts",
    type: "date",
  },
  timeZone: {
    label: "Time Zone",
    type: "string",
  },
  timezoneNew: {
    label: "Time Zone New",
    type: "string",
  },
  estimatedReleaseDate: {
    label: "When is your estimated release date?",
    type: "date",
  },
  preQualNotes: {
    label: "Pre Qual Notes",
    type: "string",
  },
  isSsiSsdiBenefits: {
    label: "Are you currently receiving SSI or SSDI benefits?",
    type: "boolean",
  },
  scheduledTime: {
    label: "Scheduled Call Time",
    type: "date",
  },
  reasonForNotQualified: {
    label: "Reason For Not Qualified",
    type: "string",
  },
  // New Tablet Fields
  isReleaseDateNextThreeMonths: {
    label: "Is your release date in the next three months?",
    type: "boolean",
    tablet: true,
  },
  isReceivedDenialLetter: {
    label:
      "Do you have a disability application pending, and if yes, have you received a denial letter in the past 60 days?",
    type: "boolean",
    tablet: true,
  },
  isOverPaymentsSsiSsdiBenefits: {
    label:
      "Do you owe SSA more than $6,000 for overpayments of SSI or SSDI benefits?",
    type: "boolean",
    tablet: true,
  },
  kidsUnderEighteen: {
    label: "How many kids under 18 live with you in the same household?",
    type: "text",
    tablet: true,
  },
  isPayTaxes: {
    label:
      "In the last 10 years, did you work for 5 or more years and pay taxes?",
    type: "boolean",
    tablet: true,
  },
  isReceiveMonthlyIncome: {
    label:
      "Do you receive any other monthly income or benefits in excess of $900?",
    type: "boolean",
    tablet: true,
  },
  isMentalPhysicalDisability: {
    label:
      "What is your service-connected disability rating percentage? [If none, write N/A]",
    type: "boolean",
    tablet: true,
  },
  lastTimeWorkedJob: {
    label: "When was the last time you worked at a job? A guess is fine.",
    type: "date",
    tablet: true,
  },
  isFullTimeWorkedReleased: {
    label: "Do you plan to go back to work full-time after you are released?",
    type: "boolean",
    tablet: true,
  },
  isFullTimeJobRelease: {
    label:
      "Do you feel you will be able to keep a full-time job after your release?",
    type: "boolean",
    tablet: true,
  },
  disabilityRatingPercentage: {
    label:
      "What is your service-connected disability rating percentage? [If none, write N/A]",
    type: "text",
    tablet: true,
  },
  criminalConvictions: {
    label: "Please list your criminal convictions.",
    type: "text",
    tablet: true,
  },
  mentalHealthSymptoms: {
    label: "What are your mental health symptoms?",
    type: "text",
    tablet: true,
  },
  isMentalHealthReceivedTreatment: {
    label:
      "Have you received treatment by a doctor for these mental conditions in the past year?",
    type: "boolean",
    tablet: true,
  },
  mentalHealthPrescribed: {
    label:
      "What medications are you prescribed for these mental conditions? [If none, write N/A]",
    type: "text",
    tablet: true,
  },
  physicalConditions: {
    label: "What are your physical conditions?",
    type: "text",
    tablet: true,
  },
  physicalSymptoms: {
    label: "What are your physical symptoms?",
    type: "text",
    tablet: true,
  },
  isPhysicalReceivedTreatment: {
    label:
      "Have you received treatment by a doctor for these physical conditions in the past year?",
    type: "boolean",
    tablet: true,
  },
  physicalReceivedPrescribed: {
    label:
      "What medications are you prescribed for these physical conditions? [If none, write N/A]",
    type: "text",
    tablet: true,
  },
};

const intake_field_labels: {
  [key: string]: PrequalFieldItem;
} = {
  hasPreviouslyFiledForSocialSecurityBenefits: {
    label: "Have you applied for Social Security benefits before?",
    type: "boolean",
  },
  previousSocialSecurityBenefitsFilingDate: {
    label: "When did you apply for Social Security benefits?",
    type: "string",
  },
  previousSocialSecurityBenefitsResult: {
    label: "What was the result?",
    type: "string",
  },
  previousSocialSecurityHearingDate: {
    label: "Do you have a hearing date scheduled?",
    type: "date",
  },
  lastDateOfEmployment: {
    label: "When was the time you last worked?",
    type: "date",
  },
  isLastEmploymentFullTime: {
    label: "Was it full-time or part-time work?",
    type: "boolean",
  },
  whyDidTheLastJobEnd: {
    label: "Why did you leave your last job?",
    type: "string",
  },
  howLongDidLastJobLast: {
    label: "How long did your last job last?",
    type: "string",
  },
  yearsWorkedInTheLastTenYears: {
    label: "How many years have you worked in the past 10 years?",
    type: "string",
  },
  yearsPaidTax: {
    label: "How many did you pay taxes?",
    type: "string",
  },
  childrenCount: {
    label: "How many children do you have?",
    type: "string",
  },
  childCountReceivingSocialSecurityBenefits: {
    label: "How many of them are receiving disability benefits currently?",
    type: "string",
  },
  hasRegularEarnedIncomeFromJobs: {
    label: "Do you do jobs for cash regularly?",
    type: "boolean",
  },
  isLivingWithPartner: {
    label: "Do you live with a partner or spouse?",
    type: "boolean",
  },
  isFinanciallySupportedByPartner: {
    label: "Do you get financial support from them?",
    type: "boolean",
  },
  partnerIncome: {
    label: "What is their annual income?",
    type: "string",
  },
  hasTooMuchCaseOrAssetsToQualifyForSSI: {
    label:
      "Do you have any assets or cash over $2,000 (single) or $3,000 (married)?",
    type: "boolean",
  },
  isReceivingVaBenefits: {
    label: "Are you currently receiving Veterans Affairs (VA) benefits?",
    type: "boolean",
  },
  vaBenefitAmount: {
    label: "What is the monthly VA benefit amount you receive?",
    type: "string",
  },
  highestLevelOfEducation: {
    label: "What is the highest level of education you’ve completed?",
    type: "string",
  },
  canWorkFullTime: {
    label: "Do you feel capable of working full-time now?",
    type: "boolean",
  },
  isSeekingFullTimeEmployment: {
    label: "Are you currently looking for full-time work?",
    type: "boolean",
  },
  longestJobLengthHeldInLastTenYears: {
    label: "What’s the longest you’ve worked full-time in the past 10 years?",
    type: "string",
  },
  conditionsAffectAbilityToWork: {
    label: "What conditions affect your ability to work?",
    type: "string_array",
  },
  relatedInLast15Months: {
    label:
      "Select all that apply to you that's related to your health condition in the past 12 months:",
    type: "string_array",
  },
  mentalHealthConditions: {
    label: "What are your mental health conditions?",
    type: "string_array",
  },
  physicalHealthConditions: {
    label: "What are your physical health conditions?",
    type: "string_array",
  },
  mentalHealthSymtops: {
    label: "Mental health symtoms",
    type: "string_array",
  },
  hasDrRecommendedApplicationForDisabilityBenefits: {
    label:
      "Has any doctor recommended that you should apply for disability benefits?",
    type: "boolean",
  },
  hasCurrentOrPastDrugOrAlcoholDependency: {
    label: "Have you ever struggled with drug or alcohol dependency?",
    type: "boolean",
  },
  sobrietyLength: {
    label: "How long have you been sober?",
    type: "string",
  },
  currentMedications: {
    label: "What medications are you taking?",
    type: "string",
  },
  isUsingMedicalEquipment: {
    label: "Do you use medical equipment in your daily life?",
    type: "boolean",
  },
  currentMedicalEquipment: {
    label: "What medical equipment do you use?",
    type: "string_array",
  },
  hasServiceOrSupportAnimal: {
    label: "Do you have a certified service or support animal?",
    type: "boolean",
  },
  additionalInformation: {
    label: "Additional information",
    type: "string",
  },
};

const displayFieldByType = (type: string, value: any, label = "") => {
  if (label === intake_field_labels.isLastEmploymentFullTime.label) {
    if (value === true) {
      return "Full Time";
    }
    if (value === false) {
      return "Part Time";
    }
  }
  if (type === "boolean") {
    if (value == null) {
      return ""; // or "N/A"
    } else if (value) {
      return "Yes";
    } else {
      return "No";
    }
  }
  if (type === "date" && value) {
    return moment.utc(value).local().format(Constants.dateFormat);
  }
  if (type === "list" && value) {
    return value
      .split(",")
      .filter((val: string) => val !== "")
      .join(", ");
  }
  if (type === "string_array" && value) {
    return Array.from(new Set(value.split(";;;") as string[])).map(
      (val: string) => <Tag className='mb-2 me-0 ms-2'>{val}</Tag>
    );
  }
  return value;
};

const PreQualFieldList = ({
  resById,
  intakeQuestionnaires,
  userDetails,
  onEdit,
}: {
  resById: any;
  intakeQuestionnaires: any;
  userDetails: any;
  onEdit: () => void;
}) => {
  const [filterFields, setFilterFields] = useState("");
  const [showEmptyFields, setShowEmptyFields] = useState(false);

  const [filterIntakeFields, setFilterIntakeFields] = useState("");
  const [showIntakeEmptyFields, setIntakeShowEmptyFields] = useState(false);

  const prequal_fields = Object.entries(prequal_field_labels)
    .filter((field) => field[0] in resById)
    .map(([key, value]) => ({
      key,
      value: resById[key],
    }));

  const intake_fields = intakeQuestionnaires
    ? Object.entries(intake_field_labels)
        .filter((field) => field[0] in intakeQuestionnaires)
        .map(([key, value]) => ({
          key,
          value: intakeQuestionnaires[key],
        }))
    : [];

  return (
    <div className='row mt-5'>
      <div className='col-12 mb-4'>
        {userDetails && (
          <List
            id='prequal_fields_list'
            size='small'
            loading={!userDetails}
            bordered
            header={
              <div className='d-flex justify-content-between align-items-center'>
                <h5 className='mb-0'>User Details</h5>
              </div>
            }
            itemLayout='horizontal'
            dataSource={[
              {
                label: "What is your date of birth?",
                value: userDetails.dateOfBirth
                  ? `${moment(userDetails.dateOfBirth).format(
                      Constants.dateFormat
                    )} - (Age: ${moment().diff(
                      userDetails.dateOfBirth,
                      "years"
                    )})`
                  : "N/A",
              },
              {
                label: "Are you a military veteran?",
                value: displayFieldByType(
                  "boolean",
                  userDetails.isMilitaryVeteran
                ),
              },
              {
                label:
                  "Do you receive service-connected disability benefits through the Veterans Administration?",
                value: displayFieldByType(
                  "boolean",
                  userDetails.IsVeteransAdministration
                ),
              },
              {
                label:
                  "Will you live with your spouse after release? If yes, do they make over $2,800 a month?",
                value: displayFieldByType(
                  "boolean",
                  userDetails.IsSpouseAfterRelease
                ),
              },
            ]}
            renderItem={(item) => (
              <List.Item>
                <div className='prequal_fields_key fw-semibold'>
                  <span className='ms-2'>{item.label}</span>
                </div>
                <div className='prequal_fields_value'>{item.value}</div>
              </List.Item>
            )}
          />
        )}
      </div>
      <div className='col-12 mb-4'>
        <List
          id='prequal_fields_list'
          size='small'
          loading={!resById}
          bordered
          header={
            <div>
              <div className='d-flex justify-content-between align-items-center'>
                <h5 className='mb-0'>Prequalification</h5>
                <button className='edit_btn_pre' onClick={onEdit}>
                  <EditFilled
                    style={{
                      fontSize: "22px",
                      color: "#77d4b1",
                    }}
                  />
                </button>
              </div>
              <Input
                className='my-2'
                value={filterFields}
                onChange={(e) => setFilterFields(e.target.value)}
                placeholder='Filter fields...'
              />
            </div>
          }
          footer={
            <div className='d-flex justify-content-center'>
              <Button onClick={() => setShowEmptyFields(!showEmptyFields)}>
                {showEmptyFields ? "Hide" : "Show"} Blank Fields
              </Button>
            </div>
          }
          itemLayout='horizontal'
          dataSource={prequal_fields
            .filter((field) =>
              filterFields
                ? prequal_field_labels[field.key].label
                    .toLowerCase()
                    .includes(filterFields.toLowerCase())
                : true
            )
            .filter((field) => (showEmptyFields ? true : field.value !== null))}
          renderItem={(item) => (
            <List.Item>
              <div className='prequal_fields_key fw-semibold'>
                {prequal_field_labels[item.key].tablet && (
                  <Tooltip
                    className='qualification_details'
                    title='Tablet Field'
                  >
                    <TabletOutlined />
                  </Tooltip>
                )}
                <span className='ms-2'>
                  {prequal_field_labels[item.key].label}
                </span>
              </div>
              <div className='prequal_fields_value'>
                {displayFieldByType(
                  prequal_field_labels[item.key].type,
                  item.value
                )}
              </div>
            </List.Item>
          )}
        />
      </div>
      <div className='col-12'>
        <List
          id='prequal_fields_list'
          size='small'
          loading={!intakeQuestionnaires}
          bordered
          header={
            <div>
              <div className='d-flex align-items-center'>
                <Tooltip
                  className='qualification_details'
                  title='Self Serve Field'
                >
                  <span
                    style={{
                      borderRadius: "16px",
                      border:
                        "1px solid var(--Colors-Neutral-Border-colorBorder, #D9D9D9)",
                      background: "var(--Colors-Mindset-Site-Grey, #F6F6F6)",
                      display: "flex",
                      width: "35px",
                      height: "25px",
                      marginLeft: "5px",
                      marginTop: "-3px",
                      marginRight: "5px",
                      padding: "2px var(--Space-Padding-paddingXS, 8px)",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "var(--Space-Margin-marginXXS, 4px)",
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                    >
                      <path
                        d='M4.76823 4.81511C4.0026 4.03907 4.0026 2.77865 4.76823 2.00261C5.53385 1.22657 6.77344 1.22657 7.53906 2.00261L8 2.47136L8.46094 2.00261C9.22656 1.22657 10.4661 1.22657 11.2318 2.00261C11.9974 2.77865 11.9974 4.03907 11.2318 4.81511L8.58594 7.50261C8.42448 7.66667 8.21354 7.7474 8 7.7474C7.78646 7.7474 7.57552 7.66667 7.41406 7.50261L4.76823 4.81511ZM15.2969 10.0078C15.638 10.4714 15.5391 11.1224 15.0755 11.4635L11.7786 13.8932C11.1693 14.3412 10.4349 14.5833 9.67708 14.5833H5.5H1.33333C0.872396 14.5833 0.5 14.2109 0.5 13.75V12.0833C0.5 11.6224 0.872396 11.25 1.33333 11.25H2.29167L3.46094 10.3125C4.05208 9.83855 4.78646 9.58334 5.54427 9.58334H7.58333H8H9.66667C10.1276 9.58334 10.5 9.95573 10.5 10.4167C10.5 10.8776 10.1276 11.25 9.66667 11.25H8H7.58333C7.35417 11.25 7.16667 11.4375 7.16667 11.6667C7.16667 11.8958 7.35417 12.0833 7.58333 12.0833H10.724L13.8411 9.78646C14.3047 9.44532 14.9557 9.54428 15.2969 10.0078ZM5.54167 11.25H5.51823C5.52604 11.25 5.53385 11.25 5.54167 11.25Z'
                        fill='#3E3E56'
                      />
                    </svg>
                  </span>
                </Tooltip>
                <h5 className='mb-0'>Care Self-Serve Intake Results</h5>
              </div>
              <Input
                className='my-2'
                value={filterIntakeFields}
                onChange={(e) => setFilterIntakeFields(e.target.value)}
                placeholder='Filter fields...'
              />
            </div>
          }
          footer={
            <div className='d-flex justify-content-center'>
              <Button
                onClick={() => setIntakeShowEmptyFields(!showIntakeEmptyFields)}
              >
                {showIntakeEmptyFields ? "Hide" : "Show"} Blank Fields
              </Button>
            </div>
          }
          itemLayout='horizontal'
          dataSource={intake_fields
            .filter((field) =>
              filterIntakeFields
                ? intake_field_labels[field.key].label
                    .toLowerCase()
                    .includes(filterIntakeFields.toLowerCase())
                : true
            )
            .filter((field) =>
              showIntakeEmptyFields
                ? true
                : field.value !== null &&
                  field.value !== "" &&
                  (Number.isNaN(Number(field.value)) ||
                    (!Number.isNaN(Number(field.value)) &&
                      Number(field.value) !== 0) ||
                    intake_field_labels[field.key].type === "boolean")
            )}
          renderItem={(item) => (
            <List.Item>
              <div
                className='prequal_fields_key fw-semibold'
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className='ms-2'>
                  {intake_field_labels[item.key].label}
                </span>
              </div>
              <div className='prequal_fields_value'>
                {displayFieldByType(
                  intake_field_labels[item.key].type,
                  item.value,
                  intake_field_labels[item.key].label
                )}
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default PreQualFieldList;
