import {
  SaveCollegeHistory,
  GetCollegeHistory,
  DeleteCollegeHistory,
  UpdateCollegeHistory,
  GetCollegeHistoryById,
} from "../../apis/collegeHistory";

export function saveCollegeHistory(payload: any) {
  return SaveCollegeHistory(payload);
}
export function getCollegeHistory(id: any) {
  return GetCollegeHistory(id);
}
export function getCollegeHistoryById(id: any) {
  return GetCollegeHistoryById(id);
}
export function deleteCollegeHistory(Id: any) {
  return DeleteCollegeHistory(Id);
}
export function updateCollegeHistory(payload: any) {
  return UpdateCollegeHistory(payload);
}
