import React from "react";
import "../Loader/index.module.scss";

const LoaderLoadMore = () => {
  return (
    <>
      <div className='d-flex justify-content-center align-items-center col-md-12 text-center'>
        <div className='loader2'></div>
      </div>
    </>
  );
};

export default LoaderLoadMore;
