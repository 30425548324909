import axios from "axios";

export function ClientsWithNoLeadId() {
  return axios.get("ClientsWithNoLeadId");
}
export function GePipelineStatusById(pipelineId: any) {
  return axios.get("PipelineStatusById?pipelineId=" + pipelineId);
}
export function GetLeadByApplicationStatusId(
  applicationStatusId: any,
  pageSize: number,
  pageNum: number,
  sortByDescending: boolean
) {
  return axios.get(
    "LeadsByApplicationStatusId?applicationStatusId=" +
      applicationStatusId +
      "&pageSize=" +
      pageSize +
      "&pageNum=" +
      pageNum +
      "&sortByDescending=" +
      sortByDescending
  );
}
export function PipelineStatusWithNoLead(
  pipelineId: any,
  onlyShowMyCases: any
) {
  return axios.get(
    `PipelineStatusWithNoLead?pipelineId=${pipelineId}&onlyShowMyCases=${onlyShowMyCases}`
  );
}
export function GetClientsByApplicationStatusId(
  applicationStatusId: any,
  pageSize: number,
  pageNum: number,
  sortByDescending: boolean,
  onlyShowMyCases: any
) {
  return axios.get(
    `ClientsByApplicationStatusId?applicationStatusId=${applicationStatusId}&pageSize=${pageSize}&pageNum=${pageNum}&sortByDescending=${sortByDescending}&onlyShowMyCases=${onlyShowMyCases}`
  );
}
export function LeadUserAttributes(payload: any) {
  return axios.put("LeadUserAttributes", payload);
}
export function GetLeadUserAttributes(Id: any) {
  return axios.get("LeadUserAttributes?Id=" + Id);
}
export function ClientUserAttributes(payload: any) {
  return axios.put("ClientUserAttributes", payload);
}
export function GetClientUserAttributes(Id: any) {
  return axios.get("ClientUserAttributes?Id=" + Id);
}
export function ProfileViewDetailsData(payload: any) {
  return axios.post("ProfileViewDetails", payload);
}
export function UnMapLead(ClaimantId: any) {
  return axios.delete("UnMapLead?encryptedId=" + ClaimantId);
}
export function CreateLead(payload: any) {
  return axios.post("Lead", payload);
}
export function SendSecureRegistrationLinkToClaimant(payload: any) {
  return axios.post("SendSecureRegistrationLink", payload);
}
export function GetSecureRegistrationLinksForClaimant(Id: any) {
  return axios.get("GetSecureRegistrationLinksForUser?encryptedUserId=" + Id);
}
export function CreateNewClaimant(payload: any) {
  return axios.post("CreateClaimantAccountFromLead", payload);
}
