import React, { useState } from "react";
import { Input } from "antd";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { activityLogUnmaskingSsn } from "../../../actions/application";

interface SocialSecurityNumberInputProps {
  value?: string;
  onChange?: (value: string) => void;
  logActivity?: boolean;
}

const SocialSecurityNumberInput: React.FC<SocialSecurityNumberInputProps> = ({
  value = "",
  onChange,
  logActivity = true,
}) => {
  const [ssnVisible, setSsnVisible] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const toggleSsnVisibility = (): void => {
    setSsnVisible((prev) => {
      const newState = !prev;

      if (newState && logActivity) {
        const param = searchParams.get("id") || "0";
        activityLogUnmaskingSsn(param);
      }

      return newState;
    });
  };

  const getMaskedSsn = (ssn: string): string =>
    !ssn || ssn.length < 4 ? "***-**-****" : `***-**-${ssn.slice(-4)}`;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value;
    if (onChange) {
      onChange(inputValue);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Input
        value={ssnVisible ? value : getMaskedSsn(value)}
        onChange={handleInputChange}
        maxLength={9}
        disabled={!ssnVisible}
        placeholder='SSN'
        className='input_style3 formfield'
      />
      <a
        className='eye_icon'
        onClick={toggleSsnVisibility}
        style={{ marginLeft: 8 }}
      >
        {ssnVisible ? (
          <EyeFilled style={{ fontSize: "20px", color: "#747497" }} />
        ) : (
          <EyeInvisibleFilled style={{ fontSize: "20px", color: "#747497" }} />
        )}
      </a>
    </div>
  );
};

export default SocialSecurityNumberInput;
