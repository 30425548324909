import { createAsyncThunk } from '@reduxjs/toolkit'
import { getRequest } from '../generic api/get'
import { postRequest } from '../generic api/post'
import { GET_ALL_USERS_ACTION, LOGIN_ACTION } from './actions'
import { GET_ALL_USERS_API, LOGIN_API } from './api'

export const getUsers:any = createAsyncThunk(GET_ALL_USERS_ACTION, async () => {
  const response = await getRequest(GET_ALL_USERS_API)
  return response
})

export const loginApiService:any = createAsyncThunk(LOGIN_ACTION, async (data) => {
  const response = await postRequest(LOGIN_API, data)
  return response
})