import React from "react";
import AddressWithoutNameField from "./SharedFields/AddressWithoutNameField";
import NameField from "./SharedFields/NameField";
import DollarField from "./SharedFields/DollarField";
const FeeLetter = () => {
  return (
    <>
      <div className='row'>
        <NameField
          label="Judge's First and Last Name"
          placeholder="Judge's Name"
          name='judgeName'
        />
      </div>
      <div className='row'>
        <label className='col-form-label'>
          <strong>Social Security Office Address</strong>
          <AddressWithoutNameField />
        </label>
      </div>
      <div className='row'>
        <DollarField label='Fee Petition Amount' name='feePetitionAmount' />
      </div>
      <div className='row'>
        <DollarField label='Attorney Fee Withheld' name='feeAttorneyWithheld' />
      </div>
    </>
  );
};

export default FeeLetter;
