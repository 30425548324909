import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "../pages/Login";
import PrivateRoute from "./PrivateRoute";
import Application from "../pages/Application";
import AccountLayout from "../layouts/accountLayout";
import CustomerLayout from "../layouts/customerLayout";
import LeadsLayout from "../layouts/leadslayout/leadsLayout";
import Leads from "../pages/Leads";
import GenerateDocuments from "../pages/GenerateDocuments";
import RequestedInfo from "../pages/RequestedInfo";
import Clients from "../pages/Clients";
import UploadDoc from "../pages/UploadDoc";
import PreQualificationClient from "../pages/PreQualificationClient";
import SendPhysicalMail from "../pages/SendPhysicalMail";
import SendFax from "../pages/SendFax";
import AdminTemplates from "../components/AdminTemplates";
import ChangePassword from "../pages/ChangePassword";
import PrequalificationLead from "../pages/PreQualificationLead";
import LeadsDetailLayout from "../layouts/leadsDetailLayout";
import LeadsNotes from "../pages/LeadsNotes";
import ClientsNotes from "../pages/ClientsNotes";
import ManageUser from "../pages/ManageUser";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Tenants from "../pages/Tenants";
import NotificationConfiguration from "../pages/NotificationConfiguration";
import NotificationTemplates from "../pages/NotificationTemplates";
import Logs from "../pages/Logs";
import GlobalTaskScreen from "../pages/GlobalTaskScreen";
import CaseManagement from "../pages/CaseManagement";
import ManageGroupUser from "../pages/ManageGroupUser";
import ManageMedication from "../pages/ManageMedication";
import FeatureFlagSettingsPage from "../pages/FeatureFlagSettings";
import { Constants } from "../common/constants";
import HearingCalendar from "../pages/HearingCalendar";
import Facilities from "../pages/Facilities";
const RouterComponent = () => {
  const routes = [
    {
      name: "Clients",
      element: <Clients />,
      url: "/clients",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Leads",
      element: <Leads />,
      url: "/leads",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Application",
      element: <Application />,
      url: "/application",
      isPublic: false,
      layout: "customer",
    },
    {
      name: "Login",
      element: <LoginPage />,
      url: "/login",
      isPublic: true,
      layout: "account",
    },
    {
      name: "OldLogin",
      element: <LoginPage loginWithCredentials={true} />,
      url: "/oldlogin",
      isPublic: true,
      layout: "account",
    },
    {
      name: "Login",
      element: <Navigate to={"/login"} />,
      url: "/",
      isPublic: true,
      layout: "account",
    },
    {
      name: "UploadDoc",
      element: <UploadDoc />,
      url: "/uploaddocuments",
      isPublic: false,
      layout: "customer",
    },
    {
      name: "ClientsNotes",
      element: <ClientsNotes />,
      url: "/clientsnotes",
      isPublic: false,
      layout: "customer",
    },
    {
      name: "LeadsNotes",
      element: <LeadsNotes />,
      url: "/leadsnotes",
      isPublic: false,
      layout: "leadsDetail",
    },
    {
      name: "PreQualificationClient",
      element: <PreQualificationClient />,
      url: "/PreQualificationClient",
      isPublic: false,
      layout: "customer",
    },
    {
      name: "PrequalificationLead",
      element: <PrequalificationLead />,
      url: "/prequalification",
      isPublic: false,
      layout: "leadsDetail",
    },
    {
      name: "RequestedInfo",
      element: <RequestedInfo />,
      url: "/requestedinfo",
      isPublic: false,
      layout: "customer",
    },
    {
      name: "SendPhysicalMail",
      element: <SendPhysicalMail />,
      url: "/sendphysicalmail",
      isPublic: false,
      layout: "customer",
    },
    {
      name: "GenerateDocuments",
      element: <GenerateDocuments />,
      url: "/generateDocuments",
      isPublic: false,
      layout: "customer",
    },
    {
      name: "SendFax",
      element: <SendFax />,
      url: "/sendFax",
      isPublic: false,
      layout: "customer",
    },
    {
      name: "AdminTemplates",
      element: <AdminTemplates />,
      url: "/templates",
      isPublic: false,
      layout: "customer",
    },
    {
      name: "NotificationConfiguration",
      element: <NotificationConfiguration />,
      url: "/notificationconfiguration",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "ChangePassword",
      element: <ChangePassword />,
      url: "/changePassword",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "ManageUser",
      element: <ManageUser />,
      url: "/manageUser",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "ManageUser",
      element: <Tenants />,
      url: "/tenants",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "ManageFacilities",
      element: <Facilities />,
      url: "/facilities",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "ForgotPassword",
      element: <ForgotPassword />,
      url: "/forgotPassword",
      isPublic: true,
      layout: "account",
    },
    {
      name: "ResetPassword",
      element: <ResetPassword />,
      url: "/resetPassword",
      isPublic: true,
      layout: "account",
    },
    {
      name: "NotificationTemplates",
      element: <NotificationTemplates />,
      url: "/notificationTemplates",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Logs",
      element: <Logs />,
      url: "/logs",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "CaseManagement",
      element: <CaseManagement />,
      url: "/casemanagement",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "GlobalTaskScreen",
      element: <GlobalTaskScreen />,
      url: "/globaltaskscreen",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "ManageGroupUser",
      element: <ManageGroupUser />,
      url: "/managegroupuser",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "ManageMedication",
      element: <ManageMedication />,
      url: "/manageMedication",
      isPublic: false,
      layout: "leads",
    },
    {
      name: "FeatureFlags",
      element: <FeatureFlagSettingsPage />,
      url: `/${Constants.pageRoutes.featureFlagSettings}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "HearingsCalendar",
      element: <HearingCalendar />,
      url: "/hearingCalendar",
      isPublic: false,
      layout: "leads",
    },
  ];

  return (
    <Routes>
      <Route element={<AccountLayout />}>
        {routes
          .filter((a) => a.layout === "account")
          .map((route, index) => {
            if (route.isPublic) {
              return (
                <Route key={index} path={route?.url} element={route?.element} />
              );
            } else {
              return (
                <Route
                  key={index}
                  path={route?.url}
                  element={<PrivateRoute> {route?.element} </PrivateRoute>}
                />
              );
            }
          })}
      </Route>

      <Route element={<LeadsLayout />}>
        {routes
          .filter((a) => a.layout === "leads")
          .map((route, index) => {
            if (route.isPublic) {
              return (
                <Route key={index} path={route?.url} element={route?.element} />
              );
            } else {
              return (
                <Route
                  key={index}
                  path={route?.url}
                  element={<PrivateRoute> {route?.element} </PrivateRoute>}
                />
              );
            }
          })}
      </Route>

      <Route element={<CustomerLayout />}>
        {routes
          .filter((a) => a.layout === "customer")
          .map((route, index) => {
            if (route.isPublic) {
              return (
                <Route key={index} path={route?.url} element={route?.element} />
              );
            } else {
              return (
                <Route
                  key={index}
                  path={route?.url}
                  element={<PrivateRoute> {route?.element} </PrivateRoute>}
                />
              );
            }
          })}
      </Route>
      <Route element={<LeadsDetailLayout />}>
        {routes
          .filter((a) => a.layout === "leadsDetail")
          .map((route, index) => {
            if (route.isPublic) {
              return (
                <Route key={index} path={route?.url} element={route?.element} />
              );
            } else {
              return (
                <Route
                  key={index}
                  path={route?.url}
                  element={<PrivateRoute> {route?.element} </PrivateRoute>}
                />
              );
            }
          })}
      </Route>
    </Routes>
  );
};

export default RouterComponent;
