export const validatePassword = (_: any, value: any) => {
  const passwordStrength = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  );
  if (value && !passwordStrength.test(value)) {
    return Promise.reject(
      new Error(
        "Password must be 8 or more characters long, contain at least one capital, one lowercase, one number and one non-alphanumeric symbol (eg. Sw1ftmedic@l)"
      )
    );
  }
  return Promise.resolve();
};
