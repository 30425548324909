import "react-big-calendar/lib/css/react-big-calendar.css";

import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { Calendar, View, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Loader from "../../components/Loader";
import {
  AutoComplete,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Select,
  SelectProps,
} from "antd";
import { searchClients } from "../../actions/application";
import { addCountryCode } from "../../utils/commonHelper";
import TextArea from "antd/lib/input/TextArea";
import { Constants } from "../../common/constants";
import {
  addHearingCalendarEvent,
  deleteHearingCalendarEvent,
  getHearingCalendarEvents,
  updateHearingCalendarEvent,
} from "../../actions/hearingCalendarEvents";
import { Link, useNavigate } from "react-router-dom";
import Linkify from "linkify-react";
import { FlashMessage } from "../../utils/flash_message";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { useDebounce } from "use-debounce";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import useFeatureFlag from "../../components/useFeatureFlag";
import { get } from "../../utils/clientStorageUtils";
import {
  saveGlobalTaskNotes,
  updateGlobalTask,
} from "../../actions/taskManagement";

moment.locale("en");
const localizer = momentLocalizer(moment);

const now = new Date();

interface Client {
  id: number;
  phoneNumber: string;
  maskedSsn: string;
  email: string;
  fullName: string;
  encryptedId: string;
}

const localTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

const HearingCalendar = () => {
  const [form] = Form.useForm();
  const [myEvents, setEvents] = useState<any>([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [calendarView, setCalendarView] = useState("week");
  const timeFormat = "MM/DD/YYYY hh:mm A";

  const featureFlags = useFeatureFlag().featureFlags;

  const [clientSearchQuery, setClientSearchQuery] = useState("");
  const [debouncedClientSearchQuery] = useDebounce(
    clientSearchQuery,
    Constants.debounceTime
  );
  const [clients, setClients] = useState<Client[]>([]);
  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      featureFlags[Constants.featureFlags.hearingCalendarFeatureFlag] === false
    ) {
      navigate("/leads");
    }
    getCalendarEvents();
  }, [featureFlags]);

  useEffect(() => {
    if (!eventModalOpen) setClientSearchQuery("");
  }, [eventModalOpen]);

  useEffect(() => {
    if (!debouncedClientSearchQuery) {
      setOptions([
        {
          value: "",
          label: (
            <div style={{ display: "flex" }}>
              <span>Please input value</span>
            </div>
          ),
        },
      ]);
    } else {
      setOptions([
        {
          value: "",
          label: (
            <div style={{ display: "flex" }}>
              <span>Loading......</span>
            </div>
          ),
        },
      ]);
      searchResult();
    }
  }, [debouncedClientSearchQuery]);

  const getFormattedSearchResultDisplayForItem = (item: Client) => {
    let displayValue =
      item.fullName +
      (item.email ? ` | ${item.email}` : "") +
      (item.phoneNumber ? ` | ${addCountryCode(item.phoneNumber)}` : "") +
      (item.maskedSsn ? ` | ${item.maskedSsn}` : "") +
      (item.id ? ` | ${item.id}` : "");
    return displayValue;
  };

  const searchResult = () => {
    searchClients(debouncedClientSearchQuery).then((res: any) => {
      const resData = res.data.responseData;
      const filteredResData = resData.filter(
        (user: any) => user.isLead === false
      );
      setClients(filteredResData);

      if (!filteredResData || filteredResData.length === 0) {
        setOptions([
          {
            value: debouncedClientSearchQuery,
            label: (
              <div style={{ display: "flex" }}>
                <span>
                  {debouncedClientSearchQuery === ""
                    ? "Please input value"
                    : "No claimant found"}
                </span>
              </div>
            ),
          },
        ]);
      } else {
        setOptions(
          filteredResData.map((item: Client) => {
            return {
              key: item.encryptedId,
              value: item.fullName,
              label: getFormattedSearchResultDisplayForItem(item),
              item,
            };
          })
        );
      }
    });
  };

  const dayFormat = (date: Date, culture: any, localizer: any) =>
    moment(date).format("ddd | MMM D");

  const getCalendarEvents = () => {
    setIsLoader(true);
    setIsAdd(false);
    getHearingCalendarEvents().then((res) => {
      var eventsData = res.data.responseData;
      if (res.data.responseCode == 200) {
        setEvents(
          eventsData.map((item: any) => ({
            ...item,
            startDate: moment.utc(item.startDate).toDate(),
            endDate: moment.utc(item.endDate).toDate(),
            start: moment.utc(item.startDate).toDate(),
            end: moment.utc(item.endDate).toDate(),
            title: `${
              Constants.hearingCalendar.hearingTypes[item.hearingType].label
            } for ${item.userName} (${
              Constants.hearingCalendar.meetingTypes[item.meetingType].label
            })`,
          }))
        );
        setIsLoader(false);
      }
    });
  };

  const formSubmit = () => {
    form.submit();
  };

  const addOrUpdateEvent = (e: any) => {
    const payload = {
      startDate: moment(form.getFieldValue("startDate")).utc().format(),
      localStartDate: moment(form.getFieldValue("startDate")).format(),
      endDate: moment(form.getFieldValue("endDate")).utc().format(),
      meetingType: form.getFieldValue("meetingType"),
      hearingType: form.getFieldValue("hearingType"),
      description: form.getFieldValue("description"),
      mindsetUserId: Number.parseInt(form.getFieldValue("userId")),
      id: selectedEvent?.id ?? null,
    };
    if (isAdd) {
      addHearingCalendarEvent(payload).then((res) => {
        var taskPayload = {
          Id: 0,
          Description: payload.description,
          EncryptedUserId: `${payload.mindsetUserId}`,
          LeadsId: null,
          priorityId: 1,
          StatusId: 1,
          TypeId: 2,
          OriginalEstimationedHours: 0,
          DueDate: moment(form.getFieldValue("startDate")).format(),
          DueDateUtc: payload.startDate,
          AssignedTo: [`${JSON.parse(get("model")).userId}`],
          ReminderDate: null,
          HearingCalendarEventId: res.data.id,
        };
        saveGlobalTaskNotes(taskPayload).then((res) => {
          FlashMessage(
            "success",
            "Scheduled Hearing task created on claimant profile."
          );
        });
        setEventModalOpen(false);
        getCalendarEvents();
        FlashMessage("success", "Event has been added successfully.");
      });
    } else {
      updateHearingCalendarEvent(payload).then((res) => {
        setEventModalOpen(false);
        getCalendarEvents();
        FlashMessage("success", "Event has been updated successfully.");
      });
    }
  };

  const cancel = () => {
    setEventModalOpen(false);
    form.resetFields();
  };

  const handleSelectSlot = useCallback(
    ({ start, end }: any) => {
      setIsAdd(true);
      setClientSearchQuery("");
      form.setFieldsValue({
        startDate: moment(start).format(timeFormat),
        endDate: moment(end).format(timeFormat),
        meetingType: 0,
        hearingType: 0,
        description: "",
        userId: "",
      });
      setSelectedEvent(null);
      setEventModalOpen(true);
    },
    [setEvents]
  );

  const handleSelectEvent = useCallback((event: any) => {
    setIsAdd(false);
    setSelectedEvent(event);
    setPopoverVisible(true);
  }, []);

  const content = (
    <div onClick={(e) => e.stopPropagation()} style={{ minWidth: 300 }}>
      <div className='row' style={{ width: "fit-content" }}>
        <div className='col-md-3' style={{ width: "fit-content" }}>
          <b>Claimant:</b>
        </div>
        <div className='col-md-9' style={{ width: "fit-content" }}>
          <Link to={`/clientsnotes?id=${selectedEvent?.encryptedUserId}`}>
            {selectedEvent?.userName}
          </Link>
        </div>
      </div>
      <div className='row' style={{ width: "fit-content" }}>
        <div className='col-md-3' style={{ width: "fit-content" }}>
          <b>Lawyer:</b>
        </div>
        <div className='col-md-9' style={{ width: "fit-content" }}>
          {selectedEvent?.lawyerName}
        </div>
      </div>
      <div className='row' style={{ width: "fit-content" }}>
        <div className='col-md-3' style={{ width: "fit-content" }}>
          <b>Description:</b>
        </div>
        <div className='col-md-9 pt-1' style={{ width: "fit-content" }}>
          <pre>
            <Linkify>{selectedEvent?.description}</Linkify>
          </pre>
        </div>
      </div>
      <a
        className='position-absolute p-2'
        style={{ right: 30, top: 10, padding: "10px" }}
        onClick={(e) => {
          form.setFieldsValue(selectedEvent);
          form.setFieldValue("userId", selectedEvent.mindsetUserId);
          setClientSearchQuery(selectedEvent.userName);
          setEventModalOpen(true);
          setPopoverVisible(false);
        }}
      >
        <EditFilled
          style={{
            fontSize: "25px",
            color: "#747497",
          }}
        />
      </a>
      <a
        className='position-absolute p-2 right-0'
        style={{ padding: "10px", top: 10 }}
        onClick={() => {
          setPopoverVisible(false);
          confirm({
            title: "",
            content: (
              <div style={{ marginTop: "30px" }}>
                <ExclamationCircleFilled />
                <span className='m-2'>
                  Do you really want to delete this event? Any task that is
                  linked to this event will be removed.
                </span>
              </div>
            ),
            onOk() {
              deleteHearingCalendarEvent(selectedEvent.id).then((res) => {
                FlashMessage(
                  "success",
                  "Event has been removed from the calendar"
                );
                getCalendarEvents();
              });
            },
          });
        }}
      >
        <DeleteFilled
          style={{
            fontSize: "25px",
            color: "#747497",
          }}
        />
      </a>
    </div>
  );

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );

  const handleSelect = (value: any, option: any) => {
    form.setFieldsValue({
      userId: option.item?.id,
    });
    setClientSearchQuery(option.item?.fullName);
  };

  return (
    <>
      {isLoader ? (
        <Loader></Loader>
      ) : (
        <Fragment>
          <Modal
            title={isAdd ? "Add Event" : "Update Event"}
            centered
            okText={isAdd ? "Add" : "Update"}
            open={eventModalOpen}
            onOk={() => formSubmit()}
            onCancel={() => cancel()}
            width={1000}
          >
            <Form
              className='mt-4'
              form={form}
              name='EventForm'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={addOrUpdateEvent}
              autoComplete='off'
            >
              <div className='d-flex align-items-center form-group row margin_mobile'>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  From ({localTimezoneName})
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='startDate'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                    getValueFromEvent={(onChange) =>
                      onChange == null
                        ? null
                        : moment(onChange).format(timeFormat)
                    }
                    getValueProps={(i) => ({
                      value: i == null ? null : moment(i),
                    })}
                  >
                    <DatePicker
                      showTime={{ format: "hh:mm A", use12Hours: true }}
                      placeholder='From'
                      format={timeFormat}
                      minuteStep={15}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className='d-flex align-items-center form-group row margin_mobile'>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  To ({localTimezoneName})
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='endDate'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                    getValueFromEvent={(onChange) =>
                      onChange == null
                        ? null
                        : moment(onChange).format(timeFormat)
                    }
                    getValueProps={(i) => ({
                      value: i == null ? null : moment(i),
                    })}
                  >
                    <DatePicker
                      showTime={{ format: "hh:mm A", use12Hours: true }}
                      placeholder='To'
                      format={timeFormat}
                      minuteStep={15}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className='d-flex align-items-center form-group row margin_mobile'>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  Claimant
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='userId'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                      {
                        validator: validateBlankSpace,
                      },
                    ]}
                    getValueProps={(i) => {
                      return i;
                    }}
                  >
                    <AutoComplete
                      className='search_ant_drp'
                      disabled={!isAdd}
                      dropdownStyle={{
                        textTransform: "capitalize",
                      }}
                      onBlur={(e) => {
                        if (
                          form.getFieldsValue().userId === clientSearchQuery
                        ) {
                          setClientSearchQuery("");
                          form.setFieldsValue({ userId: "" });
                        }
                      }}
                      onSelect={handleSelect}
                      dropdownMatchSelectWidth={252}
                      options={options}
                      value={clientSearchQuery}
                    >
                      <Input
                        placeholder='Search by number, email, name, claimantId or last 4 SSN'
                        className='input_style text-capitalize'
                        onChange={(e) => setClientSearchQuery(e.target.value)}
                      />
                    </AutoComplete>
                  </Form.Item>
                </div>
              </div>
              <div className='d-flex align-items-center form-group row margin_mobile'>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  Meeting type
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='meetingType'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Select
                      options={Constants.hearingCalendar.meetingTypes}
                    ></Select>
                  </Form.Item>
                </div>
              </div>
              <div className='d-flex align-items-center form-group row margin_mobile'>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  Hearing Type
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='hearingType'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Select
                      options={Constants.hearingCalendar.hearingTypes}
                    ></Select>
                  </Form.Item>
                </div>
              </div>
              <div className='d-flex align-items-center form-group row margin_mobile'>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  Description
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='description'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <TextArea />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
          <div className='pt-5' style={{ height: "800px" }}>
            {localTimezoneName}
            <Calendar
              defaultDate={defaultDate}
              defaultView={calendarView as View}
              views={["month", "week", "day"]}
              events={myEvents}
              view={calendarView as View}
              onView={(view) => setCalendarView(view)}
              onSelectEvent={handleSelectEvent}
              step={60}
              onSelectSlot={handleSelectSlot}
              selectable
              scrollToTime={scrollToTime}
              localizer={localizer}
              popup={true}
              doShowMoreDrillDown={false}
              formats={{ dayFormat }}
              onNavigate={(date: any) => {}}
              components={{
                event: (props: any) => (
                  <Popover
                    content={content}
                    title={props.title}
                    trigger='click'
                    showArrow={false}
                    overlayClassName='calendar-event-custom-popover'
                    visible={
                      popoverVisible && selectedEvent?.id === props.event.id
                    }
                    onVisibleChange={(visible) => setPopoverVisible(visible)}
                  >
                    <div>{props.title}</div>
                  </Popover>
                ),
              }}
            />
          </div>
        </Fragment>
      )}
    </>
  );
};

export default HearingCalendar;
