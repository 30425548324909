import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  getNotes,
  updatePinnedValueForNotes,
  getNotesById,
  deleteNotes,
  updateNotes,
  uploadAttachment,
  getAttachments,
  downloadAttachment,
  deleteAttachment,
  addNotesReply,
  statusIdChangedData,
  getGroupUsers,
} from "../../actions/notes";
import { Form, Tabs } from "antd";
import { useSearchParams } from "react-router-dom";
import { FlashMessage } from "../../utils/flash_message";
import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal, Tooltip } from "antd";
import { MentionsInput, Mention } from "react-mentions";
import { getSupportUsers } from "../../actions/supportUser";
import { get } from "../../utils/clientStorageUtils";
import defaultMentionStyle from "../SaveNotes/defaultMentionStyle";
import defaultStyle from "../SaveNotes/defaultStyle";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import calendar from "../../images/notes_cal.png";
import attach1 from "../../images/attach.png";
import {
  DeleteFilled,
  EditFilled,
  PaperClipOutlined,
  SendOutlined,
  PushpinOutlined,
  MoreOutlined,
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
  CommentOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import Loader from "../../components/Loader";
import { Constants } from "../../common/constants";
import { getTaskType, deleteGlobalTask } from "../../actions/taskManagement";
import {
  GroupUser,
  MentionUser,
  getUpdatedMentionsValue,
} from "../../utils/mentions";
import useCanWriteAndEnforcePagePermissions from "../../common/customHooks/useCanWriteAndEnforcePagePermissions";
import { Enums } from "../../common/enums";
import NotesMetaData from "../SaveNotes/NoteMetaData";
import GlobalTaskNotes from "../../common/components/GlobalTaskNotes";

const Notes = (props: any) => {
  useCanWriteAndEnforcePagePermissions();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [user, setUser] = useState<MentionUser[]>([]);
  const [plainText, setPlainText] = useState("");
  const [tagsText, setTagsText] = useState("");
  const [notes, setNotes] = useState([]);
  const [attach, setAttach] = useState([]);
  const [notesId, setNotesId] = useState(0);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [noteById, setNoteById] = useState({});
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [singleLineValue, setSingleLineValue] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [tagUserIds, setTagUserIds] = useState("");
  const [replyNoteId, setReplyNoteId] = useState(0);
  const [isPopUpLoader, setisPopUpLoader] = useState(false);
  const [isPopUpLoaderr, setisPopUpLoaderr] = useState(false);
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const [taskType, setTaskType] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [disable, setDisable] = useState(false);
  const [groups, setgroups] = useState<GroupUser[]>([]);

  const [noteType, setNoteType] = useState<string[]>();
  const [noteMinutes, setNoteMinutes] = useState(30);
  const [noteSource, setNoteSource] = useState();
  const [noteCategory, setNoteCategory] = useState();
  const [replyNoteCategory, setReplyNoteCategory] = useState("");
  const [replyNoteSource, setReplyNoteSource] = useState("");
  const [replyNoteMinute, setReplyNoteMinute] = useState("");
  const [replyNoteType, setReplyNoteType] = useState("");
  const userIdData = JSON.parse(get("model"))
    ? JSON.parse(get("model")).userId
    : "";

  const [noteId, setNoteId] = useState({});
  const [isCalenderModal, setIsCalenderModal] = useState(false);
  const [currentElement, setCurrentElement] = useState(null);
  const [tabActiveKey, setTabActiveKey] = useState("all");

  const activeTabFilter = (item: any) => {
    if (tabActiveKey === "all") {
      return true;
    } else if (tabActiveKey === "notes") {
      return item.taskId === null;
    } else if (tabActiveKey === "tasks") {
      return item.taskId !== null;
    } else if (tabActiveKey === "communication") {
      return item.category >= 5 && item.category <= 7;
    }
    return false;
  };

  const deleteAttachmentApi = (id: any) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      setIsLoader(true);
      deleteAttachment(id).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", res.data.responseMessage);
          setIsLoader(false);
        } else if (res.data.responseCode != 200) {
          FlashMessage("error", res.data.responseMessage);
          setIsLoader(false);
        }
        getAttachmentsApi();
      });
    } else {
      setIsLoader(false);
    }
  };

  const downloadAttachmentApi = (id: any, displayName: any) => {
    setIsLoader(true);
    downloadAttachment(id).then((res) => {
      setIsLoader(false);
      createAndDownloadBlobFile(
        base64ToArrayBuffer(res.data.responseData),
        displayName
      );
    });
  };

  const viewAttachmentApi = (id: any, displayName: any) => {
    setIsLoader(true);
    downloadAttachment(id).then((res) => {
      setIsLoader(false);
      createAndViewBlobFile(
        base64ToArrayBuffer(res.data.responseData),
        displayName
      );
    });
  };

  const handleClick_UploadDocument = (id: any) => {
    setNotesId(id);
    inputRef.current?.click();
  };

  const uploadAttachmentApi = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    var files = e.target.files;
    var filesUploaded = 0;
    setIsLoader(true);

    Array.from(files).forEach((file: any) => {
      var form = new FormData();
      form.append("file", file);
      var noteId = notesId;
      let file_size = file.size;
      if (file_size <= 30000000) {
        uploadAttachment(form, noteId).then((res) => {
          filesUploaded = filesUploaded + 1;
          if (res.data.responseCode == 200) {
            if (filesUploaded == files.length) {
              FlashMessage("success", "Document Uploaded Successfully.");
              getNotesApi();
              getAttachmentsApi();
              setIsLoader(false);
            }
          }
        });
      } else {
        filesUploaded = filesUploaded + 1;
        FlashMessage(
          "error",
          `Please select a file whose size is less than 30MB.`
        );
        if (filesUploaded == files.length) {
          getNotesApi();
          getAttachmentsApi();
          setIsLoader(false);
        }
      }
    });
  };

  const getAttachmentsApi = () => {
    var param = searchParams.get("id") ?? "0";
    getAttachments(param, props?.isLead ?? false).then((res) => {
      var attachment = res.data.responseData;
      if (res.data.responseCode == 200) {
        setAttach(attachment);
      }
    });
  };

  const getNotesApi = () => {
    setIsLoader(true);
    var param = searchParams.get("id") ?? "0";
    if (param != "0") {
      getNotes(param, props?.isLead ?? false).then((res) => {
        var noteData = res.data.responseData;
        if (res.data.responseCode == 200) {
          noteData.sort((a: any, b: any) => {
            // Check if notes are pinned
            const aIsPinned = a.isPinned;
            const bIsPinned = b.isPinned;

            // If one note is pinned and the other is not, the pinned one should come first
            if (aIsPinned && !bIsPinned) {
              return -1;
            }
            if (!aIsPinned && bIsPinned) {
              return 1;
            }

            // Determine if the notes are incomplete tasks
            const aIsIncompleteTask =
              a.taskId !== null && a.completedBy === null;
            const bIsIncompleteTask =
              b.taskId !== null && b.completedBy === null;

            // If both are incomplete tasks, sort them by due date
            if (aIsIncompleteTask && bIsIncompleteTask) {
              return (
                new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
              );
            }

            // If only one is an incomplete task, it should come first
            if (aIsIncompleteTask && !bIsIncompleteTask) {
              return -1; // a comes before b
            }
            if (!aIsIncompleteTask && bIsIncompleteTask) {
              return 1; // b comes before a
            }

            const aSortKey = a.completedBy ? "completedOn" : "createdDate";
            const bSortKey = b.completedBy ? "completedOn" : "createdDate";

            // If neither or both are non-tasks or completed tasks, sort them chronologically
            return (
              new Date(b[bSortKey]).getTime() - new Date(a[aSortKey]).getTime()
            );
          });
          setNotes(noteData);
          setIsLoader(false);
        }
      });
    }
  };

  const updatePinnedValueForNotesApi = (id: any, isPinnedObj: boolean) => {
    var payload = {
      NoteId: id,
      IsPinned: !isPinnedObj,
    };
    setisPopUpLoader(true);
    updatePinnedValueForNotes(payload).then((res) => {
      if (res.data.responseCode == 200) {
        if (payload.IsPinned == true) {
          FlashMessage("success", "Note pinned Successfully.");
          setisPopUpLoader(false);
        } else {
          FlashMessage("success", "Note unpinned Successfully.");
        }
        getNotesApi();
      }
    });
  };

  const updateNotesApi = (e: any) => {
    var payload = {
      Description: singleLineValue,
      PlainText: plainText,
      TagsText: tagsText,
      Id: e.id,
      Type: JSON.stringify(noteType),
      Source: noteSource,
      Category: noteCategory,
      Minutes: noteMinutes,
    };
    setisPopUpLoader(true);
    updateNotes(payload, tagUserIds).then((res) => {
      if (res.data.responseCode == 200) {
        FlashMessage("success", "Note updated Successfully.");
        setOpen(false);
        setisPopUpLoader(false);
        getNotesApi();
      }
    });
  };

  const deleteNotesApi = (id: any, taskId: any) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      setIsLoader(true);
      deleteNotes(id).then((res) => {
        if (res.data.responseCode == 200) {
          if (taskId != null) {
            deleteGlobalTask(taskId).then((res) => {
              if (res.data.responseCode == 200) {
                FlashMessage("success", res.data.responseMessage);
                setIsLoader(false);
              }
            });
          } else {
            FlashMessage("success", res.data.responseMessage);
            setIsLoader(false);
          }
        } else if (res.data.responseCode != 200) {
          FlashMessage("error", res.data.responseMessage);
          setIsLoader(false);
        }
        getNotesApi();
      });
    } else {
      setIsLoader(false);
    }
  };

  const getNotesByIdApi = (id: any) => {
    setNoteId(id);
    setisPopUpLoader(true);
    setOpen(true);
    getNotesById(id).then((res) => {
      var noteDataById = res.data.responseData;
      if (res.data.responseCode == 200) {
        setNoteById(noteDataById);
        setPlainText(noteDataById.plainText);
        setTagsText(noteDataById.tagsText);
        setSingleLineValue(noteDataById.description);
        try {
          setNoteType(JSON.parse(noteDataById.type));
        } catch {
          setNoteType(undefined);
        }
        setNoteMinutes(noteDataById.minutes);
        setNoteSource(noteDataById.source);
        setNoteCategory(noteDataById.category);
        setisPopUpLoader(false);
        form.setFieldsValue(noteDataById);
      }
    });
  };

  const formSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (searchParams.get("refresh_notes")) {
      getNotesApi();
    } else {
      getNotesApi();
    }
  }, [searchParams.get("id"), searchParams.get("refresh_notes")]);

  useEffect(() => {
    getAttachmentsApi();
  }, [searchParams.get("id")]);

  useEffect(() => {
    getGroupUserApi();
    getSupportUsersApi();
  }, []);

  const handleChangeSingle = (
    e: any,
    newValue: any,
    newPlainTextValue: any,
    _mentions: any
  ) => {
    const { newTagUserIds, newSingleLineValue, newPlainText, newTagsText } =
      getUpdatedMentionsValue(
        newValue,
        newPlainTextValue,
        _mentions,
        user,
        groups
      );
    setTagUserIds(newTagUserIds);
    setSingleLineValue(newSingleLineValue);
    setPlainText(newPlainText);
    setTagsText(newTagsText);
  };
  const getGroupUserApi = () => {
    getGroupUsers().then((res) => {
      if (res.data.responseCode == Enums.responseCodes.success) {
        var groupData = res.data.responseData.map((ele: any) => ({
          id: ele.groupId,
          display: ele.groupName,
        }));
        setgroups(groupData);
        setTagsText("");
      }
    });
  };
  const getSupportUsersApi = () => {
    setisPopUpLoaderr(true);
    getSupportUsers().then((res) => {
      var userdata = res.data.responseData;

      if (res.data.responseCode == 200) {
        setisPopUpLoaderr(false);
        let userid = JSON.parse(get("model"))
          ? JSON.parse(get("model")).userId
          : "";
        var filterData = userdata
          .map((ele: any) => ({
            id: ele.id,
            display: `${ele.firstName} ${ele.lastName}`,
          }))
          .filter((ele: any) => ele.id != userid);
        setUser(filterData);
        let currentId = JSON.parse(get("model"))
          ? JSON.parse(get("model")).id
          : "";
        var allUser = userdata
          .map((a: any) => ({
            id: a.id,
            allUsersDisplay: `${a.firstName} ${a.lastName}`,
          }))
          .filter((b: any) => b.id == userid || b.id != userid);
        setAllUsers(allUser);
      }
    });
  };

  const resetFormValues = () => {
    var formDefaultValues = {
      plainText: "",
      plainText1: "",
    };
    setTimeout(() => {
      form.setFieldsValue(formDefaultValues);
      form1.setFieldsValue(formDefaultValues);
    }, 100);
  };

  const replyClick = (
    id: number,
    category: string,
    minutes: string,
    type: string,
    source: string
  ) => {
    resetFormValues();
    form2.resetFields();
    if (replyNoteId != id) {
      setReplyNoteId(id);
      setReplyNoteCategory(category);
      setReplyNoteMinute(minutes);
      setReplyNoteSource(source);
      setReplyNoteType(type);
    } else {
      setReplyNoteId(0);
      setReplyNoteCategory("");
      setReplyNoteSource("");
      setReplyNoteMinute("");
      setReplyNoteType("");
    }
  };

  const addNotesReplyApi = (e: any) => {
    setDisable(true);
    var param = searchParams.get("id") ?? "0";
    var payload = {
      Description: singleLineValue,
      EncryptedUserId: props?.isLead ? null : param,
      LeadsId: props?.isLead ? param : null,
      PlainText: plainText,
      TagsText: tagsText,
      ParentNoteId: replyNoteId, //e.id
      Type: replyNoteType,
      Source: replyNoteSource,
      Category: replyNoteCategory,
      Minutes: replyNoteMinute,
    };

    addNotesReply(payload, tagUserIds)
      .then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Note reply sent.");
          setPlainText("");
          getNotesApi();
          setReplyNoteId(0);
          resetFormValues();
        }
      })
      .finally(() => {
        setDisable(false);
      });
  };
  const statusUpdate = (e: any) => {
    if (
      window.confirm("Are you sure you want to mark this task as completed?")
    ) {
      setIsLoader(true);
      statusIdChangedData(e).then((res) => {
        if (res.data.responseCode == 200) {
          setIsLoader(false);
          getNotesApi();
        }
      });
    }
  };

  const canEditDeleteNote = (note: any) => {
    const dateTime = moment.utc(note.createdDate);
    const now = moment.utc();

    const differenceInMinutes = now.diff(dateTime, "minutes");
    const isNoteCreationLessThanOneHourOld =
      Math.abs(differenceInMinutes) <= 60;

    if (isNoteCreationLessThanOneHourOld) {
      return (
        note.isSameUser || roleInStorage == Constants.appUserRoles.superUser
      );
    }
    return false;
  };

  return (
    <>
      <Modal
        title='Update Note'
        centered
        okText={isPopUpLoader ? "Loading" : "Update"}
        open={open}
        onOk={() => (isPopUpLoader ? null : formSubmit())}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <div
          id='panelsStayOpen-collapseOne'
          className='accordion-collapse collapse show'
          aria-labelledby='panelsStayOpen-headingOne'
        >
          <div className='position-relative'>
            {isPopUpLoader && <Loader></Loader>}
            {!isPopUpLoader && (
              <Form
                className='mt-4'
                form={form}
                name='NotesForm'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={noteById}
                autoComplete='off'
                onFinish={updateNotesApi}
              >
                <Form.Item name='id' />
                <Form.Item
                  name='tagsText'
                  rules={[
                    {
                      required: true,
                      message: "Please input the value!",
                    },
                    { validator: validateBlankSpace },
                  ]}
                >
                  <MentionsInput
                    style={defaultStyle}
                    value={tagsText}
                    onChange={handleChangeSingle}
                    singleLine={false}
                    allowSpaceInQuery={true}
                  >
                    <Mention
                      data={groups}
                      style={defaultMentionStyle}
                      displayTransform={(id: any, name: any) => `@${name}:`}
                      trigger='@'
                    />
                    <Mention
                      data={user}
                      style={defaultMentionStyle}
                      displayTransform={(id: any, name: any) => `@${name}:`}
                      trigger='@'
                    />
                  </MentionsInput>
                </Form.Item>
                <NotesMetaData
                  mode='Edit'
                  noteType={noteType}
                  setNoteType={setNoteType}
                  noteMinutes={noteMinutes}
                  setNoteMinutes={setNoteMinutes}
                  noteSource={noteSource}
                  setNoteSource={setNoteSource}
                  noteCategory={noteCategory}
                  setNoteCategory={setNoteCategory}
                  noteId={noteId}
                  noteById={noteById}
                  setInitialValue={(params: object) => {
                    form.setFieldsValue({
                      ...noteById,
                      ...params,
                    });
                  }}
                />
              </Form>
            )}
          </div>
        </div>
      </Modal>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <section id='MindsetAdminNotes' className='mt-5'>
          <div className='row'>
            <div className='col'>
              <Tabs
                size='large'
                type='card'
                onChange={setTabActiveKey}
                activeKey={tabActiveKey}
              >
                <Tabs.TabPane tab='All' key='all'></Tabs.TabPane>
                <Tabs.TabPane tab='Notes' key='notes'></Tabs.TabPane>
                <Tabs.TabPane tab='Tasks' key='tasks'></Tabs.TabPane>
                <Tabs.TabPane
                  tab='Communication'
                  key='communication'
                ></Tabs.TabPane>
              </Tabs>
              <div className='row'>
                {notes
                  .filter((a: any) => a.parentNoteId == null)
                  .filter(activeTabFilter).length == 0 && (
                  <p className='text-center'>Nothing to see here, yet...</p>
                )}
                <div className='accordion' id='accordionPanelsStayOpenExample'>
                  {notes
                    .filter((a: any) => a.parentNoteId == null)
                    .filter(activeTabFilter)
                    .map((ele: any) => {
                      return (
                        <>
                          <Accordion defaultActiveKey='0' className='mt-4'>
                            {ele.completedBy != null ? (
                              <Accordion.Item
                                className={
                                  ele.taskId && !ele.isPinned
                                    ? "taskId mb-4"
                                    : ele.isPinned
                                    ? "outline_pinned mb-4"
                                    : "mb-4"
                                }
                                eventKey='0'
                              >
                                <Accordion.Header className='cursorPointer'>
                                  <div>
                                    <span className='open_acc'>
                                      {ele.createdUserName}{" "}
                                      {moment
                                        .utc(ele.createdDate)
                                        .local()
                                        .format(Constants.dateTimeFormat)}
                                    </span>
                                  </div>
                                  <div className='d-flex align-items-center'>
                                    <div>
                                      {moment
                                        .utc(ele.modifiedDate)
                                        .local()
                                        .format(Constants.dateTimeFormat) !=
                                      moment
                                        .utc(ele.createdDate)
                                        .local()
                                        .format(Constants.dateTimeFormat) ? (
                                        <span className='open_acc'>
                                          {moment
                                            .utc(ele.modifiedDate)
                                            .local()
                                            .format(Constants.dateTimeFormat)}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className='ms-2'>
                                      <span className='icon_font'>-</span>
                                      <span className='icon_font2'>+</span>
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body className='pe-0 position-relative pb-5'>
                                  <div
                                    id='panelsStayOpen-collapseOne'
                                    className='accordion-collapse collapse show'
                                    aria-labelledby='panelsStayOpen-headingOne'
                                  >
                                    <div className='accordion-body pe-0'>
                                      <pre className='mb-2 notes_parent'>
                                        {ele.plainText}
                                      </pre>
                                      <div className='row ps-4 pe-2  '>
                                        {notes
                                          .filter(
                                            (a: any) => a.parentNoteId == ele.id
                                          )
                                          .sort((a: any, b: any) => {
                                            return a.id - b.id;
                                          })
                                          .map((item: any) => {
                                            return (
                                              <>
                                                <div>
                                                  <span className='open_acc'>
                                                    {item.createdUserName}{" "}
                                                    {moment
                                                      .utc(item.createdDate)
                                                      .local()
                                                      .format(
                                                        Constants.dateTimeFormat
                                                      )}
                                                  </span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                  <div>
                                                    {moment
                                                      .utc(item.modifiedDate)
                                                      .local()
                                                      .format(
                                                        Constants.dateTimeFormat
                                                      ) !=
                                                    moment
                                                      .utc(item.createdDate)
                                                      .local()
                                                      .format(
                                                        Constants.dateTimeFormat
                                                      ) ? (
                                                      <span className='open_acc'>
                                                        {moment
                                                          .utc(
                                                            item.modifiedDate
                                                          )
                                                          .local()
                                                          .format(
                                                            Constants.dateTimeFormat
                                                          )}
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className='row  m-0 justify-content-between'>
                                                  <div className='col-md-9 ps-3 d-flex align-items-baseline justify-content-between'>
                                                    <pre className='margin_comment'>
                                                      {item.plainText}
                                                    </pre>
                                                  </div>
                                                  <div className='col-md-3 d-flex justify-content-end'>
                                                    {canEditDeleteNote(
                                                      item
                                                    ) && (
                                                      <Dropdown className='ms-3 w-30 ant_drp'>
                                                        <Dropdown.Toggle
                                                          variant='success'
                                                          id='dropdown-basic'
                                                          className='border-none-class p-0 w-100'
                                                        >
                                                          <button
                                                            className='btn btn-secondary dropdown-toggle w-100 button_css_bullets'
                                                            type='button'
                                                            id='dropdownMenuButton'
                                                            data-toggle='dropdown'
                                                            aria-haspopup='true'
                                                            aria-expanded='false'
                                                          >
                                                            <MoreOutlined
                                                              style={{
                                                                fontSize:
                                                                  "25px",
                                                                color:
                                                                  "#747497",
                                                              }}
                                                            />
                                                          </button>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className='w-100 background_button_dl'>
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              getNotesByIdApi(
                                                                item.id
                                                              )
                                                            }
                                                            className='item_drp anchor_all'
                                                          >
                                                            <a className='mr-2 icons_all'>
                                                              <EditFilled />
                                                              <span className='ms-2'>
                                                                Edit
                                                              </span>
                                                            </a>
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              deleteNotesApi(
                                                                item.id,
                                                                item.taskId
                                                              )
                                                            }
                                                            className='item_drp anchor_all'
                                                          >
                                                            <a className='icons_all'>
                                                              {" "}
                                                              <DeleteOutlined />
                                                              <span className='ms-2'>
                                                                Delete
                                                              </span>
                                                            </a>
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    )}
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          })}
                                      </div>
                                      {replyNoteId == ele.id && (
                                        <>
                                          <div className='mb-2 d-flex align-items-end pe-4 ps-4'>
                                            <Form
                                              className='col-md-12 w-100'
                                              form={form2}
                                              name='NotesReplyForm'
                                              initialValues={ele}
                                              labelCol={{
                                                span: 24,
                                              }}
                                              wrapperCol={{
                                                span: 24,
                                              }}
                                              autoComplete='off'
                                              onFinish={addNotesReplyApi}
                                            >
                                              <div className='row'>
                                                <div className='col-md-12 ps-0'>
                                                  <div className='text-right position-relative mt-3'>
                                                    <Form.Item
                                                      className='note_add'
                                                      name='plainText1'
                                                      initialValue={""}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "Please input the value!",
                                                        },
                                                        {
                                                          validator:
                                                            validateBlankSpace,
                                                        },
                                                      ]}
                                                    >
                                                      <MentionsInput
                                                        id={
                                                          "txt_note_reply_" +
                                                          ele.id
                                                        }
                                                        className='bg_input_texarea'
                                                        singleLine={false}
                                                        value={plainText}
                                                        onChange={
                                                          handleChangeSingle
                                                        }
                                                        placeholder='Reply to note'
                                                        allowSpaceInQuery={true}
                                                      >
                                                        <Mention
                                                          style={
                                                            defaultMentionStyle
                                                          }
                                                          trigger='@'
                                                          data={groups}
                                                          displayTransform={(
                                                            id: any,
                                                            name: any
                                                          ) => `@${name}:`}
                                                        />
                                                        <Mention
                                                          style={
                                                            defaultMentionStyle
                                                          }
                                                          trigger='@'
                                                          data={user}
                                                          displayTransform={(
                                                            id: any,
                                                            name: any
                                                          ) => `@${name}:`}
                                                        />
                                                      </MentionsInput>
                                                    </Form.Item>
                                                    <Form.Item
                                                      name='id'
                                                      hidden={true}
                                                    >
                                                      <input type='text' />
                                                    </Form.Item>
                                                    <button
                                                      type='submit'
                                                      disabled={disable}
                                                      className={`plus_position mb-0`}
                                                    >
                                                      <SendOutlined
                                                        style={{
                                                          fontSize: "18px",
                                                          color: "#77d4b1",
                                                        }}
                                                      />
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </Form>
                                          </div>
                                        </>
                                      )}

                                      <div className='d-flex justify-content-between mt-4 icons_pannel w-100'>
                                        <div>
                                          <button
                                            onClick={() =>
                                              handleClick_UploadDocument(ele.id)
                                            }
                                            className='btn button-primary p-0'
                                            type='button'
                                          >
                                            <PaperClipOutlined
                                              style={{
                                                fontSize: "22px",
                                                color: "#747497",
                                              }}
                                            />
                                          </button>
                                          {ele.taskId == null ? (
                                            <img
                                              onClick={() => {
                                                setCurrentElement(ele);
                                                setIsCalenderModal(true);
                                              }}
                                              className='notes_cal cursorPointer'
                                              src={calendar}
                                              alt=''
                                            />
                                          ) : (
                                            <img
                                              onClick={() => {
                                                setCurrentElement(ele);
                                                setIsCalenderModal(true);
                                              }}
                                              className='notes_cal cursorPointer'
                                              src={calendar}
                                              alt=''
                                            />
                                          )}
                                          {ele.isPinned == false ? (
                                            <button
                                              onClick={() =>
                                                updatePinnedValueForNotesApi(
                                                  ele.id,
                                                  ele.isPinned
                                                )
                                              }
                                              className='btn button-primary ms-2 p-0'
                                              type='button'
                                            >
                                              <PushpinOutlined
                                                rotate={45}
                                                style={{
                                                  fontSize: "22px",
                                                  color: "#747497",
                                                }}
                                              />
                                            </button>
                                          ) : (
                                            <button
                                              onClick={() =>
                                                updatePinnedValueForNotesApi(
                                                  ele.id,
                                                  ele.isPinned
                                                )
                                              }
                                              className='btn button-primary ms-2 p-0'
                                              type='button'
                                            >
                                              <PushpinOutlined
                                                rotate={-45}
                                                style={{
                                                  fontSize: "22px",
                                                  color: "#747497",
                                                }}
                                              />
                                            </button>
                                          )}
                                          <Tooltip title={"Add Comment"}>
                                            <span
                                              className='ms-2 cursorPointer'
                                              onClick={() =>
                                                replyClick(
                                                  ele.id,
                                                  ele.category,
                                                  ele.minutes,
                                                  ele.type,
                                                  ele.source
                                                )
                                              }
                                            >
                                              <CommentOutlined
                                                style={{
                                                  fontSize: "18px",
                                                  color: "#747497",
                                                }}
                                              />
                                            </span>
                                          </Tooltip>
                                        </div>
                                        <div>
                                          {ele.statusId != null &&
                                          ele.statusId == 1 ? (
                                            <Tooltip title={"Complete task"}>
                                              <button
                                                onClick={() =>
                                                  statusUpdate(ele.id)
                                                }
                                                className='btn flex p-0 me-3'
                                                type='button'
                                              >
                                                <CheckCircleFilled
                                                  style={{
                                                    fontSize: "22px",
                                                    color: "#747497",
                                                  }}
                                                />
                                              </button>
                                            </Tooltip>
                                          ) : ele.completedBy != null ? (
                                            <>
                                              <span className='open_acc due_date'>
                                                Completed by{" "}
                                                {ele.completedByName}
                                                {" on "}
                                                {moment
                                                  .utc(ele.completedOn)
                                                  .local()
                                                  .format(
                                                    "MM/DD/YY [at] h:mma"
                                                  )}
                                              </span>
                                              <Tooltip title={"Complete task"}>
                                                <button
                                                  className='btn flex p-0 me-3'
                                                  type='button'
                                                >
                                                  <CheckCircleFilled
                                                    style={{
                                                      fontSize: "22px",
                                                      color: "#77d4b1",
                                                    }}
                                                  />
                                                </button>
                                              </Tooltip>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {canEditDeleteNote(ele) ? (
                                            <>
                                              <Tooltip title={"Edit"}>
                                                <button
                                                  onClick={() =>
                                                    getNotesByIdApi(ele.id)
                                                  }
                                                  className='btn flex p-0 me-3'
                                                  type='button'
                                                >
                                                  <EditFilled
                                                    style={{
                                                      fontSize: "22px",
                                                      color: "#747497",
                                                    }}
                                                  />
                                                </button>
                                              </Tooltip>
                                              <Tooltip title={"Delete"}>
                                                <button
                                                  onClick={() =>
                                                    deleteNotesApi(
                                                      ele.id,
                                                      ele.taskId
                                                    )
                                                  }
                                                  className='btn button-primary me-3 p-0'
                                                  type='button'
                                                >
                                                  <DeleteFilled
                                                    style={{
                                                      fontSize: "22px",
                                                      color: "#747497",
                                                    }}
                                                  />
                                                </button>
                                              </Tooltip>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          <input
                                            type='file'
                                            multiple
                                            ref={inputRef}
                                            onChange={uploadAttachmentApi}
                                            accept='application/pdf, image/png,.jpg,.jpeg '
                                            style={{
                                              display: "none",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      {attach
                                        .filter(
                                          (item: any) =>
                                            item.mindsetUserNotesId == ele.id
                                        )
                                        .map((eleNote: any) => {
                                          return (
                                            <>
                                              <div className='row mb-2 m-0 justify-content-between'>
                                                <div className='col-md-9 ps-3 accordion_attachmant'>
                                                  {eleNote.displayName} |{" "}
                                                  {moment
                                                    .utc(eleNote.createdDate)
                                                    .local()
                                                    .format(
                                                      Constants.dateTimeFormat
                                                    )}
                                                  <img
                                                    className='notes_attach'
                                                    src={attach1}
                                                    alt=''
                                                  />
                                                </div>
                                                <div className='col-md-3 d-flex justify-content-end'>
                                                  <Dropdown className='ms-3 w-30 ant_drp'>
                                                    <Dropdown.Toggle
                                                      variant='success'
                                                      id='dropdown-basic'
                                                      className='border-none-class p-0 w-100'
                                                    >
                                                      <button
                                                        className='btn btn-secondary dropdown-toggle w-100 button_css_bullets'
                                                        type='button'
                                                        id='dropdownMenuButton'
                                                        data-toggle='dropdown'
                                                        aria-haspopup='true'
                                                        aria-expanded='false'
                                                      >
                                                        <MoreOutlined
                                                          style={{
                                                            fontSize: "25px",
                                                            color: "#747497",
                                                          }}
                                                        />
                                                      </button>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='w-100 background_button_dl'>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          viewAttachmentApi(
                                                            eleNote.id,
                                                            eleNote.displayName
                                                          )
                                                        }
                                                        className='item_drp anchor_all'
                                                      >
                                                        <a className='mr-2 icons_all'>
                                                          <EyeOutlined />
                                                          <span className='ms-2'>
                                                            View
                                                          </span>
                                                        </a>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          downloadAttachmentApi(
                                                            eleNote.id,
                                                            eleNote.displayName
                                                          )
                                                        }
                                                        className='item_drp anchor_all'
                                                      >
                                                        <a className='mr-2 icons_all'>
                                                          <DownloadOutlined />
                                                          <span className='ms-2'>
                                                            Download
                                                          </span>
                                                        </a>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          deleteAttachmentApi(
                                                            eleNote.id
                                                          )
                                                        }
                                                        className='item_drp anchor_all'
                                                      >
                                                        <a className='icons_all'>
                                                          {" "}
                                                          <DeleteOutlined />
                                                          <span className='ms-2'>
                                                            Delete
                                                          </span>
                                                        </a>
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            ) : (
                              <Accordion.Item
                                className={
                                  ele.taskId && !ele.isPinned
                                    ? "taskId mb-4"
                                    : ele.isPinned
                                    ? "outline_pinned mb-4"
                                    : "mb-4"
                                }
                                eventKey='0'
                              >
                                <Accordion.Header className='cursorPointer'>
                                  <div className='d-flex justify-content-between w-100'>
                                    <span className='open_acc'>
                                      {ele.createdUserName}{" "}
                                      {moment
                                        .utc(ele.createdDate)
                                        .local()
                                        .format(Constants.dateTimeFormat)}
                                    </span>
                                    {ele.dueDate && (
                                      <span className='open_acc due_date'>
                                        due {moment(ele.dueDate).fromNow()}
                                      </span>
                                    )}
                                  </div>
                                  <div className='d-flex align-items-center'>
                                    <div>
                                      {moment
                                        .utc(ele.modifiedDate)
                                        .local()
                                        .format(Constants.dateTimeFormat) !=
                                      moment
                                        .utc(ele.createdDate)
                                        .local()
                                        .format(Constants.dateTimeFormat) ? (
                                        <span className='open_acc'>
                                          {moment
                                            .utc(ele.modifiedDate)
                                            .local()
                                            .format(Constants.dateTimeFormat)}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className='ms-2'>
                                      <span className='icon_font'>-</span>
                                      <span className='icon_font2'>+</span>
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body className='pe-0 position-relative pb-5'>
                                  <div
                                    id='panelsStayOpen-collapseOne'
                                    className='accordion-collapse collapse show'
                                    aria-labelledby='panelsStayOpen-headingOne'
                                  >
                                    <div className='accordion-body pe-0'>
                                      <pre className='mb-2'>
                                        {ele.plainText}
                                      </pre>
                                      <div className='row ps-4 pe-2  '>
                                        {notes
                                          .filter(
                                            (a: any) => a.parentNoteId == ele.id
                                          )
                                          .sort((a: any, b: any) => {
                                            return a.id - b.id;
                                          })
                                          .map((item: any) => {
                                            return (
                                              <>
                                                <div className='d-flex'>
                                                  <span className='open_acc'>
                                                    {item.createdUserName}{" "}
                                                    {moment
                                                      .utc(item.createdDate)
                                                      .local()
                                                      .format(
                                                        Constants.dateTimeFormat
                                                      )}
                                                  </span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                  <div>
                                                    {moment
                                                      .utc(item.modifiedDate)
                                                      .local()
                                                      .format(
                                                        Constants.dateTimeFormat
                                                      ) !=
                                                    moment
                                                      .utc(item.createdDate)
                                                      .local()
                                                      .format(
                                                        Constants.dateTimeFormat
                                                      ) ? (
                                                      <span className='open_acc'>
                                                        {moment
                                                          .utc(
                                                            item.modifiedDate
                                                          )
                                                          .local()
                                                          .format(
                                                            Constants.dateTimeFormat
                                                          )}
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className='row  m-0 justify-content-between'>
                                                  <div className='col-md-9 ps-3 d-flex align-items-baseline justify-content-between'>
                                                    <pre className='margin_comment'>
                                                      {item.plainText}
                                                    </pre>
                                                  </div>
                                                  <div className='col-md-3 d-flex justify-content-end'>
                                                    {canEditDeleteNote(
                                                      item
                                                    ) && (
                                                      <Dropdown className='ms-3 w-30 ant_drp'>
                                                        <Dropdown.Toggle
                                                          variant='success'
                                                          id='dropdown-basic'
                                                          className='border-none-class p-0 w-100'
                                                        >
                                                          <button
                                                            className='btn btn-secondary dropdown-toggle w-100 button_css_bullets'
                                                            type='button'
                                                            id='dropdownMenuButton'
                                                            data-toggle='dropdown'
                                                            aria-haspopup='true'
                                                            aria-expanded='false'
                                                          >
                                                            <MoreOutlined
                                                              style={{
                                                                fontSize:
                                                                  "25px",
                                                                color:
                                                                  "#747497",
                                                              }}
                                                            />
                                                          </button>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className='w-100 background_button_dl'>
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              getNotesByIdApi(
                                                                item.id
                                                              )
                                                            }
                                                            className='item_drp anchor_all'
                                                          >
                                                            <a className='mr-2 icons_all'>
                                                              <EditFilled />
                                                              <span className='ms-2'>
                                                                Edit
                                                              </span>
                                                            </a>
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              deleteNotesApi(
                                                                item.id,
                                                                item.taskId
                                                              )
                                                            }
                                                            className='item_drp anchor_all'
                                                          >
                                                            <a className='icons_all'>
                                                              {" "}
                                                              <DeleteOutlined />
                                                              <span className='ms-2'>
                                                                Delete
                                                              </span>
                                                            </a>
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    )}
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          })}
                                      </div>
                                      {replyNoteId == ele.id && (
                                        <>
                                          <div className='mb-2 d-flex align-items-end pe-4 ps-4'>
                                            <Form
                                              className='col-md-12 w-100'
                                              form={form2}
                                              name='NotesReplyForm'
                                              initialValues={ele}
                                              labelCol={{
                                                span: 24,
                                              }}
                                              wrapperCol={{
                                                span: 24,
                                              }}
                                              autoComplete='off'
                                              onFinish={addNotesReplyApi}
                                            >
                                              <div className='row'>
                                                <div className='col-md-12 ps-0'>
                                                  <div className='text-right position-relative mt-3'>
                                                    <Form.Item
                                                      className='note_add'
                                                      name='plainText1'
                                                      initialValue={""}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "Please input the value!",
                                                        },
                                                        {
                                                          validator:
                                                            validateBlankSpace,
                                                        },
                                                      ]}
                                                    >
                                                      <MentionsInput
                                                        id={
                                                          "txt_note_reply_" +
                                                          ele.id
                                                        }
                                                        className='bg_input_texarea'
                                                        singleLine={false}
                                                        value={plainText}
                                                        onChange={
                                                          handleChangeSingle
                                                        }
                                                        placeholder='Reply to note'
                                                        allowSpaceInQuery={true}
                                                      >
                                                        <Mention
                                                          style={
                                                            defaultMentionStyle
                                                          }
                                                          trigger='@'
                                                          data={groups}
                                                          displayTransform={(
                                                            id: any,
                                                            name: any
                                                          ) => `@${name}:`}
                                                        />
                                                        <Mention
                                                          style={
                                                            defaultMentionStyle
                                                          }
                                                          trigger='@'
                                                          data={user}
                                                          displayTransform={(
                                                            id: any,
                                                            name: any
                                                          ) => `@${name}:`}
                                                        />
                                                      </MentionsInput>
                                                    </Form.Item>
                                                    <Form.Item
                                                      name='id'
                                                      hidden={true}
                                                    >
                                                      <input type='text' />
                                                    </Form.Item>
                                                    <button
                                                      type='submit'
                                                      disabled={disable}
                                                      className={`plus_position mb-0`}
                                                    >
                                                      <SendOutlined
                                                        style={{
                                                          fontSize: "18px",
                                                          color: "#77d4b1",
                                                        }}
                                                      />
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </Form>
                                          </div>
                                        </>
                                      )}

                                      <div className='d-flex justify-content-between mt-4 icons_pannel w-100'>
                                        <div>
                                          <button
                                            onClick={() =>
                                              handleClick_UploadDocument(ele.id)
                                            }
                                            className='btn button-primary ms-3 p-0'
                                            type='button'
                                          >
                                            <PaperClipOutlined
                                              style={{
                                                fontSize: "22px",
                                                color: "#747497",
                                              }}
                                            />
                                          </button>
                                          {ele.taskId == null ? (
                                            <img
                                              onClick={() => {
                                                setCurrentElement(ele);
                                                setIsCalenderModal(true);
                                              }}
                                              className='notes_cal cursorPointer'
                                              src={calendar}
                                              alt=''
                                            />
                                          ) : (
                                            <img
                                              onClick={() => {
                                                setCurrentElement(ele);
                                                setIsCalenderModal(true);
                                              }}
                                              className='notes_cal cursorPointer'
                                              src={calendar}
                                              alt=''
                                            />
                                          )}
                                          {ele.isPinned == false ? (
                                            <button
                                              onClick={() =>
                                                updatePinnedValueForNotesApi(
                                                  ele.id,
                                                  ele.isPinned
                                                )
                                              }
                                              className='btn button-primary ms-2 p-0'
                                              type='button'
                                            >
                                              <PushpinOutlined
                                                rotate={45}
                                                style={{
                                                  fontSize: "22px",
                                                  color: "#747497",
                                                }}
                                              />
                                            </button>
                                          ) : (
                                            <button
                                              onClick={() =>
                                                updatePinnedValueForNotesApi(
                                                  ele.id,
                                                  ele.isPinned
                                                )
                                              }
                                              className='btn button-primary ms-2 p-0'
                                              type='button'
                                            >
                                              <PushpinOutlined
                                                rotate={-45}
                                                style={{
                                                  fontSize: "22px",
                                                  color: "#747497",
                                                }}
                                              />
                                            </button>
                                          )}
                                          <Tooltip title={"Add Comment"}>
                                            <span
                                              className='ms-2 cursorPointer'
                                              onClick={() =>
                                                replyClick(
                                                  ele.id,
                                                  ele.category,
                                                  ele.minutes,
                                                  ele.type,
                                                  ele.source
                                                )
                                              }
                                            >
                                              <CommentOutlined
                                                style={{
                                                  fontSize: "18px",
                                                  color: "#747497",
                                                }}
                                              />
                                            </span>
                                          </Tooltip>
                                        </div>
                                        <div>
                                          {ele.statusId != null &&
                                          ele.statusId == 1 ? (
                                            <Tooltip title={"Complete task"}>
                                              <button
                                                onClick={() =>
                                                  statusUpdate(ele.id)
                                                }
                                                className='btn flex p-0 me-3'
                                                type='button'
                                              >
                                                <div className='round me-1'>
                                                  <input
                                                    type='checkbox'
                                                    checked
                                                    id='checkbox'
                                                  />
                                                  <label></label>
                                                </div>
                                              </button>
                                            </Tooltip>
                                          ) : ele.completedBy != null ? (
                                            <>
                                              <button
                                                className='btn flex p-0 me-3'
                                                type='button'
                                              >
                                                <CheckCircleFilled
                                                  style={{
                                                    fontSize: "22px",
                                                    color: "#77d4b1",
                                                  }}
                                                />
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {canEditDeleteNote(ele) ? (
                                            <>
                                              <Tooltip title={"Edit"}>
                                                <button
                                                  onClick={() =>
                                                    getNotesByIdApi(ele.id)
                                                  }
                                                  className='btn flex p-0 me-3'
                                                  type='button'
                                                >
                                                  <EditFilled
                                                    style={{
                                                      fontSize: "22px",
                                                      color: "#747497",
                                                    }}
                                                  />
                                                </button>
                                              </Tooltip>
                                              <Tooltip title={"Delete"}>
                                                <button
                                                  onClick={() =>
                                                    deleteNotesApi(
                                                      ele.id,
                                                      ele.taskId
                                                    )
                                                  }
                                                  className='btn button-primary me-3 p-0'
                                                  type='button'
                                                >
                                                  <DeleteFilled
                                                    style={{
                                                      fontSize: "22px",
                                                      color: "#747497",
                                                    }}
                                                  />
                                                </button>
                                              </Tooltip>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          <input
                                            type='file'
                                            multiple
                                            ref={inputRef}
                                            onChange={uploadAttachmentApi}
                                            accept='application/pdf, image/png,.jpg,.jpeg '
                                            style={{
                                              display: "none",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      {attach
                                        .filter(
                                          (item: any) =>
                                            item.mindsetUserNotesId == ele.id
                                        )
                                        .map((eleNote: any) => {
                                          return (
                                            <>
                                              <div className='row mb-2 m-0 justify-content-between'>
                                                <div className='col-md-9 ps-3 accordion_attachmant'>
                                                  {eleNote.displayName} |{" "}
                                                  {moment
                                                    .utc(eleNote.createdDate)
                                                    .local()
                                                    .format(
                                                      Constants.dateTimeFormat
                                                    )}
                                                  <img
                                                    className='notes_attach'
                                                    src={attach1}
                                                    alt=''
                                                  />
                                                </div>
                                                <div className='col-md-3 d-flex justify-content-end'>
                                                  <Dropdown className='ms-3 w-30 ant_drp'>
                                                    <Dropdown.Toggle
                                                      variant='success'
                                                      id='dropdown-basic'
                                                      className='border-none-class p-0 w-100'
                                                    >
                                                      <button
                                                        className='btn btn-secondary dropdown-toggle w-100 button_css_bullets'
                                                        type='button'
                                                        id='dropdownMenuButton'
                                                        data-toggle='dropdown'
                                                        aria-haspopup='true'
                                                        aria-expanded='false'
                                                      >
                                                        <MoreOutlined
                                                          style={{
                                                            fontSize: "25px",
                                                            color: "#747497",
                                                          }}
                                                        />
                                                      </button>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='w-100 background_button_dl'>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          viewAttachmentApi(
                                                            eleNote.id,
                                                            eleNote.displayName
                                                          )
                                                        }
                                                        className='item_drp anchor_all'
                                                      >
                                                        <a className='mr-2 icons_all'>
                                                          <EyeOutlined />
                                                          <span className='ms-2'>
                                                            View
                                                          </span>
                                                        </a>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          downloadAttachmentApi(
                                                            eleNote.id,
                                                            eleNote.displayName
                                                          )
                                                        }
                                                        className='item_drp anchor_all'
                                                      >
                                                        <a className='mr-2 icons_all'>
                                                          <DownloadOutlined />
                                                          <span className='ms-2'>
                                                            Download
                                                          </span>
                                                        </a>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          deleteAttachmentApi(
                                                            eleNote.id
                                                          )
                                                        }
                                                        className='item_drp anchor_all'
                                                      >
                                                        <a className='icons_all'>
                                                          {" "}
                                                          <DeleteOutlined />
                                                          <span className='ms-2'>
                                                            Delete
                                                          </span>
                                                        </a>
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            )}
                          </Accordion>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {isCalenderModal && (
        <GlobalTaskNotes
          isLeads={props.isLead}
          NoteTypeIdData={currentElement}
          openModal={isCalenderModal}
          setCloseModal={setIsCalenderModal}
          directTaskOpen={false}
        />
      )}
    </>
  );
};

export default Notes;

export function createAndViewBlobFile(body: any, filename: any) {
  var ext = filename.split(".").pop();
  var type = "image/png";

  if (ext == "pdf") type = "application/pdf";
  else if (ext == "doc" || ext == "docx")
    type =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  else if (ext == "txt") type = "text/html";
  else if (ext == "xlsx") type = "text/xlsx";
  else type = "image/" + ext;
  const blob = new Blob([body], { type: type });
  const fileName = `${filename}`;
  const link = document.createElement("a");
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("target", "_blank");
    link.setAttribute("href", url);
    //link.setAttribute('download', fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function createAndDownloadBlobFile(body: any, filename: any) {
  const blob = new Blob([body]);
  const fileName = `${filename}`;

  const link = document.createElement("a");
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function base64ToArrayBuffer(base64: any) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}
