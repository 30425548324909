import {
  SaveNotificationConfiguration,
  GetNotificationConfiguration,
  GetNotificationConfigurationById,
  UpdateNotificationConfiguration,
  DeleteNotificationConfiguration,
} from "../../apis/notificationConfiguration";

export function saveNotificationConfiguration(payload: any, form: any) {
  return SaveNotificationConfiguration(payload, form);
}
export function getNotificationConfiguration() {
  return GetNotificationConfiguration();
}
export function getNotificationConfigurationById(id: any) {
  return GetNotificationConfigurationById(id);
}
export function updateNotificationConfiguration(payload: any) {
  return UpdateNotificationConfiguration(payload);
}
export function deleteNotificationConfiguration(id: any) {
  return DeleteNotificationConfiguration(id);
}
