import {
  SaveNotes,
  UpdatePinnedValueForNotes,
  GetNotes,
  GetNotesById,
  DeleteNotes,
  UpdateNotes,
  UploadAttachment,
  GetAttachments,
  DownloadAttachment,
  DeleteAttachment,
  AddNotesReply,
  StatusIdCahnegdData,
  GetGroupUsers,
  GetNoteOptions,
} from "../../apis/notes";

export function saveNotes(payload: any, groupUserIds: any) {
  return SaveNotes(payload, groupUserIds);
}
export function addNotesReply(payload: any, groupOfIds: any) {
  return AddNotesReply(payload, groupOfIds);
}
export function getNotes(id: any, IsLead: any) {
  return GetNotes(id, IsLead);
}
export function getNotesById(id: any) {
  return GetNotesById(id);
}
export function deleteNotes(Id: any) {
  return DeleteNotes(Id);
}
export function updateNotes(payload: any, groupOfIds: any) {
  return UpdateNotes(payload, groupOfIds);
}
export function uploadAttachment(payload: any, id: any) {
  return UploadAttachment(payload, id);
}
export function getAttachments(id: any, IsLead: any) {
  return GetAttachments(id, IsLead);
}
export function downloadAttachment(id: any) {
  return DownloadAttachment(id);
}
export function deleteAttachment(Id: any) {
  return DeleteAttachment(Id);
}
export function updatePinnedValueForNotes(payload: any) {
  return UpdatePinnedValueForNotes(payload);
}
export function statusIdChangedData(id: any) {
  return StatusIdCahnegdData(id);
}
export function getGroupUsers() {
  return GetGroupUsers();
}
export function getNoteOptions() {
  return GetNoteOptions();
}
