import axios from "axios";

export function SaveEducationHistory(payload: any) {
  return axios.post("EducationHistory", payload);
}
export function GetEducationHistory(id: any) {
  return axios.get("EducationHistory?Id=" + id);
}
export function DeleteEducationHistory(Id: any) {
  return axios.delete("EducationHistory?Id=" + Id);
}
export function GetEducationHistoryById(Id: any) {
  return axios.get("EducationHistoryById?Id=" + Id);
}
export function UpdateEducationHistory(payload: any) {
  return axios.put("EducationHistory", payload);
}
