import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Table, Tooltip, Select, DatePicker } from "antd";
import moment from "moment";
import { getAllActivityTypes, getActivityLogData } from "../../actions/logs";
import { FlashMessage } from "../../utils/flash_message";
import { EmailExist } from "../../actions/accounts";
import { responseCodes } from "../../components/Common/constants";
import { Constants } from "../../common/constants";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { validateEmail } from "../../validations/validateEmail";
import { number } from "../../validations/numeric_only-validate";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import Loader from "../../components/Loader";
import { getClients } from "../../actions/application";
import { getAllClients } from "../../apis/caseManagement";
import { get } from "../../utils/clientStorageUtils";
import { useForm } from "antd/lib/form/Form";
import { getSupportUsers } from "../../actions/supportUser";
import { useNavigate } from "react-router-dom";
import useCanWriteAndEnforcePagePermissions from "../../common/customHooks/useCanWriteAndEnforcePagePermissions";

interface Client {
  applicationStatus: string;
  caseManagerName: string;
  encryptedId: string;
  firstName: string;
  lastName: string;
  lastModifiedDate: string;
  lastAssignedDate: string;
  createdDate: string;
}

interface SupportUserResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  phoneNumber: string;
  supportUserType: string;
  encryptedId: string;
  createdDate: string;
}
interface SupportUser extends SupportUserResponse {
  fullName: string;
}

const defaultSortDirection = [
  "ascend" as const,
  "descend" as const,
  "ascend" as const,
];

const supportUserTypes = [
  Constants.appUserRoles.caseManager,
  Constants.appUserRoles.admin,
  Constants.appUserRoles.superUser,
  Constants.appUserRoles.intakeMsr,
];

const CaseManagement = () => {
  const hasWritePermission = useCanWriteAndEnforcePagePermissions();
  const userData = JSON.parse(get("model"));
  const [form] = useForm();
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(Constants.caseDefaultPageSize);
  const dateFormat = "MM/DD/YYYY";
  const [orderBy, setOrderBy] = useState("-modified_date");
  const [isLoader, setIsLoader] = useState(false);
  const [clients, setClients] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [supportUsers, setSupportUsers] = useState<SupportUser[]>([]);
  const [supportUserLoading, setSupportUserLoading] = useState(false);
  const [filter, setFilter] = useState({
    lawyer: -1,
    supportUser: hasWritePermission ? -1 : userData.userId,
    startDate: undefined,
    endDate: undefined,
  });

  const navigate = useNavigate();

  function toUTCString(dateString: string) {
    return new Date(dateString).toISOString().split(".")[0] + "Z"; // ISO format without milliseconds
  }

  const loadClients = () => {
    setIsLoader(true);
    getAllClients(
      orderBy,
      pageNum,
      pageSize,
      filter.supportUser,
      filter.lawyer,
      filter.startDate
        ? moment(filter.startDate).format(Constants.dateTimeFormat)
        : undefined,
      filter.endDate
        ? moment(filter.endDate).format(Constants.dateTimeFormat)
        : undefined,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    ).then((res) => {
      const response = res.data;
      setClients(
        response.data.map((client: Client) => {
          return {
            fullName: `${client.firstName} ${client.lastName}`,
            ...client,
          };
        })
      );
      setTotalCount(response.totalCount);
      setIsLoader(false);
    });
  };

  const compareByName = (a: SupportUser, b: SupportUser) => {
    const nameA = a.fullName.toUpperCase(); // ignore case
    const nameB = b.fullName.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  const getSupportUsersApi = () => {
    setSupportUserLoading(true);
    getSupportUsers(true).then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        setSupportUsers(
          userdata.map((user: SupportUserResponse) => {
            return {
              ...user,
              fullName: `${user.firstName} ${user.lastName}`,
            };
          })
        );
        setSupportUserLoading(false);
      }
    });
  };

  useEffect(() => {
    getSupportUsersApi();
  }, []);

  useEffect(() => {
    loadClients();
  }, [pageNum, orderBy, filter, pageSize]);

  const columns = [
    {
      title: "Assigned Case Manager",
      dataIndex: "caseManagerName",
      key: "caseManagerName",
      sorter: true,
      sortDirections: defaultSortDirection,
      render: (item: any, file: any) =>
        item === "" ? (
          <span style={{ fontWeight: "bold" }}>Unassigned</span>
        ) : (
          item
        ),
    },
    {
      title: "Assigned Lawyer",
      dataIndex: "lawyerName",
      key: "lawyerName",
      sorter: true,
      sortDirections: defaultSortDirection,
      render: (item: any, file: any) =>
        item === "" ? (
          <span style={{ fontWeight: "bold" }}>Unassigned</span>
        ) : (
          item
        ),
    },
    {
      title: "Claimant Name",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      sortDirections: defaultSortDirection,
      render: (item: any, file: any) => {
        return (
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/clientsnotes?id=${file.encryptedId}`)}
          >
            {item}
          </span>
        );
      },
    },
    {
      title: "Last Assigned Date",
      dataIndex: "lastAssignedDate",
      key: "lastAssignedDate",
      defaultSortOrder: "descend" as const,
      sorter: true,
      render: (item: any, file: any) => {
        return item
          ? moment.utc(item).local().format(Constants.dateTimeFormat)
          : "";
      },
      sortDirections: defaultSortDirection,
    },
    {
      title: "Pipeline Status",
      dataIndex: "applicationStatus",
      key: "applicationStatus",
      sorter: true,
      sortDirections: defaultSortDirection,
    },
  ];
  return (
    <>
      <Form
        className='mt-4'
        form={form}
        name='SupportUserForm'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete='off'
        onFinish={(e) => setFilter(e)}
        initialValues={filter}
      >
        <div className='col-md-12 margin-auto mb-5'>
          <div className='mobile_responsive_field'>
            <div className='p-0'>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <span style={{ marginTop: "10px" }}>CaseManager:</span>
                <Form.Item
                  name='supportUser'
                  rules={[{ validator: validateBlankSpace }]}
                >
                  <Select
                    placeholder='Support Users'
                    showSearch
                    filterOption={(input, option) =>
                      (option ? option.label : "")
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    defaultValue={hasWritePermission ? -1 : userData.userId}
                    loading={supportUserLoading}
                    options={
                      !hasWritePermission
                        ? [
                            { label: "My Cases", value: userData.userId },
                            {
                              label: "Unassigned",
                              value: -2,
                            },
                          ]
                        : [
                            { label: "All", value: -1 },
                            {
                              label: "Unassigned",
                              value: -2,
                            },
                            ...supportUsers
                              .filter((user) =>
                                supportUserTypes.includes(user.supportUserType)
                              )
                              .sort(compareByName)
                              .map((user: SupportUser) => ({
                                label: user.fullName,
                                value: user.id,
                              })),
                          ]
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className='p-0'>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <span style={{ marginTop: "10px" }}>Lawyer:</span>

                <Form.Item
                  name='lawyer'
                  rules={[{ validator: validateBlankSpace }]}
                >
                  <Select
                    placeholder='Lawyers'
                    showSearch
                    filterOption={(input, option) =>
                      (option ? option.label : "")
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    defaultValue={-1}
                    loading={supportUserLoading}
                    options={[
                      { label: "All", value: -1 },
                      {
                        label: "Unassigned",
                        value: -2,
                      },
                      ...supportUsers
                        .filter(
                          (user) =>
                            user.supportUserType ===
                            Constants.appUserRoles.lawyer
                        )
                        .sort(compareByName)
                        .map((user: SupportUser) => ({
                          label: user.fullName,
                          value: user.id,
                        })),
                    ]}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='p-0'>
              <Form.Item
                name='startDate'
                getValueFromEvent={(onChange) =>
                  onChange == null ? null : moment(onChange).format(dateFormat)
                }
                getValueProps={(i) => ({
                  value: i == null ? null : moment(i),
                })}
              >
                <DatePicker placeholder='From' format={dateFormat} />
              </Form.Item>
            </div>
            <div className='p-0'>
              <Form.Item
                name='endDate'
                getValueFromEvent={(onChange) =>
                  onChange == null ? null : moment(onChange).format(dateFormat)
                }
                getValueProps={(i) => ({
                  value: i == null ? null : moment(i),
                })}
              >
                <DatePicker
                  placeholder='To'
                  format={dateFormat}
                  disabledDate={(d) =>
                    d.isBefore(form.getFieldValue("startDate"))
                  }
                />
              </Form.Item>
            </div>
            <div>
              <button
                type='submit'
                className='btn btn-primary button_signup button_clients px-3 p-0 height_btn'
              >
                Search
              </button>
              <button
                type='reset'
                onClick={() =>
                  setFilter({
                    lawyer: -1,
                    supportUser: hasWritePermission ? -1 : userData.userId,
                    startDate: undefined,
                    endDate: undefined,
                  })
                }
                className='btn btn-primary button_signup button_leads px-3 p-0 height_btn ms-3'
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </Form>
      <Table
        dataSource={clients}
        pagination={{
          current: pageNum,
          pageSize: pageSize,
          onChange: (page) => {
            setPageNum(page);
          },
          total: totalCount,
          onShowSizeChange: (currnet, size) => {
            setPageSize(size);
            setPageNum(1);
          },
          showSizeChanger: true,
          showTotal: (total) => {
            return `Total Count:  ${total}`;
          },
        }}
        sortDirections={["ascend", "descend"]}
        style={{ marginTop: "40px" }}
        loading={isLoader}
        columns={columns}
        onChange={(pagination, filters, sorter: any) => {
          setOrderBy(
            `${sorter.order === "descend" ? "-" : ""}${sorter.columnKey}`
          );
        }}
      />
    </>
  );
};

export default CaseManagement;
