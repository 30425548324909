import React from "react";
import { List, Button } from "antd";
import "./index.scss";
import { DeleteFilled } from "@ant-design/icons";
import { TemplateOption } from "./index";

interface BundleList {
  items: (TemplateOption | undefined)[];
  onRemoveItem: (bundleListItem: TemplateOption) => void;
}

const BunbldeList = (props: BundleList) => {
  return (
    <List
      itemLayout='horizontal'
      dataSource={props.items}
      renderItem={(item) => (
        <>
          <List.Item
            actions={[
              <Button
                id='listActionDeleteButton'
                className='listActionButton'
                onClick={() => {
                  props.onRemoveItem(item || { value: -1, label: "none" });
                }}
              >
                <DeleteFilled
                  style={{
                    fontSize: "20px",
                  }}
                />
              </Button>,
            ]}
          >
            <List.Item.Meta title={item?.label} />
          </List.Item>
        </>
      )}
    />
  );
};

export default BunbldeList;
