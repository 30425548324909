export const Enums = {
  notificationType: {
    primary: 1,
    success: 2,
    danger: 3,
    warning: 4,
    info: 5,
  },
  responseCodes: {
    success: 200,
    error: 303,
    NotFound: 404,
  },
  status: {
    main_NonQualifiedStatus: 4,
    spanish_NonQualifiedStatus: 38,
    archived_NonQualifiedStatus: 93,
  },
  taskStatus: {
    inProgress: 3,
    underReview: 7,
    notStarted: 1,
  },
  taskType: {
    messageRequest: 10,
  },
  sourceType: {
    outboundNoAnswer: 5,
  },
  categoryType: {
    failedContactAttempt: 20,
  },
  minutesType: {
    minutes: 3,
  },
};
