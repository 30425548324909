import {
  AddTenant,
  DownloadLogo,
  UploadLogo,
  GetTenant,
  GetTenantById,
  UpdateTenant,
  DeleteTenant,
  TenantExistsByCode,
} from "../../apis/tenants";

export function addTenant(payload: any, form: any) {
  return AddTenant(payload, form);
}
export function getTenant() {
  return GetTenant();
}
export function getTenantById(id: any) {
  return GetTenantById(id);
}
export function updateTenant(payload: any) {
  return UpdateTenant(payload);
}
export function deleteTenant(id: any) {
  return DeleteTenant(id);
}
export function tenantExistsByCode(code: any) {
  return TenantExistsByCode(code);
}
export function uploadLogo(tenantId: any, payload: any) {
  return UploadLogo(tenantId, payload);
}
export function downloadLogo(id: any) {
  return DownloadLogo(id);
}
