import { Button } from "antd";
import "./BrandButton.style.css";

const BrandButton = ({
  label,
  onClick,
  disabled,
  isLoading,
  className,
  dataCy,
  style,
}: {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  dataCy?: string;
  style?: object;
}) => {
  return (
    <Button
      loading={isLoading}
      className={`brand-button ${className}`}
      disabled={disabled}
      onClick={onClick}
      data-cy={dataCy}
      style={style}
    >
      {label}
    </Button>
  );
};

export default BrandButton;
