import { Table, Tooltip, Tag, Badge, Button } from "antd";
import Column from "antd/lib/table/Column";
import moment from "moment";
import { Constants } from "../../../common/constants";
import { Enums } from "../../../common/enums";
import { openInNewTab } from "./GlobalTasksTableV1";
import { getOnlyShowMyCasesFromSearchParams } from "../../../utils/commonHelper";
import { useSearchParams } from "react-router-dom";
import "./style.css";
import { useState } from "react";

const PRIORITY_COLORS: {
  [key: string]: "success" | "processing" | "warning" | "error" | "default";
} = {
  Low: "default",
  Medium: "success",
  High: "warning",
  Critical: "error",
};

const ReadMoreDescription = ({ description }: { description: string }) => {
  const [readMore, setReadMore] = useState(false);
  return (
    <p className='text-left'>
      {readMore ? description : `${description.substring(0, 200)}...`}{" "}
      {readMore ? (
        <Button onClick={() => setReadMore(false)} type='link' className='p-0'>
          [Show Less]
        </Button>
      ) : (
        <Button
          style={{
            zIndex: 1000,
          }}
          type='link'
          className='p-0'
          onClick={() => setReadMore(true)}
        >
          [Show Full Description]
        </Button>
      )}
    </p>
  );
};

const GlobalTasksTableV2 = ({ dataSource }: { dataSource: any[] }) => {
  const [searchParams] = useSearchParams();

  return (
    <Table
      className='v2-data-table'
      dataSource={dataSource}
      onRow={(record: any) => {
        return {
          onClick: () => {
            if (
              record.mindsetUsers.length === 0 &&
              record.leadsData.length > 0
            ) {
              openInNewTab("/leadsnotes?id=" + record.leadsData[0].id);
            } else {
              const isMobileTask =
                (record.statusId === Enums.taskStatus.inProgress ||
                  record.statusId === Enums.taskStatus.underReview) &&
                record.typeId === Enums.taskType.messageRequest;
              const taskRoute = isMobileTask ? "requestedinfo" : "clientsnotes";
              openInNewTab(
                `/${taskRoute}?id=${
                  record.mindsetUsers[0].encryptedId
                }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
                  searchParams
                )}`
              );
            }
          },
        };
      }}
      pagination={{
        pageSize: Constants.logsDefaultPageSize,
        showSizeChanger: false,
        showTotal: (total) => `Total Tasks:  ${total}`,
      }}
    >
      <Column
        title='Due Date'
        dataIndex='dueDate'
        key='dueDate'
        render={(dueDate, record) => (
          <div className='profile-cell'>
            {moment(dueDate).format(Constants.dateTimeFormat)}
            {moment(dueDate).isBefore(moment()) && !record.completedOn && (
              <Tag color='red'>Overdue</Tag>
            )}
          </div>
        )}
        sorter={(a: any, b: any) => {
          a = a.dueDate || "";
          b = b.dueDate || "";
          return a.localeCompare(b);
        }}
      />

      <Column
        title='Profile'
        dataIndex='profile'
        key='profile'
        render={(_profile, file: any) =>
          file.mindsetUsers[0] ? (
            <div className='profile-cell'>
              <span>{file.mindsetUsers[0].fullName}</span>
              <Tag color='green'>Claimant</Tag>
            </div>
          ) : (
            <div className='profile-cell'>
              <span>{file.leadsData[0].fullName}</span>
              <Tag color='geekblue'>Lead</Tag>
            </div>
          )
        }
      />
      <Column
        title='Type'
        dataIndex='typeName'
        key='typeName'
        sorter={(a: any, b: any) => a.typeName.localeCompare(b.typeName)}
        render={(typeName, file: any) => (
          <Tooltip
            title={file.description}
            style={{
              width: "400px",
            }}
          >
            {typeName}
          </Tooltip>
        )}
      />

      <Column
        title='Description'
        align='left'
        dataIndex='description'
        key='description'
        render={(description) =>
          description.length > 200 ? (
            <ReadMoreDescription description={description} />
          ) : (
            <p className='text-left'>{description}</p>
          )
        }
        sorter={(a: any, b: any) => {
          a = a.description || "";
          b = b.description || "";
          return a.localeCompare(b);
        }}
      />
      <Column
        title='Priority'
        dataIndex='priorityName'
        key='priorityName'
        render={(priorityName) => (
          <Badge status={PRIORITY_COLORS[priorityName]} text={priorityName} />
        )}
        sorter={(a: any, b: any) => {
          return (
            Object.keys(PRIORITY_COLORS).indexOf(a.priorityName) -
            Object.keys(PRIORITY_COLORS).indexOf(b.priorityName)
          );
        }}
      />
    </Table>
  );
};

export default GlobalTasksTableV2;
