import React, { ReactComponentElement, ReactElement } from "react";
import { Button, notification, Space } from "antd";
type NotificationType = "success" | "info" | "warning" | "error";
export const FlashMessage = (
  type: string,
  message: string | ReactElement,
  title: string = "",
  timeout: number = 3,
  key: string = ""
) => {
  // export function FlashMessage(type:string, message:string, title:string="", timeout:number=3000) {
  timeout = timeout || timeout === 0 ? timeout : 3;
  var config = {
    message: title,
    description: message,
    duration: timeout,
    key: key || (typeof message === "string" ? message : ""),
    // style: {
    //   width: 600,
    //   marginLeft: 335 - 600,
    // },
  };
  switch (type) {
    case "info":
      notification.info(config);
      break;
    case "success":
      notification.success(config);
      break;
    case "warning":
      notification.warning(config);
      break;
    case "error":
      notification.error(config);
      break;
  }
};
