import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from '@react-oauth/google';

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(    <GoogleOAuthProvider clientId="132697518019-o858lknp87vbeosruh83ikn4hoq7m8ce.apps.googleusercontent.com">
<App />    </GoogleOAuthProvider>,
);
