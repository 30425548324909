export const number = (_: any, value: any) => {
  if (value != null && isNaN(Number(value))) {
    return Promise.reject(new Error("Must be a number"));
  }
  return Promise.resolve();
};

export const numberGreaterThanTwelve = (_: any, value: number) => {
  if (value == null || (value >= 0 && value <= 12)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Must be in-between 0 to 12"));
};
export const numberFromOneAndTwelve = (_: any, value: any) => {
  if (
    value == null ||
    value == "  " ||
    value == "" ||
    value == " " ||
    (value >= 1 && value <= 12)
  ) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Must be in-between 1 to 12"));
};
export const numberGreaterThanTwentyFour = (_: any, value: number) => {
  if (value == null || (value >= 0 && value <= 24)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(`Maximun value should be 24`));
};
export const numberGreaterThanSeven = (_: any, value: number) => {
  if (value == null || (value >= 0 && value <= 7)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(`Maximun value should be 7`));
};
export const numberGreaterThanNinetyNine = (_: any, value: number) => {
  if (value == null || (value >= 0 && value <= 99)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(`Age should be between 0 and 99`));
};

export const antdPhoneNumberRule = {
  pattern: /^\d{10}$/,
  message: "Please enter exactly 10 digits",
};

export const validateZipCode = (_: any, value: any) => {
  if (
    value == null ||
    value.toString() == "" ||
    isNaN(Number(value)) ||
    value.toString().length == 5
  )
    return Promise.resolve();
  if (value == null || /^\d{5}$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Zip code must be exactly 5 digits"));
};
