import React, { useEffect, useState, useRef } from "react";
import { Form, Input, DatePicker, Radio, Select, Checkbox } from "antd";
import { Constants } from "../../common/constants";
import { minLength } from "../../validations/validate_min_length";
import { minLengthNine } from "../../validations/validate_min_length";
import { useSearchParams } from "react-router-dom";
import {
  getApplicationDetailsById,
  updateApplication,
  addUpdateApplicationFormStepTwo,
  addUpdateApplicationFormStepThree,
  addUpdateApplicationFormStepFour,
  addUpdateApplicationFormStepFive,
  addUpdateApplicationFormStepSix,
  addUpdateApplicationFormStepSeven,
  addUpdateApplicationFormStepEight,
  addUpdateApplicationFormStepNine,
  addUpdateApplicationFormStepTen,
  getHeights,
  getWeights,
  getPriorMarriageById,
} from "../../actions/application";
import moment from "moment";
import { FlashMessage } from "../../utils/flash_message";
import { windowScrollTop } from "../../utils/window_scroll_top";
import "antd/dist/antd.min.css";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { get } from "../../utils/clientStorageUtils";
import {
  EyeFilled,
  EyeInvisibleFilled,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { number } from "../../validations/numeric_only-validate";
import { activityLogUnmaskingSsn } from "../../actions/application";
import { getCommonAllLocations } from "../../actions/common";
import useCanWriteAndEnforcePagePermissions from "../../common/customHooks/useCanWriteAndEnforcePagePermissions";
import GooglePlacesAutocompleteWrapper from "../../components/GooglePlacesAutoCompleteWrapper";

const NAText = "N/A";
const dateFormat = Constants.dateFormat;

const Application = () => {
  const canWrite = useCanWriteAndEnforcePagePermissions();
  const [appData, setAppData] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    middleName: "",
    ssn: "",
    ssnnew: "",
    dateOfBirth: "",
    gender: "",
    isDeniedSSN: false,
    isUnableWork: false,
    heightWithoutShoes: "",
    medicalCondition: ";;;;;;;;;",
    mailingAddress: "",
    isLowVision: false,
    isDisabled: false,
    isDeniedBenefits: false,
    startDateOfDisability: "",
    phoneNumber: "",
    timeToCall: "",
    placeOfBirth: "",
    isReside: false,
    isDeadlyCondition: false,
    isUSCitizen: false,
    languageForSpeaking: "1",
    languageForReading: "1",
    typeOfCitizenship: "",
    isSpeakEnglish: false,
    isReadEnglish: false,
    isWriteEnglish: false,
    isOtherSSNumber: false,
    spouseFName: "",
    spouseMName: "",
    spouseLName: "",
    spouseSSNumber: "",
    spouseDOB: "",
    isMarried: false,
    isSpouseWorked: false,
    placeOfMarriage: "",
    dateOfMarriage: "",
    fatherName: "",
    motherName: "",
    isPriorMarriage: false,
    isTenYearMarriage: false,
    isAnyChildren: false,
    isMillitary: false,
    isAppliedPDB: false,
    moneyEarnedInPrevYear: "",
    isOtherName: false,
    otherNames: ";;;;;;;;;",
    isWorkIn2023: false,
    isSelfEmployedPrevYear: false,
    isSelfEmployedCurYear: false,
    isPriorApplications: false,
    moneyEarnedInCurrYear: "",
    isWorkedOutsideUS: false,
    isSpouseWorkedOutsideUS: false,
    isAgreeWithEarningHistory: false,
    isWorkedTaxesNotDecucted: false,
    isOwnBankAccount: false,
    weightWIthoutShoes: "",
    isReceiveMoneyUnableToWork: false,
    isExpectMoneyFromRecentEmployer: false,
    isOneParent: false,
    dob: "",
    isDocusignEligible: false,
  });
  const [checkedGender, setCheckedGender] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [addressValue, setAddressValue] = useState("");
  const [priorMarriage, setPriorMarriage] = useState({});
  const [isViewSSN, setIsViewSSN] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isOtherName, setIsOtherName] = useState(false);
  const [isSelfEmployedPrevYear, setIsSelfEmployedPrevYear] = useState(false);
  const [isSelfEmployedCurYear, setIsSelfEmployedCurYear] = useState(false);
  const [isMother, setIsMother] = useState(false);
  const [isFather, setIsFather] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [previousYear, setPreviousYear] = useState(
    new Date().getFullYear() - 1
  );
  const [height, setHeight] = useState([]);
  const [weight, setWeight] = useState([]);
  const [location, setLocation] = useState([]);
  const [form] = Form.useForm();
  const intervalId = 0;
  const FormStepOne = useRef(null);
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const [isViewDob, setIsViewDob] = useState(false);

  const getCommonAllLocationsApi = () => {
    getCommonAllLocations().then((res) => {
      var loc = res.data.responseData;
      setLocation(loc);
    });
  };

  const updateApplicationApi = (e: any) => {
    if (e != null) {
      var payload = {
        EncryptedId: searchParams.get("id") ?? "0",
        firstName: e.firstName,
        lastName: e.lastName,
        middleName: e.middleName,
        ssn: e.ssn,
        dateOfBirth: e.dateOfBirth
          ? moment(e.dateOfBirth).format("YYYY-MM-DDTHH:mm:ss")
          : null,
        gender: checkedGender ? "Male" : "Female",
        isDeniedSSN: e.isDeniedSSN,
        isUnableWork: e.isUnableWork,
      };
      if (!autoSave) {
        setIsLoader(true);
      }
      updateApplication(payload).then((res) => {
        if (!autoSave) {
          FlashMessage("success", "Information Saved Successfully");
          windowScrollTop();
          setPage(2);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          setAutoSave(false);
        }
      });
    }
  };

  const addUpdateApplicationFormStepTwoApi = (e: any) => {
    var startDisabilityDate =
      e.startDateOfDisability == null || e.startDateOfDisability == ""
        ? ""
        : moment(e.startDateOfDisability).utc().format();
    if (e != null) {
      var medicalCondition = "";
      if (e.medicalConditions != null) {
        for (var i = 0; i < e.medicalConditions.length; i++) {
          medicalCondition =
            medicalCondition +
            (i == 0 ? "" : ";;;") +
            (e.medicalConditions[i] ?? "");
        }
      }
      var payload = {
        EncryptedId: searchParams.get("id") ?? "0",
        MedicalCondition: medicalCondition,
        MailingAddress: addressValue,
        IsReside: e.isReside,
        IsLowVision: e.isLowVision,
        IsDisabled: e.isDisabled,
        IsDeniedBenefits: e.isDeniedBenefits,
        StartDateOfDisability:
          startDisabilityDate == "" ? null : startDisabilityDate,
      };

      if (!autoSave) {
        setIsLoader(true);
      }
      addUpdateApplicationFormStepTwo(payload).then((res) => {
        if (!autoSave) {
          FlashMessage("success", "Information Saved Successfully");
          windowScrollTop();
          setPage(3);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          setAutoSave(false);
        }
      });
    }
  };

  const addUpdateApplicationFormStepThreeApi = (e: any) => {
    if (e != null) {
      var payload = {
        EncryptedId: searchParams.get("id") ?? "0",
        PhoneNumber: e.phoneNumber,
        TimeToCall: e.timeToCall,
        PlaceOfBirth: e.placeOfBirth,
        IsDeadlyCondition: e.isDeadlyCondition,
        IsUSCitizen: e.isUSCitizen,
      };
      addUpdateApplicationFormStepThree(payload).then((res) => {
        if (!autoSave) {
          FlashMessage("success", "Information Saved Successfully");
          windowScrollTop();
          setPage(4);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          setAutoSave(false);
        }
      });
    }
  };

  const addUpdateApplicationFormStepFourApi = (e: any) => {
    if (e != null) {
      var payload = {
        EncryptedId: searchParams.get("id") ?? "0",
        LanguageForSpeaking: e.languageForSpeaking,
        LanguageForReading: e.languageForReading,
        TypeOfCitizenship: e.typeOfCitizenship,
        IsSpeakEnglish: e.isSpeakEnglish,
        IsReadEnglish: e.isReadEnglish,
        IsWriteEnglish: e.isWriteEnglish,
        IsMarried: e.isMarried,
      };
      var appdata = appData;
      appdata.isMarried = e.isMarried;
      setAppData(appData);

      if (!autoSave) {
        setIsLoader(true);
      }
      addUpdateApplicationFormStepFour(payload).then((res) => {
        if (!autoSave) {
          FlashMessage("success", "Information Saved Successfully");
          windowScrollTop();
          appData.isMarried ? setPage(5) : setPage(6);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          setAutoSave(false);
        }
      });
    }
  };

  const addUpdateApplicationFormStepFiveApi = (e: any) => {
    clearTimeout(intervalId);

    if (e != null) {
      setAutoSave(false);
    }
    if (e != null) {
      var DateOfMarriage = e.dateOfMarriage
        ? moment(e.dateOfMarriage).utc().format()
        : null;
      var startSpouseDOB = e.spouseDOB
        ? moment(e.spouseDOB).format("YYYY-MM-DDTHH:mm:ss")
        : null;
      var payload = {
        EncryptedId: searchParams.get("id") ?? "0",
        SpouseFName: e.spouseFName,
        SpouseMName: e.spouseMName,
        SpouseLName: e.spouseLName,
        SpouseSSNumber: e.spouseSSNumber,
        SpouseDOB: startSpouseDOB,
        IsSpouseWorked: e.isSpouseWorked,
        PlaceOfMarriage: e.placeOfMarriage,
        DateOfMarriage: DateOfMarriage,
        IsSpouseWorkedOutsideUS: e.isSpouseWorkedOutsideUS,
      };
      if (!autoSave) {
        setIsLoader(true);
      }
      addUpdateApplicationFormStepFive(payload).then((res) => {
        if (!autoSave) {
          FlashMessage("success", "Information Saved Successfully");
          windowScrollTop();
          setPage(6);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          setAutoSave(false);
        }
      });
    }
  };

  const addUpdateApplicationFormStepSixApi = (e: any) => {
    clearTimeout(intervalId);
    if (e != null) {
      setAutoSave(false);
    }
    if (e != null) {
      var payload = {
        EncryptedId: searchParams.get("id") ?? "0",
        IsPriorMarriage: e.isPriorMarriage,
        IsMillitary: e.isMillitary,
        IsAppliedPDB: e.isAppliedPDB,
        FatherName: isFather == false ? e.fatherName : NAText,
        MotherName: isMother == false ? e.motherName : NAText,
        IsTenYearMarriage: e.isTenYearMarriage,
      };
      var appdata = appData;
      appdata.isTenYearMarriage = e.isTenYearMarriage;
      setAppData(appData);
      if (!autoSave) {
        setIsLoader(true);
      }
      addUpdateApplicationFormStepSix(payload).then((res) => {
        if (!autoSave) {
          FlashMessage("success", "Information Saved Successfully");
          windowScrollTop();
          appData.isTenYearMarriage ? setPage(10) : setPage(7);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          setAutoSave(false);
        }
      });
    }
  };

  const onChangeSelfEmployedPrevYear = (e: any) => {
    setIsSelfEmployedPrevYear(e.target.value);
  };

  const onChangeSelfEmployedNextYear = (e: any) => {
    setIsSelfEmployedCurYear(e.target.value);
  };
  const addUpdateApplicationFormStepSevenApi = (e: any) => {
    clearTimeout(intervalId);
    if (e != null) {
      setAutoSave(false);
    }
    if (e != null) {
      var otherNames = "";
      if (e.otherNames != null) {
        for (var i = 0; i < e.otherNames.length; i++) {
          otherNames =
            otherNames + (i == 0 ? "" : ";;;") + (e.otherNames[i] ?? "");
        }
      }
      var payload = {
        EncryptedId: searchParams.get("id") ?? "0",
        MoneyEarnedInPrevYear: isSelfEmployedPrevYear
          ? e.moneyEarnedInPrevYear
          : null,
        IsOtherName: e.isOtherName,
        otherNames: otherNames,
        IsWorkInCurYear: e.isWorkInCurYear,
        IsSelfEmployedPrevYear: isSelfEmployedPrevYear,
        IsSelfEmployedCurYear: isSelfEmployedCurYear,
        IsPriorApplications: e.isPriorApplications,
      };
      addUpdateApplicationFormStepSeven(payload).then((res) => {
        FlashMessage("success", "Information Saved Successfully");
        windowScrollTop();
        setPage(8);
        setIsLoader(false);
      });
    }
  };

  const addUpdateApplicationFormStepEightApi = (e: any) => {
    clearTimeout(intervalId);

    if (e != null) {
      setAutoSave(false);
    }
    if (e != null) {
      var payload = {
        EncryptedId: searchParams.get("id") ?? "0",
        MoneyEarnedInCurrYear: isSelfEmployedCurYear
          ? e.moneyEarnedInCurrYear
          : null,
        IsWorkedOutsideUS: e.isWorkedOutsideUS,
        IsAgreeWithEarningHistory: e.isAgreeWithEarningHistory,
        IsWorkedTaxesNotDecucted: e.isWorkedTaxesNotDecucted,
        IsOwnBankAccount: e.isOwnBankAccount,
      };
      if (!autoSave) {
        setIsLoader(true);
      }
      addUpdateApplicationFormStepEight(payload).then((res) => {
        if (!autoSave) {
          FlashMessage("success", "Information Saved Successfully");
          windowScrollTop();
          setPage(9);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          setAutoSave(false);
        }
      });
    }
  };

  const addUpdateApplicationFormStepNineApi = (e: any) => {
    clearTimeout(intervalId);
    if (e != null) {
      var payload = {
        EncryptedId: searchParams.get("id") ?? "0",
        IsOtherSSNumber: e.isOtherSSNumber,
        HeightWithoutShoes: e.heightWithoutShoes,
        WeightWIthoutShoes: e.weightWIthoutShoes,
        IsReceiveMoneyUnableToWork: e.isReceiveMoneyUnableToWork,
        IsExpectMoneyFromRecentEmployer: e.isExpectMoneyFromRecentEmployer,
        IsOneParent: e.isOneParent,
        IsDocusignEligible: appData.isDocusignEligible,
      };
      if (!autoSave) {
        setIsLoader(true);
      }
      addUpdateApplicationFormStepNine(payload).then((res) => {
        if (!autoSave) {
          FlashMessage("success", "Information Saved Successfully");
          setIsLoader(false);
          setPage(0);
        } else {
          setIsLoader(false);
          setAutoSave(false);
        }
        navigate("/templates?id=" + searchParams.get("id") ?? "0"); ///thi is wrong oh kya mtlb
      });
    }
  };

  const getApplicationDetailsByIdApi = () => {
    var param = searchParams.get("id") ?? "0";
    setIsLoader(true);
    getHeightsApi();
    getWeightsApi();
    getApplicationDetailsById(param).then((res) => {
      var appRes = res.data.responseData;
      setAppData(appRes);
      appRes.startDateOfDisability =
        appRes.startDateOfDisability == null ||
        appRes.startDateOfDisability == "0001-01-01T00:00:00"
          ? null
          : moment.utc(appRes.startDateOfDisability).local().format();
      appRes.dateOfBirth =
        appRes.dateOfBirth == null ||
        appRes.dateOfBirth == "0001-01-01T00:00:00"
          ? null
          : moment(appRes.dateOfBirth).format();
      appRes.spouseDOB =
        appRes.spouseDOB == null || appRes.spouseDOB == "0001-01-01T00:00:00"
          ? null
          : moment(appRes.spouseDOB).format();
      appRes.ssnnew =
        appRes.ssn == null ? "" : `***-**-` + appRes.ssn.substr(-4);
      appRes.dob =
        appRes.dateOfBirth == null
          ? ""
          : `**/**/` + appRes.dateOfBirth.toString().slice(0, 4);
      appRes.dateOfMarriage =
        appRes.dateOfMarriage == null ||
        appRes.dateOfMarriage == "0001-01-01T00:00:00"
          ? null
          : moment.utc(appRes.dateOfMarriage).local().format();

      if (appRes.medicalCondition == null || appRes.medicalCondition == "")
        appRes.medicalCondition = ";;;;;;";
      if (appRes.medicalCondition) {
        var medConArray = appRes.medicalCondition.split(";;;");
        appRes.medicalConditions = [];
        for (var i = 0; i < medConArray.length; i++) {
          appRes.medicalConditions.push(medConArray[i] ?? "");
        }
      }
      if (appRes.otherNames == null || appRes.otherNames == "")
        appRes.otherNames = ";;;;;;";
      if (appRes.otherNames) {
        var otherNamesArray = appRes.otherNames.split(";;;");
        appRes.otherNames = [];
        for (var ii = 0; ii < otherNamesArray.length; ii++) {
          appRes.otherNames.push(otherNamesArray[ii] ?? "");
        }
      }
      if (appRes.languageForReading == null) {
        appRes.languageForReading = "English";
      }
      if (appRes.languageForSpeaking == null) {
        appRes.languageForSpeaking = "English";
      }
      if (appRes.fatherName == NAText) {
        setIsFather(true);
        appRes.isFather = true;
        appRes.fatherName = "";
      }
      if (appRes.motherName == NAText) {
        setIsMother(true);
        appRes.isMother = true;
        appRes.motherName = "";
      }
      if (appRes.gender === "Male") {
        setCheckedGender(true);
      } else if (appRes.gender === "Female") {
        setCheckedGender(false);
      } else {
        setCheckedGender(null);
      }

      setIsOtherName(appRes.isOtherName);
      setAddressValue(appRes.mailingAddress);
      setIsSelfEmployedCurYear(appRes.isSelfEmployedCurYear);
      setIsSelfEmployedPrevYear(appRes.isSelfEmployedPrevYear);

      setIsLoader(false);
      form.setFieldsValue(appRes);
    });
  };

  const handleBackButton = () => {
    if (!appData.isMarried && page == 6) {
      setPage(page - 2);
    } else if (appData.isTenYearMarriage && page == 10) {
      setPage(page - 4);
    } else if (appData.isTenYearMarriage && page == 7) {
      setPage(page + 3);
    } else {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    getApplicationDetailsByIdApi();
  }, [searchParams.get("id")]);
  useEffect(() => {
    getCommonAllLocationsApi();
  }, [searchParams.get("id")]);
  useEffect(() => {
    getPriorMarriageByIdApi();
  }, [searchParams.get("id")]);
  const getHeightsApi = () => {
    getHeights().then((res) => {
      var height = res.data.responseData;
      if (res.data.responseCode == 200) {
        setHeight(height);
      }
    });
  };

  const getWeightsApi = () => {
    getWeights().then((res) => {
      var weight = res.data.responseData;
      if (res.data.responseCode == 200) {
        setWeight(weight);
      }
    });
  };
  const showHideSSN = () => {
    setIsViewSSN(!isViewSSN);
    var param = searchParams.get("id") ?? "0";
    activityLogUnmaskingSsn(param).then((res) => {});
  };
  const showHideDob = () => {
    setIsViewDob(!isViewDob);
  };
  const languages = [
    {
      id: 1,
      name: `English`,
    },
    {
      id: 2,
      name: `Spanish`,
    },
    {
      id: 3,
      name: `French`,
    },
    {
      id: 4,
      name: `Arabic`,
    },
    {
      id: 5,
      name: `Russian`,
    },
    {
      id: 6,
      name: `German`,
    },
    {
      id: 7,
      name: `Chinese`,
    },
    {
      id: 8,
      name: `Japanese`,
    },
    {
      id: 9,
      name: `Hindi`,
    },
    {
      id: 10,
      name: `Italian`,
    },
    {
      id: 11,
      name: `Others`,
    },
  ];
  const bestTimeToCall = [
    {
      id: 1,
      name: `Morning (7am - 11am)`,
    },
    {
      id: 2,
      name: `Mid-day (11am - 3pm)`,
    },
    {
      id: 3,
      name: `Evening (3pm - 6pm)`,
    },
    {
      id: 4,
      name: `Night (6pm - 9pm)`,
    },
  ];
  const onChangeMother = (e: any) => {
    setIsMother(e.target.checked);
  };
  const onChangeFather = (e: any) => {
    setIsFather(e.target.checked);
  };
  const onkeyup = (e: any) => {
    var onlyNumber = e.target.value
      .split("(")
      .join("")
      .split(")")
      .join("")
      .split("-")
      .join("")
      .split(" ")
      .join("");
    if (onlyNumber.length > 10) {
      onlyNumber = onlyNumber.substring(0, 10);
    }
    form.setFieldsValue({ phoneNumber: onlyNumber });
  };
  const genderYes = (checked: any) => {
    setCheckedGender(checked);
  };
  const addUpdateApplicationFormStepTenApi = (e: any) => {
    var priorDob = e.priorSpouseDob
      ? moment(e.priorSpouseDob).format("YYYY-MM-DDTHH:mm:ss")
      : null;
    var priorMarriage = e.priorDoMarriage
      ? moment(e.priorDoMarriage).format()
      : null;
    var dateDivorce = e.dateOfDivorce
      ? moment(e.dateOfDivorce).format("YYYY-MM-DDTHH:mm:ss")
      : null;
    var payload = {
      EncryptedId: searchParams.get("id") ?? "0",
      PriorSpouseFullName: e.priorSpouseFullName,
      PriorSpouseDob: priorDob,
      PriorDoMarriage: priorMarriage,
      PriorMarriageCity: e.priorMarriageCity,
      PriorMarriageState: e.priorMarriageState,
      DateOfDivorce: dateDivorce,
      DivorceCity: e.divorceCity,
      DivorceState: e.divorceState,
    };
    addUpdateApplicationFormStepTen(payload).then((res) => {
      FlashMessage("success", "Information Saved Successfully");
      setIsLoader(false);
      setPage(7);
    });
  };
  const getPriorMarriageByIdApi = () => {
    var param = searchParams.get("id") ?? "0";
    getPriorMarriageById(param).then((res) => {
      var data = res.data.responseData;
      data.priorSpouseDob =
        data.priorSpouseDob == null ||
        data.priorSpouseDob == "0001-01-01T00:00:00"
          ? null
          : data.priorSpouseDob;
      data.priorDoMarriage =
        data.priorDoMarriage == null ||
        data.priorDoMarriage == "0001-01-01T00:00:00"
          ? null
          : data.priorDoMarriage;
      data.dateOfDivorce =
        data.dateOfDivorce == null ||
        data.dateOfDivorce == "0001-01-01T00:00:00"
          ? null
          : data.dateOfDivorce;
      setPriorMarriage(data);
      form.setFieldsValue(data);
    });
  };
  const predefinedValue = {
    label: addressValue,
    value: Constants.googleAutoCompleteStaticId,
  };
  const { noInputValue, lessThanNineDigits } =
    Constants.errorMessages.applicationErrorMessages;

  const handleSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || !/^\d+$/.test(e.target.value)) {
      form.setFields([
        {
          name: "ssnnew",
          value: "",
          errors: [noInputValue],
        },
      ]);
      return;
    }
    if (e.target.value.length < 9) {
      form.setFields([
        {
          name: "ssnnew",
          value: "",
          errors: [lessThanNineDigits],
        },
      ]);
      return;
    }
    form.setFields([
      {
        name: "ssnnew",
        errors: [],
        value: `***-**-${+e.target.value.slice(-4)}`,
      },
    ]);
  };

  const handleDobChange = (d: any) => {
    if (!d) {
      form.setFields([
        {
          name: "dob",
          errors: [noInputValue],
          value: null,
        },
      ]);
      return;
    }
    form.setFields([
      {
        name: "dob",
        errors: [],
        value: `**/**/${d.format(dateFormat).toString().slice(-4)}`,
      },
    ]);
  };

  return (
    <>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <>
          {page === 1 && (
            <Form
              form={form}
              name='basic'
              ref={FormStepOne}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={appData}
              autoComplete='off'
              onFinish={updateApplicationApi}
            >
              <div className={`form_app mt-5  ps-5 pe-5`}>
                <h6 className={`text-center label_weight`}>
                  Applicant’s Name:
                </h6>
                <p className={`text-center heading_input mb-0`}>
                  Please provide the name as it appears on the most recent
                  Social Security card.
                </p>
                <div className='row justify-content-center'>
                  <div className='col-md-4 text-center'>
                    <p className={`text_form_label text-center mb-0`}>
                      First Name
                    </p>
                    <Form.Item
                      name='firstName'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input
                        className={`input_style3 formfield`}
                        placeholder='First Name'
                      />
                    </Form.Item>
                  </div>
                  <div className='col-md-4 text-center'>
                    <p className={`text_form_label text-center mb-0`}>
                      Middle Name
                    </p>
                    <Form.Item name='middleName'>
                      <Input
                        className={`input_style3 formfield`}
                        placeholder='Middle Name'
                      />
                    </Form.Item>
                  </div>
                  <div className='col-md-4 text-center'>
                    <p className={`text_form_label text-center mb-0`}>
                      Last Name
                    </p>
                    <Form.Item
                      name='lastName'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input
                        className={`input_style3 formfield`}
                        placeholder='Last Name'
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='row justify-content-center align-items-end'>
                  <div className='col-md-4 text-center'></div>
                  <div className='col-md-4 text-center'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      Social Security Number (SSN):
                    </h6>
                    <Form.Item
                      name='ssn'
                      className={isViewSSN ? "" : "displayNone"}
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                        { validator: number },
                        { validator: minLengthNine },
                      ]}
                    >
                      <Input
                        name='maskedssn'
                        maxLength={9}
                        minLength={9}
                        className={`input_style3 formfield`}
                        placeholder='SSN'
                        onChange={handleSSNChange}
                      />
                    </Form.Item>
                    <Form.Item
                      name='ssnnew'
                      className={!isViewSSN ? "" : "displayNone"}
                      rules={
                        (!isViewSSN && [
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]) ||
                        undefined
                      }
                    >
                      <Input
                        disabled
                        className={`input_style3 formfield`}
                        placeholder='SSN'
                      />
                    </Form.Item>
                  </div>
                  {canWrite ? (
                    <div className='col-md-4 pe-0 ps-0 position-relative'>
                      <a className='eye_icon' onClick={showHideSSN}>
                        {!isViewSSN && (
                          <EyeInvisibleFilled
                            style={{
                              fontSize: "25px",
                              color: "#747497",
                            }}
                          />
                        )}
                        {isViewSSN && (
                          <EyeFilled
                            style={{
                              fontSize: "25px",
                              color: "#747497",
                            }}
                          />
                        )}
                      </a>
                    </div>
                  ) : (
                    <div className='col-md-4 pe-0 ps-0 position-relative'></div>
                  )}
                </div>
                <div className='row justify-content-center align-items-end'>
                  <div className='col-md-4 text-center'></div>
                  <div className='col-md-4 text-center ant_design_picker'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      Date of Birth
                    </h6>
                    {canWrite ? (
                      <Form.Item
                        name='dateOfBirth'
                        className={isViewDob ? "" : "displayNone"}
                        rules={[
                          {
                            required: true,
                            message: noInputValue,
                          },
                        ]}
                        getValueFromEvent={(onChange) =>
                          onChange == null
                            ? null
                            : moment(onChange).format(dateFormat)
                        }
                        getValueProps={(i) => ({
                          value: i == null ? null : moment(i),
                        })}
                      >
                        <DatePicker
                          name='dob'
                          disabledDate={(d: any) =>
                            !d || d.isBefore("01/01/1900")
                          }
                          format={dateFormat}
                          onChange={handleDobChange}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name='dateOfBirth'
                        className={isViewDob ? "" : "displayNone"}
                        getValueFromEvent={(onChange) =>
                          onChange == null
                            ? null
                            : moment(onChange).format(dateFormat)
                        }
                        getValueProps={(i) => ({
                          value: i == null ? null : moment(i),
                        })}
                      >
                        <DatePicker
                          disabled
                          disabledDate={(d: any) =>
                            !d || d.isBefore("01/01/1900")
                          }
                          format={dateFormat}
                        />
                      </Form.Item>
                    )}
                    {canWrite ? (
                      <Form.Item
                        name='dob'
                        className={!isViewDob ? "" : "displayNone"}
                        rules={
                          (!isViewDob && [
                            {
                              required: true,
                              message: noInputValue,
                            },
                          ]) ||
                          undefined
                        }
                      >
                        <Input
                          disabled
                          className={`input_style3 formfield`}
                          placeholder='dob'
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name='dob'
                        className={!isViewDob ? "" : "displayNone"}
                        rules={
                          (!isViewDob && [
                            {
                              required: true,
                              message: noInputValue,
                            },
                          ]) ||
                          undefined
                        }
                      >
                        <Input
                          disabled
                          className={`input_style3 formfield`}
                          placeholder='dob'
                        />
                      </Form.Item>
                    )}
                  </div>
                  {canWrite ? (
                    <div className='col-md-4 pe-0 ps-0 position-relative'>
                      <a className='eye_icon' onClick={showHideDob}>
                        {!isViewDob && (
                          <EyeInvisibleFilled
                            style={{
                              fontSize: "25px",
                              color: "#747497",
                            }}
                          />
                        )}
                        {isViewDob && (
                          <EyeFilled
                            style={{
                              fontSize: "25px",
                              color: "#747497",
                            }}
                          />
                        )}
                      </a>
                    </div>
                  ) : (
                    <div className='col-md-4 pe-0 ps-0 position-relative'></div>
                  )}
                </div>
                <div className='row justify-content-center mt-1'>
                  <div className='col-md-4'>
                    <h6 className={`text-center label_weight mb-1`}>Gender</h6>
                    <div className='d-flex justify-content-center'>
                      <div className='d-flex align-items-center ms-3 gap-3'>
                        <label className='container_radio mb-0'>
                          Male
                          <input
                            type='radio'
                            name='gender'
                            checked={
                              checkedGender === null ? false : checkedGender
                            }
                            onChange={() => genderYes(true)}
                          />
                          <span className='checkmark'></span>
                        </label>
                        <label className='container_radio mb-highSchoolState0 ml-3 mr-3'>
                          Female
                          <input
                            type='radio'
                            name='gender'
                            checked={
                              checkedGender === null ? false : !checkedGender
                            }
                            onChange={() => genderYes(false)}
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <p className={`text-center heading_input mb-0 mt-1`}>
                  During the last 14 months, has the applicant been unable to
                  work because of illness, injuries or conditions that have
                  lasted or are expected to last at least 12 Months or can be
                  expected to result in death?
                </p>
                <div className='d-flex justify-content-center align-items-center'>
                  <Form.Item
                    name='isUnableWork'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0 mt-1`}>
                  Has the applicant previously been denied for Social Security
                  benefits or Supplemental Security Income (SSI) in the last 60
                  days?
                </p>
                <div className='d-flex justify-content-center align-items-center'>
                  <Form.Item
                    name='isDeniedSSN'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-5'>
                  <button
                    type='submit'
                    className={`btn btn-primary button_clients2`}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
          {page === 2 && (
            <Form
              onFinish={addUpdateApplicationFormStepTwoApi}
              form={form}
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              initialValues={appData}
            >
              <div className={`form_app mt-5  ps-5 pe-5`}>
                <div className='row justify-content-center'>
                  <h6 className={`text-center label_weight`}>
                    Please list your mental and or physical medical conditions
                    below:
                  </h6>
                  <p className={`text-center heading_input mb-0`}>
                    List the medical reason why you are applying for Social
                    Security Disability
                  </p>
                  <div>
                    <Form.List name='medicalConditions'>
                      {(medicalConditions, { add, remove }) => {
                        return (
                          <div className='row align-items-baseline'>
                            <div className='row col-md-10'>
                              {medicalConditions.map((field, index) => (
                                <div
                                  key={field.key}
                                  className='col-md-4 mt-2 position-relative'
                                >
                                  <Form.Item
                                    name={[index]}
                                    rules={[
                                      {
                                        required: index == 0 ? true : false,
                                        message:
                                          "Medical Condition is Required",
                                      },
                                      {
                                        validator: validateBlankSpace,
                                      },
                                    ]}
                                  >
                                    <Input
                                      className={`input_style3 formfield`}
                                      placeholder='Medical Condition'
                                    />
                                  </Form.Item>
                                  {medicalConditions.length > 3 &&
                                  medicalConditions.length - 1 == index ? (
                                    <a
                                      className='minus_icon'
                                      onClick={() => remove(field.name)}
                                    >
                                      <MinusCircleOutlined
                                        style={{
                                          fontSize: "25px",
                                          color: "#747497",
                                        }}
                                      />
                                    </a>
                                  ) : null}
                                </div>
                              ))}
                            </div>
                            <div className='  col-md-1'>
                              <Form.Item>
                                <a onClick={() => add()}>
                                  <PlusCircleOutlined
                                    style={{
                                      fontSize: "25px",
                                      color: "#747497",
                                    }}
                                  />
                                </a>
                              </Form.Item>
                            </div>
                          </div>
                        );
                      }}
                    </Form.List>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <div className='col-md-4'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      What is your mailing address?
                    </h6>
                    <Form.Item
                      name='mailingAddress'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <GooglePlacesAutocompleteWrapper
                        apiKey={process.env.REACT_APP_API_KEY ?? ""}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          value: predefinedValue,
                          isClearable: true,
                          onChange: (o: any) => {
                            form.setFieldsValue({
                              mailingAddress: o?.label ?? "",
                            });

                            setAddressValue(o?.label);
                          },
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Do you reside at this address?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isReside'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='row justify-content-center'>
                  <div className='col-md-6'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      When was the start date of your disability?
                    </h6>
                    <div className='d-flex justify-content-center text-center'>
                      <Form.Item
                        name='startDateOfDisability'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]}
                        getValueFromEvent={(onChange) =>
                          onChange == null
                            ? null
                            : moment(onChange).format(dateFormat)
                        }
                        getValueProps={(i) => ({
                          value: i == null ? null : moment(i),
                        })}
                      >
                        <DatePicker
                          disabledDate={(d: any) =>
                            !d || d.isBefore("01/01/1900")
                          }
                          format={dateFormat}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Are you blind or have low vision?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isLowVision'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Are you disabled?{" "}
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isDisabled'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Have you been denied benefits in the last 60 days?{" "}
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isDeniedBenefits'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-5'>
                  <button
                    type='button'
                    onClick={handleBackButton}
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    BACK
                  </button>
                  <button
                    type='submit'
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
          {page === 3 && (
            <Form
              form={form}
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              onFinish={addUpdateApplicationFormStepThreeApi}
              initialValues={appData}
            >
              <div className={`form_app mt-5  ps-5 pe-5`}>
                <div className='row justify-content-center'>
                  <div className='col-md-4'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      What is your phone number?
                    </h6>
                    <Form.Item
                      name='phoneNumber'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                        { validator: minLength },
                      ]}
                    >
                      <Input
                        onKeyUpCapture={onkeyup}
                        maxLength={15}
                        className={`input_style3} formfield`}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <h6 className={`text-center label_weight mb-1 mt-1`}>
                    Best time to call your phone?
                  </h6>
                  <p className={`text-center heading_input mb-0`}>
                    Example: Between 9am and 5pm.
                  </p>
                  <div className='col-md-4'>
                    <Form.Item
                      name='timeToCall'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Select
                        options={bestTimeToCall.map((loc: any) => ({
                          label: loc.name,
                          value: loc.name,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <h6 className={`text-center label_weight mb-1 mt-1`}>
                    Place of Birth?
                  </h6>
                  <p className={`text-center heading_input mb-0`}>
                    City and state or city and international country.
                  </p>
                  <div className='col-md-4'>
                    <Form.Item
                      name='placeOfBirth'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input className={`input_style3} formfield`} />
                    </Form.Item>
                  </div>
                </div>

                <p className={`text-center heading_input mb-0`}>
                  Have you been diagnosed with condition that is expected to end
                  in death?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isDeadlyCondition'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  {/* <ApplicationToggleButton checked={appData.isDeadlyCondition} /> */}
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Are you a U.S Citizen?{" "}
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isUSCitizen'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-5'>
                  <button
                    type='button'
                    onClick={handleBackButton}
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    BACK
                  </button>
                  <button
                    type='submit'
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
          {page === 4 && (
            <Form
              form={form}
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              onFinish={addUpdateApplicationFormStepFourApi}
              initialValues={appData}
            >
              <div className={`form_app mt-5  ps-5 pe-5`}>
                <div className='row justify-content-center'>
                  <h6 className={`text-center label_weight mb-1 mt-1`}>
                    What is your preferred language for speaking?
                  </h6>
                  <div className='col-md-4'>
                    <Form.Item
                      name='languageForSpeaking'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Select
                        placeholder='Choose the language'
                        options={languages.map((loc: any) => ({
                          label: loc.name,
                          value: loc.name,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <h6 className={`text-center label_weight mb-1 mt-1`}>
                    What is your preferred language for reading?
                  </h6>
                  <div className='col-md-4'>
                    <Form.Item
                      name='languageForReading'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Select
                        placeholder='Choose the language'
                        options={languages.map((loc: any) => ({
                          label: loc.name,
                          value: loc.name,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <div className='col-md-8'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      Type of Citizenship?
                    </h6>
                    <div className='d-flex align-items-center justify-content-center'>
                      <Form.Item
                        name='typeOfCitizenship'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={"US citizen born inside US"}>
                            US citizen born inside US
                          </Radio>
                          <Radio value={"US citizen born outside the US"}>
                            US citizen born outside the US
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Do you speak English?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isSpeakEnglish'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Do you read English?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isReadEnglish'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Do you write English?{" "}
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isWriteEnglish'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Are you currently married?{" "}
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isMarried'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-5'>
                  <button
                    type='button'
                    onClick={handleBackButton}
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    BACK
                  </button>
                  <button
                    type='submit'
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
          {page === 5 && (
            <Form
              form={form}
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              onFinish={addUpdateApplicationFormStepFiveApi}
              initialValues={appData}
            >
              <div className={`form_app mt-5  ps-5 pe-5`}>
                <div className='row justify-content-center'>
                  <h6 className={`text-center label_weight mb-1 mt-1`}>
                    Spouse's Name
                  </h6>
                  <div className='col-md-4'>
                    <p className={`text-center heading_input mb-0`}>
                      First Name
                    </p>
                    <Form.Item
                      name='spouseFName'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input className={`input_style3 formfield`} />
                    </Form.Item>
                  </div>
                  <div className='col-md-4'>
                    <p className={`text-center heading_input mb-0`}>
                      Middle Name
                    </p>

                    <Form.Item name='spouseMName'>
                      <Input className={`input_style3 formfield`} />
                    </Form.Item>
                  </div>
                  <div className='col-md-4'>
                    <p className={`text-center heading_input mb-0`}>
                      Last Name
                    </p>
                    <Form.Item
                      name='spouseLName'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input className={`input_style3 formfield`} />
                    </Form.Item>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <h6 className={`text-center label_weight mb-1 mt-1`}>
                    Spouse's Social Security Number?
                  </h6>
                  <div className='col-md-4'>
                    <Form.Item
                      name='spouseSSNumber'
                      rules={[
                        {
                          validator: validateBlankSpace,
                        },
                        { validator: number },
                        { validator: minLengthNine },
                      ]}
                    >
                      <Input
                        maxLength={9}
                        minLength={9}
                        className={`input_style3 formfield`}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <div className='col-md-6'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      Spouse's date of birth?
                    </h6>
                    <div className='d-flex justify-content-center text-center'>
                      <Form.Item
                        name='spouseDOB'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]}
                        getValueFromEvent={(onChange) =>
                          onChange == null
                            ? null
                            : moment(onChange).format(dateFormat)
                        }
                        getValueProps={(i) => ({
                          value: i == null ? null : moment(i),
                        })}
                      >
                        <DatePicker
                          disabledDate={(d: any) =>
                            !d || d.isBefore("01/01/1900")
                          }
                          format={dateFormat}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Has your spouse worked for the Railroad 5 years or more?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isSpouseWorked'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='row justify-content-center'>
                  <h6 className={`text-center label_weight mb-1 mt-1`}>
                    Place of marriage?
                  </h6>
                  <p className={`text-center heading_input mb-0`}>
                    City and state, or international country.
                  </p>
                  <div className='col-md-4'>
                    <Form.Item
                      name='placeOfMarriage'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input className={`input_style3 formfield`} />
                    </Form.Item>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <div className='col-md-6'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      Date of marriage?
                    </h6>
                    <div className='d-flex justify-content-center text-center'>
                      <Form.Item
                        name='dateOfMarriage'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]}
                        getValueFromEvent={(onChange) =>
                          onChange == null
                            ? null
                            : moment(onChange).format(dateFormat)
                        }
                        getValueProps={(i) => ({
                          value: i == null ? null : moment(i),
                        })}
                      >
                        <DatePicker
                          disabledDate={(d: any) =>
                            !d || d.isBefore("01/01/1900")
                          }
                          format={dateFormat}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Has your spouse worked outside the US?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isSpouseWorkedOutsideUS'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-5'>
                  <button
                    type='button'
                    onClick={handleBackButton}
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    BACK
                  </button>
                  <button
                    type='submit'
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
          {page === 6 && (
            <Form
              form={form}
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              onFinish={addUpdateApplicationFormStepSixApi}
              initialValues={appData}
            >
              <div className={`form_app mt-5  ps-5 pe-5`}>
                <div className='row justify-content-center align-items-end'>
                  <div className='col-md-4 text-center'></div>
                  <div className='col-md-4 text-center'>
                    <h6
                      className={`text-center text-center heading_input mb-0 mb-1 mt-1`}
                    >
                      Mother's Maiden Name
                    </h6>
                    {isMother == false ? (
                      <Form.Item
                        name='motherName'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          className={`input_style3 formfield`}
                          placeholder='Mother Name'
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item name='motherName1'>
                        <Input
                          className={`input_style3 formfield`}
                          disabled={true}
                          placeholder='Mother Name'
                        />
                      </Form.Item>
                    )}
                  </div>
                  <div className='col-md-4 text-center'>
                    <div className='form-group row align-items-baseline'>
                      <div className='col-1 p-0'>
                        <Form.Item name='isMother' valuePropName='checked'>
                          <Checkbox onChange={onChangeMother} className={``} />
                        </Form.Item>
                      </div>
                      <label className='col-form-label fill_description des_font col-8 ps-0 text-left'>
                        Not Applicable
                      </label>
                    </div>
                  </div>
                </div>
                <div className='row justify-content-center align-items-end'>
                  <div className='col-md-4 text-center'></div>
                  <div className='col-md-4 text-center'>
                    <h6
                      className={`text-center text-center heading_input mb-0 mb-1 mt-1`}
                    >
                      Father's Full Name
                    </h6>
                    {isFather == false ? (
                      <Form.Item
                        name='fatherName'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          className={`input_style3 formfield`}
                          placeholder='Father Name'
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item name='fatherName1'>
                        <Input
                          className={`input_style3 formfield`}
                          disabled={true}
                          placeholder='Father Name'
                        />
                      </Form.Item>
                    )}
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group row align-items-baseline'>
                      <div className='col-1 p-0'>
                        {isFather}
                        <Form.Item name='isFather' valuePropName='checked'>
                          <Checkbox onChange={onChangeFather} className={``} />
                        </Form.Item>
                      </div>
                      <label className='col-form-label fill_description des_font col-8 ps-0'>
                        Not Applicable
                      </label>
                    </div>
                  </div>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Any prior marriages
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isPriorMarriage'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Were you in the military prior to 1968?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isMillitary'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Filed or intend to file for workers' compensation or other
                  public disability benefits?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isAppliedPDB'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-5'>
                  <button
                    type='button'
                    onClick={handleBackButton}
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    BACK
                  </button>
                  <button
                    type='submit'
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
          {page === 7 && (
            <Form
              form={form}
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              onFinish={addUpdateApplicationFormStepSevenApi}
              initialValues={appData}
            >
              <div className={`form_app mt-5  ps-5 pe-5`}>
                <p className={`text-center heading_input mb-0`}>
                  Have you ever used any other name(s)?{" "}
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isOtherName'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e: any) => {
                        setIsOtherName(e.target.value);
                      }}
                      buttonStyle='solid'
                      value={true}
                    >
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                {isOtherName && (
                  <div className='row justify-content-center'>
                    <h6 className={`text-center label_weight`}>Other Names:</h6>
                    <div>
                      <Form.List name='otherNames'>
                        {(otherNames, { add, remove }) => {
                          return (
                            <div className='row'>
                              <div className='row col-md-10'>
                                {otherNames.map((field, index) => (
                                  <div
                                    key={field.key}
                                    className='col-md-4 mt-2'
                                  >
                                    <Form.Item
                                      name={[index]}
                                      rules={[
                                        {
                                          required: index == 0 ? true : false,
                                          message: "Other Name is Required",
                                        },
                                        {
                                          validator: validateBlankSpace,
                                        },
                                      ]}
                                    >
                                      <Input
                                        className={`input_style3 formfield`}
                                        placeholder='Other Name'
                                      />
                                    </Form.Item>
                                    {otherNames.length > 3 &&
                                    otherNames.length - 1 == index ? (
                                      <a onClick={() => remove(field.name)}>
                                        <MinusCircleOutlined
                                          style={{
                                            fontSize: "25px",
                                            color: "#747497",
                                          }}
                                        />
                                      </a>
                                    ) : null}
                                  </div>
                                ))}
                              </div>
                              <div className='  col-md-1'>
                                <Form.Item>
                                  <a onClick={() => add()}>
                                    <PlusCircleOutlined
                                      style={{
                                        fontSize: "25px",
                                        color: "#747497",
                                      }}
                                    />
                                  </a>
                                </Form.Item>
                              </div>
                            </div>
                          );
                        }}
                      </Form.List>
                    </div>
                  </div>
                )}
                <p className={`text-center heading_input mb-0`}>
                  Have you worked or will you work for an employer in 2023?{" "}
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isWorkInCurYear'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Were you self-employed in {previousYear}?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isSelfEmployedPrevYear'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={onChangeSelfEmployedPrevYear}
                      buttonStyle='solid'
                      value={true}
                    >
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                {
                  isSelfEmployedPrevYear && (
                    <div className='row justify-content-center'>
                      <h6 className={`text-center label_weight mb-1 mt-1`}>
                        When self-employed, how much did you earn in{" "}
                        {previousYear}?
                      </h6>
                      <div className='col-md-4'>
                        <Form.Item
                          name='moneyEarnedInPrevYear'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                            {
                              validator: validateBlankSpace,
                            },
                          ]}
                        >
                          <Input className={`input_style3 formfield`} />
                        </Form.Item>
                      </div>
                    </div>
                  )
                  // : <></>
                }
                <p className={`text-center heading_input mb-0`}>
                  Were you self-employed in {currentYear}?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isSelfEmployedCurYear'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={onChangeSelfEmployedNextYear}
                      buttonStyle='solid'
                      value={true}
                    >
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Any previous application(s) for Medicare, Social Security, or
                  Supplemental Security Income benefits?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isPriorApplications'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-5'>
                  <button
                    type='button'
                    onClick={handleBackButton}
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    BACK
                  </button>
                  <button
                    type='submit'
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
          {page === 8 && (
            <Form
              form={form}
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              onFinish={addUpdateApplicationFormStepEightApi}
              initialValues={appData}
            >
              <div className={`form_app mt-5  ps-5 pe-5`}>
                {isSelfEmployedCurYear ? (
                  <div className='row justify-content-center mt-2'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      When self-employed, how much did you earn in {currentYear}
                      ?
                    </h6>
                    <div className='col-md-4'>
                      <Form.Item
                        name='moneyEarnedInCurrYear'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input className={`input_style3 formfield`} />
                      </Form.Item>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <p className={`text-center heading_input mb-0`}>
                  Have you worked outside the US?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isWorkedOutsideUS'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Do you agree with the earnings history as shown on your Social
                  Security statement?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isAgreeWithEarningHistory'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Have you ever worked in a job where U.S. Social Security taxes
                  were not deducted or withheld ?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isWorkedTaxesNotDecucted'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Own or co-own a bank account to use for Direct Deposit?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isOwnBankAccount'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-5'>
                  <button
                    type='button'
                    onClick={handleBackButton}
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    BACK
                  </button>
                  <button
                    type='submit'
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
          {page === 9 && (
            <Form
              form={form}
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              onFinish={addUpdateApplicationFormStepNineApi}
              initialValues={appData}
            >
              <div className={`form_app mt-5  ps-5 pe-5`}>
                <p className={`text-center heading_input mb-0`}>
                  Have you ever used any other Social Security Number(s)?{" "}
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isOtherSSNumber'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='row justify-content-center'>
                  <h6 className={`text-center label_weight mb-1 mt-1`}>
                    Your height without shoes?
                  </h6>
                  <div className='col-md-4'>
                    <Form.Item
                      name='heightWithoutShoes'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Select
                        options={height.map((loc: any) => ({
                          label: loc.value,
                          value: loc.valueInches,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <h6 className={`text-center label_weight mb-1 mt-1`}>
                    Your weight without shoes?
                  </h6>
                  <div className='col-md-4'>
                    <Form.Item
                      name='weightWIthoutShoes'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Select
                        options={weight.map((loc: any) => ({
                          label: loc.value,
                          value: loc.valueInt,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Received money from employer on/after date unable to work?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isReceiveMoneyUnableToWork'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Expect to receive money from most recent employer in the
                  future?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isExpectMoneyFromRecentEmployer'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <p className={`text-center heading_input mb-0`}>
                  Has one parent who receives one-half support?
                </p>
                <div className='text-center'>
                  <Form.Item
                    name='isOneParent'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle='solid' value={true}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-5'>
                  <button
                    type='button'
                    onClick={handleBackButton}
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    BACK
                  </button>
                  <button
                    type='submit'
                    className={`btn btn-primary button_clients2 ms-3`}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </Form>
          )}
          {page === 10 && (
            <Form
              form={form}
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              onFinish={addUpdateApplicationFormStepTenApi}
              initialValues={priorMarriage}
            >
              <div className={`form_app mt-5  ps-5 pe-5`}>
                <div className='row justify-content-center'>
                  <div className='col-md-4'>
                    <p className={`text-center heading_input mb-0`}>
                      Prior Spouse FullName
                    </p>
                    <Form.Item
                      name='priorSpouseFullName'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input className={`input_style3 formfield`} />
                    </Form.Item>
                  </div>
                  <div className='col-md-4'>
                    <p className={`text-center heading_input mb-0`}>
                      Prior Spouse DOB
                    </p>
                    <Form.Item
                      name='priorSpouseDob'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                      getValueFromEvent={(onChange) =>
                        onChange == null
                          ? null
                          : moment(onChange).format(dateFormat)
                      }
                      getValueProps={(i) => ({
                        value: i == null ? null : moment(i),
                      })}
                    >
                      <DatePicker
                        disabledDate={(d: any) =>
                          !d || d.isBefore("01/01/1900")
                        }
                        format={dateFormat}
                      />
                    </Form.Item>
                  </div>
                  <div className='row justify-content-center'>
                    <div className='col-md-6'>
                      <h6 className={`text-center label_weight mb-1 mt-1`}>
                        Prior Date of Marriage
                      </h6>
                      <div className='d-flex justify-content-center text-center'>
                        <Form.Item
                          name='priorDoMarriage'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                          ]}
                          getValueFromEvent={(onChange) =>
                            onChange == null
                              ? null
                              : moment(onChange).format(dateFormat)
                          }
                          getValueProps={(i) => ({
                            value: i == null ? null : moment(i),
                          })}
                        >
                          <DatePicker
                            disabledDate={(d: any) =>
                              !d || d.isBefore("01/01/1900")
                            }
                            format={dateFormat}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className='row justify-content-center'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      Prior Marriage City
                    </h6>
                    <div className='col-md-4'>
                      <Form.Item
                        name='priorMarriageCity'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input className={`input_style3 formfield`} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='row justify-content-center'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      Prior Marriage State
                    </h6>
                    <div className='col-md-4'>
                      <Form.Item
                        name='priorMarriageState'
                        rules={[
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Select
                          options={location.map((loc: any) => ({
                            label: loc.name,
                            value: loc.name,
                          }))}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='row justify-content-center'>
                    <div className='col-md-6'>
                      <h6 className={`text-center label_weight mb-1 mt-1`}>
                        Date Of Divorce
                      </h6>
                      <div className='d-flex justify-content-center text-center'>
                        <Form.Item
                          name='dateOfDivorce'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                          ]}
                          getValueFromEvent={(onChange) =>
                            onChange == null
                              ? null
                              : moment(onChange).format(dateFormat)
                          }
                          getValueProps={(i) => ({
                            value: i == null ? null : moment(i),
                          })}
                        >
                          <DatePicker
                            disabledDate={(d: any) =>
                              !d || d.isBefore("01/01/1900")
                            }
                            format={dateFormat}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className='row justify-content-center'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      Divorce City
                    </h6>
                    <div className='col-md-4'>
                      <Form.Item
                        name='divorceCity'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input className={`input_style3 formfield`} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='row justify-content-center'>
                    <h6 className={`text-center label_weight mb-1 mt-1`}>
                      Divorce State
                    </h6>
                    <div className='col-md-4'>
                      <Form.Item
                        name='divorceState'
                        rules={[
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Select
                          options={location.map((loc: any) => ({
                            label: loc.name,
                            value: loc.name,
                          }))}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center align-items-center mt-5'>
                    <button
                      type='button'
                      onClick={handleBackButton}
                      className={`btn btn-primary button_clients2 ms-3`}
                    >
                      BACK
                    </button>
                    <button
                      type='submit'
                      className={`btn btn-primary button_clients2 ms-3`}
                    >
                      NEXT
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </>
      )}
    </>
  );
};

export default Application;
