import axios from "axios";

export function SaveChildrenInformation(payload: any) {
  return axios.post("ChildrenInformation", payload);
}
export function GetChildrenInformation(id: any) {
  return axios.get("ChildrenInformation?Id=" + id);
}
export function GetChildrenInformationById(id: any) {
  return axios.get("ChildrenInformationById?Id=" + id);
}
export function DeleteChildrenInformation(Id: any) {
  return axios.delete("ChildrenInformation?Id=" + Id);
}
export function UpdateChildrenInformation(payload: any) {
  return axios.put("ChildrenInformation", payload);
}
