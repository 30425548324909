import React from "react";
import "./index.scss";
import { useSearchParams } from "react-router-dom";

import Prequalification from "../../components/PreQualification";

const PreQualificationClient = () => {
  const [searchParams] = useSearchParams();
  return <Prequalification leadId={searchParams.get("leadId")} />;
};

export default PreQualificationClient;
