import axios from "axios";

export function GetSupportUsers(getInactiveAndActiveUsers = false) {
  return axios.get(
    `CustomerSupportUser?getInactiveAndActiveUsers=${getInactiveAndActiveUsers}`
  );
}
export function AddSupportUser(payload: any) {
  return axios.post("CustomerSupportUser", payload);
}
export function UpdateSupportUser(payload: any) {
  return axios.put("CustomerSupportUser", payload);
}
export function DeleteSupportUser(id: any) {
  return axios.delete("CustomerSupportUser?Id=" + id);
}
