import {AddGroupUser, DeleteGroupUser,GetGroupUser,GetGroupUserById, UpdateGroupUser,} from "../../apis/groupUsers";

export function addGroupUser(payload: any, form: any) {
    return AddGroupUser(payload, form);
  }

  export function getGroupUser() {
    return GetGroupUser();
  }

  export function updateGroupUser(payload: any) {
    return UpdateGroupUser(payload);
  }

export function deleteGroupUser(id: any) {
    return DeleteGroupUser(id);
  }

  export function getGroupUserById(id: any) {
    return GetGroupUserById(id);
  }