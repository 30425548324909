import {
  SaveNotification,
  GetAllNotifications,
  ClearNotification,
  ReadNotification,
  ClearAllNotifications,
} from "../../apis/notification";

export function saveNotification(payload: any, groupOfIds: any) {
  return SaveNotification(payload, groupOfIds);
}
export function getAllNotifications() {
  return GetAllNotifications();
}
export function clearNotification(id: any) {
  return ClearNotification(id);
}
export function readNotification(id: any) {
  return ReadNotification(id);
}
export function clearAllNotifications(){
  return ClearAllNotifications();
}
