import {
  SaveEmergencyContact,
  GetEmergencyContact,
  DeleteEmergencyContact,
  UpdateEmergencyContact,
  GetEmergencyContactById,
} from "../../apis/emergencyContact";

export function saveEmergencyContact(payload: any) {
  return SaveEmergencyContact(payload);
}
export function getEmergencyContact(id: any) {
  return GetEmergencyContact(id);
}
export function getEmergencyContactById(id: any) {
  return GetEmergencyContactById(id);
}
export function deleteEmergencyContact(Id: any) {
  return DeleteEmergencyContact(Id);
}
export function updateEmergencyContact(payload: any) {
  return UpdateEmergencyContact(payload);
}
