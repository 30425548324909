import { useState, useEffect } from "react";
import { Form, Input, Table, Select, DatePicker } from "antd";
import moment from "moment";
import { getAllActivityTypes, getActivityLogData } from "../../actions/logs";
import { FlashMessage } from "../../utils/flash_message";
import { Constants } from "../../common/constants";
import { validateEmail } from "../../validations/validateEmail";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import Loader from "../../components/Loader";
import useCanWriteAndEnforcePagePermissions from "../../common/customHooks/useCanWriteAndEnforcePagePermissions";

const Logs = () => {
  useCanWriteAndEnforcePagePermissions();
  const [form] = Form.useForm();
  const [isLoader, setIsLoader] = useState(false);
  const dateFormat = "MM/DD/YYYY";
  const [types, setTypes] = useState([]);
  const [logs, setLogs] = useState([]);
  const [emailEnbDis, setEmailEnbDis] = useState(null);
  const [activityByEnbDis, setActivityByEnbDis] = useState(null);

  const getAllActivityTypesApi = () => {
    getAllActivityTypes().then((res) => {
      setTypes(res.data.responseData);
    });
  };
  useEffect(() => {
    getAllActivityTypesApi();
    setEmailEnbDis(null);
    setActivityByEnbDis(null);
    console.log(emailEnbDis, "emailenableDis");
    console.log(activityByEnbDis, "activitybyenadis");
  }, []);
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created Date and Time",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (item: any, file: any) => {
        return moment.utc(item).local().format(Constants.dateTimeFormat);
      },
    },
    {
      title: "Activity Type",
      dataIndex: "type",
      key: "type",
    },
  ];
  const ActivityByChangeValue = (a: any) => {
    setActivityByEnbDis(a.target.value ? a.target.value : "");
  };
  const emailChangeVlaue = (e: any) => {
    console.log(e, "e");

    setEmailEnbDis(e.target.value ? e.target.value : "");
  };
  const getActivityLogDataApi = (e: any) => {
    console.log(e, "etg");
    setIsLoader(true);
    var email = e.email ? e.email : "";
    var activityTypeId = e.activityType ? e.activityType : "";
    var startDate = e.startDate ? e.startDate : "";
    var endDate = e.endDate ? e.endDate : "";
    var activityBy = e.activityBy ? e.activityBy : "";

    if (
      e.email == null &&
      e.activityType == null &&
      e.startDate == null &&
      e.endDate == null &&
      e.activityBy == null
    ) {
      FlashMessage("error", "It is required to fill atleast one field.");
      setIsLoader(false);
    } else {
      getActivityLogData(
        email,
        activityTypeId,
        startDate,
        endDate,
        activityBy
      ).then((res) => {
        setIsLoader(false);
        if (res.data.responseCode == 200) {
          setLogs(res.data.responseData);
          form.setFieldsValue(res.data.responseData);
        } else {
          setLogs([]);
          FlashMessage("error", res.data.responseMessage);
        }
      });
    }
  };
  const clear = () => {
    setLogs([]);
    setActivityByEnbDis(null);
    setEmailEnbDis(null);
    console.log(emailEnbDis, "emailenableDis213");
    console.log(activityByEnbDis, "activitybyenadis55");
  };
  return (
    <>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <>
          <Form
            className='mt-4'
            form={form}
            name='SupportUserForm'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            autoComplete='off'
            onFinish={getActivityLogDataApi}
          >
            <div className='col-md-12 margin-auto mb-5'>
              <Form.Item name='id' />
              <div className='mobile_responsive_field'>
                <div className='p-0'>
                  <Form.Item
                    name='email'
                    rules={[
                      { validator: validateEmail },
                      { validator: validateBlankSpace },
                    ]}
                  >
                    {activityByEnbDis == null || activityByEnbDis == "" ? (
                      <Input
                        name='email'
                        placeholder='Email'
                        onChange={emailChangeVlaue}
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    ) : (
                      <Input
                        name='email'
                        placeholder='Email'
                        disabled
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    )}
                  </Form.Item>
                </div>
                <div className='p-0 width_cust_act'>
                  <Form.Item
                    name='activityType'
                    rules={[{ validator: validateBlankSpace }]}
                  >
                    <Select
                      placeholder='Activity Type'
                      options={types.map((loc: any) => ({
                        label: loc.type,
                        value: loc.id,
                      }))}
                    />
                  </Form.Item>
                </div>

                <div className='p-0'>
                  <Form.Item
                    name='activityBy'
                    rules={[
                      { validator: validateEmail },
                      { validator: validateBlankSpace },
                    ]}
                  >
                    {emailEnbDis == null || emailEnbDis == "" ? (
                      <Input
                        name='email'
                        placeholder='Activity By'
                        onChange={ActivityByChangeValue}
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    ) : (
                      <Input
                        name='email'
                        placeholder='Activity By'
                        disabled
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    )}
                  </Form.Item>
                </div>

                <div className='p-0'>
                  <Form.Item
                    name='startDate'
                    getValueFromEvent={(onChange) =>
                      onChange == null
                        ? null
                        : moment(onChange).format(dateFormat)
                    }
                    getValueProps={(i) => ({
                      value: i == null ? null : moment(i),
                    })}
                  >
                    <DatePicker placeholder='From' format={dateFormat} />
                  </Form.Item>
                </div>

                <div className='p-0'>
                  <Form.Item
                    name='endDate'
                    getValueFromEvent={(onChange) =>
                      onChange == null
                        ? null
                        : moment(onChange).format(dateFormat)
                    }
                    getValueProps={(i) => ({
                      value: i == null ? null : moment(i),
                    })}
                  >
                    <DatePicker
                      placeholder='To'
                      format={dateFormat}
                      disabledDate={(d) =>
                        d.isBefore(form.getFieldValue("startDate"))
                      }
                    />
                  </Form.Item>
                </div>
                <div>
                  <button
                    type='submit'
                    className='btn btn-primary button_signup button_clients px-3 p-0 height_btn'
                  >
                    Search
                  </button>
                  <button
                    type='reset'
                    onClick={() => clear()}
                    className='btn btn-primary button_signup button_leads px-3 p-0 height_btn ms-3'
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </Form>
          <Table
            dataSource={logs}
            pagination={{
              pageSize: Constants.logsDefaultPageSize,
              showSizeChanger: false,
              showTotal: (total) => {
                return `Total Count:  ${total}`;
              },
            }}
            columns={columns}
          />
        </>
      )}
    </>
  );
};

export default Logs;
