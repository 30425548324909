import {
  DeleteClaim,
  GetClaimOptions,
  GetClaims,
  SaveClaim,
  SaveClaimPayload,
  UpdateClaim,
} from "../../apis/claims";

export function saveClaim(payload: SaveClaimPayload) {
  return SaveClaim(payload);
}

export function getClaims(claimantMindsetUserId: number) {
  return GetClaims(claimantMindsetUserId);
}

export function getClaimOptions() {
  return GetClaimOptions();
}

export function updateClaim(payload: any) {
  return UpdateClaim(payload);
}

export function deleteClaim(claimId: number) {
  return DeleteClaim(claimId);
}
