import {
  SaveChildrenInformation,
  GetChildrenInformation,
  DeleteChildrenInformation,
  UpdateChildrenInformation,
  GetChildrenInformationById,
} from "../../apis/childrenInformation";

export function saveChildrenInformation(payload: any) {
  return SaveChildrenInformation(payload);
}
export function getChildrenInformation(id: any) {
  return GetChildrenInformation(id);
}
export function getChildrenInformationById(id: any) {
  return GetChildrenInformationById(id);
}
export function deleteChildrenInformation(Id: any) {
  return DeleteChildrenInformation(Id);
}
export function updateChildrenInformation(payload: any) {
  return UpdateChildrenInformation(payload);
}
