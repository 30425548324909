import { Constants } from "../../common/constants";

export const validateGradeCompleted = (_: any, value: any) => {
  if (
    !value ||
    !Object.values(Constants.highestGradeCompleted).some(
      (grade) => grade.value === value
    )
  ) {
    return Promise.reject(new Error("Please input the value!"));
  }
  return Promise.resolve();
};
