import React, { useEffect, useState } from "react";
import "../index.scss";
import dwdtemp from "../../../images/download_temp.png";
import { AutoComplete, Modal, SelectProps, Table, Tooltip } from "antd";
import info from "../../../images/info.png";
import {
  getMedicationDetails,
  getMedicationDetailsById,
  deleteMedicationDetails,
  updateMedicationDetails,
  saveMedicationDetails,
} from "../../../actions/medicationDetails";
import { FlashMessage } from "../../../utils/flash_message";
import { useSearchParams } from "react-router-dom";
import {
  Form,
  Input,
  DatePicker,
  DatePickerProps,
  Menu,
  Dropdown,
  Select,
} from "antd";
import _uniqBy from "lodash/uniqBy";
import moment from "moment";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Constants } from "../../../common/constants";
import { getMedicalHistory } from "../../../actions/doctorsVisits";
import { validateBlankSpace } from "../../../validations/validateBlankSpace";
import Loader from "../../Loader";
import { useDebounce } from "use-debounce";
import {
  addMedicationName,
  searchMedicationNames,
} from "../../../actions/application";
import { DefaultOptionType } from "antd/lib/select";

interface MedicationName {
  id: number;
  name: string;
  approved: boolean;
}

const MedicationDetails = (props: any) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [physician, setPhysician] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [searchParams] = useSearchParams();
  const [appdata, setAppData] = useState({});
  const dateFormat = "MM/DD/YYYY";
  const [form] = Form.useForm();
  const [isPopUpLoader, setisPopUpLoader] = useState(false);
  const [medicationSearchQuery, setMedicationSearchQuery] = useState("");
  const [debouncedMedicationSearchQuery] = useDebounce(
    medicationSearchQuery,
    Constants.debounceTime
  );
  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);

  useEffect(() => {
    if (!openEdit) setMedicationSearchQuery("");
  }, [openEdit]);

  useEffect(() => {
    setOptions([
      {
        value: "",
        label: (
          <div style={{ display: "flex" }}>
            <span>Loading......</span>
          </div>
        ),
      },
    ]);
    searchResult((debouncedMedicationSearchQuery ?? "").trim());
  }, [debouncedMedicationSearchQuery]);

  const searchResult = (query: string) => {
    searchMedicationNames(query).then((res) => {
      var resData = res.data;
      var filterRecord = resData.filter(
        (item: MedicationName) => item.approved
      );

      if (filterRecord == null || filterRecord.length == 0) {
        setOptions([
          {
            value: query,
            label: (
              <div style={{ display: "flex" }}>
                <span>{query === "" ? "Please input value" : query}</span>
              </div>
            ),
          },
        ]);
      } else {
        setOptions(
          filterRecord.map((item: MedicationName) => {
            return {
              key: item.id,
              value: item.name,
              label: item.name,
            };
          })
        );
      }
    });
  };

  const handleTextLength = (item: any) => {
    if (item != null) {
      return (
        <Tooltip title={item}>
          {item.substring(0, 20) + (item.length > 20 ? "......" : "")}
        </Tooltip>
      );
    }
  };

  const compareByName = (a: DefaultOptionType, b: DefaultOptionType) => {
    const nameA = (a.value ?? "").toString().toUpperCase(); // ignore case
    const nameB = (b.value ?? "").toString().toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  const columns = [
    {
      title: "Physician Name",
      dataIndex: "physicianName",
      key: "physicianName",
    },
    {
      title: "Prescription Name",
      dataIndex: "prescriptionName",
      key: "prescriptionName",
      render: handleTextLength,
    },
    {
      title: "Prescription Start Date",
      dataIndex: "prescriptionStartDate",
      key: "prescriptionStartDate",
      render: (item: any, file: any) => {
        return item == null
          ? null
          : moment(item).format(Constants.dateFormat);
      },
    },
    {
      title: "Medication Details",
      dataIndex: "prescriptionDetails",
      key: "prescriptionDetails",
      render: handleTextLength,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return (
          <div className='d-flex'>
            <Tooltip title={"Edit"}>
              <a
                className='mr-2'
                onClick={() => getMedicationDetailsByIdApi(id)}
              >
                <EditFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title={"Delete"}>
              <a onClick={() => deleteMedicationDetailsApi(id)}>
                <DeleteFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const getMedicalHistoryApi = () => {
    var param = searchParams.get("id") ?? "0";
    getMedicalHistory(param).then((res) => {
      var primary = res.data.responseData;
      if (res.data.responseCode == 200) {
        setPhysician(primary);
      }
    });
  };
  const addUpdateMedicationDetailsApi = async (e: any) => {
    const resData = await searchMedicationNames(e.prescriptionName);
    const filterRecord = resData.data ?? [];
    let existingMedication = filterRecord.filter(
      (item: any) =>
        item.name.trim().toLowerCase() ===
        e.prescriptionName.trim().toLowerCase()
    )[0];

    if (!existingMedication) {
      existingMedication = (await addMedicationName(e.prescriptionName, false))
        .data;
    }
    var param = searchParams.get("id") ?? "0";
    var payload = {
      Id: isAdd ? 0 : e.id,
      PhysicianId: e.physicianId,
      MedicationId: existingMedication.id,
      PrescriptionDetails: e.prescriptionDetails,
      PrescriptionStartDate:
        e.prescriptionStartDate == null
          ? null
          : moment(e.prescriptionStartDate).format(Constants.dateFormatWithoutUtc),
      EncryptedUserId: param,
    };
    if (!isAdd) {
      setisPopUpLoader(true);
      updateMedicationDetails(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Medication Details updated Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          getMedicationDetailsApi();
        }
      });
    } else {
      setisPopUpLoader(true);
      saveMedicationDetails(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Medication Details added Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          props.TemplateCount();
        }
      });
    }
  };
  const deleteMedicationDetailsApi = (id: any) => {
    setisPopUpLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteMedicationDetails(id).then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", response.data.responseMessage);
          setisPopUpLoader(false);
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
          setisPopUpLoader(false);
        }
        getMedicationDetailsApi();
        props.TemplateCount();
      });
    } else setisPopUpLoader(false);
  };
  const getMedicationDetailsByIdApi = (id: any) => {
    setisPopUpLoader(true);
    setOpenEdit(true);
    getMedicalHistoryApi();
    getMedicationDetailsById(id).then((res) => {
      var eduData = res.data.responseData;

      if (res.data.responseCode == 200) {
        var prescriptionStartDateValueStore =
          eduData.prescriptionStartDate == null
            ? null
            : moment(eduData.prescriptionStartDate).format();
        eduData.prescriptionStartDate = prescriptionStartDateValueStore;
        setAppData(eduData);
        setMedicationSearchQuery(eduData.prescriptionName);
        form.setFieldsValue(eduData);
        setisPopUpLoader(false);
      }
    });
  };

  const getMedicationDetailsApi = () => {
    setisPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    var param = searchParams.get("id") ?? "0";
    getMedicationDetails(param).then((res) => {
      var med = res.data.responseData;
      if (res.data.responseCode == 200) {
        setDocumentData(med);
        setisPopUpLoader(false);
      } else {
        FlashMessage("error", res.data.responseMessage);
      }
    });
  };

  const formSubmit = () => {
    form.submit();
  };
  const addPopUp = () => {
    getMedicalHistoryApi();
    setIsAdd(true);
    setOpenEdit(true);
    resetFormValues();
  };
  const resetFormValues = () => {
    var formDefaultValues = {
      physicianId: "",
      prescriptionName: "",
      prescriptionDetails: "",
      isCurrentlyWorking: false,
      prescriptionStartDate: null,
    };
    setTimeout(() => {
      setAppData(formDefaultValues);
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };
  return (
    <>
      {openEdit && (
        <Modal
          title={isAdd ? "Add Medication Details" : "Update Medication Details"}
          centered
          okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
          open={openEdit}
          onOk={() => (isPopUpLoader ? null : formSubmit())}
          onCancel={() => setOpenEdit(false)}
          width={1000}
        >
          <>
            {isPopUpLoader && <Loader></Loader>}
            {!isPopUpLoader && (
              <>
                <p className='fill_description mt-2 text-center mb-1'>
                  Please enter medications prescribed by any of the doctors
                  you’ve provided in “Doctor Information” section.
                </p>

                <Form
                  className='mt-4'
                  form={form}
                  name='MedicationDetailsForm'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  initialValues={appdata}
                  autoComplete='off'
                  onFieldsChange={() =>
                    setMedicationSearchQuery(
                      form.getFieldValue("prescriptionName")
                    )
                  }
                  onFinish={addUpdateMedicationDetailsApi}
                >
                  <div className='d-flex mb-4 mobile_flex'>
                    <Form.Item name='id' />
                    <label className='col-form-label label_form_qs mr-1'>
                      a. Physician that prescribed the medication{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='physicianId'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Select
                          options={physician.map((loc: any) => ({
                            label: loc.physicianName,
                            value: loc.id,
                          }))}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs mr-1'>
                      b. Medication/Prescription Name{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='prescriptionName'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <AutoComplete
                          className='search_ant_drp'
                          dropdownStyle={{
                            textTransform: "capitalize",
                          }}
                          dropdownMatchSelectWidth={252}
                          options={
                            options
                              ? _uniqBy(options.sort(compareByName), (e: any) =>
                                e.label?.toString().toUpperCase().trim()
                              )
                              : []
                          }
                        >
                          <Input
                            placeholder='Search by medication name'
                            className='input_style text-capitalize'
                          />
                        </AutoComplete>
                      </Form.Item>
                    </div>
                  </div>

                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs mr-1'>
                      c. Medication Details{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='prescriptionDetails'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          maxLength={1000}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs mr-1'>
                      d. Prescription Start Date{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='prescriptionStartDate'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]}
                        getValueFromEvent={(onChange) =>
                          onChange == null
                            ? null
                            : moment(onChange).format(dateFormat)
                        }
                        getValueProps={(i) => ({
                          value: i == null ? null : moment(i),
                        })}
                      >
                        <DatePicker format={dateFormat} />
                      </Form.Item>
                    </div>
                  </div>
                  <p className='click_doc text-center'>
                    Click save to enter additional medication details
                  </p>
                </Form>
              </>
            )}
          </>
        </Modal>
      )}
      {open && (
        <Modal
          title='Medication Details'
          centered
          footer={null}
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={1000}
        >
          {isPopUpLoader && <Loader></Loader>}
          {!isPopUpLoader && (
            <Table
              dataSource={documentData}
              pagination={{
                pageSize: Constants.gridDefaultPageSize,
                showSizeChanger: false,
              }}
              columns={columns}
            />
          )}{" "}
        </Modal>
      )}

      <div className='col-md-12 col-xl-4 mt-3 template_panel'>
        <div className='background_sq_boxes height_temp_box p-4 text-center position-relative'>
          <div className='popover__wrapper2 position-absolute'>
            <img className='img-fluid info' src={info} />
            <div className='popover__content3'>
              <p className='popover__message2'>
                {" "}
                List the medications and which doctors prescribed them. You can
                only select doctors you have previously entered in “All Doctor
                Information”.
              </p>
            </div>
          </div>
          <img className='img-fluid dws_temp' src={dwdtemp} />
          <h5 className='boxes_headings mb-3 ps-5 pe-5'>Medication Details</h5>
          <button
            onClick={getMedicationDetailsApi}
            type='button'
            className={`btn btn-primary button_viewtemp`}
          >
            View
          </button>
          <button
            onClick={() => addPopUp()}
            type='button'
            className={`btn btn-primary button_edittemp mt-4`}
          >
            Add
          </button>
          {props.medicationDetailsCount == 1 ? (
            <p className='mr_record'>{props.medicationDetailsCount} record </p>
          ) : (
            <p className='mr_record'>{props.medicationDetailsCount} records</p>
          )}
        </div>
      </div>
    </>
  );
};

export default MedicationDetails;
