import React, { useState, useEffect } from "react";
import logo_admin from "../../images/logo_admin.png";
import bell_admin from "../../images/bell_admin.png";
import drp_admin from "../../images/drp_admin.png";
import styles from "./index.module.scss";
import { signOutUser } from "../../actions/accounts";
import {
  useNavigate,
  useSearchParams,
  useLocation,
  Link,
} from "react-router-dom";
import { remove } from "../../utils/clientStorageUtils";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getAllNotifications,
  clearNotification,
  readNotification,
  clearAllNotifications,
} from "../../actions/notification";
import { Constants } from "../../common/constants";
import { Button, Popover } from "antd";
import * as signalR from "@microsoft/signalr";
import moment from "moment";
import { get } from "../../utils/clientStorageUtils";
import { AnyListenerPredicate } from "@reduxjs/toolkit";
import useFeatureFlag from "../useFeatureFlag";
const voice = require("../../images/sounds/tn_tn.mp3");

const buttonWidth = 70;

const Header = () => {
  const [notification, setNotification] = useState("");
  const [length, setLength] = useState(0);

  const [isSignalRConnected, setIsSignalRConnected] = useState(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const featureFlags = useFeatureFlag().featureFlags;

  const signOutUserApi = () => {
    signOutUser()
      .then((response: any) => {})
      .catch((Error: any) => {});
    navigate("/login");
    remove("token");
    remove("username");
    remove("email");
    remove("id");
  };

  const roleInStorage = JSON.parse(get("model")).userRoles[0];

  const content = (
    <div>
      <span
        className='display_notification1 cursorPointer'
        onClick={() => clearAllNotificationsApi()}
      >
        {notification.length == 0 ? "No data" : "Clear All"}
      </span>
      {notification}
    </div>
  );

  const beep = (volume: any) => {
    return new Promise((resolve, reject) => {
      volume = volume || 100;
      try {
        let soundSource = voice;
        // if (typeof Audio != undefined) {
        let sound = new Audio(soundSource);
        sound.volume = volume / 100;
        sound.onended = () => {
          resolve(true);
        };
        sound.play();
        //}
      } catch (error) {
        reject(error);
      }
    });
  };

  const MAX_LIST = 99;

  const getAllNotificationsApi = () => {
    getAllNotifications().then((res) => {
      var resData = res.data.responseData;
      if (res.data.responseCode == 200) {
        setLength(resData.length > MAX_LIST ? MAX_LIST + `+` : resData.length);
        setNotification(
          resData.slice(0, MAX_LIST).map((ele: any) => {
            var dateandTime = moment
              .utc(ele.createdDate)
              .local()
              .format(Constants.dateTimeFormat);
            return (
              <div className='row notificationPanel'>
                <div
                  className='cursorPointer col-sm-10 me-0 pe-0'
                  onClick={() => readNotificationApi(ele.id, ele.route)}
                >
                  <div className='row'>
                    <div className='col-sm-12'> {dateandTime}</div>
                    <div
                      className={`col-sm-12 ${
                        !ele.isRead && `read-notification`
                      }`}
                    >
                      {ele.message}
                    </div>
                  </div>
                </div>
                <span
                  className='display_notification2 cursorPointer col-sm-2 ms-0 ps-0 mt-3 '
                  onClick={() => clearNotificationApi(ele.id)}
                >
                  <b>X</b>
                </span>
              </div>
            );
          })
        );
      }
    });
  };

  useEffect(() => {
    getAllNotificationsApi();
  }, []);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL ?? ""}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("TagNotification", (res: any) => {
      const loginIdInStorage = JSON.parse(get("model")).userId;
      if (res.filter((a: any) => a == loginIdInStorage).length > 0) {
        (document.getElementById("playBtn") as HTMLFormElement).click();
        getAllNotificationsApi();
      }
    });
    connection.start().catch((err) => console.log(err));
    return () => {
      connection.off("TagNotification");
      connection.stop().catch((err) => console.log("connection Stop Error"));
    };
  }, [searchParams.get("id")]);

  const clearNotificationApi = (id: any) => {
    clearNotification(id).then((res) => {
      // readNotificationApi(id);
      getAllNotificationsApi();
      // navigate(route);
    });
  };
  const clearAllNotificationsApi = () => {
    clearAllNotifications().then((res) => {
      getAllNotificationsApi();
    });
  };
  const readNotificationApi = (id: any, route: any) => {
    readNotification(id).then((res) => {
      console.log(res, "jjfhjhr");
      getAllNotificationsApi();
      navigate(route);
    });
  };
  return (
    <section id='admin_application_header'>
      <button id='playBtn' className='d-none' onClick={() => beep(30)}></button>
      <div className='container-fluid p-3'>
        <div className={`bg_row row m-0 align-items-center`}>
          <div className='col-md-4'>
            <img
              onClick={() => navigate("/leads")}
              className={styles?.admin_logo}
              src={logo_admin}
              alt=''
            />
          </div>
          <div className='col-md-4 text-center'>
            <h5 className={`${styles?.heading_app} mb-0`}>
              {location.pathname.indexOf(Constants.pageRoutes.application) >=
              0 ? (
                <span>Application</span>
              ) : location.pathname.indexOf(Constants.pageRoutes.templates) >=
                0 ? (
                <span>Templates</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.uploadDocuments
                ) >= 0 ? (
                <span>Documents</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.requestedInfo
                ) >= 0 ? (
                <span>Request Info</span>
              ) : location.pathname.indexOf(Constants.pageRoutes.notes) >= 0 ? (
                <span>Notes</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.prequalification
                ) >= 0 ? (
                <span>Pre Qualification</span>
              ) : location.pathname.indexOf(Constants.pageRoutes.clients) >=
                0 ? (
                <span>Claimants</span>
              ) : location.pathname.indexOf(Constants.pageRoutes.leads) >= 0 ? (
                <span>Leads</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.notificationTemplates
                ) >= 0 ? (
                <span>Notification Templates</span>
              ) : location.pathname.indexOf(Constants.pageRoutes.tenants) >=
                0 ? (
                <span>Tenants</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.notificationconfiguration
                ) >= 0 ? (
                <span>Notification Configuration</span>
              ) : location.pathname.indexOf(Constants.pageRoutes.manageUser) >=
                0 ? (
                <span>Support Users</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.hearingCalendar
                ) >= 0 ? (
                <span>Hearings Calendar</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.changePassword
                ) >= 0 ? (
                <span>Change Password</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.globaltaskscreen
                ) >= 0 ? (
                <span>Tasks</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.caseManagement
                ) >= 0 ? (
                <span>Case Management</span>
              ) : location.pathname.indexOf(Constants.pageRoutes.logs) >= 0 ? (
                <span>Logs</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.manageMedication
                ) >= 0 ? (
                <span>Manage Medications</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.sendPhysicalMail
                ) >= 0 ? (
                <span>Send Physical Mail</span>
              ) : location.pathname.indexOf(
                  Constants.pageRoutes.generateDocuments
                ) >= 0 ? (
                <span>Generate Documents</span>
              ) : location.pathname.indexOf(Constants.pageRoutes.sendFax) >=
                0 ? (
                <span>Send Fax</span>
              ) : (
                <span>Application</span>
              )}
            </h5>
            <Popover
              id='notifications-popover'
              placement='bottom'
              // title={text}
              content={content}
              trigger='click'
            >
              <a>
                <div className='circle_count'>{length}</div>
                <img className={styles?.admin_bell} src={bell_admin} alt='' />
              </a>
            </Popover>
          </div>
          <div className='col-md-4 d-flex align-items-center justify-content-end'>
            <h5
              className={`${styles?.heading_app} mb-0 cursorPointer`}
              onClick={signOutUserApi}
            >
              LOG OUT
            </h5>

            <Dropdown className='ms-3'>
              <Dropdown.Toggle
                variant='success'
                id='dropdown-basic'
                className='border-none-class p-0 header_bg'
              >
                <img
                  className={`img-fluid ${styles?.arrow_image_drp}`}
                  src={drp_admin}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className=''>
                <Dropdown.Item className='item_drp'>
                  <Link
                    to={"/changePassword"}
                    className='heading_tab w-100 d-block'
                    rel='noopener noreferrer'
                  >
                    Change Password
                  </Link>
                </Dropdown.Item>
                {Constants.permissionedRolesBySection.manageUser.readGroup.includes(
                  roleInStorage
                ) && (
                  <Dropdown.Item className='item_drp'>
                    <Link
                      to={"/manageUser"}
                      className='heading_tab w-100 d-block'
                      rel='noopener noreferrer'
                    >
                      Support User
                    </Link>
                  </Dropdown.Item>
                )}
                {Constants.permissionedRolesBySection.tenantManagement.readGroup.includes(
                  roleInStorage
                ) && (
                  <Dropdown.Item className='item_drp'>
                    <Link
                      to={"/tenants"}
                      className='heading_tab w-100 d-block'
                      rel='noopener noreferrer'
                    >
                      Manage Enterprise
                    </Link>
                  </Dropdown.Item>
                )}
                {Constants.permissionedRolesBySection.tenantManagement.readGroup.includes(
                  roleInStorage
                ) && (
                  <Dropdown.Item className='item_drp'>
                    <Link
                      to={"/facilities"}
                      className='heading_tab w-100 d-block'
                      rel='noopener noreferrer'
                    >
                      Manage Facilities
                    </Link>
                  </Dropdown.Item>
                )}
                {Constants.permissionedRolesBySection.manageMedication.readGroup.includes(
                  roleInStorage
                ) && (
                  <Dropdown.Item className='item_drp'>
                    <Link
                      to={"/manageMedication"}
                      className='heading_tab w-100 d-block'
                      rel='noopener noreferrer'
                    >
                      Manage Medication
                    </Link>
                  </Dropdown.Item>
                )}
                {Constants.permissionedRolesBySection.hearingCalendar.readGroup.includes(
                  roleInStorage
                ) &&
                  featureFlags[
                    Constants.featureFlags.hearingCalendarFeatureFlag
                  ] && (
                    <Dropdown.Item className='item_drp'>
                      <Link
                        to={"/hearingCalendar"}
                        className='heading_tab w-100 d-block'
                        rel='noopener noreferrer'
                      >
                        Hearing Calendar
                      </Link>
                    </Dropdown.Item>
                  )}
                {Constants.permissionedRolesBySection.manageFeatureFlagSettings.readGroup.includes(
                  roleInStorage
                ) && (
                  <Dropdown.Item className='item_drp'>
                    <Link
                      to={`/${Constants.pageRoutes.featureFlagSettings}`}
                      className='heading_tab w-100 d-block'
                      rel='noopener noreferrer'
                    >
                      Feature Flags
                    </Link>
                  </Dropdown.Item>
                )}

                {Constants.permissionedRolesBySection.notificationTemplate.readGroup.includes(
                  roleInStorage
                ) && (
                  <Dropdown.Item className='item_drp'>
                    <Link
                      to={"/notificationTemplates"}
                      className='heading_tab w-100 d-block'
                      rel='noopener noreferrer'
                    >
                      Notification Template
                    </Link>
                  </Dropdown.Item>
                )}
                {Constants.permissionedRolesBySection.notificationConfiguration.readGroup.includes(
                  roleInStorage
                ) && (
                  <Dropdown.Item className='item_drp'>
                    <Link
                      to={"/notificationconfiguration"}
                      className='heading_tab w-100 d-block'
                      rel='noopener noreferrer'
                    >
                      Notification Configuration
                    </Link>
                  </Dropdown.Item>
                )}
                {Constants.permissionedRolesBySection.groupUser.readGroup.includes(
                  roleInStorage
                ) && (
                  <Dropdown.Item className='item_drp'>
                    <Link
                      to={"/managegroupuser"}
                      className='heading_tab w-100 d-block'
                      rel='noopener noreferrer'
                    >
                      Manage Group Users
                    </Link>
                  </Dropdown.Item>
                )}
                {Constants.permissionedRolesBySection.logs.readGroup.includes(
                  roleInStorage
                ) && (
                  <Dropdown.Item className='item_drp'>
                    <Link
                      to={"/logs"}
                      className='heading_tab w-100 d-block'
                      rel='noopener noreferrer'
                    >
                      Logs
                    </Link>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
