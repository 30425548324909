import {
    AddHearingCalendarEvent,
    DeleteHearingCalendarEvent,
    GetHearingCalendarEvents,
    UpdateHearingCalendarEvent,
  } from "../../apis/hearingCalendarEvents";
  
  export function addHearingCalendarEvent(payload: any) {
    return AddHearingCalendarEvent(payload);
  }
  export function getHearingCalendarEvents() {
    return GetHearingCalendarEvents();
  }
  export function updateHearingCalendarEvent(payload: any) {
    return UpdateHearingCalendarEvent(payload);
  }
  export function deleteHearingCalendarEvent(id: any) {
    return DeleteHearingCalendarEvent(id);
  }