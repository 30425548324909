import { createSlice } from '@reduxjs/toolkit'
import { loginApiService } from './auth.thunk'

export const authSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    loading: false,
    error: false,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    /* reducers for managing login api service */
    builder.addCase(loginApiService.pending, (state, action) => {
      state.error = false;
      state.data = [];
      state.loading = true;
    })
    builder.addCase(loginApiService.rejected, (state, action) => {
      state.error = true;
      state.data = [];
      state.loading = false;
    })
    builder.addCase(loginApiService.fulfilled, (state, action) => {
      state.error = false;
      state.data = action.payload
      state.loading = false
    })   
    
    // you can add reducers for another api services here

  },
})
export default authSlice.reducer