import React from "react";
import { Form, DatePicker } from "antd";
import { Moment } from "moment";
import moment from "moment";
import { antdNoEmptyRule } from "../../../../validations/validateBlankSpace";

export default function FromDate() {
  const disabledDate = (current: Moment): boolean =>
    current && current >= moment().add(1, "days").startOf("day");

  return (
    <Form.Item
      label='From Date'
      name='fromDate'
      rules={[
        {
          required: true,
          message: "Please input from date",
        },
        antdNoEmptyRule,
      ]}
    >
      { <DatePicker format='MM/DD/YYYY' disabledDate={disabledDate} /> }
    </Form.Item>
  );
}
