import React from "react";
import OHOAddressInput from "./SharedFields/AddressWithoutNameField";
import { DatePicker, Form, Input } from "antd";
import { antdNoEmptyRule } from "../../../validations/validateBlankSpace";

const FiveDayLetter = () => {
  return (
    <>
      <div className='row'>
        <label className='col-form-label'>
          <strong>OHO Address</strong>
          <OHOAddressInput />
        </label>
      </div>
      <div className='row'>
        <Form.Item
          label="Judge's First and Last Name"
          rules={[{ required: true }, antdNoEmptyRule]}
          name='judgeName'
        >
          <Input placeholder='Enter Name of Judge' />
        </Form.Item>
      </div>
      <div className='row'>
        <Form.Item
          label='Hearing Date'
          rules={[{ required: true }, antdNoEmptyRule]}
          name='hearingDate'
        >
          <DatePicker format={"MM/DD/YYYY"} />
        </Form.Item>
      </div>
      <div className='row'>
        <Form.Item
          label='Waiting On Records From... (Comma Separated)'
          rules={[{ required: true }, antdNoEmptyRule]}
          name='medicalProviders'
        >
          <Input placeholder='Medical Provider 1, Medical Provider 2' />
        </Form.Item>
      </div>
    </>
  );
};

export default FiveDayLetter;
