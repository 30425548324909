import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Table, Tooltip } from "antd";
import { Constants } from "../../common/constants";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { MentionsInput, Mention } from "react-mentions";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import {
  saveNotificationTemplate,
  getPlaceholders,
  getNotificationTemplate,
  getNotificationTemplateById,
  updateNotificationTemplate,
  deleteNotificationTemplate,
  getNotificationConfigByTemplate,
} from "../../actions/notificationTemplate";
import { FlashMessage } from "../../utils/flash_message";
import defaultStyle from "../../components/SaveNotes/defaultStyle";
import defaultMentionStyle from "../../components/SaveNotes/defaultMentionStyle";
import Loader from "../../components/Loader";
import useCanWriteAndEnforcePagePermissions from "../../common/customHooks/useCanWriteAndEnforcePagePermissions";

const NotificationTemplates = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [tagsText, setTagsText] = useState("");
  const [plainText, setPlainText] = useState("");
  const [plainTextSubject, setPlainTextSubject] = useState("");
  const [open, setOpen] = useState(false);
  const [templateConfigOption, setTemplateConfigOption] = useState(false);
  const [user, setUser] = useState([]);
  const [userById, setUserById] = useState({});
  const [configName, setConfigName] = useState([]);
  const [placeholder, setPlaceholder] = useState([]);
  const [form] = Form.useForm();
  const [isLoader, setIsLoader] = useState(false);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const [usersId, setUsersId] = useState({});
  const canWrite = useCanWriteAndEnforcePagePermissions();
  const handleChangeSingle = (
    e: any,
    newValue: any,
    newPLainTextValue: any,
    mentions: any
  ) => {
    var desc = "";
    var tagUsers = "";
    desc = newValue;
    if (mentions.length > 0) {
      for (var i = 0; i < mentions.length; i++) {
        var replaceText =
          "@[" +
          mentions[i].display.split("@").join("").split(":").join("") +
          "](" +
          mentions[i].id +
          ")";
        desc = desc.split(replaceText).join("");
        tagUsers = tagUsers + mentions[i].id;
        if (i != mentions.length - 1) tagUsers = tagUsers + ",";
      }
    }
    setPlainText(newPLainTextValue);
    setTagsText(newValue);
  };

  const handleChangeSubject = (
    e: any,
    newValue: any,
    newPLainTextValue: any,
    mentions: any
  ) => {
    var desc = "";
    var tagUsers = "";
    desc = newValue;
    if (mentions.length > 0) {
      for (var i = 0; i < mentions.length; i++) {
        var replaceText =
          "@[" +
          mentions[i].display.split("@").join("").split(":").join("") +
          "](" +
          mentions[i].id +
          ")";
        desc = desc.split(replaceText).join("");
        tagUsers = tagUsers + mentions[i].id;
        if (i != mentions.length - 1) tagUsers = tagUsers + ",";
      }
    }
    setPlainTextSubject(newPLainTextValue);
    setTagsText(newValue);
  };

  const formSubmit = () => {
    form.submit();
  };

  const addPopUp = () => {
    getPlaceholdersApi();
    setIsAdd(true);
    setOpen(true);
    resetFormValues();
  };

  const resetFormValues = () => {
    var formDefaultValues = {
      name: "",
      content: "",
      subject: "",
    };
    setTimeout(() => {
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };
  const handleTextLength = (item: any) => {
    if (item != null) {
      return (
        <Tooltip title={item}>
          {item.substring(0, 20) + (item.length > 20 ? "......" : "")}
        </Tooltip>
      );
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: handleTextLength,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        if (canWrite) {
          return (
            <>
              <div className='d-flex'>
                <Tooltip title={"Edit"}>
                  <a
                    className='mr-2'
                    onClick={() => getNotificationTemplateByIdApi(id)}
                  >
                    <EditFilled
                      style={{
                        fontSize: "25px",
                        color: "#747497",
                      }}
                    />
                  </a>
                </Tooltip>
                <Tooltip title={"Delete"}>
                  <a
                    className='mr-2'
                    onClick={() => deleteNotificationTemplateApi(id)}
                  >
                    <DeleteFilled
                      style={{
                        fontSize: "25px",
                        color: "#747497",
                      }}
                    />
                  </a>
                </Tooltip>
              </div>
            </>
          );
        }
      },
    },
  ];

  const columns1 = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Pipeline",
      dataIndex: "pipelineName",
      key: "pipelineName",
    },
    {
      title: "Status",
      dataIndex: "pipelineStatusName",
      key: "pipelineStatusName",
    },
    {
      title: "Trigger (Days)",
      dataIndex: "triggerDays",
      key: "triggerDays",
    },
    {
      title: "Trigger (Hours)",
      dataIndex: "triggerHours",
      key: "triggerHours",
    },
  ];

  const cancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const addUpdateNotificationTemplateApi = (e: any) => {
    setIsLoader(true);
    var payload = {
      Id: isAdd ? 0 : e.id,
      Name: e.name,
      Content: plainText,
      Subject: plainTextSubject,
    };
    if (isAdd) {
      setIsPopUpLoader(true);
      saveNotificationTemplate(payload).then((res) => {
        if (res.data.responseCode == 200) {
          setIsPopUpLoader(false);
          FlashMessage("success", "Notification Template added Successfully.");
          setOpen(false);
          getNotificationTemplateApi();
        }
      });
    } else {
      setIsPopUpLoader(true);
      updateNotificationTemplate(payload).then((res) => {
        if (res.data.responseCode == 200) {
          setIsPopUpLoader(false);
          FlashMessage(
            "success",
            "Notification Template updated Successfully."
          );
          setOpen(false);
          getNotificationTemplateApi();
        }
      });
    }
  };

  useEffect(() => {
    getNotificationTemplateApi();
  }, []);

  const getNotificationTemplateApi = () => {
    setIsLoader(true);
    setIsAdd(false);
    getNotificationTemplate().then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        setIsLoader(false);
        setUser(userdata);
      }
    });
  };
  const deleteNotificationTemplateApi = (id: any) => {
    setIsLoader(true);
    var userss = user
      .filter((a: any) => a.id == id)
      .map((x: any) => {
        return x.id;
      });
    setUsersId(userss);
    if (window.confirm("Are you sure you want to delete this record?")) {
      getNotificationConfigByTemplate(id).then((res) => {
        if (res.data.responseCode == 200) {
          if (res.data.responseData.length > 0) {
            var configname = res.data.responseData;
            setConfigName(configname);
            var test = configname.map((ele: any) => {
              return ele.name;
            });
            setTemplateConfigOption(true);
            setIsLoader(false);
          } else {
            deleteNotificationTemplate(id).then((response) => {
              if (response.data.responseCode == 200) {
                setIsLoader(false);

                FlashMessage("success", response.data.responseMessage);
              } else if (response.data.responseCode != 200) {
                setIsLoader(false);
                FlashMessage("error", response.data.responseMessage);
              }
              getNotificationTemplateApi();
            });
          }
        }
      });
    } else {
      setIsLoader(false);
    }
  };

  const deleteTemplateApi = (id: any) => {
    setTemplateConfigOption(false);
    deleteNotificationTemplate(id).then((response) => {
      if (response.data.responseCode == 200) {
        setIsLoader(false);

        FlashMessage("success", response.data.responseMessage);
      } else if (response.data.responseCode != 200) {
        setIsLoader(false);
        FlashMessage("error", response.data.responseMessage);
      }
      getNotificationTemplateApi();
    });
  };

  const getNotificationTemplateByIdApi = (id: any) => {
    setIsPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    getNotificationTemplateById(id).then((res) => {
      var appRes = res.data.responseData;
      setIsPopUpLoader(false);
      setPlainText(appRes.content);
      setPlainTextSubject(appRes.subject);
      setUserById(appRes);
      form.setFieldsValue(appRes);
      getPlaceholdersApi();
    });
  };

  const getPlaceholdersApi = () => {
    getPlaceholders().then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        var filterData = userdata.map((ele: any) => ({
          id: ele.id,
          display: `${ele.placeholder}`,
        }));
      }
      setPlaceholder(filterData);
    });
  };

  return (
    <>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <>
          {" "}
          <Modal
            title={
              isAdd
                ? "Add Notification Template"
                : "Update Notification Template"
            }
            centered
            okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
            open={open}
            onOk={() => (isPopUpLoader ? null : formSubmit())}
            onCancel={() => cancel()}
            width={1000}
          >
            {isPopUpLoader && <Loader></Loader>}
            {!isPopUpLoader && (
              <Form
                className='mt-4'
                form={form}
                name='NotificationTemplateForm'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={userById}
                autoComplete='off'
                onFinish={addUpdateNotificationTemplateApi}
              >
                <div>
                  <Form.Item name='id' />
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      1. Name
                    </label>
                    <Form.Item
                      name='name'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      2. Subject
                    </label>
                    <Form.Item
                      className='subject_input'
                      name='subject'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <MentionsInput
                        style={defaultStyle}
                        value={tagsText}
                        onChange={handleChangeSubject}
                        singleLine={true}
                        allowSpaceInQuery={true}
                      >
                        <Mention
                          style={defaultMentionStyle}
                          displayTransform={(id: any, name: any) => `@${name}:`}
                          trigger='@'
                          data={placeholder}
                        />
                      </MentionsInput>
                    </Form.Item>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      3. Content
                    </label>
                    <div className='col-sm-12'>
                      <Form.Item
                        className='textarea_ant'
                        name='content'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <MentionsInput
                          style={defaultStyle}
                          value={tagsText}
                          onChange={handleChangeSingle}
                          singleLine={false}
                          allowSpaceInQuery={true}
                        >
                          <Mention
                            style={defaultMentionStyle}
                            displayTransform={(id: any, name: any) =>
                              `@${name}:`
                            }
                            trigger='@'
                            data={placeholder}
                          />
                        </MentionsInput>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Modal>
          <Modal
            open={templateConfigOption}
            onCancel={() => setTemplateConfigOption(false)}
            onOk={() => deleteTemplateApi(usersId)}
            width={1000}
          >
            <>
              <p>
                "This template is already being used,Do you still wish to delete
                this?<br></br>
                Press OK to delete "{" "}
              </p>
              <Table
                dataSource={configName}
                pagination={{
                  pageSize: Constants.logsDefaultPageSize,
                  showSizeChanger: false,
                }}
                columns={columns1}
              />
            </>
          </Modal>
          <div className='d-flex justify-content-end'>
            <div className='col-md-4 mb-4'>
              <div className='d-flex justify-content-end mt-5'>
                {canWrite && (
                  <button
                    type='button'
                    onClick={() => addPopUp()}
                    className='btn btn-primary button_signup button_clients button_add_padding px-5'
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
          <Table
            dataSource={user}
            pagination={{
              pageSize: Constants.logsDefaultPageSize,
              showSizeChanger: false,
              showTotal: (total) => {
                return `Total Count:  ${total}`;
              },
            }}
            columns={columns}
          />
        </>
      )}
    </>
  );
};

export default NotificationTemplates;
