import React from "react";
import { Form, Input, Button, Row, Col, Spin } from "antd";
import { FlashMessage } from "../../utils/flash_message";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { validatePassword } from "../../validations/validatePassword";
import { resetPassword } from "../../actions/accounts";
import { useEffect, useState } from "react";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? "0";
  const submitHandler = (formValues: any) => {
    formValues["id"] = id ?? "0";
    resetPassword(formValues)
      .then((response) => {
        if (response.data.responseCode == 200) {
          if (response.data.responseData) {
            FlashMessage("success", response.data.responseMessage);
            redirectUserAfterEmailSuccess(3000);
          }
        }
      })
      .catch((error) => {});
  };

  const redirectUserAfterEmailSuccess = (time: any) => {
    setTimeout(() => {
      navigate("/login");
    }, time);
  };

  return (
    <>
      <Row className={`login_bg formContainer justify-content-center`}>
        <Col
          span={8}
          xs={18}
          md={18}
          lg={8}
          className={`login_form formFieldContainer`}
        >
          <Form
            className=''
            name='basic'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={submitHandler}
            autoComplete='off'
          >
            <h4 className='heading_app text-center mb-4'>Reset Password</h4>

            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: "Please input the value!",
                },
                { validator: validatePassword },
              ]}
            >
              <Input.Password
                className={`password_check`}
                placeholder='Password'
              />
            </Form.Item>

            <Form.Item
              name='confirmPassword'
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "New password does not match the confirm password!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                className={`password_check`}
                placeholder='Confirm Password'
              />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }} className='text-center mt-4'>
              <Button
                type='primary'
                className='btn btn-primary button_login'
                htmlType='submit'
              >
                Change Password
              </Button>

              <Button
                onClick={() => navigate("/login")}
                className='btn-secondary-black fill ms-3 cancel_form'
                htmlType='button'
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default ResetPassword;
