import React from "react";
import { Form, Input } from "antd";
import AddressWithoutNameField from "./AddressWithoutNameField";
import { antdNoEmptyRule } from "../../../../validations/validateBlankSpace";

interface AddressWithNameFieldProps {
  label?: string;
}

const AddressWithNameField = ({
  label = "Address Name",
}: AddressWithNameFieldProps) => {
  return (
    <>
      <Form.Item
        label={label}
        name='addressName'
        rules={[
          {
            required: true,
            message: "Please input the name of the address",
          },
          antdNoEmptyRule,
        ]}
      >
        <Input placeholder='Enter Name' />
      </Form.Item>
      <AddressWithoutNameField />
    </>
  );
};

export default AddressWithNameField;
