import {
  GetMarriageInfo,
  SaveMarriageInfo,
  GetMarriageInfoById,
  UpdateMarriageInfo,
  DeleteMarriageInfo,
} from "../../apis/marriageInfo";

export function saveMarriageInfo(payload: any) {
  return SaveMarriageInfo(payload);
}
export function getMarriageInfo(id: any) {
  return GetMarriageInfo(id);
}
export function getMarriageInfoById(id: any) {
  return GetMarriageInfoById(id);
}
export function deleteMarriageInfo(Id: any) {
  return DeleteMarriageInfo(Id);
}
export function updateMarriageInfo(payload: any) {
  return UpdateMarriageInfo(payload);
}
