import React from "react";
import { Form, Input } from "antd";
import { antdNoEmptyRule } from "../../../../validations/validateBlankSpace";

interface NameInputProps {
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
}

const NameInput = ({ label, name, placeholder="EnterName", required=true }: NameInputProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: placeholder,
        },
        antdNoEmptyRule
      ]}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  );
};

export default NameInput;
