/* eslint-disable */
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { FlashMessage } from "../utils/flash_message";
import { ExpireTokens } from "../utils/expire_tokens";
import { Constants } from "../common/constants";
import { Enums } from "../common/enums";
import { constants } from "zlib";

const setUpInterceptor = (store: any) => {
  const handleError = async (error: AxiosError) => {
    const response = error.response;
    if (response && response.status === 401) {
      // go to login view
      ExpireTokens();
      window.location.href = "/" + Constants.pageRoutes.login;
    } else {
      if (response && response.status !== 404) {
        FlashMessage("error", Constants.errorMessages.commonErrorMessage);
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  };
  axios.interceptors.request.use(async (config: any | AxiosRequestConfig) => {
    config.baseURL = (process.env.REACT_APP_API_URL ?? "") + "/api";
    localStorage.getItem("accessToken")
      ? (config.headers.Authorization =
          "Bearer " + localStorage.getItem("accessToken"))
      : null,
      (config.headers.ACCESS_CONTROL_ALLOW_ORIGIN = "*");
    //config.header.Accept="application/json"
    return config;
  });

  axios.interceptors.response.use((response) => response, handleError);
};

export default setUpInterceptor;
