import { Table, Tooltip } from "antd";
import { Constants } from "../../../common/constants";
import moment from "moment";
import { Enums } from "../../../common/enums";
import { getOnlyShowMyCasesFromSearchParams } from "../../../utils/commonHelper";
import { useSearchParams } from "react-router-dom";

export const openInNewTab = (url: any) => {
  window.open(url);
};

const GlobalTasksTableV1 = ({ dataSource }: { dataSource: any[] }) => {
  const [searchParams] = useSearchParams();

  const columns = [
    {
      title: "Created date",
      className: "des-2-pr-3",
      key: "id",
      render: (item: any, file: any) => {
        return (
          <>
            {moment
              .utc(file.createdDate)
              .local()
              .format(Constants.dateTimeFormat)}
          </>
        );
      },
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      className: "des-2-pr-2",
      key: "dueDate",
      sorter: (a: any, b: any) => {
        a = a.dueDate || "";
        b = b.dueDate || "";
        return a.localeCompare(b);
      },
      render: (item: any, file: any) => {
        return (
          <>
            {file.leadsData.map((l: any) => {
              return l.id != null && l.fullName != "" ? (
                <Tooltip title={l.fullName}>
                  <span
                    onClick={() => openInNewTab("/leadsnotes?id=" + l.id)}
                    className='cursorPointer'
                  >
                    {l.fullName}
                    <span>
                      <b> - </b>
                    </span>
                    {file.typeName}
                    <span>
                      <b> - </b>
                    </span>
                    {moment(item).format(Constants.dateTimeFormat)}
                  </span>
                </Tooltip>
              ) : (
                []
              );
            })}
            {file.mindsetUsers.map((m: any) => {
              return m.encryptedId != null && m.fullName != "" ? (
                <Tooltip title={m.fullName}>
                  <span
                    onClick={() => {
                      const isMobileTask =
                        (file.statusId === Enums.taskStatus.inProgress ||
                          file.statusId === Enums.taskStatus.underReview) &&
                        file.typeId === Enums.taskType.messageRequest;
                      const taskRoute = isMobileTask
                        ? "requestedinfo"
                        : "clientsnotes";
                      openInNewTab(
                        `/${taskRoute}?id=${
                          m.encryptedId
                        }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
                          searchParams
                        )}`
                      );
                    }}
                    className='cursorPointer'
                  >
                    {m.fullName}
                    <span>
                      <b> - </b>
                    </span>
                    {file.typeName}
                    <span>
                      <b> - </b>
                    </span>
                    {moment(item).format(Constants.dateTimeFormat)}
                  </span>
                </Tooltip>
              ) : (
                []
              );
            })}
          </>
        );
      },
    },
  ];

  return (
    <Table
      //dataSource={filteredTask}
      className='data-table-ant'
      dataSource={dataSource}
      pagination={{
        pageSize: Constants.logsDefaultPageSize,
        showSizeChanger: false,
        showTotal: (total) => {
          return `Total Tasks:  ${total}`;
        },
      }}
      columns={columns}
    />
  );
};

export default GlobalTasksTableV1;
