import React from "react";
import { Form, InputNumber } from "antd";
import { antdNoEmptyRule } from "../../../../validations/validateBlankSpace";

interface DollarInputProps {
  label: string;
  placeholder?: string;
  name: string; 
  required?: boolean;
}

const DollarField = ({
  label,
  placeholder = "Enter Dollar Amount",
  name,
  required = true,
}: DollarInputProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: placeholder,
        },
        antdNoEmptyRule,
      ]}
    >
      <InputNumber
        className={`ant-input`}
        controls={false}
        min={0}
        onWheel={ event => event.currentTarget.blur()} //removing scroll wheel behavior
        placeholder={placeholder}
        prefix="$"
        type="number"
      />
    </Form.Item>
  );
};

export default DollarField;
