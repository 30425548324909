import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Checkbox, Form, Alert } from "antd";
import axios from "axios";
import { AllPdfDocuments } from "../../apis/documents";
import { FlashMessage } from "../../utils/flash_message";
import { Select, InputNumber, Input } from "antd";
import "./index.scss";
import useCanWriteAndEnforcePagePermissions from "../../common/customHooks/useCanWriteAndEnforcePagePermissions";
import Loader from "../../components/Loader";
import PhoneField from "../GenerateDocuments/TemplateForms/SharedFields/PhoneField";
import { antdPhoneNumberRule } from "../../validations/numeric_only-validate";

type DocumentOptionType = {
  value: number;
  label: string;
  documentType: number;
};

const SendFax = () => {
  useCanWriteAndEnforcePagePermissions();
  const [faxForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isFaxSending, setIsFaxSending] = useState(false);
  const [searchParams] = useSearchParams();
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<number[]>([]);
  const [documentOptions, setDocumentOptions] = useState<DocumentOptionType[]>(
    []
  );

  useEffect(() => {
    const id = searchParams.get("id") ?? "0";
    AllPdfDocuments(id).then((r) => {
      setIsLoading(false);
      if (r.data.responseData.length < 1) {
        return;
      }

      setDocumentOptions(
        r.data.responseData.map(
          (item: { id: number; name: string; documentTypeId: number }) => {
            return {
              value: item.id,
              label: item.name,
              documentType: item.documentTypeId,
            };
          }
        )
      );
    });
  }, [setDocumentOptions, setSelectedDocumentIds]);

  const submitFaxForm = (formData: any) => {
    setIsFaxSending(true);
    const selectedDocuments = documentOptions
      .filter((document) => selectedDocumentIds.includes(document.value))
      .map(({ value, documentType }: DocumentOptionType) => ({
        DocumentId: value,
        DocumentType: documentType,
      }));

    axios
      .post("SendFax", {
        EncryptedToUserId: searchParams.get("id") ?? "0",
        FaxNumber: formData.faxToNumber.toString(),
        PhoneNumber: formData.phoneNumber.toString(),
        ForceResend: formData.forceResend ? true : false,
        InstitutionName: formData.faxToName,
        DocumentList: selectedDocuments,
      })
      .then((res) => {
        faxForm.resetFields();
        FlashMessage(
          "success",
          <div>
            <span>
              Your fax has been scheduled! When the fax is delivered or has an
              error it's status will be posted in{" "}
            </span>
            <a
              href='https://mindsetcare.slack.com/archives/C063C4TTA57'
              target='_blank'
              rel='noopener noreferrer'
            >
              this slack channel
            </a>
            ,
          </div>,
          "",
          5
        );
      })
      .catch((error) => {
        let fixDocusignSignatureTutorialLink = <></>;
        if (
          error.response.data.responseMessage
            .toLowerCase()
            .includes("Remove the docusign headers".toLocaleLowerCase())
        ) {
          fixDocusignSignatureTutorialLink = (
            <a
              href='https://www.loom.com/share/4e1e5e2bb2ea48e98b5a9f057912bae3?sid=d0d6a985-091e-46db-9665-879bc35ab246'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
          );
        }
        FlashMessage(
          "error",
          <div>
            <span>{error.response.data.responseMessage}</span>
            {fixDocusignSignatureTutorialLink}
          </div>,
          "",
          0
        );
      })
      .finally(() => {
        setIsFaxSending(false);
      });
  };

  return (
    (!isLoading && (
      <section id='MindsetAdminTemplates' className='mt-5'>
        <div className='row'>
          <div className='col'>
            <h6 className='label_weight mb-4'>Send Fax</h6>
            {documentOptions.length < 1 && (
              <div>
                <Alert
                  message="No PDFs were found in the claimant's documents. Please add a PDF file to the
                claimant's documents folder to send a fax."
                  type='warning'
                  showIcon
                />
              </div>
            )}
            {documentOptions.length > 0 && (
              <Form form={faxForm} onFinish={submitFaxForm} layout='vertical'>
                <div className='row'>
                  <Form.Item
                    label={"Documents To Fax"}
                    rules={[
                      {
                        required: true,
                        message: "Please select documents to fax",
                      },
                    ]}
                    name='faxDocuments'
                  >
                    <Select
                      className='ant-select'
                      showSearch={false}
                      mode='multiple'
                      options={documentOptions}
                      placeholder={`Please select documents to fax`}
                      onChange={(e) => {
                        setSelectedDocumentIds(e);
                      }}
                      value={selectedDocumentIds}
                    />
                  </Form.Item>
                </div>
                <div className='row mt-2 fax-name'>
                  <Form.Item
                    label={"Fax To:"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter the fax addressee",
                      },
                    ]}
                    name='faxToName'
                  >
                    <Input
                      autoFocus
                      placeholder='Please enter the fax address'
                    />
                  </Form.Item>
                </div>
                <div className='row justify-content-start'>
                  <PhoneField
                    label='Fax Number'
                    name='faxToNumber'
                    placeholder='Please enter a fax #'
                  />
                </div>
                <div className='row justify-content-start'>
                  <Form.Item
                    className='w-50'
                    label='Phone Number'
                    rules={[
                      {
                        required: true,
                        message: "Please enter the phone number",
                      },
                      antdPhoneNumberRule,
                    ]}
                    validateTrigger='onBlur'
                    name='phoneNumber'
                  >
                    <InputNumber
                      className='ant-input w-100'
                      placeholder='Please input FO phone number'
                      controls={false}
                      type='number'
                    />
                  </Form.Item>
                </div>
                <div className='row mt-3'>
                  <Form.Item name='forceResend'>
                    <Checkbox className='mb-3'>Force resend</Checkbox>
                  </Form.Item>
                </div>
                <div className='row mt-1 justify-content-center'>
                  <Button
                    className='w-30'
                    type='primary'
                    htmlType='submit'
                    loading={isFaxSending}
                    disabled={!selectedDocumentIds.length}
                  >
                    Send Fax
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </section>
    )) || <Loader />
  );
};

export default SendFax;
