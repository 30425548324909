import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import CustomerHeader from "../../components/CustomerHeader";
import Leads from "../../pages/Leads";
import { BackTop } from 'antd';
import Clients from "../../pages/Clients";

const LeadsLayout = () => {
  return (
    <>
      <Header />
      <section id='admin_application_section'>
        <div className='container-fluid pl-3 pr-3 pb-3 pt-0'>
          <div className='row m-0 bg_row align-items-start'>
            <CustomerHeader />

            <div className='row'>
              <Outlet />
            </div>
          </div>
        </div>
        <div className="back-top-con">
                        <BackTop />

                      
                    </div>
      </section>
    </>
  );
};

export default LeadsLayout;
