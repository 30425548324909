import Header from "../../components/Header";
import CustomerHeader from "../../components/CustomerHeader";
import LeadsLeftPanel from "../../components/LeadsLeftPanel";
import CustomerNotes from "../../components/SaveNotes";
import { BackTop } from 'antd';
import { Outlet } from "react-router-dom";

const LeadsDetailLayout = () => {
  return (
    <>
      <Header />
      <section id='admin_application_section'>
        <div className='container-fluid pl-3 pr-3 pb-3 pt-0'>
          <div className='row m-0 bg_row align-items-start'>
            <CustomerHeader />
            <div className="col-12 col-md-auto left_column">
              <LeadsLeftPanel />
            </div>
            <div className="col right_column">
              <CustomerNotes IsLeads={true} />
              <Outlet />
            </div>
          </div>
        </div>
        <div className="back-top-con">
          <BackTop />
        </div>
      </section>
    </>
  );
};

export default LeadsDetailLayout;
