import {
  SaveEducationHistory,
  GetEducationHistory,
  DeleteEducationHistory,
  GetEducationHistoryById,
  UpdateEducationHistory,
} from "../../apis/highSchoolInfo";

export function saveEducationHistory(payload: any) {
  return SaveEducationHistory(payload);
}
export function getEducationHistory(id: any) {
  return GetEducationHistory(id);
}
export function deleteEducationHistory(Id: any) {
  return DeleteEducationHistory(Id);
}
export function getEducationHistoryById(id: any) {
  return GetEducationHistoryById(id);
}
export function updateEducationHistory(payload: any) {
  return UpdateEducationHistory(payload);
}
