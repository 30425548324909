import {
  SaveWorkHistory,
  GetWorkHistory,
  DeleteWorkHistory,
  UpdateWorkHistory,
  GetWorkHistoryById,
  AddUpdateRequestedInfo,
  GetRequestedInfoById,
  GetTemplateCount,
} from "../../apis/workHistory";

export function saveWorkHistory(payload: any) {
  return SaveWorkHistory(payload);
}
export function getWorkHistory(id: any) {
  return GetWorkHistory(id);
}
export function getWorkHistoryById(id: any) {
  return GetWorkHistoryById(id);
}
export function deleteWorkHistory(Id: any) {
  return DeleteWorkHistory(Id);
}
export function updateWorkHistory(payload: any) {
  return UpdateWorkHistory(payload);
}
export function getTemplateCount(id: any) {
  return GetTemplateCount(id);
}
