const appUserRoles = {
  superUser: "SuperAdmin",
  admin: "GeneralAdmin",
  caseManager: "CaseManager",
  customerSupportService: "CustomerSupportService",
  intakeMsr: "IntakeMSR",
  preQualMsr: "PreQualMSR",
  lawyer: "Lawyer",
};

const allGroup = [
  appUserRoles.superUser,
  appUserRoles.admin,
  appUserRoles.caseManager,
  appUserRoles.customerSupportService,
  appUserRoles.intakeMsr,
  appUserRoles.preQualMsr,
  appUserRoles.lawyer,
];
const adminGroup = [
  appUserRoles.superUser,
  appUserRoles.admin,
  appUserRoles.lawyer,
];

const adminManageGroupUser = [appUserRoles.superUser, appUserRoles.admin];
const userPermissions = [
  appUserRoles.superUser,
  appUserRoles.admin,
  appUserRoles.intakeMsr,
];
const caseManagerGroup = [
  appUserRoles.superUser,
  appUserRoles.caseManager,
  appUserRoles.admin,
];
const managerGroup = [...adminGroup, appUserRoles.caseManager];
const customerSupportGroup = [
  ...managerGroup,
  appUserRoles.customerSupportService,
];
const noPreQualMsrGroup = allGroup.filter(
  (item) => item !== appUserRoles.preQualMsr
);

const notQualifiedReason = [
  {
    id: 1,
    name: "Not Accepted by Mindset",
  },
  {
    id: 2,
    name: "Released Prior to Registration",
  },
  {
    id: 3,
    name: "Longterm Sentence",
  },
  {
    id: 4,
    name: "Already Has Representation",
  },
  {
    id: 5,
    name: "Transferred to another facility",
  },
  {
    id: 6,
    name: "No show",
  },
  {
    id: 7,
    name: "Rescheduled",
  },
  {
    id: 8,
    name: "Already Receiving Benefits",
  },
  {
    id: 9,
    name: "Above Age Limit",
  },
  {
    id: 10,
    name: "Weak Case/Lack of Treatment",
  },
  {
    id: 11,
    name: "Changed Mind/Not Interested",
  },
  {
    id: 12,
    name: "Looking for SSA",
  },
  {
    id: 13,
    name: "Over Income Limit",
  },
  {
    id: 14,
    name: "Active Claim Pending at Appeals Council (AC)",
  },
];

export const Constants = {
  appUserRoles,
  adminGroup,
  tokenExpirationMinutes: 30,
  dateFormat: "MM/DD/YYYY",
  monthYearFormat: "MM/YYYY",
  ByDefaultTimeInNote: "09:00:00",
  TimeFormate: "h:mm:ss A",
  // dateTimeFormat: "MM/DD/YYYY HH:mm:ss",
  dateTimeFormat: "LLL",
  gridDefaultPageSize: 5,
  logsDefaultPageSize: 25,
  notificationConfigurationPageSize: 100,
  googleAutoCompleteStaticId: "Predefined Place ID",
  defaultNoteTypeValue: "11",
  dateFormatWithoutUtc: "YYYY-MM-DDTHH:mm:ss",
  defaultTypeTaskLabel: "Task",
  tabletTenantCode: "homeWav202020",
  defaultTypeTaskId: "2",
  messages: {
    // unFollowRepository: "Do you want to unfollow this repository?",
  },
  userRoles: {
    businessMember: "BusinessMember",
    admin: "Admin",
    endUser: "EndUser",
    visitor: "Visitor",
    businessAdmin: "BusinessAdmin",
    coachAdmin: "CoachAdmin",
    customer: "Customer",
  },
  errorMessages: {
    commonErrorMessage: "Something went wrong!",
    emailAlreadyExists: "Email Already Exists",
    applicationErrorMessages: {
      noInputValue: "Please input the value!",
      lessThanNineDigits: "Value can't be less than 9 digits",
    },
  },
  pageRoutes: {
    application: "application",
    templates: "templates",
    uploadDocuments: "uploaddocuments",
    requestedInfo: "requestedinfo",
    notes: "notes",
    prequalification: "prequalification",
    prequalificationclient: "prequalificationclient",
    clients: "client",
    leads: "leads",
    login: "login",
    clientsnotes: "clientsnotes",
    leadNotes: "leadsnotes",
    logs: "logs",
    notificationTemplates: "notificationTemplates",
    notificationconfiguration: "notificationconfiguration",
    sendFax: "sendFax",
    sendPhysicalMail: "sendphysicalmail",
    generateDocuments: "generateDocuments",
    tenants: "tenants",
    manageUser: "manageUser",
    changePassword: "changePassword",
    caseManagement: "casemanagement",
    globaltaskscreen: "globaltaskscreen",
    managegroupuser: "managegroupuser",
    manageMedication: "manageMedication",
    featureFlagSettings: "featureFlagSettings",
    hearingCalendar: "hearingCalendar",
    facilities: "facilities",
  },
  debounceTime: 500,
  permissionedRolesBySection: {
    claimantNotes: {
      readGroup: noPreQualMsrGroup,
      writeGroup: noPreQualMsrGroup,
    },
    leadNotes: {
      readGroup: allGroup,
      writeGroup: allGroup,
    },
    claimantProfileSensitiveData: {
      readGroup: noPreQualMsrGroup,
      writeGroup: noPreQualMsrGroup,
    },
    sendFax: {
      readGroup: managerGroup,
      writeGroup: managerGroup,
    },
    generateDocuments: {
      readGroup: managerGroup,
      writeGroup: managerGroup,
    },
    sendPhysicalMail: {
      readGroup: managerGroup,
      writeGroup: managerGroup,
    },
    manageUser: {
      readGroup: noPreQualMsrGroup,
      writeGroup: customerSupportGroup,
    },
    caseManagement: {
      readGroup: noPreQualMsrGroup,
      writeGroup: adminGroup,
    },
    tenantManagement: {
      readGroup: allGroup,
      writeGroup: customerSupportGroup,
    },
    notificationTemplate: {
      readGroup: noPreQualMsrGroup,
      writeGroup: managerGroup,
    },
    notificationConfiguration: {
      readGroup: noPreQualMsrGroup,
      writeGroup: managerGroup,
    },
    logs: {
      readGroup: adminGroup,
      writeGroup: adminGroup,
    },
    groupUser: {
      readGroup: adminManageGroupUser,
      writeGroup: adminManageGroupUser,
    },
    manageMedication: {
      readGroup: allGroup,
      writeGroup: adminGroup,
    },
    manageFeatureFlagSettings: {
      readGroup: adminGroup,
      writeGroup: adminGroup,
    },
    uploadDocuments: {
      readGroup: allGroup,
      writeGroup: allGroup,
    },
    templates: {
      readGroup: allGroup,
      writeGroup: allGroup,
    },
    facilities: {
      readGroup: allGroup,
      writeGroup: allGroup,
    },
    requestedInfo: {
      readGroup: allGroup,
      writeGroup: allGroup,
    },
    hearingCalendar: {
      readGroup: managerGroup,
      writeGroup: managerGroup,
    },
  },
  caseDefaultPageSize: 15,
  clientsDefaultPageSize: 15,
  leadsDefaultPageSize: 15,
  caseManagerGroup,
  medicationsDefaultPageSize: 15,
  adminManageGroupUser,
  userPermissions,
  featureFlags: {
    Test: "Test",
    medicalRequestsFlag: "Medical Records Requests",
    hearingCalendarFeatureFlag: "HearingCalendar",
    claimantAccountCreationFromLeadFeatureFlag:
      "ClaimantAccountCreationFromLead",
  },
  hearingCalendar: {
    meetingTypes: [
      { value: 0, label: "Audio" },
      { value: 1, label: "Video" },
      { value: 2, label: "In-person" },
    ],

    hearingTypes: [
      { value: 0, label: "Hearing" },
      { value: 1, label: "Pre-Hearing" },
    ],
    scheduledHearingTaskId: 2,
  },
  highestGradeCompleted: {
    neverAttended: {
      label: "Never attended",
      value: "0",
    },
    preK: {
      label: "Pre-K",
      value: "Pre-K",
    },
    kindergarten: {
      label: "Kindergarten",
      value: "Kindergarten",
    },

    firstGrade: {
      label: "1st Grade",
      value: "1",
    },
    secondGrade: {
      label: "2nd Grade",
      value: "2",
    },
    thirdGrade: {
      label: "3rd Grade",
      value: "3",
    },
    fourthGrade: {
      label: "4th Grade",
      value: "4",
    },
    fifthGrade: {
      label: "5th Grade",
      value: "5",
    },
    sixthGrade: {
      label: "6th Grade",
      value: "6",
    },
    seventhGrade: {
      label: "7th Grade",
      value: "7",
    },
    eighthGrade: {
      label: "8th Grade",
      value: "8",
    },
    ninthGrade: {
      label: "9th Grade",
      value: "9",
    },
    tenthGrade: {
      label: "10th Grade",
      value: "10",
    },
    eleventhGrade: {
      label: "11th Grade",
      value: "11",
    },
    twelfthGrade: {
      label: "12th Grade",
      value: "12",
    },
  },
  applicationStatus: {
    QualifiedIANeedsFiling: 78,
    PendingReview: 5,
    PendingRegistration: 97,
  },
  notQualifiedReason: notQualifiedReason.sort((a, b) =>
    a.name.localeCompare(b.name)
  ),
};
