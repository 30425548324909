import { useState } from "react";
import BrandButton from "../../BrandButton";
import { get } from "../../../utils/clientStorageUtils";
import { saveClaim } from "../../../actions/claims";
import { DatabaseOption } from "./Claims/constants";
import axios from "axios";

const CompleteRegistration = ({
  claimTypes,
  claimantMindsetUserId,
  onSuccess,
  isAllowed,
}: {
  claimTypes: DatabaseOption[];
  claimantMindsetUserId: number;
  onSuccess: Function;
  isAllowed: boolean;
}) => {
  const userIdData = JSON.parse(get("model")).userId;
  const [isLoading, setIsLoading] = useState(false);
  const selfMindsetUserId = JSON.parse(get("model")).userId;

  const createClaims = async () => {
    setIsLoading(true);
    await Promise.all(
      claimTypes.map((claimType) => {
        const payload = {
          ClaimTypeId: claimType.id,
          ClaimantMindsetUserId: claimantMindsetUserId,
          RegisteredByMindsetUserId: userIdData,
          RegisteredDateTimeUtc: new Date().toISOString(),
          CaseLevelId: 1,
          initialApplicationOriginId: 1,
          IsEligible: true,
        };
        return saveClaim(payload);
      })
    );
    const {
      data: { responseData },
    } = await axios.get(
      `ClaimantAssignment?claimantMindsetUserId=${claimantMindsetUserId}`
    );

    if (responseData) {
      if (!responseData.intakeSpecialistMindsetUserId) {
        await axios.patch(
          `ClaimantAssignment?claimantMindsetUserId=${claimantMindsetUserId}`,
          {
            id: responseData.id,
            IntakeSpecialistMindsetUserId: selfMindsetUserId,
            version: responseData.version,
          }
        );
      }
    } else {
      await axios.post(`ClaimantAssignment`, {
        ClaimantMindsetUserId: claimantMindsetUserId,
        IntakeSpecialistMindsetUserId: selfMindsetUserId,
      });
    }
    await onSuccess();
    setIsLoading(false);
  };

  if (!isAllowed) {
    return (
      <p>
        Once rep forms are signed, an Intake Specialist can complete
        registration of the claim(s).
      </p>
    );
  }

  return (
    <>
      <p>Click Complete Registration once registration is complete.</p>
      <div className='d-flex justify-content-between align-items-center'>
        <BrandButton
          isLoading={isLoading}
          label='Complete Registration'
          onClick={createClaims}
          dataCy='claims-registration-btn'
        />
      </div>
    </>
  );
};

export default CompleteRegistration;
