import {
  AddSupportUser,
  UpdateSupportUser,
  GetSupportUsers,
  DeleteSupportUser,
} from "../../apis/supportUser";

export function addSupportUser(payload: any) {
  return AddSupportUser(payload);
}
export function updateSupportUser(payload: any) {
  return UpdateSupportUser(payload);
}
export function getSupportUsers(getInactiveAndActiveUsers = false) {
  return GetSupportUsers(getInactiveAndActiveUsers);
}
export function deleteSupportUser(id: any) {
  return DeleteSupportUser(id);
}
