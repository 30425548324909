import { isLoggedIn } from "../utils/localstorage";
import { set } from "../utils/clientStorageUtils";
import { Constants } from "../common/constants";
const PrivateRoute = ({ children }: any) => {
  const loggedIn = isLoggedIn();
  if (!loggedIn) {
    set("originalUrl", window.location.pathname + window.location.search);
    window.location.href = "/" + Constants.pageRoutes.login;
  }

  return <div>{children}</div>;
};

export default PrivateRoute;
