import React, { useState, useEffect } from "react";
import { Form, Select, InputNumber } from "antd";

import { getNoteOptions } from "../../actions/notes";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { Enums } from "../../common/enums";
import { Constants } from "../../common/constants";

interface NotesMetaDataProps {
  mode: "Create" | "Edit" | "Claims";
  noteType: string[] | undefined;
  setNoteType: Function;
  noteMinutes: number | undefined;
  setNoteMinutes: Function;
  noteSource: string | undefined;
  setNoteSource: Function;
  noteCategory: string | undefined;
  setNoteCategory: Function;
  setInitialValue?: Function;
  noteId?: any;
  noteById?: any;
}

const NotesMetaData: React.FC<NotesMetaDataProps> = ({
  mode,
  noteType,
  setNoteType,
  noteMinutes,
  setNoteMinutes,
  noteSource,
  setNoteSource,
  noteCategory,
  setNoteCategory,
  setInitialValue,
  noteId,
  noteById,
}: NotesMetaDataProps) => {
  const [loading, setLoading] = useState(true);
  const [noteTypeOptions, setNoteTypeOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [noteSourceOptions, setNoteSourceOptions] = useState<
    { id: string; value: string; label: string }[]
  >([]);
  const [noteCategoryOptions, setNoteCategoryOptions] = useState<
    { id: string; value: string; label: string }[]
  >([]);

  const initialValueType =
    mode === "Create" || mode === "Claims" ? undefined : "Legacy";
  // const initialValues =
  //   mode === "Claims"
  //     ? {
  //         type: noteType,
  //         source: noteSource,
  //         category: noteCategory,
  //         minutes: noteMinutes,
  //       }
  //     : {};

  const initialValues = {};
  const [defaultType, setDefaultType] = useState({
    value: noteById?.type,
    label: Constants.defaultTypeTaskLabel,
    isDisabled: false,
  });
  const getNoteOptionsApi = () => {
    setLoading(true);
    getNoteOptions().then((res) => {
      if (res.data.responseCode === 200) {
        setNoteTypeOptions(
          res.data.noteTypes
            .slice(2)
            .map((noteType: { id: number; value: string }) => ({
              id: noteType.id,
              value: noteType.id,
              label: noteType.value,
            }))
        );
        setNoteSourceOptions(
          res.data.noteSourceTypes
            .slice(1)
            .map((noteSourceType: { id: number; value: string }) => ({
              id: noteSourceType.id,
              value: noteSourceType.id,
              label: noteSourceType.value,
            }))
        );
        setNoteCategoryOptions(
          res.data.noteCategoryTypes
            .slice(1)
            .map((noteCategoryType: { id: number; value: string }) => ({
              id: noteCategoryType.id,
              value: noteCategoryType.id,
              label: noteCategoryType.value,
            }))
        );
        if (mode === "Edit" && setInitialValue) {
          setInitialValue({
            type:
              noteType === res.data.noteTypes[0].id
                ? undefined
                : noteById?.type === Constants.defaultTypeTaskId
                ? defaultType
                : noteType,
            source:
              noteSource === res.data.noteSourceTypes[0].id
                ? undefined
                : noteSource,
            category:
              noteCategory === res.data.noteCategoryTypes[0].id
                ? undefined
                : noteCategory,
          });
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getNoteOptionsApi();
  }, []);

  // useEffect(() => {
  //   if (!loading) {
  //     console.log("SET INITIAL VALUES");
  //     console.log(noteCategory, noteSource, noteMinutes);
  //     setNoteCategory(noteCategory);
  //     setNoteSource(noteSource);
  //     setNoteMinutes(noteMinutes);
  //   }
  // }, [loading]);

  const handleChangeNoteType = (e: any) => {
    if (e.filter((a: any) => a == Constants.defaultNoteTypeValue).length > 0) {
      if (mode == "Create" && setInitialValue) {
        var formValues = {
          minutes: Enums.minutesType.minutes,
          source: Enums.sourceType.outboundNoAnswer,
          category: Enums.categoryType.failedContactAttempt,
        };
        setNoteCategory(formValues.category);
        setNoteSource(formValues.source);
        setNoteMinutes(formValues.minutes);
        setInitialValue(formValues);
      }
    } else if (mode == "Create" && setInitialValue) {
      if (setInitialValue) {
        setInitialValue({
          minutes: null,
          source: null,
          category: null,
        });
      }
    }
  };
  return !loading ? (
    <div className='d-flex gap-2 mt-2 flex-wrap'>
      <Form.Item
        name='type'
        rules={[
          {
            required: true,
            message: "Please input the value!",
          },
          { validator: validateBlankSpace },
        ]}
      >
        {noteById?.type == Constants.defaultTypeTaskId &&
        noteById?.id == noteId ? (
          <Select
            showSearch
            mode='multiple'
            placeholder='Select type'
            style={{ width: "260px" }}
            optionFilterProp='children'
            // defaultValue={initialValues.type ? initialValues.type : undefined}
            onChange={(e: string[]) => {
              setNoteType(e);
              handleChangeNoteType(e);
            }}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.trim().toLowerCase())
            }
            options={noteTypeOptions}
            disabled={!defaultType.isDisabled}
          />
        ) : (
          <Select
            showSearch
            mode='multiple'
            placeholder='Select type'
            style={{ width: "260px" }}
            optionFilterProp='children'
            // defaultValue={initialValues.type ? initialValues.type : undefined}
            onChange={(e: string[]) => {
              setNoteType(e);
              handleChangeNoteType(e);
            }}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.trim().toLowerCase())
            }
            options={noteTypeOptions}
          />
        )}
      </Form.Item>
      {(noteById != undefined || noteId != undefined) &&
      noteById.id == noteId ? (
        <></>
      ) : (
        <Form.Item
          name='minutes'
          rules={[
            {
              required: true,
              message: "Please input the value!",
            },
            { validator: validateBlankSpace },
          ]}
        >
          <InputNumber
            min={1}
            max={1440}
            placeholder='Enter minutes (1-1440)'
            size='large'
            style={{ width: "180px", padding: "3px 0px" }}
            onChange={(e) => {
              setNoteMinutes(e);
            }}
          />
        </Form.Item>
      )}
      <Form.Item
        name='source'
        rules={[
          {
            required: true,
            message: "Please input the value!",
          },
          { validator: validateBlankSpace },
        ]}
      >
        <Select
          showSearch
          placeholder='Select source'
          optionFilterProp='children'
          onChange={(e) => {
            setNoteSource(e);
          }}
          style={{ width: "180px" }}
          // defaultValue={initialValues.source || initialValueType}
          filterOption={(input, option) =>
            (option?.label ?? "")
              .toLowerCase()
              .includes(input.trim().toLowerCase())
          }
          options={noteSourceOptions}
        />
      </Form.Item>
      <Form.Item
        name='category'
        rules={[
          {
            required: true,
            message: "Please input the value!",
          },
          { validator: validateBlankSpace },
        ]}
      >
        <Select
          showSearch
          placeholder='Select category'
          style={{ width: "260px" }}
          optionFilterProp='children'
          onChange={(e) => {
            setNoteCategory(e);
          }}
          // defaultValue={initialValues.category || initialValueType}
          filterOption={(input, option) =>
            (option?.label ?? "")
              .toLowerCase()
              .includes(input.trim().toLowerCase())
          }
          options={noteCategoryOptions}
        />
      </Form.Item>
    </div>
  ) : (
    <div className='mt-2'>Loading MetaFields...</div>
  );
};

export default NotesMetaData;
