import React, { useState } from "react";
import "../index.scss";
import dwdtemp from "../../../images/download_temp.png";
import { Modal, Table, Tooltip } from "antd";
import info from "../../../images/info.png";
import {
  getChildrenInformation,
  getChildrenInformationById,
  deleteChildrenInformation,
  updateChildrenInformation,
  saveChildrenInformation,
} from "../../../actions/childrenInformation";
import { FlashMessage } from "../../../utils/flash_message";
import { useSearchParams } from "react-router-dom";
import { Form, Input, Checkbox } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Constants } from "../../../common/constants";
import { validateBlankSpace } from "../../../validations/validateBlankSpace";
import Loader from "../../Loader";

const ChildrenInformation = (props: any) => {
  const [open, setOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [searchParams] = useSearchParams();
  const [appdata, setAppData] = useState({});
  const [isCurrentlyMarried, setIsCurrentlyMarried] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isPopUpLoader, setisPopUpLoader] = useState(false);
  const dateFormat = "MM/DD/YYYY";
  const [form] = Form.useForm();
  const handleTextLength = (item: any) => {
    if (item != null) {
      return (
        <Tooltip title={item}>
          {item.substring(0, 20) + (item.length > 20 ? "......" : "")}
        </Tooltip>
      );
    }
  };
  const onChangeCurrentlyMarried = (e: any) => {
    setIsCurrentlyMarried(e.target.checked);
  };
  const onChangeDisabled = (e: any) => {
    setIsDisabled(e.target.checked);
  };
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: handleTextLength,
    },
    {
      title: "Middle Name",
      dataIndex: "middleName",
      key: "middleName",
      render: handleTextLength,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: handleTextLength,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return (
          <div className='d-flex'>
            <Tooltip title={"Edit"}>
              <a
                className='mr-2'
                onClick={() => getChildrenInformationByIdApi(id)}
              >
                <EditFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>

            <Tooltip title={"Delete"}>
              <a onClick={() => deleteChildrenInformationApi(id)}>
                <DeleteFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const addUpdateChildrenInformationApi = (e: any) => {
    var param = searchParams.get("id") ?? "0";
    var payload = {
      Id: isAdd ? 0 : e.id,
      FirstName: e.firstName,
      MiddleName: e.middleName,
      LastName: e.lastName,
      EncryptedUserId: param,
      IsCurrentlyMarried: e.isCurrentlyMarried,
      IsDisabled: e.isDisabled,
    };
    if (!isAdd) {
      setisPopUpLoader(true);
      updateChildrenInformation(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Children Information updated Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          getChildrenInformationApi();
        }
      });
    } else {
      setisPopUpLoader(true);
      saveChildrenInformation(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Children Information added Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          props.TemplateCount();
        }
      });
    }
  };

  const deleteChildrenInformationApi = (id: any) => {
    setisPopUpLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteChildrenInformation(id).then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", response.data.responseMessage);
          setisPopUpLoader(false);
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
          setisPopUpLoader(false);
        }
        getChildrenInformationApi();
        props.TemplateCount();
      });
    } else {
      setisPopUpLoader(false);
    }
  };

  const getChildrenInformationByIdApi = (id: any) => {
    setisPopUpLoader(true);
    setOpenEdit(true);
    getChildrenInformationById(id).then((res) => {
      var eduData = res.data.responseData;
      if (res.data.responseCode == 200) {
        setAppData(eduData);
        setIsCurrentlyMarried(eduData.isCurrentlyMarried);
        setIsDisabled(eduData.isDisabled);
        setisPopUpLoader(false);
        form.setFieldsValue(eduData);
      }
    });
  };

  const getChildrenInformationApi = () => {
    setisPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    var param = searchParams.get("id") ?? "0";
    getChildrenInformation(param).then((res) => {
      var child = res.data.responseData;
      if (res.data.responseCode == 200) {
        setDocumentData(child);
        setisPopUpLoader(false);
      } else {
        FlashMessage("error", res.data.responseMessage);
      }
    });
  };
  const formSubmit = () => {
    form.submit();
  };
  const addPopUp = () => {
    setIsAdd(true);
    setOpenEdit(true);
    resetFormValues();
  };
  const resetFormValues = () => {
    var formDefaultValues = {
      firstName: "",
      middleName: "",
      lastName: "",
      isCurrentlyMarried: false,
      isDisabled: false,
    };
    setTimeout(() => {
      setAppData(formDefaultValues);
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };
  return (
    <>
      {openEdit && (
        <Modal
          title={
            isAdd ? "Add Children Information" : "Update Children Information"
          }
          centered
          okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
          open={openEdit}
          onOk={() => (isPopUpLoader ? null : formSubmit())}
          onCancel={() => setOpenEdit(false)}
          width={1000}
        >
          <>
            <div className='col-xl-9 margin-auto'>
              <div className='row'>
                <div className='col-xl-11'>
                  {isPopUpLoader && <Loader></Loader>}
                  {!isPopUpLoader && (
                    <Form
                      className='mt-4'
                      form={form}
                      name='ChildrenInformationForm'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      initialValues={appdata}
                      autoComplete='off'
                      onFinish={addUpdateChildrenInformationApi}
                    >
                      <div className='form-group row margin_mobile'>
                        <Form.Item name='id' />
                        <label className='col-form-label fill_description col-sm-3 des_font'>
                          1. First Name
                        </label>
                        <div className='col-sm-9 p-0'>
                          <Form.Item
                            name='firstName'
                            rules={[
                              {
                                required: true,
                                message: "Please input the value!",
                              },
                              {
                                validator: validateBlankSpace,
                              },
                            ]}
                          >
                            <Input
                              maxLength={100}
                              className={`form-control input_form_sign`}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className='form-group row margin_mobile'>
                        <label className='col-form-label fill_description col-sm-3 des_font'>
                          2. Middle Name
                        </label>
                        <div className='col-sm-9 p-0'>
                          <Form.Item
                            name='middleName'
                            rules={[
                              {
                                validator: validateBlankSpace,
                              },
                            ]}
                          >
                            <Input
                              maxLength={100}
                              className={`form-control input_form_sign`}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className='form-group row margin_mobile'>
                        <label className='col-form-label fill_description col-sm-3 des_font'>
                          3. Last Name
                        </label>
                        <div className='col-sm-9 p-0'>
                          <Form.Item
                            name='lastName'
                            rules={[
                              {
                                required: true,
                                message: "Please input the value!",
                              },
                              {
                                validator: validateBlankSpace,
                              },
                            ]}
                          >
                            <Input
                              maxLength={100}
                              className={`form-control input_form_sign`}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className='form-group row margin_mobile align-items-baseline'>
                        <label className='col-form-label fill_description des_font col-sm-3 mobile_view_chk'>
                          4. Currently Married?
                        </label>
                        <div className='col-sm-1 p-0 mobile_view_chk_input-2 text-left'>
                          <Form.Item
                            name='isCurrentlyMarried'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={onChangeCurrentlyMarried}
                              className={``}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className='form-group row margin_mobile align-items-baseline'>
                        <label className='col-form-label fill_description des_font col-sm-3 mobile_view_chk white-space'>
                          5. Disabled/Handicapped?
                        </label>
                        <div className='col-sm-1 p-0 mobile_view_chk_input-2 text-left'>
                          <Form.Item name='isDisabled' valuePropName='checked'>
                            <Checkbox
                              onChange={onChangeDisabled}
                              className={``}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className='form-group row margin_mobile'>
                        <div className='col-sm-9 p-0'>
                          <Form.Item name='id'>
                            <Input
                              type='hidden'
                              className={`form-control input_form_sign`}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <p className='click_doc text-center'>
                        Click save to enter additional children
                      </p>
                    </Form>
                  )}
                </div>
              </div>
            </div>
          </>
        </Modal>
      )}
      {open && (
        <Modal
          title='Children Information'
          centered
          footer={null}
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={1000}
        >
          {isPopUpLoader && <Loader></Loader>}
          {!isPopUpLoader && (
            <Table
              dataSource={documentData}
              pagination={{
                pageSize: Constants.gridDefaultPageSize,
                showSizeChanger: false,
              }}
              columns={columns}
            />
          )}
        </Modal>
      )}

      <div className='col-md-12 col-xl-4 mt-3 template_panel'>
        <div className='background_sq_boxes height_temp_box p-4 text-center position-relative'>
          <div className='popover__wrapper2 position-absolute'>
            <img className='img-fluid info popover__title' src={info} />
            <div className='popover__content3'>
              <p className='popover__message2'>
                {" "}
                : SSA needs to know about any children you have or dependents
                under 22 that you are responsible for.
              </p>
            </div>
          </div>
          <img className='img-fluid dws_temp' src={dwdtemp} />
          <h5 className='boxes_headings mb-3 ps-5 pe-5'>
            Children Information
          </h5>
          <button
            onClick={getChildrenInformationApi}
            type='button'
            className={`btn btn-primary button_viewtemp`}
          >
            View
          </button>
          <button
            onClick={() => addPopUp()}
            type='button'
            className={`btn btn-primary button_edittemp mt-4`}
          >
            Add
          </button>
          {props.childrenInfoCount == 1 ? (
            <p className='mr_record'>{props.childrenInfoCount} record </p>
          ) : (
            <p className='mr_record'>{props.childrenInfoCount} records</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ChildrenInformation;
