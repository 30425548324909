import {
  GetApplicationDetailsById,
  GetLeads,
  GetClients,
  GetLeadsById,
  UpdateLeads,
  UpdateApplication,
  AddUpdateApplicationFormStepTwo,
  AddUpdateApplicationFormStepThree,
  AddUpdateApplicationFormStepFour,
  AddUpdateApplicationFormStepFive,
  AddUpdateApplicationFormStepSix,
  AddUpdateApplicationFormStepSeven,
  AddUpdateApplicationFormStepEight,
  AddUpdateApplicationFormStepNine,
  AddUpdateApplicationFormStepTen,
  GetHeights,
  GetWeights,
  SearchLeadsandClients,
  UpdateLeadsLeftPanel,
  UpdateClientLeftPanel,
  GetActivityLogByLoginId,
  MapClientWithLead,
  ActivityLogUnmaskingSsn,
  GetPriorMarriageById,
  GetLeadCounts,
  ResetDocuSignStatus,
  SearchMedicationNames,
  AddMedicationName,
  GetMedicalFacilities,
  GetProfileViewDetails,
  GetMedicationNames,
  UpdateMedication,
  DeleteMedication,
  GetFeatureFlags,
  UpdateFeatureFlags,
  SearchClients,
  GetIntakeQuestionnaires,
} from "../../apis/applcation";

export const getActivityLogByLoginId = (Id: any) => {
  return GetActivityLogByLoginId(Id);
};
export const getApplicationDetailsById = (Id: any) => {
  return GetApplicationDetailsById(Id);
};
export const getPriorMarriageById = (Id: any) => {
  return GetPriorMarriageById(Id);
};
export const getLeadsById = (id: any) => {
  return GetLeadsById(id);
};
export const getLeads = () => {
  return GetLeads();
};
export const getLeadsInboundCount = () => {
  return GetLeadCounts();
};

export const searchLeadsandClients = (keyword: any) => {
  return SearchLeadsandClients(keyword);
};
export const searchClients = (keyword: any) => {
  return SearchClients(keyword);
};
export const getClients = (id: any) => {
  return GetClients(id);
};
export function updateLeads(payload: any) {
  return UpdateLeads(payload);
}
export function updateApplication(payload: any) {
  return UpdateApplication(payload);
}
export function addUpdateApplicationFormStepTwo(payload: any) {
  return AddUpdateApplicationFormStepTwo(payload);
}
export function addUpdateApplicationFormStepThree(payload: any) {
  return AddUpdateApplicationFormStepThree(payload);
}
export function addUpdateApplicationFormStepFour(payload: any) {
  return AddUpdateApplicationFormStepFour(payload);
}
export function addUpdateApplicationFormStepFive(payload: any) {
  return AddUpdateApplicationFormStepFive(payload);
}
export function addUpdateApplicationFormStepSix(payload: any) {
  return AddUpdateApplicationFormStepSix(payload);
}
export function addUpdateApplicationFormStepSeven(payload: any) {
  return AddUpdateApplicationFormStepSeven(payload);
}
export function addUpdateApplicationFormStepEight(payload: any) {
  return AddUpdateApplicationFormStepEight(payload);
}
export function addUpdateApplicationFormStepNine(payload: any) {
  return AddUpdateApplicationFormStepNine(payload);
}
export function addUpdateApplicationFormStepTen(payload: any) {
  return AddUpdateApplicationFormStepTen(payload);
}
export function getHeights() {
  return GetHeights();
}
export function getWeights() {
  return GetWeights();
}
export function updateLeadsLeftPanel(payload: any) {
  return UpdateLeadsLeftPanel(payload);
}
export function updateClientLeftPanel(payload: any) {
  return UpdateClientLeftPanel(payload);
}
export function mapClientWithLead(id: any, clientId: any) {
  return MapClientWithLead(id, clientId);
}
export function activityLogUnmaskingSsn(id: any) {
  return ActivityLogUnmaskingSsn(id);
}
export function resetDocuSignStatus(id: any) {
  return ResetDocuSignStatus(id);
}
export const searchMedicationNames = (keyword: any) => {
  return SearchMedicationNames(keyword);
};
export const getMedicationNames = () => {
  return GetMedicationNames();
};

export const getFeatureFlags = () => {
  return GetFeatureFlags();
};

export const updateFeatureFlags = (config: any) => {
  return UpdateFeatureFlags(config);
};
export const updateMedication = (
  id: string,
  name: string,
  approved: boolean
) => {
  return UpdateMedication(id, name, approved);
};
export const deleteMedication = (id: string) => {
  return DeleteMedication(id);
};
export const addMedicationName = (name: string, approved: boolean) => {
  return AddMedicationName(name, approved);
};
export const getMedicalFacilities = (Id: any, keyword: string) => {
  return GetMedicalFacilities(Id, keyword);
};
export function getProfileViewDetails() {
  return GetProfileViewDetails();
}
export const getIntakeQuestionnaires = (Id: any) => {
  return GetIntakeQuestionnaires(Id);
};
